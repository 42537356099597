import { Helmet } from "react-helmet";

export interface HelmetBrandedProps {
  groupName: string;
  logo: string;
}

export const HelmetBranded = (props: HelmetBrandedProps) => {
  const logo = props.logo ? props.logo : "/favicon.ico";
  return (
    <Helmet>
      <title>{`${props.groupName} Platform`}</title>
      <link rel="icon" href={logo} />
    </Helmet>
  );
};
