import { Flex, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/authProvider";
import { Access } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { AccessCard } from "./AccessCard";
import { ViewAccess } from "./ViewAccess";

export const AccessManagement = () => {
  const { getUser, getGroup } = useContext(AuthContext);

  const [streamingService, setStreamingService] = useState<NattyLightService>();
  const [access, setAccess] = useState<Access[]>([]);
  const [selectedAccess, setSelectedAccess] = useState<Access | null>(null);

  useEffect(() => {
    try {
      getUser().then((user: any) => {
        const group = getGroup();
        const streamingService = new NattyLightService(
          user.access_token,
          group
        );
        setStreamingService(streamingService);

        streamingService.getMapping().then((mapping: any) => {
          console.log("mapping: ", mapping);
        });

        streamingService
          .getAccesses()
          .then((access: Access[]) => {
            console.log("access: ", access);
            setAccess(access);
            setSelectedAccess(access[0]);
          })
          .catch((error: any) => {
            console.error(error);
          });
      });
    } catch (error) {
      console.error(error);
    }

    return () => { };
  }, [getGroup, getUser]);

  const refreshAccess = () => {
    streamingService?.getAccesses().then((access: Access[]) => {
      console.log("access: ", access);
      setAccess(access);
    });
  };

  return (
    <Flex
      height="100%"
      alignItems="stretch"
      overflow="hidden"
    >
      <Flex /* Figma="Card View */
        flexDirection="column"
        alignItems="stretch"
        flex={"1 0 800px"}
      >
        <Flex /* Figma Frame 404 */
          flexDirection="column"
          alignItems="flex-start"
          padding="20px"
          gap="12px"
          background="white.900"
          borderBottom="1px solid"
          borderBottomColor="black.300"
          borderRight="1px solid"
          borderRightColor="black.100"
        >
          <Text variant="titleSmall">
            Access Management
          </Text>
        </Flex>
        <Flex
          background="white.500"
          borderRight="1px solid"
          borderRightColor="black.100"
          overflowY={"scroll"}
        >
          <Flex
            alignItems="stretch"
            flexWrap={"wrap"}
            padding="20px"
            gap="12px"
            overflowY={"scroll"}
          >
            {access &&
              access.map((access: Access) => (
                <AccessCard
                  key={access.id}
                  access={access}
                  active={selectedAccess?.id === access.id}
                  onClick={() => {
                    setSelectedAccess(access);
                  }}
                />
              ))}
          </Flex>
        </Flex>
      </Flex>

      {streamingService && selectedAccess && (
        <ViewAccess
          id={selectedAccess.id}
          client={streamingService}
          onDone={() => {
            refreshAccess();
          }}
        />
      )}
    </Flex>
  );
};
