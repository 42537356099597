import {
  Button,
  Flex,
  Text,
  VStack,
  Stack,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { NewUser } from "./newUser";
import { User } from "./user";
import { SearchInput } from "../searchInput";
import { AuthContext } from "../../providers/authProvider";
import { NewOrganizationUser } from "./newOrganizationUser";
import { UserAccess } from "./userAccess";
import { BulkUserEdit } from "./bulkUserEdit";
import { BulkOrganizationUserEdit } from "./bulkOrganizationUserEdit";
import { ImportUsers } from "./importUsers";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";

interface UsersProps {
  group: string;
  orgAdmin?: boolean;
}

export const Users = ({ group, orgAdmin = false }: UsersProps) => {
  const [users, setUsers] = React.useState<any>([]);
  const [usersSkip, setUsersSkip] = React.useState<number>(0);
  const usersTop = 10;
  const [usersShowLoadMore, setUsersShowLoadMore] =
    React.useState<boolean>(true);
  const [orgs, setOrgs] = React.useState<any>([]);
  const [newUserCreating, setNewUserCreating] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
  const [selectedOrg, setSelectedOrg] = React.useState<any>(null);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState<any>(
      orgAdmin ? null : { name: "allUsers", label: "All Users" }
    );
  const [showSecondaryColumn, setShowSecondaryColumn] = React.useState(false);
  const {
    searchUsers,
    createOrganizationUser,
    searchOrganizationUsers,
    getUsers,
    getOrganizationUsers,
    getOrganizations,
    getMyOrganizations,
  } = React.useContext(AuthContext);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [userToAddToOrg, setUserToAddToOrg] = React.useState<any>(null);
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [importUsers, setImportUsers] = React.useState(false);
  const [userSearchValue, setUserSearchValue] = React.useState("");
  const [userSearchHasFocus, setUserSearchHasFocus] = React.useState(false);
  const [addUserHasFocus, setAddUserHasFocus] = React.useState(false);

  const primaryColumnOptionSelected = async (selectedOption: any) => {
    setImportUsers(false);
    if (selectedOption.name === "allUsers") {
      setSelectedOrg(null);
      setSelectedUsers(null);
      setUsersSkip(0);
      setUsersShowLoadMore(true);

      getUsers(usersSkip, usersTop).then((res: any) => {
        const users = res.data.data;

        setUsers(users);
        setPrimaryColumnSelectedOption(selectedOption);
        setNewUserCreating(false);

        if (users.length > 0) {
          setSelectedUsers([users[0]]);
        }
      });
    } else {
      console.log(selectedOption);
      setUsers([]);
      setPrimaryColumnSelectedOption(selectedOption);
      setShowSecondaryColumn(false);
      setSelectedOrg(selectedOption);
      setSelectedUsers(null);

      const res: any = await getOrganizationUsers(selectedOption.id);
      const users = res.data.data;
      setUsers(users);
      if (users.length > 0) {
        setSelectedUsers([users[0]]);
      }

      if (users.length < usersTop) {
        setUsersShowLoadMore(false);
      }
    }
  };
  const userSelected = (user: any) => {
    setImportUsers(false);
    setSelectedUsers([user]);
    setNewUserCreating(false);
  };
  const addUserSelected = (user: any) => {
    if (selectedUsers.some((u: any) => u.id === user.id)) {
      setSelectedUsers(selectedUsers.filter((u: any) => u.id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    setNewUserCreating(false);
  };
  const primaryColumnOptions = [{ name: "allUsers", label: "All Users" }];

  const findUsers = async (term: string, org?: any) => {
    if (term) {
      setLoading(true);
      let resp;
      if (org) {
        resp = await searchOrganizationUsers(org.id, term);
      } else {
        resp = await searchUsers(term);
      }

      setOptions(resp.data.data);

      setLoading(false);
    } else {
      setOptions([]);
    }
  };

  const handleUserSearchChange = async (e: any) => {
    if (!e.target.value) {
      clearSearch();
      return;
    }

    const term = e.target.value;
    setUserSearchValue(term);

    const res = await searchUsers(term);
    const users = res.data.data.like;
    if (users) {
      console.log(users);
      setUsers(users);
      if (users.length > 0) {
        setSelectedUsers([users[0]]);
      }
    }
  };

  const clearSearch = () => {
    setUserSearchValue("");

    getUsers(usersSkip, usersTop).then((res: any) => {
      const newUsers = res.data.data;
      setUsers((users: any) => [...users, ...newUsers]);
      if (newUsers.length > 0 && usersSkip === 0) {
        setSelectedUsers([newUsers[0]]);
      }
      if (newUsers.length < usersTop) {
        setUsersShowLoadMore(false);
      }
    });
  };

  useEffect(() => {
    if (!orgAdmin) {
      getUsers(usersSkip, usersTop).then((res: any) => {
        const newUsers = res.data.data;
        setUsers((users: any) => [...users, ...newUsers]);
        if (newUsers.length > 0 && usersSkip === 0) {
          setSelectedUsers([newUsers[0]]);
        }
        if (newUsers.length < usersTop) {
          setUsersShowLoadMore(false);
        }
      });

      getOrganizations().then((res: any) => {
        const orgs = res.data.data;
        setOrgs(orgs);
      });
    } else {
      getMyOrganizations().then((res: any) => {
        const orgs = res.data.data.organizations;
        console.log(orgs);
        setOrgs(orgs);
      });
    }
  }, [
    usersTop,
    usersSkip,
    orgAdmin,
    getUsers,
    getOrganizations,
    getMyOrganizations,
  ]);

  useEffect(() => {
    if (selectedOrg) {
      getOrganizationUsers(selectedOrg).then((res: any) => {
        const newUsers = res.data.data;
        setUsers((users: any) => [...users, ...newUsers]);
        if (newUsers.length > 0 && usersSkip === 0) {
          setSelectedUsers([newUsers[0]]);
        }
        if (newUsers.length < usersTop) {
          setUsersShowLoadMore(false);
        }
      });
    }
  }, [getOrganizationUsers, selectedOrg, usersSkip]);

  useEffect(() => {
    console.log("addUserHasFocus: ", addUserHasFocus);
  }, [addUserHasFocus]);

  // handle command / control A
  const handleKeyboard = ({ repeat, metaKey, ctrlKey, key }: KeyboardEvent) => {
    if (repeat) return;

    // Handle both, `ctrl` and `meta`.
    if (
      (metaKey || ctrlKey) &&
      key === "a" &&
      !userSearchHasFocus &&
      !addUserHasFocus
    ) {
      setSelectedUsers(users);
      (document.activeElement as HTMLElement).blur();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyboard);

    // Important to remove the listeners.
    return () => {
      return document.removeEventListener("keydown", handleKeyboard);
    };
  });

  return (
    <Flex h="100%" alignItems="stretch" overflow={"hidden"}>
      <VStack layerStyle="windowmenu" alignItems="stretch" flex="1" spacing="0">
        <Text variant="windowheader">Users</Text>
        {!orgAdmin
          ? primaryColumnOptions.map((option) => {
              return (
                <Box width={"100%"}>
                  <WindowColumnPrimaryButton
                    key={option.name}
                    onClick={() => {
                      const optionSelected = {
                        name: option.name,
                        label: option.label,
                      };
                      primaryColumnOptionSelected(optionSelected);
                    }}
                    active={primaryColumnSelectedOption?.name === option.name}
                  >
                    {option.label}
                  </WindowColumnPrimaryButton>
                  <Text variant="windowsubheader">By Organization</Text>
                </Box>
              );
            })
          : primaryColumnOptions
              .filter((p) => p.label === "Organizations")
              .map((option) => {
                return (
                  <WindowColumnPrimaryButton
                    key={option.name}
                    onClick={() => {
                      const optionSelected = {
                        name: option.name,
                        label: option.label,
                      };
                      primaryColumnOptionSelected(optionSelected);
                    }}
                    active={primaryColumnSelectedOption?.name === option.name}
                  >
                    {option.label}
                  </WindowColumnPrimaryButton>
                );
              })}
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          width={"100%"}
          paddingTop={"4px"}
        >
          {orgs.map((option: any) => {
            return (
              <Box key={option.name}>
                <WindowColumnPrimaryButton
                  onClick={() => {
                    primaryColumnOptionSelected(option);
                  }}
                  active={primaryColumnSelectedOption?.name === option.name}
                >
                  <Text
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.name}
                  </Text>
                </WindowColumnPrimaryButton>
              </Box>
            );
          })}
        </VStack>
      </VStack>
      {showSecondaryColumn && (
        <VStack layerStyle="windowsubmenu" alignItems="flex-start">
          <Text variant="windowsubmenuheader">
            {primaryColumnSelectedOption.label}
          </Text>
        </VStack>
      )}
      <VStack
        flex={"1"}
        alignItems={"stretch"}
        spacing={"0"}
        overflow={"hidden"}
      >
        <Flex layerStyle="windowheader">
          {selectedOrg && <Text>{selectedOrg.name} Users</Text>}
          {!selectedOrg && <Text>All Users</Text>}
          {/*<Input
            placeholder="Search Users"
            bg="black.800"
            borderRadius="40px"
            border="hidden"
            h="25px"
            w="174px"
            textColor="white.500"
          />*/}
        </Flex>
        {users && (
          <Flex h="100%" alignItems="stretch" overflow={"hidden"}>
            <Stack
              layerStyle="windowsubmenu"
              alignItems="stretch"
              alignContent="stretch"
              spacing={"0"}
            >
              {primaryColumnSelectedOption?.name === "allUsers" && (
                <Stack
                  background="white.900"
                  padding="8px"
                  borderBottom="1px solid"
                  borderBottomColor="black.300"
                  width="100%"
                >
                  <InputGroup size="md">
                    <Input
                      size={"sm"}
                      type="text"
                      id="userSearch"
                      name="userSearch"
                      placeholder="Search Users"
                      value={userSearchValue}
                      onChange={handleUserSearchChange}
                      onFocus={() => setUserSearchHasFocus(true)}
                      onBlur={() => setUserSearchHasFocus(false)}
                    />
                    {userSearchValue && (
                      <InputRightElement>
                        <IconButton
                          aria-label="Clear"
                          size="xs"
                          icon={<CloseIcon />}
                          onClick={clearSearch}
                          variant="ghost"
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>

                  <Menu>
                    <Stack background="white.900">
                      <MenuButton
                        as={Button}
                        variant="primaryBlue"
                        size="sm"
                        width="100%"
                        textAlign="left"
                        rightIcon={<ChevronDownIcon />}
                      >
                        Add User
                      </MenuButton>
                    </Stack>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setNewUserCreating(true);
                          setSelectedUsers([]);
                        }}
                      >
                        Create new user
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setImportUsers(true);
                          setSelectedUsers([]);
                        }}
                      >
                        Import users
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Stack>
              )}
              {primaryColumnSelectedOption?.name !== "allUsers" &&
                !orgAdmin && (
                  <Stack
                    background="white.900"
                    padding="8px"
                    borderBottom="1px solid"
                    borderBottomColor="black.300"
                  >
                    <SearchInput
                      loading={loading}
                      options={options}
                      requests={findUsers}
                      onClickFunction={(user: any) => {
                        if (user.id) {
                          setUserToAddToOrg(user);
                        } else {
                          setNewUserEmail(user);
                          setNewUserCreating(true);
                          setSelectedUsers(null);
                        }
                        setOptions([]);
                      }}
                      placeholder="Add a user"
                      onFocus={() => setAddUserHasFocus(true)}
                      onBlur={() => setAddUserHasFocus(false)}
                    />
                    {userToAddToOrg && (
                      <Button
                        variant="primaryBlue"
                        size="sm"
                        onClick={async () => {
                          console.log(userToAddToOrg);
                          const userBody = {
                            email: userToAddToOrg.email,
                          };

                          const userResult: any = await createOrganizationUser(
                            selectedOrg.id,
                            userBody
                          );

                          console.log(userResult);

                          const res: any = await getOrganizationUsers(
                            selectedOrg.id
                          );
                          const users = res.data.data;
                          setUsers(users);
                        }}
                      >
                        Add User
                      </Button>
                    )}
                  </Stack>
                )}
              {primaryColumnSelectedOption?.name !== "allUsers" && orgAdmin && (
                <Stack
                  background="white.900"
                  padding="8px"
                  borderBottom="1px solid"
                  borderBottomColor="black.300"
                >
                  <SearchInput
                    loading={loading}
                    options={options}
                    requests={findUsers}
                    org={selectedOrg}
                    onClickFunction={(user: any) => {
                      if (user.id) {
                        setUserToAddToOrg(user);
                      } else {
                        setNewUserEmail(user);
                        setNewUserCreating(true);
                        setSelectedUsers(null);
                      }
                      setOptions([]);
                    }}
                    placeholder="Search for a user..."
                  />
                  {userToAddToOrg && (
                    <Button
                      variant="primaryBlue"
                      size="sm"
                      onClick={async () => {
                        console.log(userToAddToOrg);
                        const userBody = {
                          email: userToAddToOrg.email,
                        };

                        const userResult: any = await createOrganizationUser(
                          selectedOrg.id,
                          userBody
                        );

                        console.log(userResult);

                        const res: any = await getOrganizationUsers(
                          selectedOrg.id
                        );
                        const users = res.data.data;
                        setUsers(users);
                      }}
                    >
                      Add User
                    </Button>
                  )}
                </Stack>
              )}
              <Stack overflowY={"scroll"} padding={"8px"} spacing={"1"}>
                {newUserCreating && (
                  <WindowColumnSecondaryButton
                    key={"newUser"}
                    onClick={() => {
                      //this.primaryColumnOptionSelected(option);
                    }}
                    active={true}
                  >
                    New User
                  </WindowColumnSecondaryButton>
                )}
                {users.length > 0 ? (
                  users.map((user: any) => {
                    return (
                      <WindowColumnSecondaryButton
                        //key={`${user.id}-${selectedUser?.id === user.id}`}
                        key={user.id}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          if (e.metaKey || e.ctrlKey) {
                            addUserSelected(user);
                          } else {
                            userSelected(user);
                          }
                        }}
                        active={
                          selectedUsers &&
                          selectedUsers.some((u: any) => u.id === user.id)
                        }
                      >
                        <Text
                          width={"100%"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                        >
                          {user.email}
                        </Text>
                      </WindowColumnSecondaryButton>
                    );
                  })
                ) : (
                  <Text variant={"bodySmall"}>No users found</Text>
                )}
                {usersShowLoadMore && users.length > 0 && !userSearchValue && (
                  <Button
                    variant="primaryBlack"
                    size="sm"
                    alignSelf={"center"}
                    width="100%"
                    onClick={() => {
                      setUsersSkip(usersSkip + usersTop);
                    }}
                  >
                    Load more
                  </Button>
                )}
              </Stack>
            </Stack>
            <VStack
              alignItems={"stretch"}
              layerStyle="windowcontentarea"
              spacing={"0"}
              gap={"24px"}
            >
              {selectedUsers && selectedUsers.length === 1 && (
                <>
                  <User
                    key={selectedUsers[0].id}
                    group={group}
                    org={selectedOrg}
                    user={selectedUsers[0]}
                    onUserRemoved={() => {
                      setUsers(
                        users.filter((u: any) => u.id !== selectedUsers[0].id)
                      );
                      setSelectedUsers([]);
                    }}
                    onUserDeleted={() => {
                      setUsers(
                        users.filter((u: any) => u.id !== selectedUsers[0].id)
                      );
                      setSelectedUsers([]);
                    }}
                    agAdminView={
                      primaryColumnSelectedOption?.name === "allUsers"
                    }
                  />
                </>
              )}
              {selectedUsers && selectedUsers.length > 1 && !selectedOrg && (
                <>
                  <Text variant="contentheader">Bulk Actions</Text>
                  <BulkUserEdit users={selectedUsers} />
                </>
              )}
              {selectedUsers && selectedUsers.length > 1 && selectedOrg && (
                <>
                  <Text variant="contentheader">Bulk Actions</Text>
                  <BulkOrganizationUserEdit
                    org={selectedOrg}
                    users={selectedUsers}
                  />
                </>
              )}
              {newUserCreating && selectedOrg && (
                <NewOrganizationUser
                  newUserEmail={newUserEmail}
                  org={selectedOrg}
                  result={(user: any) => {
                    if (user) {
                      console.log("result: ", user);
                      setUsers([...users, user]);
                      setNewUserCreating(false);
                      setSelectedUsers([user]);
                    } else {
                      setNewUserCreating(false);
                    }
                  }}
                />
              )}
              {newUserCreating && !selectedOrg && (
                <NewUser
                  newUserEmail={newUserEmail}
                  org={selectedOrg}
                  result={(user: any) => {
                    if (user) {
                      console.log("result: ", user);
                      setUsers([...users, user]);
                      setNewUserCreating(false);
                      setSelectedUsers([user]);
                    } else {
                      setNewUserCreating(false);
                    }
                  }}
                />
              )}
              {importUsers && (
                <ImportUsers
                  result={(newUsers: any) => {
                    if (newUsers) {
                      console.log("result: ", newUsers);
                      setUsers([...users, ...newUsers]);
                      setImportUsers(false);
                    } else {
                      setImportUsers(false);
                    }
                  }}
                />
              )}
            </VStack>
            {selectedUsers && selectedUsers.length === 1 && selectedOrg && (
              <>
                <VStack
                  alignItems={"stretch"}
                  layerStyle="windowcontentarea"
                  spacing={"0"}
                  gap={"24px"}
                  flex={"2"}
                >
                  <Text variant="contentheader">Access</Text>
                  <UserAccess
                    key={selectedUsers[0].id}
                    user={selectedUsers[0]}
                    org={selectedOrg}
                  />
                </VStack>
              </>
            )}
          </Flex>
        )}
      </VStack>
    </Flex>
  );
};
