import { Subject } from "../services/natty-lite/Models";
import { RequestAccessViewType } from "../types";

export const isWildcard = (subject: string) => {
  const testChars = /[*>]/g;
  if (testChars.test(subject)) {
    console.log("wildcard detected");
    return true;
  }
  console.log("wildcard not detected");
  return false;
};

export const isValidStreamName = (name: string) => {
  const testChars = /^[a-zA-Z0-9_-]+$/g;
  if (testChars.test(name)) {
    console.log("Valid stream name");
    return true;
  }
  console.log("Invalid stream name");
  return false;
};

export const isValidSubjectName = (name: string) => {
  if (name.slice(0, 1) === ".") {
    return false;
  }

  const testChars = /^[A-Za-z0-9\-_>*.]+/g;
  if (testChars.test(name)) {
    return true;
  }

  return false;
};

export const getRequestAccessViewType = (
  subjects: Subject[]
): RequestAccessViewType => {
  const subjectNames = subjects.map((s) => s.name);
  const hasWildCardSubject = subjectNames.find((s) => isWildcard(s));

  let queueGroups: string[] = [];
  subjects.forEach((s) => (queueGroups = [...queueGroups, ...s.queueGroups]));

  if (queueGroups.length === 0) {
    // 1
    if (!hasWildCardSubject) {
      return RequestAccessViewType.ReadOnly;
    }
    // 4,7,10
    return RequestAccessViewType.SubjectOnly;
  } else {
    // 2,3,8,9
    if (!hasWildCardSubject) {
      return RequestAccessViewType.QueueGroupOnly;
    } else {
      // 5,6,11,12
      return RequestAccessViewType.SubjectAndQueueGroup;
    }
  }
};
