import React, { useContext, useEffect } from "react";
import { JsonPointer } from "json-ptr";

import {
  FormControl,
  FormLabel,
  Text,
  IconButton,
  useClipboard,
  Textarea,
  HStack,
  Button,
  VStack,
  useCheckboxGroup,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { AuthContext } from "../../providers/authProvider";
import { ArrowBackIcon, ArrowForwardIcon, CopyIcon } from "@chakra-ui/icons";
import { CheckboxCard } from "../checkboxCard";
import { DeleteIcon } from "@chakra-ui/icons";

interface RoleProps {
  productId: string;
  roleData: any;
  roleDeleted: any;
  roleUpdated: any;
}

export const Role = ({ productId, roleData, roleDeleted, roleUpdated }: RoleProps) => {
  const [role, setRole] = React.useState(roleData);
  const [name, setName] = React.useState(roleData.name);
  const [description, setDescription] = React.useState(roleData.description);
  const [availablePermissions, setAvailablePermissions] = React.useState(
    [] as any
  );
  const [availablePermissionChecked, setAvailablePermissionChecked] =
    React.useState([] as any);
  const [usedPermissions, setUsedPermissions] = React.useState([] as any);
  const [usedPermissionChecked, setUsedPermissionChecked] = React.useState(
    [] as any
  );
  const { onCopy: onCopyId } = useClipboard(roleData?.id);
  const { onCopy: onCopyCodedId } = useClipboard(roleData?.codedId);

  const { updateRole, getPermissionsForRole, getPermissions, deleteRole } =
    useContext(AuthContext);

  const { getCheckboxProps: getAvailableCheckboxProps } = useCheckboxGroup({
    onChange: setAvailablePermissionChecked,
  });

  const { getCheckboxProps: getUsedCheckboxProps } = useCheckboxGroup({
    onChange: setUsedPermissionChecked,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = React.useState<any>(null);

  useEffect(() => {
    getPermissionsForRole(productId, role.id)
      .then((res) => {
        const usedPermissions = res.data.data;
        console.log(usedPermissions);
        setUsedPermissions(usedPermissions);

        getPermissions(productId)
          .then((res) => {
            const availablePermissions = res.data.data;
            const filtered = availablePermissions.filter((a: any) => {
              return !usedPermissions.find((u: any) => {
                return u.id === a.id;
              });
            });

            setAvailablePermissions(filtered);
          })
          .catch((error: any) => {
            console.log(error);
          });
      })
      .catch((error) => console.log(error));
  }, [getPermissions, getPermissionsForRole, productId, role]);

  const updateRoleData = async (key: string, value: any) => {
    try {
      const exists = JsonPointer.get(role, key);
      const op = exists ? "replace" : "add";

      const updatedRole = await updateRole(productId, role.id, op, key, value);
      setRole(updatedRole.data.data);
      roleUpdated(updatedRole.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRole = async () => {
    try {
      await deleteRole(productId, role.id);
      roleDeleted(role.id);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data);
      onOpen();
    }
  }

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="name">Role Name</FormLabel>
        <FormInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => updateRoleData("/name", e.target.value)}
          id="name"
          placeholder=""
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Description (optional)</FormLabel>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={(e) => updateRoleData("/description", e.target.value)}
          id="description"
          placeholder=""
          size={"sm"}
        />
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="name">ID</FormLabel>
          <HStack>
            <Text>{role.id}</Text>
            <IconButton
              aria-label="Copy ID"
              icon={<CopyIcon />}
              onClick={onCopyId}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="name">Coded ID</FormLabel>
          <HStack>
            <Text>{role.codedId}</Text>
            <IconButton
              aria-label="Copy Coded ID"
              icon={<CopyIcon />}
              onClick={onCopyCodedId}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>
      <Text
        variant="contentsubheader"
        textTransform={"capitalize"}
      >
        {role.name} Permissions
      </Text>
      <HStack
        flex={"1"}
        alignItems={"stretch"}
        overflowY={"hidden"}
        minHeight={"360px"}
      >
        <VStack
          alignItems={"stretch"}
          flex={"1"}
        >
          <Text
            variant="labelSmall"
            textTransform={"uppercase"}
          >
            All Permissions
          </Text>
          <VStack
            flex={"1"}
            borderRadius={"12px"}
            border={"1px solid"}
            borderColor={"neutral.300"}
            backgroundColor={"neutral.200"}
            overflowY={"scroll"}
            spacing={"1"}
            padding={"4px"}
            alignItems={"stretch"}
          >
            {availablePermissions &&
              availablePermissions.map((permission: any) => {
                const checkbox = getAvailableCheckboxProps({
                  value: `${permission.id} ${permission.coded}`,
                });
                return (
                  <CheckboxCard key={permission.id} {...checkbox}>
                    <Text
                      variant={"labelMedium"}
                      textTransform={"capitalize"}
                    >
                      {permission.action} {permission.ownershipRequired ? <Text display="inline" textTransform={"none"}>your</Text> : <Text display="inline" textTransform={"none"}>any</Text>} {permission.target}</Text>
                    <Text
                      variant={"bodySmall"}
                    >
                      {permission.description}</Text>
                  </CheckboxCard>
                );
              })}
          </VStack>
        </VStack>
        <VStack
          alignSelf={"center"}
        >
          <Button
            variant={"primaryBlue"}
            onClick={async () => {
              if (
                availablePermissionChecked &&
                availablePermissionChecked.length > 0
              ) {
                const updatedPermissions = [
                  ...role.permissions,
                  ...availablePermissionChecked,
                ];
                const res = await updateRole(
                  productId,
                  role.id,
                  "replace",
                  "/permissions",
                  updatedPermissions
                );
                setRole(res.data.data);
                setAvailablePermissionChecked([]);
              }
            }}
            disabled={availablePermissionChecked.length < 1}
            leftIcon={<ArrowForwardIcon />}
            iconSpacing={"0"}
          >
          </Button>
          <Button
            variant={"primaryBlue"}
            onClick={async () => {
              if (usedPermissionChecked && usedPermissionChecked.length > 0) {
                const updatedPermissions = role.permissions.filter(
                  (x: string) => !usedPermissionChecked.includes(x)
                );

                const res = await updateRole(
                  productId,
                  role.id,
                  "replace",
                  "/permissions",
                  updatedPermissions
                );
                setRole(res.data.data);
                setUsedPermissionChecked([]);
              }
            }}
            disabled={usedPermissionChecked.length < 1}
            leftIcon={<ArrowBackIcon />}
            iconSpacing={"0"}
          >
          </Button>
        </VStack>
        <VStack
          alignItems={"stretch"}
          flex={"1"}
        >
          <Text
            variant="labelSmall"
            textTransform={"uppercase"}
          >
            Applied Permissions
          </Text>
          <VStack
            flex={"1"}
            borderRadius={"12px"}
            border={"1px solid"}
            borderColor={"neutral.300"}
            backgroundColor={"neutral.200"}
            overflowY={"scroll"}
            spacing={"1"}
            padding={"4px"}
            alignItems={"stretch"}
          >
            {usedPermissions &&
              usedPermissions.map((permission: any) => {
                const checkbox = getUsedCheckboxProps({
                  value: `${permission.id} ${permission.coded}`,
                });
                return (
                  <CheckboxCard key={permission.id} {...checkbox}>
                    <Text
                      variant={"labelMedium"}
                      textTransform={"capitalize"}
                    >
                      {permission.action} {permission.ownershipRequired ? <Text display="inline" textTransform={"none"}>your</Text> : <Text display="inline" textTransform={"none"}>any</Text>} {permission.target}</Text>
                    <Text
                      variant={"bodySmall"}
                    >
                      {permission.description}</Text>
                  </CheckboxCard>
                );
              })}
          </VStack>
        </VStack>
      </HStack>
      <Stack alignItems={"flex-start"}>
        {
          !roleData?.core && (
            <Button
              leftIcon={<DeleteIcon />}
              variant="primaryRed"
              onClick={handleDeleteRole}
            >
              Delete Role
            </Button>
          )
        }
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{error?.message}</Text>
            {error?.data &&
              error.data.map((error: any) => (
                <Text key={error.id}>{error.name}</Text>
              ))}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
