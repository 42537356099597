import {
  useTab,
  //* useMultiStyleConfig,
  Button,
  VStack,
  Text,
} from "@chakra-ui/react";
import { forwardRef } from "react";

const CustomTabComponent = (props: any, ref: any) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps["aria-selected"];

  // 2. Hook into the Tabs `size`, `variant`, props
  //* const styles = useMultiStyleConfig("Tabs", tabProps);

  return (
    <VStack>
      <Button
        variant={"unstyled"}
        size={"sm"}
        transition={"color 100ms ease-in-out"}
        borderRadius={"0"}
        boxSizing={"content-box"}
        _hover={{
          color: "black.800",
        }}
        _focus={{
          borderBottom: "2px solid",
          borderBottomColor: "black.700",
          color: "black.900",
        }}
        borderBottom={
          isSelected
            ? "2px solid"
            : "none"
        }
        borderBottomColor={
          isSelected
            ? "black700"
            : "none"
        }
        color={
          isSelected
            ? "black.900"
            : "black.700"
        }
        {...tabProps}
      >
        <Text variant="bodyMedium">{tabProps.children}</Text>
      </Button>
    </VStack>
  );
};

export const CustomTab = forwardRef(CustomTabComponent);
