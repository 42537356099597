import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Text,
  Switch,
  useRadioGroup,
  useClipboard,
  Flex,
  IconButton,
  Stack,
  Input,
} from "@chakra-ui/react";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { Formik, Form, Field, FieldArray } from "formik";
import React, { useEffect, useContext } from "react";
import { FormInput } from "../FormInput";
import { FormSelect } from "../FormSelect";
import { RadioCard } from "../radioCard";
import { CopyIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../providers/authProvider";

export const Client = ({ group, client, result, clientDeleted }) => {
  const [initialValues, setInitialValues] = React.useState({});
  const [newGrantType, setNewGrantType] = React.useState("");
  const [newRedirectUri, setNewRedirectUri] = React.useState("");
  const [newPostLogoutRedirectUri, setNewPostLogoutRedirectUri] =
    React.useState("");
  const [newWebMessageUri, setNewWebMessageUri] = React.useState("");
  const [newRequestUri, setNewRequestUri] = React.useState("");
  const [error, setError] = React.useState(null);
  const [label, setLabel] = React.useState("login");
  const [authGroup, setAuthGroup] = React.useState(null);
  const [clientSecret, setClientSecret] = React.useState("");
  const { onCopy: onCopyClientId } = useClipboard(client?.client_id);
  const { onCopy: onCopyClientSecret } = useClipboard(clientSecret);
  const {
    updateClient,
    getClientSecret,
    createClient,
    deleteClient,
    getAuthGroup,
  } = useContext(AuthContext);

  const labelClicked = (value) => {
    setLabel(value);

    if (client) return;

    let newInitialValues = { ...initialValues };

    switch (value) {
      case "login":
        newInitialValues.client_name = "New Login Client";
        break;
      case "api":
        newInitialValues.client_name = "New API Client";
        break;
      case "app":
        newInitialValues.client_name = "New App Client";
        newInitialValues.grant_types = [
          "authorization_code",
          "refresh_token",
          "client_credentials",
          "implicit",
        ];
        newInitialValues.token_endpoint_auth_method = "client_secret_post";
        newInitialValues.response_types = "code";
        break;
      case "custom":
        newInitialValues.client_name = "New Custom Client";
        break;
      default:
        break;
    }

    setInitialValues(newInitialValues);
  };

  const pkceClicked = () => {
    let newInitialValues = { ...initialValues };

    newInitialValues.application_type = "web";
    newInitialValues.grant_types = ["authorization_code", "refresh_token"];
    newInitialValues.response_types = "code";
    newInitialValues.token_endpoint_auth_method = "none";
    newInitialValues.introspection_endpoint_auth_method = "none";
    newInitialValues.revocation_endpoint_auth_method = "none";

    setInitialValues(newInitialValues);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "label",
    defaultValue: client ? client.client_label : "login",
    onChange: labelClicked,
  });

  const radioGroup = getRootProps();

  const CLIENT_APPLICATION_TYPES = ["web", "native"];

  const CLIENT_RESPONSE_TYPES = [
    "none",
    "code", // authorization code
    "id_token", // implicit
    "token id_token", // hybrid
    "code id_token", // hybrid
    "code token", // hybrid
    "code id_token token", // hybrid
  ];

  const TOKEN_ENDPOINT_AUTH_METHODS_SUPPORTED = [
    "client_secret_basic",
    "client_secret_jwt",
    "client_secret_post",
    "private_key_jwt",
    "none",
  ];

  const INTROSPECTION_ENDPOINT_AUTH_METHODS_SUPPORTED = [
    "client_secret_basic",
    "client_secret_jwt",
    "client_secret_post",
    "private_key_jwt",
    "none",
  ];

  const REVOCATION_ENDPOINT_AUTH_METHODS_SUPPORTED = [
    "client_secret_basic",
    "client_secret_jwt",
    "client_secret_post",
    "private_key_jwt",
    "none",
  ];

  /* const CLIENT_GRANT_TYPES = [
    "authorization_code",
    "implicit",
    "refresh_token",
    "client_credentials",
  ];*/

  const CLIENT_LABELS = ["login", "api", "app", "custom"];

  useEffect(() => {
    console.log("client", client);
    if (client) {
      if (Array.isArray(client.response_types)) {
        client.response_types = client.response_types.join(" ");
      }
      setInitialValues(client);
      setLabel(client.client_label);
    } else {
      setInitialValues({
        client_name: "New Login Client",
        grant_types: [],
        response_types: "",
        redirect_uris: [],
        client_skip_consent: false,
        client_optional_skip_logout_prompt: false,
        post_logout_redirect_uris: [],
        web_message_uris: [],
        request_uris: [],
        application_type: "web",
      });
    }

    getAuthGroup().then((res) => {
      const group = res.data.data;
      setAuthGroup(group);
    });
  }, [client, getAuthGroup, group]);

  const showClientSecret = async () => {
    try {
      const clientResult = await getClientSecret(client);
      setClientSecret(clientResult.data.client_secret);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleDeleteClient = async () => {
    try {
      const resp = await deleteClient(client);
      clientDeleted();
      console.log(resp);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if (!values.client_name) {
          errors.client_name = "Required";
        }
        if (values.grant_types.length < 1) {
          errors.grant_types = "Required";
        }
        if (
          values.redirect_uris.length < 1 &&
          !values.grant_types.includes("client_credentials")
        ) {
          errors.redirect_uris = "Required";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        console.log("values: ", values);

        setSubmitting(true);
        setError(null);

        try {
          const newClient = values;
          if (!Array.isArray(values.response_types)) {
            newClient.response_types =
              values.response_types === ""
                ? []
                : values.response_types.split(" ");
          } else {
            newClient.response_types = values.response_types;
          }
          newClient.client_label = label;

          if (newClient.redirect_uris.length === 0) {
            //newClient.redirect_uris = null;
          }

          console.log(newClient);

          let newClientResponse;
          if (client) {
            const clientResult = await updateClient(newClient);
            newClientResponse = clientResult.data;
          } else {
            const clientResult = await createClient(newClient);
            newClientResponse = clientResult.data;
          }

          console.log(newClientResponse);

          result(newClientResponse);
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log("err.response.data.message", err.response.data.message);
            setError(err.response.data.message);
          }
        }

        setSubmitting(false);
      }}
    >
      {(props) => (
        <Form>
          {client &&
          (client.client_name === "UE Core" ||
            client.client_name === "Core EOS") &&
          authGroup ? (
            <VStack spacing={6} padding="10px" alignItems="flex-start">
              <Text variant="contentheader">Federation Settings</Text>
              {(!authGroup?.config?.federate?.oidc ||
                !authGroup?.config?.federate?.oauth2 ||
                (authGroup?.config?.federate.oidc.length === 0 &&
                  authGroup?.config?.federate.oauth2.length === 0)) && (
                <Text size="14px" textAlign="left">
                  No federation settings are configured for this group.
                </Text>
              )}
              {(authGroup?.config?.federate?.oidc ||
                authGroup?.config?.federate?.oauth2) &&
                [
                  ...authGroup?.config?.federate?.oidc.map((p) => ({
                    ...p,
                    type: "oidc",
                  })),
                  ...authGroup?.config?.federate?.oauth2.map((p) => ({
                    ...p,
                    type: "oauth2",
                  })),
                ]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((provider) => {
                    return (
                      <Field
                        name="client_federation_options"
                        key={provider.name}
                      >
                        {({ field }) => (
                          <FormControl display="flex" flexDirection="row">
                            <FormLabel htmlFor={provider.name}>
                              {provider.name}
                            </FormLabel>
                            <Switch
                              marginTop="2px"
                              {...field}
                              id={provider.name}
                              defaultChecked={field.value?.includes(
                                `${provider.type}.${provider.provider}.${provider.name}`
                              )}
                              value={`${provider.type}.${
                                provider.provider
                              }.${provider.name.replace(/ /g, "_")}`}
                            />
                          </FormControl>
                        )}
                      </Field>
                    );
                  })}
              {client && (
                <>
                  <Field name="client_id">
                    {() => (
                      <FormControl>
                        <FormLabel
                          color="black.900"
                          htmlFor="client_id"
                        >
                          Client ID
                        </FormLabel>
                        <HStack mb={2}>
                          <Text>{client.client_id}</Text>
                          <IconButton
                            aria-label="Copy Client Secret"
                            icon={<CopyIcon />}
                            onClick={onCopyClientId}
                            ml={2}
                            variant="primaryBlue"
                          />
                        </HStack>
                      </FormControl>
                    )}
                  </Field>

                  <FormControl>
                    <FormLabel
                      color="black.900"
                      htmlFor="client_secret"
                    >
                      Client Secret
                    </FormLabel>
                    <Flex mb={2}>
                      <Input
                        value={clientSecret}
                        isReadOnly
                        placeholder="**************************************************************************************"
                        variant={"unstyled"}
                      />
                      {clientSecret ? (
                        <IconButton
                          aria-label="Copy Client Secret"
                          icon={<CopyIcon />}
                          onClick={onCopyClientSecret}
                          ml={2}
                          variant="primaryBlue"
                        />
                      ) : (
                        <IconButton
                          aria-label="Show Client Secret"
                          icon={<ViewIcon />}
                          onClick={showClientSecret}
                          ml={2}
                          variant="primaryBlue"
                          size="lg"
                        />
                      )}
                    </Flex>
                  </FormControl>
                </>
              )}
              {/*<Field name="client_allow_org_federation">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="row"
                    isInvalid={
                      form.errors.client_allow_org_federation &&
                      form.touched.client_allow_org_federation
                    }
                  >
                    <FormLabel htmlFor="client_allow_org_federation">
                      Allow Organization Federation
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_allow_org_federation",
                          event.target.checked
                        );
                      }}
                      id="client_allow_org_federation"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_allow_org_federation}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="client_allow_org_self_identify">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="row"
                    isInvalid={
                      form.errors.client_allow_org_self_identify &&
                      form.touched.client_allow_org_self_identify
                    }
                  >
                    <FormLabel htmlFor="client_allow_org_self_identify">
                      Allow Organization Self-Identify
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_allow_org_self_identify",
                          event.target.checked
                        );
                      }}
                      id="client_allow_org_self_identify"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_allow_org_self_identify}
                    </FormErrorMessage>
                  </FormControl>
                )}
                    </Field>*/}
            </VStack>
          ) : (
            <Stack spacing={6} padding="10px">
              <Text variant="contentsubheader">Choose Service Type</Text>
              <HStack {...radioGroup}>
                {CLIENT_LABELS.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio}>
                      {value === "api"
                        ? "API"
                        : value.charAt(0).toUpperCase() + value.slice(1)}
                    </RadioCard>
                  );
                })}
              </HStack>
              {!client && (
                <Button
                  onClick={() => {
                    pkceClicked();
                  }}
                  variant="primaryBlue"
                  alignSelf={"flex-start"}
                  size={"sm"}
                >
                  Apply PKCE options
                </Button>
              )}
              <Text variant="contentsubheader">Required Settings</Text>
              <Field name="client_name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.client_name && form.touched.client_name
                    }
                  >
                    <FormLabel color="black.900" htmlFor="client_name">
                      Name
                    </FormLabel>
                    <FormInput {...field} id="client_name" placeholder="Name" />
                    <FormErrorMessage>
                      {form.errors.client_name}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {client && (
                <>
                  <Field name="client_id">
                    {() => (
                      <FormControl>
                        <FormLabel
                          color="black.900"
                          htmlFor="client_id"
                        >
                          Client ID
                        </FormLabel>
                        <HStack mb={2}>
                          <Text>{client.client_id}</Text>
                          <IconButton
                            aria-label="Copy Client Secret"
                            icon={<CopyIcon />}
                            onClick={onCopyClientId}
                            ml={2}
                            variant="primaryBlue"
                          />
                        </HStack>
                      </FormControl>
                    )}
                  </Field>

                  <FormControl>
                    <FormLabel
                      color="black.900"
                      htmlFor="client_secret"
                    >
                      Client Secret
                    </FormLabel>
                    <Flex mb={2}>
                      <Input
                        value={clientSecret}
                        isReadOnly
                        placeholder="**************************************************************************************"
                        variant={"unstyled"}
                      />
                      {clientSecret ? (
                        <IconButton
                          aria-label="Copy Client Secret"
                          icon={<CopyIcon />}
                          onClick={onCopyClientSecret}
                          ml={2}
                          variant="primaryBlue"
                        />
                      ) : (
                        <IconButton
                          aria-label="Show Client Secret"
                          icon={<ViewIcon />}
                          onClick={showClientSecret}
                          ml={2}
                          variant="primaryBlue"
                          size="lg"
                        />
                      )}
                    </Flex>
                  </FormControl>
                </>
              )}

              <FieldArray
                name="grant_types"
                render={(arrayHelpers) => (
                  <FormControl>
                    <FormLabel color="black.900" htmlFor="grant_types">
                      Grant Types
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newGrantType"
                        placeholder="ex: authorization_code, implicit, refresh_token"
                        value={newGrantType}
                        onChange={(e) => {
                          setNewGrantType(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newGrantType);
                          setNewGrantType("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.grant_types &&
                      props.values.grant_types.map((grant_type, index) => (
                        <Tag
                          key={index}
                          size={"lg"}
                          borderRadius="20px"
                          bgColor="#3D426A"
                          color="white.1000"
                          fontSize="14px"
                          m="8px 4px"
                        >
                          <TagLabel>{grant_type}</TagLabel>
                          <Button
                            variant="unstyled"
                            size="sm"
                            minWidth="auto"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TagCloseButton />
                          </Button>
                        </Tag>
                      ))}
                  </FormControl>
                )}
              />
              <FieldArray
                name="redirect_uris"
                render={(arrayHelpers) => (
                  <FormControl>
                    <FormLabel
                      color="black.900"
                      htmlFor="redirect_uris"
                    >
                      Redirect URIs
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newRedirectUri"
                        placeholder="ex: https://example.com/callback"
                        value={newRedirectUri}
                        onChange={(e) => {
                          setNewRedirectUri(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newRedirectUri);
                          setNewRedirectUri("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.redirect_uris &&
                      props.values.redirect_uris.map((redirect_uri, index) => (
                        <Tag
                          key={index}
                          size={"lg"}
                          borderRadius="20px"
                          bgColor="#3D426A"
                          color="white.1000"
                          fontSize="14px"
                          m="8px 4px"
                        >
                          <TagLabel>{redirect_uri}</TagLabel>
                          <Button
                            variant="unstyled"
                            size="sm"
                            minWidth="auto"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TagCloseButton />
                          </Button>
                        </Tag>
                      ))}
                  </FormControl>
                )}
              />

              <Field name="response_types">
                {({ field, form }) => (
                  <FormControl name="response_types">
                    <FormLabel color="black.900">
                      Response Types
                    </FormLabel>
                    <FormSelect {...field} id="response_types">
                      <option value="">Choose response types</option>
                      {CLIENT_RESPONSE_TYPES &&
                        CLIENT_RESPONSE_TYPES.map((response_type, index) => {
                          return (
                            <option key={index} value={response_type}>
                              {response_type}
                            </option>
                          );
                        })}
                    </FormSelect>
                    <FormErrorMessage>
                      {form.errors.organization}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text variant="contentsubheader">Federation Settings</Text>
              {(!authGroup?.config?.federate?.oidc ||
                !authGroup?.config?.federate?.oauth2 ||
                (authGroup?.config?.federate.oidc.length === 0 &&
                  authGroup?.config?.federate.oauth2.length === 0)) && (
                <Text size="14px">
                  No federation settings are configured for this group.
                </Text>
              )}
              {(authGroup?.config?.federate?.oidc ||
                authGroup?.config?.federate?.oauth2) &&
                [
                  ...authGroup?.config?.federate?.oidc.map((p) => ({
                    ...p,
                    type: "oidc",
                  })),
                  ...authGroup?.config?.federate?.oauth2.map((p) => ({
                    ...p,
                    type: "oauth2",
                  })),
                ]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((provider) => {
                    return (
                      <Field
                        name="client_federation_options"
                        key={provider.name}
                      >
                        {({ field }) => (
                          <FormControl display="flex" flexDirection="row">
                            <FormLabel htmlFor={provider.name}>
                              {provider.name}
                            </FormLabel>
                            <Switch
                              marginTop="2px"
                              {...field}
                              id={provider.name}
                              defaultChecked={field.value?.includes(
                                `${provider.type}.${provider.provider}.${provider.name}`
                              )}
                              value={`${provider.type}.${
                                provider.provider
                              }.${provider.name.replace(/ /g, "_")}`}
                            />
                          </FormControl>
                        )}
                      </Field>
                    );
                  })}

              <Field name="client_allow_org_federation">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="row"
                    isInvalid={
                      form.errors.client_allow_org_federation &&
                      form.touched.client_allow_org_federation
                    }
                  >
                    <FormLabel htmlFor="client_allow_org_federation">
                      Allow Organization Federation
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_allow_org_federation",
                          event.target.checked
                        );
                      }}
                      id="client_allow_org_federation"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_allow_org_federation}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="client_allow_org_self_identify">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="row"
                    isInvalid={
                      form.errors.client_allow_org_self_identify &&
                      form.touched.client_allow_org_self_identify
                    }
                  >
                    <FormLabel htmlFor="client_allow_org_self_identify">
                      Allow Organization Self-Identify
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_allow_org_self_identify",
                          event.target.checked
                        );
                      }}
                      id="client_allow_org_self_identify"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_allow_org_self_identify}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text variant="contentsubheader">Recommended Settings</Text>

              <Field name="application_type">
                {({ field, form }) => (
                  <FormControl name="application_type">
                    <FormLabel>Application Types</FormLabel>
                    <FormSelect {...field} id="application_type">
                      <option value="">Choose Application Type</option>
                      {CLIENT_APPLICATION_TYPES &&
                        CLIENT_APPLICATION_TYPES.map(
                          (application_type, index) => {
                            return (
                              <option key={index} value={application_type}>
                                {application_type}
                              </option>
                            );
                          }
                        )}
                    </FormSelect>
                    <FormErrorMessage>
                      {form.errors.organization}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="client_skip_consent">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="column"
                    isInvalid={
                      form.errors.client_skip_consent &&
                      form.touched.client_skip_consent
                    }
                  >
                    <FormLabel htmlFor="client_skip_consent">
                      Skip Authorization Screen
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_skip_consent",
                          event.target.checked
                        );
                      }}
                      id="client_skip_consent"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_skip_consent}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="client_optional_skip_logout_prompt">
                {({ field, form }) => (
                  <FormControl
                    display="flex"
                    flexDirection="column"
                    isInvalid={
                      form.errors.client_optional_skip_logout_prompt &&
                      form.touched.client_optional_skip_logout_prompt
                    }
                  >
                    <FormLabel htmlFor="client_optional_skip_logout_prompt">
                      Skip Logout Prompt
                    </FormLabel>
                    <Switch
                      marginTop="2px"
                      {...field}
                      isChecked={field.value}
                      onChange={(event) => {
                        props.setFieldValue(
                          "client_optional_skip_logout_prompt",
                          event.target.checked
                        );
                      }}
                      id="client_optional_skip_logout_prompt"
                      placeholder=""
                    />

                    <FormErrorMessage>
                      {form.errors.client_optional_skip_logout_prompt}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FieldArray
                name="post_logout_redirect_uris"
                render={(arrayHelpers) => (
                  <FormControl>
                    <FormLabel
                      color="black.900"
                      htmlFor="post_logout_redirect_uris"
                    >
                      Post Logout Redirect URIs
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newPostLogoutRedirectUri"
                        placeholder="ex: https://example.com/callback"
                        value={newPostLogoutRedirectUri}
                        onChange={(e) => {
                          setNewPostLogoutRedirectUri(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newPostLogoutRedirectUri);
                          setNewPostLogoutRedirectUri("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.post_logout_redirect_uris &&
                      props.values.post_logout_redirect_uris.map(
                        (post_logout_redirect_uri, index) => (
                          <Tag
                            key={index}
                            size={"lg"}
                            borderRadius="20px"
                            bgColor="#3D426A"
                            color="white.1000"
                            fontSize="14px"
                            m="8px 4px"
                          >
                            <TagLabel>{post_logout_redirect_uri}</TagLabel>
                            <Button
                              variant="unstyled"
                              size="sm"
                              minWidth="auto"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <TagCloseButton />
                            </Button>
                          </Tag>
                        )
                      )}
                  </FormControl>
                )}
              />

              <Text variant="contentsubheader">Useful Settings</Text>

              <Field name="scope">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.scope && form.touched.scope}
                  >
                    <FormLabel color="black.900" htmlFor="scope">
                      Additional Scopes
                    </FormLabel>
                    <FormInput {...field} id="scope" placeholder="" />
                    <FormErrorMessage>{form.errors.scope}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="subject_type">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.subject_type && form.touched.subject_type
                    }
                  >
                    <FormLabel color="black.900" htmlFor="subject_type">
                      Subject Type
                    </FormLabel>
                    <FormInput {...field} id="subject_type" placeholder="" />
                    <FormErrorMessage>
                      {form.errors.subject_type}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="register_url">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.register_url && form.touched.register_url
                    }
                  >
                    <FormLabel color="black.900" htmlFor="register_url">
                      Register URL
                    </FormLabel>
                    <FormInput {...field} id="register_url" placeholder="" />
                    <FormErrorMessage>
                      {form.errors.register_url}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="client_uri">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.client_uri && form.touched.client_uri
                    }
                  >
                    <FormLabel color="black.900" htmlFor="client_uri">
                      Client URL
                    </FormLabel>
                    <FormInput {...field} id="client_uri" placeholder="" />
                    <FormErrorMessage>
                      {form.errors.client_uri}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="logo_uri">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.logo_uri && form.touched.logo_uri}
                  >
                    <FormLabel color="black.900" htmlFor="logo_uri">
                      Logo URL
                    </FormLabel>
                    <FormInput {...field} id="logo_uri" placeholder="" />
                    <FormErrorMessage>{form.errors.logo_uri}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="policy_uri">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.policy_uri && form.touched.policy_uri
                    }
                  >
                    <FormLabel color="black.900" htmlFor="policy_uri">
                      Privacy Policy URL
                    </FormLabel>
                    <FormInput {...field} id="policy_uri" placeholder="" />
                    <FormErrorMessage>
                      {form.errors.policy_uri}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="tos_uri">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.tos_uri && form.touched.tos_uri}
                  >
                    <FormLabel color="black.900" htmlFor="tos_uri">
                      Terms of Service URL
                    </FormLabel>
                    <FormInput {...field} id="tos_uri" placeholder="" />
                    <FormErrorMessage>{form.errors.tos_uri}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FieldArray
                name="web_message_uris"
                render={(arrayHelpers) => (
                  <FormControl>
                    <FormLabel
                      color="black.900"
                      htmlFor="web_message_uris"
                    >
                      Web Message URIs
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newWebMessageUri"
                        placeholder="ex: https://example.com/callback"
                        value={newWebMessageUri}
                        onChange={(e) => {
                          setNewWebMessageUri(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newWebMessageUri);
                          setNewWebMessageUri("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.web_message_uris &&
                      props.values.web_message_uris.map(
                        (web_message_uri, index) => (
                          <Tag
                            key={index}
                            size={"lg"}
                            borderRadius="20px"
                            bgColor="#3D426A"
                            color="white.1000"
                            fontSize="14px"
                            m="8px 4px"
                          >
                            <TagLabel>{web_message_uri}</TagLabel>
                            <Button
                              variant="unstyled"
                              size="sm"
                              minWidth="auto"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <TagCloseButton />
                            </Button>
                          </Tag>
                        )
                      )}
                  </FormControl>
                )}
              />

              <FieldArray
                name="request_uris"
                render={(arrayHelpers) => (
                  <FormControl>
                    <FormLabel color="black.900" htmlFor="request_uris">
                      Request URIs
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newRequestUri"
                        placeholder="ex: https://example.com/callback"
                        value={newRequestUri}
                        onChange={(e) => {
                          setNewRequestUri(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newRequestUri);
                          setNewRequestUri("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.request_uris &&
                      props.values.request_uris.map((request_uri, index) => (
                        <Tag
                          key={index}
                          size={"lg"}
                          borderRadius="20px"
                          bgColor="#3D426A"
                          color="white.1000"
                          fontSize="14px"
                          m="8px 4px"
                        >
                          <TagLabel>{request_uri}</TagLabel>
                          <Button
                            variant="unstyled"
                            size="sm"
                            minWidth="auto"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TagCloseButton />
                          </Button>
                        </Tag>
                      ))}
                  </FormControl>
                )}
              />

              <Text variant="contentsubheader">Advanced Settings</Text>

              <Field name="token_endpoint_auth_method">
                {({ field }) => (
                  <FormControl name="token_endpoint_auth_method">
                    <FormLabel color="black.900">
                      Token Endpoint Auth Method
                    </FormLabel>
                    <FormSelect {...field} id="token_endpoint_auth_method">
                      <option value="">
                        Choose Token Endpoint Auth Method
                      </option>
                      {TOKEN_ENDPOINT_AUTH_METHODS_SUPPORTED &&
                        TOKEN_ENDPOINT_AUTH_METHODS_SUPPORTED.map(
                          (token_endpoint_auth_method, index) => {
                            return (
                              <option
                                key={index}
                                value={token_endpoint_auth_method}
                              >
                                {token_endpoint_auth_method}
                              </option>
                            );
                          }
                        )}
                    </FormSelect>
                  </FormControl>
                )}
              </Field>

              <Field name="introspection_endpoint_auth_method">
                {({ field }) => (
                  <FormControl name="introspection_endpoint_auth_method">
                    <FormLabel color="black.900">
                      Introspection Endpoint Auth Method
                    </FormLabel>
                    <FormSelect
                      {...field}
                      id="introspection_endpoint_auth_method"
                    >
                      <option value="">
                        Choose Introspection Endpoint Auth Method
                      </option>
                      {INTROSPECTION_ENDPOINT_AUTH_METHODS_SUPPORTED &&
                        INTROSPECTION_ENDPOINT_AUTH_METHODS_SUPPORTED.map(
                          (introspection_endpoint_auth_method, index) => {
                            return (
                              <option
                                key={index}
                                value={introspection_endpoint_auth_method}
                              >
                                {introspection_endpoint_auth_method}
                              </option>
                            );
                          }
                        )}
                    </FormSelect>
                  </FormControl>
                )}
              </Field>

              <Field name="revocation_endpoint_auth_method">
                {({ field }) => (
                  <FormControl name="revocation_endpoint_auth_method">
                    <FormLabel color="black.900">
                      Revocation Endpoint Auth Method
                    </FormLabel>
                    <FormSelect {...field} id="revocation_endpoint_auth_method">
                      <option value="">
                        Choose Revocation Endpoint Auth Method
                      </option>
                      {REVOCATION_ENDPOINT_AUTH_METHODS_SUPPORTED &&
                        REVOCATION_ENDPOINT_AUTH_METHODS_SUPPORTED.map(
                          (revocation_endpoint_auth_method, index) => {
                            return (
                              <option
                                key={index}
                                value={revocation_endpoint_auth_method}
                              >
                                {revocation_endpoint_auth_method}
                              </option>
                            );
                          }
                        )}
                    </FormSelect>
                  </FormControl>
                )}
              </Field>
            </Stack>
          )}

          <VStack spacing={6} padding="12px" alignItems="flex-start">
            {error && <p>{error}</p>}

            <HStack>
              {client ? (
                <>
                  <Button
                    variant="primaryBlue"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    Update Client
                  </Button>
                  <Button
                    leftIcon={<DeleteIcon />}
                    variant="primaryRed"
                    flex="none"
                    onClick={handleDeleteClient}
                  >
                    Delete Client
                  </Button>
                </>
              ) : (
                <Button
                  variant="primaryBlue"
                  type="submit"
                  disabled={props.isSubmitting}
                >
                  Add Service
                </Button>
              )}
              {result && (
                <Button
                  variant="primaryBlack"
                  onClick={() => {
                    result(null);
                  }}
                >
                  Cancel
                </Button>
              )}
            </HStack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
