import {
  Button,
  Flex,
  Text,
  VStack,
  Skeleton,
  Box,
  Divider,
} from "@chakra-ui/react";
import React, { useCallback, useContext } from "react";
import axios from "axios";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { Product } from "./product";
import { NewProduct } from "./newProduct";
import { Client } from "../clients/client";
import { UEClient, UEProduct } from "../../types";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";

export const Products = ({ group, selectedProduct = null }: any) => {
  const [products, setProducts] = React.useState([] as UEProduct[]);
  const [clients, setClients] = React.useState([] as UEClient[]);
  const [loading, setLoading] = React.useState(true);
  const [loadingClients, setLoadingClients] = React.useState(true);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState(null as any);
  const [newProductCreating, setNewProductCreating] = React.useState(false);
  const [newClientCreating, setNewClientCreating] = React.useState("");
  const [selectedClient, setSelectedClient] = React.useState(null as any);
  const { getClients, getProducts, getUser } = useContext(AuthContext);

  useEffect(() => {
    getProducts().then((res: any) => {
      const products: UEProduct[] = res.data.data;
      setProducts(products);
      setLoading(false);
      if (selectedProduct) {
        const product = products.find(
          (p: UEProduct) => p.id === selectedProduct.id
        );
        setPrimaryColumnSelectedOption(product);
      } else {
        primaryColumnOptionSelected(products[0]);
      }
    });

    const filter = `$filter=associated_product eq null`;
    getClients(filter).then((res: any) => {
      const clients: UEClient[] = res.data.data;
      setClients(clients);
      setLoadingClients(false);
    });
  }, [getClients, getProducts, selectedProduct]);

  const refresh = (activeProduct?: any) => {
    getProducts().then((res: any) => {
      const products: UEProduct[] = res.data.data;
      setProducts(products);
      setLoading(false);
      if (activeProduct) {
        primaryColumnOptionSelected(
          products.find((p: UEProduct) => p.id === activeProduct.id)
        );
      }
      setNewClientCreating("");
    });

    const filter = `$filter=associated_product eq null`;
    getClients(filter).then((res: any) => {
      const clients: UEClient[] = res.data.data;
      setClients(clients);
      setLoadingClients(false);
    });
  };

  const primaryColumnOptionSelected = async (selectedOption: any) => {
    setPrimaryColumnSelectedOption(selectedOption);
    setNewProductCreating(false);
    setNewClientCreating("");
    setSelectedClient(null);
  };

  const clientToProductAssociation = async (
    product: any,
    client: any,
    action: string
  ) => {
    console.log(product, client, action);

    let updatedClient;
    if (action === "remove") {
      client.associated_product = "";
      updatedClient = client;
    } else if (action === "add") {
      updatedClient = { ...client, associated_product: product.id };
    }

    if ("client_secret_expires_at" in updatedClient) {
      delete updatedClient.client_secret_expires_at;
    }
    if ("client_id_issued_at" in updatedClient) {
      delete updatedClient.client_id_issued_at;
    }
    if ("registration_access_token" in client) {
      delete updatedClient.registration_access_token;
    }
    if ("registration_client_uri" in client) {
      delete updatedClient.registration_client_uri;
    }
    const authUser = await getUser();
    const resp: any = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/api/${group.id}/operations/client/${client.client_id}`,
      {
        operation: "rotate_registration_access_token",
      },
      {
        headers: {
          Authorization: `Bearer ${authUser.access_token}`,
        },
      }
    );

    const registrationAccessToken = resp.data.data;

    const clientResult = await axios.put(
      `${process.env.REACT_APP_API_ROOT}/${group.id}/reg/${client.client_id}`,
      updatedClient,
      {
        headers: {
          Authorization: `Bearer ${registrationAccessToken}`,
        },
      }
    );

    console.log(clientResult);

    refresh(product);
  };

  const wrappedClientSelected = useCallback((client: any) => {
    console.log(client);
    if (client) {
      setSelectedClient(client);
    } else {
      setSelectedClient(null);
    }

    setNewClientCreating("");
  }, []);

  return (
    <Flex h="100%" alignItems="stretch">
      <VStack
        layerStyle="windowmenu"
        alignItems="flex-start"
        flex="1"
        spacing="0"
      >
        <Text variant="windowheader">Products</Text>
        <Box paddingBottom={"8px"} width={"100%"}>
          <Button
            width={"100%"}
            variant="primaryBlue"
            size="sm"
            onClick={() => {
              setNewProductCreating(true);
              setPrimaryColumnSelectedOption(null);
              setSelectedClient(null);
            }}
          >
            Add
          </Button>
        </Box>
        <Divider opacity={".4"} />
        {newProductCreating && (
          <WindowColumnPrimaryButton
            key={"newProduct"}
            onClick={() => {
              //this.primaryColumnOptionSelected(option);
            }}
            active={true}
          >
            New Product
          </WindowColumnPrimaryButton>
        )}
        <Skeleton overflow={"hidden"} overflowY={"scroll"} isLoaded={!loading}>
          <VStack
            alignItems="stretch"
            spacing="0"
            paddingTop={"8px"}
            paddingBottom={"8px"}
          >
            {products.map((option: any) => {
              return (
                <WindowColumnPrimaryButton
                  key={option.name}
                  onClick={() => {
                    primaryColumnOptionSelected(option);
                  }}
                  active={primaryColumnSelectedOption?.name === option.name}
                >
                  <Text
                    width={"162px"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.name}
                  </Text>
                </WindowColumnPrimaryButton>
              );
            })}
          </VStack>
        </Skeleton>
      </VStack>

      <VStack
        alignItems={"stretch"}
        layerStyle="windowcontentarea"
        spacing={"0"}
        gap={"24px"}
      >
        {primaryColumnSelectedOption && (
          <Product
            key={primaryColumnSelectedOption.id}
            group={group.id}
            product={primaryColumnSelectedOption}
            result={(product: any) => {
              const index = products.findIndex((p: any) => p.id === product.id);
              const newProducts = [...products];
              newProducts[index] = product;
              setProducts(newProducts);
            }}
            productDeleted={() => {
              setSelectedClient(null);
              setPrimaryColumnSelectedOption(null);
              refresh();
            }}
            clientSelected={wrappedClientSelected}
            newClientCreatingResult={(newClientCreating: string) => {
              setNewClientCreating(newClientCreating);
              setSelectedClient(null);
            }}
            clientRemoved={(client: any) => {
              clientToProductAssociation(
                primaryColumnSelectedOption,
                client,
                "remove"
              );
            }}
          />
        )}
        {newProductCreating && (
          <NewProduct
            result={(product: any) => {
              if (product) {
                console.log("result: ", product);
                setProducts([...products, product]);
                setNewProductCreating(false);
                setPrimaryColumnSelectedOption(product);
              } else {
                setNewProductCreating(false);
              }
            }}
          />
        )}
      </VStack>
      {newClientCreating === "new" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Client
            client={null}
            group={group.id}
            result={(client: any) => {
              if (client) {
                console.log("result: ", client);
                clientToProductAssociation(
                  primaryColumnSelectedOption,
                  client,
                  "add"
                );
              } else {
                setNewClientCreating("");
              }
            }}
            clientDeleted={() => { }}
          />
        </VStack>
      )}
      {selectedClient && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Client
            client={selectedClient}
            group={group.id}
            result={(client: any) => {
              if (client) {
                console.log("result: ", client);
              } else {
                setSelectedClient(null);
              }
            }}
            clientDeleted={() => {
              refresh();
            }}
          />
        </VStack>
      )}
      {newClientCreating === "existing" && (
        <>
          <Skeleton height="25px" isLoaded={!loadingClients}></Skeleton>
          <VStack
            alignItems={"stretch"}
            layerStyle="windowcontentarea"
            spacing={"0"}
            gap={"24px"}
          >
            {clients &&
              clients
                .filter((c) => group.associatedClient !== c.client_id)
                .map((client: UEClient) => (
                  <WindowColumnSecondaryButton>
                    {client.client_name}
                    <Button
                      bg="#D77740"
                      borderRadius="6px"
                      color="#FFFFFF"
                      h="25px"
                      fontSize="14px"
                      size="sm"
                      ml="5px"
                      onClick={() => {
                        clientToProductAssociation(
                          primaryColumnSelectedOption,
                          client,
                          "add"
                        );
                      }}
                    >
                      Add
                    </Button>
                  </WindowColumnSecondaryButton>
                ))}
          </VStack>
        </>
      )}
    </Flex>
  );
};