import {
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/authProvider";
import { Stream, Subject } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { Card } from "./Card";
import { EditStream } from "./EditStream";
import { EditSubject } from "./EditSubject";
import { ViewSubject } from "./ViewSubject";
import { ViewStream } from "./ViewStream";
import { RequestAccessSubject } from "./RequestAccessSubject";
import { CreateStream } from "./CreateStream";
import { CreateSubject } from "./CreateSubject";
import { RequestAccessStream } from "./RequestAccessStream";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

export const Streams = () => {
  const { getUser, getGroup } = useContext(AuthContext);

  const [streamingService, setStreamingService] = useState<NattyLightService>();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [streams, setStreams] = useState<Stream[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<Subject | null>(null);
  const [selectedStream, setSelectedStream] = useState<Stream | null>(null);
  const [activeType, setActiveType] = useState<"subjects" | "streams">(
    "streams"
  );
  const [creatingStream, setCreatingStream] = useState(false);
  const [creatingSubject, setCreatingSubject] = useState(false);
  const [editingSubject, setEditingSubject] = useState(false);
  const [editingStream, setEditingStream] = useState(false);
  const [requestingSubjectAccess, setRequestingSubjectAccess] = useState(false);
  const [requestingStreamAccess, setRequestingStreamAccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [streamCount, setStreamCount] = useState(0);
  const [subjectCount, setSubjectCount] = useState(0);

  useEffect(() => {
    try {
      getUser().then((user: any) => {
        const group = getGroup();
        const streamingService = new NattyLightService(
          user.access_token,
          group
        );
        setStreamingService(streamingService);

        streamingService.getMapping().then((mapping: any) => {
          console.log("mapping: ", mapping);
        });

        streamingService
          .getSubjects()
          .then((subjects: Subject[]) => {
            console.log("subjects: ", subjects);
            setSubjects(subjects);
            setSubjectCount(subjects.length);
            setSelectedSubject(subjects[0]);
          })
          .catch((error: any) => {
            console.error(error);
          });

        streamingService
          .getStreams()
          .then((streams: Stream[]) => {
            console.log("streams: ", streams);
            setStreams(streams);
            setStreamCount(streams.length);
            setSelectedStream(streams[0]);
          })
          .catch((error: any) => {
            console.error(error);
          });
      });
    } catch (error) {
      console.error(error);
    }

    return () => {};
  }, [getGroup, getUser]);

  const handleEditSubjectClicked = () => {
    setCreatingSubject(false);
    setEditingSubject(true);
  };

  const handleRequestSubjectAccessClicked = () => {
    setRequestingSubjectAccess(true);
  };

  const handleRequestStreamAccessClicked = () => {
    setRequestingStreamAccess(true);
  };

  const handleEditStreamClicked = () => {
    setCreatingStream(false);
    setEditingStream(true);
  };

  const refreshSubjects = () => {
    streamingService?.getSubjects().then((subjects: Subject[]) => {
      console.log("subjects: ", subjects);
      setSubjects(subjects);
      setSubjectCount(subjects.length);
    });
  };

  const refreshStreams = () => {
    streamingService?.getStreams().then((streams: Stream[]) => {
      console.log("streams: ", streams);
      setStreams(streams);
      setStreamCount(streams.length);
    });
  };

  const handleSearch = (event: any) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (activeType === "streams") {
      if (searchTerm === "") {
        refreshStreams();
      } else {
        streamingService?.searchStreams(term).then((streams: Stream[]) => {
          console.log("streams: ", streams);
          setStreams(streams);
        });
      }
    } else {
      if (searchTerm === "") {
        refreshSubjects();
      } else {
        streamingService?.searchSubjects(term).then((subjects: Subject[]) => {
          console.log("subjects: ", subjects);
          setSubjects(subjects);
        });
      }
    }
  };

  const clearSearch = () => {
    setSearchTerm("");

    refreshStreams();
    refreshSubjects();
  };

  return (
    <Flex height="100%" alignItems="stretch" overflow="hidden">
      <Flex /* Figma="Card View */
        flexDirection="column"
        alignItems="stretch"
        flex={"1 0 800px"}
      >
        <Flex /* Figma Frame 404 */
          flexDirection="column"
          alignItems="flex-start"
          padding="20px"
          gap="12px"
          background="white.900"
          borderBottom="1px solid"
          borderBottomColor="black.300"
          borderRight="1px solid"
          borderRightColor="black.100"
        >
          <Text variant="titleSmall">Simple Streams</Text>
          <HStack alignItems="flex-start" justifyContent="space-between">
            <Flex flex="1 0 32px" marginRight="8px" alignItems={"stretch"}>
              <Stack spacing={0} alignItems="stretch">
                <Button
                  height="28px"
                  variant="unstyled"
                  onClick={() => {
                    setActiveType("streams");
                    setSearchTerm("");
                    refreshSubjects();
                  }}
                  color={activeType === "streams" ? "black.900" : "black.700"}
                  leftIcon={
                    activeType === "streams" ? (
                      <Image
                        src="/images/stream-card-logo-color.svg"
                        h="12px"
                      />
                    ) : (
                      <Image src="/images/stream-card-logo-grey.svg" h="12px" />
                    )
                  }
                  iconSpacing={"4px"}
                >
                  <Text variant="bodyMedium" display="inline" marginRight="4px">
                    Streams
                  </Text>
                  <Text variant="labelMedium" display="inline">
                    {streamCount > 0 && streamCount}
                  </Text>
                </Button>
                {activeType === "streams" && (
                  <Flex
                    flex="1 0 4px"
                    background="green.500"
                    borderRadius="4px"
                  ></Flex>
                )}
              </Stack>
            </Flex>
            <Flex flex="1 0 32px" alignItems={"stretch"}>
              <Stack spacing={0} alignItems="stretch">
                <Button
                  height="28px"
                  variant="unstyled"
                  onClick={() => {
                    setActiveType("subjects");
                    setSearchTerm("");
                    refreshStreams();
                  }}
                  color={activeType === "subjects" ? "black.900" : "black.700"}
                  leftIcon={
                    activeType === "subjects" ? (
                      <Image
                        src="/images/stream-card-subject-color.svg"
                        h="12px"
                      />
                    ) : (
                      <Image
                        src="/images/stream-card-subject-grey.svg"
                        h="12px"
                      />
                    )
                  }
                  iconSpacing={"4px"}
                >
                  <Text variant="bodyMedium" display="inline" marginRight="4px">
                    Subjects
                  </Text>
                  <Text variant="labelMedium" display="inline">
                    {subjectCount > 0 && subjectCount}
                  </Text>
                </Button>
                {activeType === "subjects" && (
                  <Flex
                    flex="1 0 4px"
                    background="orange.500"
                    borderRadius="4px"
                  ></Flex>
                )}
              </Stack>
            </Flex>
          </HStack>
          <HStack>
            <Button
              variant={
                activeType === "subjects" ? "primaryOrange" : "primaryGreen"
              }
              size="md"
              width={"133px"}
              onClick={() => {
                if (activeType === "subjects") {
                  setSelectedSubject(null);
                  setCreatingSubject(true);
                } else {
                  setSelectedStream(null);
                  setCreatingStream(true);
                }
              }}
            >
              New {activeType === "subjects" ? "Subject" : "Stream"}
            </Button>
            <Text
              variant="bodySmall"
              lineHeight="1.2em"
              width="540px"
              paddingLeft="8px"
            >
              {activeType === "subjects"
                ? "On their own, subjects allow active listeners to get messages at most once. They're very fast many-to-many data transit and can also be used for request/reply services."
                : "Streams allow registered consumers to receive messages at least once and catch up where they left off if needed. They wrap one or more subjects adding persistence."}
            </Text>
          </HStack>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              placeholder={
                activeType === "subjects" ? "Search subjects" : "Search streams"
              }
              value={searchTerm}
              onChange={handleSearch}
              background="white.1000"
              border="1px solid"
              borderColor="black.200"
              borderRadius="24px"
            />
            {searchTerm && (
              <InputRightElement>
                <IconButton
                  aria-label="Clear"
                  size="xs"
                  icon={<CloseIcon />}
                  onClick={clearSearch}
                  variant="ghost"
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Flex>
        <Flex
          background="white.500"
          borderRight="1px solid"
          borderRightColor="black.100"
          overflowY={"scroll"}
          flex={"1"}
          alignItems={"flex-start"}
        >
          <Flex
            alignItems="stretch"
            flexWrap={"wrap"}
            padding="20px"
            gap="12px"
          >
            {activeType === "subjects" &&
              subjects &&
              subjects.map((subject: Subject) => (
                <Card
                  key={subject.id}
                  type="subject"
                  subject={subject}
                  active={selectedSubject?.id === subject.id}
                  onClick={() => {
                    setCreatingSubject(false);
                    setEditingSubject(false);
                    setRequestingSubjectAccess(false);
                    setSelectedSubject(subject);
                  }}
                />
              ))}
            {activeType === "streams" &&
              streams &&
              streams.map((stream: Stream) => (
                <Card
                  key={stream.id}
                  type="stream"
                  stream={stream}
                  active={selectedStream?.id === stream.id}
                  onClick={() => {
                    setCreatingStream(false);
                    setEditingStream(false);
                    setRequestingStreamAccess(false);
                    setSelectedStream(stream);
                  }}
                />
              ))}
          </Flex>
        </Flex>
      </Flex>
      {activeType === "subjects" &&
        streamingService &&
        !selectedSubject &&
        creatingSubject && (
          <CreateSubject
            client={streamingService}
            onDone={() => {
              refreshSubjects();
              setCreatingSubject(false);
            }}
          />
        )}
      {activeType === "subjects" &&
        streamingService &&
        selectedSubject &&
        !creatingSubject &&
        editingSubject && (
          <EditSubject
            id={selectedSubject.id}
            client={streamingService}
            onDone={() => {
              refreshSubjects();
              setEditingSubject(false);
            }}
          />
        )}
      {activeType === "streams" &&
        streamingService &&
        selectedStream &&
        !creatingStream &&
        editingStream && (
          <EditStream
            id={selectedStream.id}
            client={streamingService}
            onDone={() => {
              refreshStreams();
              setEditingStream(false);
            }}
          />
        )}
      {activeType === "streams" &&
        streamingService &&
        !selectedStream &&
        creatingStream && (
          <CreateStream
            client={streamingService}
            onDone={() => {
              refreshStreams();
              setCreatingStream(false);
            }}
          />
        )}
      {activeType === "subjects" &&
        streamingService &&
        selectedSubject &&
        !editingSubject &&
        !requestingSubjectAccess && (
          <ViewSubject
            client={streamingService}
            id={selectedSubject.name}
            onEditClicked={handleEditSubjectClicked}
            onRequestAccessClicked={handleRequestSubjectAccessClicked}
          />
        )}
      {activeType === "subjects" &&
        streamingService &&
        selectedSubject &&
        !editingSubject &&
        requestingSubjectAccess && (
          <RequestAccessSubject
            client={streamingService}
            id={selectedSubject.name}
            onCancel={() => {
              setRequestingSubjectAccess(false);
            }}
          />
        )}
      {activeType === "streams" &&
        streamingService &&
        selectedStream &&
        !editingStream &&
        requestingStreamAccess && (
          <RequestAccessStream
            client={streamingService}
            id={selectedStream.name}
            onCancel={() => {
              setRequestingStreamAccess(false);
            }}
          />
        )}
      {activeType === "streams" &&
        streamingService &&
        selectedStream &&
        !editingStream &&
        !requestingStreamAccess && (
          <ViewStream
            client={streamingService}
            id={selectedStream.name}
            onEditClicked={handleEditStreamClicked}
            onRequestAccessClicked={handleRequestStreamAccessClicked}
          />
        )}
    </Flex>
  );
};
