import * as jsonpatch from "fast-json-patch";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ActionMeta,
  CreatableSelect,
  OnChangeValue,
  Select,
} from "chakra-react-select";
import { Observer } from "fast-json-patch";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import {
  Product,
  Service,
  Stream,
  Subject,
} from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormSwitch } from "../FormSwitch";
import { FormTextarea } from "../FormTextArea";
import { SectionHeader } from "./SectionHeader";
import { FormBadge } from "./FormBadge";
import dayjs from "dayjs";
import { FormSelect } from "../FormSelect";
import { FormInput } from "../FormInput";
import { CustomTab } from "../CustomTab";
import { InfoIcon } from "@chakra-ui/icons";

export interface EditStreamProps {
  client: NattyLightService;
  id: string;
  onDone: () => void;
}

export const EditStream = ({ client, id, onDone }: EditStreamProps) => {
  const toast = useToast();
  const [name, setName] = React.useState("");
  const [selectedSubjects, setSelectedSubjects] = React.useState<string[]>([]);
  const [businessAreaOptions, setBusinessAreaOptions] = React.useState<
    string[]
  >([]);
  const [businessDomains, setBusinessDomains] = React.useState<string[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [services, setServices] = React.useState<Service[]>([]);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [tags, setTags] = React.useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
  const [description, setDescription] = React.useState("");
  const [denyDelete, setDenyDelete] = React.useState(false);
  const [denyPurge, setDenyPurge] = React.useState(false);
  const [sealed, setSealed] = React.useState(false);
  const [stream, setStream] = React.useState<Stream>();
  const [subjects, setSubjects] = React.useState<Subject[]>([]);
  const [allowDirect, setAllowDirect] = React.useState(false);
  const [retention, setRetention] = React.useState("");
  const [maxConsumers, setMaxConsumers] = React.useState(-1);
  const [maxConsumersNew, setMaxConsumersNew] = React.useState(0);
  const [maxMessages, setMaxMessages] = React.useState(-1);
  const [maxMessagesNew, setMaxMessagesNew] = React.useState(0);
  const [maxMessagesPerSubject, setMaxMessagesPerSubject] = React.useState(-1);
  const [maxMessagesPerSubjectNew, setMaxMessagesPerSubjectNew] =
    React.useState(0);
  const [maxBytes, setMaxBytes] = React.useState(-1);
  const [maxBytesNew, setMaxBytesNew] = React.useState(0);
  const [maxMessageSize, setMaxMessageSize] = React.useState(-1);
  const [maxMessageSizeNew, setMaxMessageSizeNew] = React.useState(0);
  const [maxAge, setMaxAge] = React.useState(0);
  const [maxAgeNew, setMaxAgeNew] = React.useState(0);
  const [discard, setDiscard] = React.useState("old");

  useEffect(() => {
    client.getAvailableSubjects().then((subjects) => {
      setSubjects(subjects);
    });

    client.getBusinessDomains().then((domains) => {
      setBusinessAreaOptions(domains);
    });

    client.getProducts().then((products) => {
      setProducts(products);
    });

    client.getServices().then((services) => {
      setServices(
        services.filter(
          (s: Service) => !s.client_name.startsWith("stream-access-")
        )
      );
    });

    client.getTags().then((tags) => {
      setTagOptions(tags);
    });

    client.getStream(id).then((stream: Stream) => {
      console.log(stream);
      setName(stream.name);
      setDescription(stream.description);
      setBusinessDomains(stream.businessDomains);
      setSelectedProducts(stream.associatedProducts);
      setSelectedServices(stream.associatedServices);
      setTags(stream.tags);
      setDenyDelete(stream.config.denyDelete);
      setDenyPurge(stream.config.denyPurge);
      setSealed(stream.config.sealed);
      setSelectedSubjects(stream.subjects);
      setAllowDirect(stream.config.allowDirect);
      setRetention(stream.config.retention);
      setMaxConsumers(stream.config.maxConsumers);
      setMaxMessages(stream.config.maxMsgs);
      setMaxMessagesPerSubject(stream.config.maxMsgsPerSubject);
      setMaxBytes(stream.config.maxBytes);
      setMaxMessageSize(stream.config.maxMsgSize);
      setMaxAge(stream.config.maxAge);
      setDiscard(stream.config.discard);

      // save the existing stream for comparison when updating
      setStream(stream);
    });
  }, [client, id]);

  const subjectOptions = subjects.map((s) => ({
    value: s.name,
    label: s.name,
  }));

  const updateStream = async () => {
    try {
      if (stream) {
        const observer: Observer<Subject> = jsonpatch.observe(stream);
        stream.associatedProducts = selectedProducts;
        stream.associatedServices = selectedServices;
        stream.tags = tags;
        stream.businessDomains = businessDomains;
        stream.description = description;
        stream.config.denyDelete = denyDelete;
        stream.config.denyPurge = denyPurge;
        stream.config.sealed = sealed;
        stream.config.allowDirect = allowDirect;
        stream.config.retention = retention;
        stream.config.discard = discard;
        stream.subjects = selectedSubjects;

        if (maxConsumersNew >= 0) {
          stream.config.maxConsumers = maxConsumersNew;
        }

        if (maxMessagesNew >= 0) {
          stream.config.maxMsgs = maxMessagesNew;
        }

        if (maxMessagesPerSubjectNew >= 0) {
          stream.config.maxMsgsPerSubject = maxMessagesPerSubjectNew;
        }

        if (maxBytesNew >= 0) {
          stream.config.maxBytes = maxBytesNew;
        }

        if (maxMessageSizeNew >= 0) {
          stream.config.maxMsgSize = maxMessageSizeNew;
        }

        if (maxAgeNew > 0) {
          stream.config.maxAge = maxAgeNew;
        }

        const patch = jsonpatch.generate(observer);
        console.log(patch);

        await client.updateStream(stream.id, patch);
        onDone();
      }
    } catch (error) {
      alert(JSON.stringify(error));

      showErrorToast(error);
    }
  };

  /*const handleArchiveStream = async () => {
    try {
      console.log("archiving stream");
      if (stream) {
        const observer: Observer<Stream> = jsonpatch.observe(stream);
        stream.archived = true;

        const patch = jsonpatch.generate(observer);
        console.log(patch);

        await client.updateStream(stream.id, patch);
        onDone();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };*/

  const handleDeleteStream = async () => {
    try {
      if (stream) {
        await client.deleteStream(stream.id);
        onDone();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedSubjects(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleBusinessAreaChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setBusinessDomains(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleTagChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setTags(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleProductChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedProducts(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleServiceChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedServices(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.response ? error.response.data.message : error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      <VStack alignItems="stretch" height={"100%"} spacing={"0"}>
        <VStack
          alignItems={"stretch"}
          padding={"20px 20px 0 20px"}
          background={"white.900"}
          spacing={"0"}
        >
          <Text variant="labelMedium" lineHeight={"1em"}>
            Edit
          </Text>
          <Text variant="titleLarge" lineHeight={"1.2em"}>
            {name}
          </Text>
        </VStack>
        <HStack padding={"12px 20px 4px 20px"} background={"white.900"}>
          <Button
            variant="primaryGreen"
            size="sm"
            onClick={updateStream}
            disabled={!name}
          >
            Save
          </Button>
          <Button onClick={onDone} variant="primaryBlack" size="sm">
            Cancel
          </Button>
          {stream && (
            <Button
              onClick={handleDeleteStream}
              variant="unstyledBlack"
              size="sm"
            >
              Delete
            </Button>
          )}
        </HStack>
        <Tabs
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          overflow={"hidden"}
        >
          <TabList
            background={"white.900"}
            padding={"2px 20px 0 20px"}
            gap={"12px"}
            borderBottom={"1px solid"}
            borderBottomColor={"black.300"}
          >
            <CustomTab>General</CustomTab>
            <CustomTab>Subjects</CustomTab>
            <CustomTab>State</CustomTab>
            <CustomTab>Associations</CustomTab>
          </TabList>
          <TabPanels
            background={"white.800"}
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            overflow={"hidden"}
          >
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px 20px 40px 20px"}
              gap={"20px"}
            >
              <SectionHeader title={"General"} />
              <VStack alignItems={"stretch"} spacing={"0.5"}>
                <FormLabel>Description</FormLabel>
                <FormTextarea
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                ></FormTextarea>
              </VStack>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">ID</Text>
                <Text variant="bodySmall">{stream?.id}</Text>
              </HStack>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <HStack spacing={"1"}>
                  <Text variant="labelSmall">Created</Text>
                  <Text variant="bodySmall">
                    {dayjs(stream?.createdAt).format("MMM DD YYYY h:mm a")}
                  </Text>
                </HStack>
                <HStack spacing={"1"}>
                  <Text variant="labelSmall">Last Modified</Text>
                  <Text variant="bodySmall">
                    {dayjs(stream?.modifiedAt).format("MMM DD YYYY h:mm a")}
                  </Text>
                </HStack>
              </VStack>
              <SectionHeader
                title="Config"
                description="Manage your stream access, limits, retention, and performance for all consumers and producers."
              />
              {stream?.imported && (
                <HStack spacing={"1"}>
                  <InfoIcon />
                  <Text variant="labelMedium">This stream was imported.</Text>
                </HStack>
              )}
              {stream?.core && (
                <HStack spacing={"1"}>
                  <InfoIcon />
                  <Text variant="labelMedium">
                    This stream is managed by Core.
                  </Text>
                </HStack>
              )}
              <VStack spacing={"3"}>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Is this stream sealed?</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setSealed(event.target.checked);
                    }}
                    isChecked={sealed}
                  ></FormSwitch>
                </FormControl>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Deny delete?</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setDenyDelete(event.target.checked);
                    }}
                    isChecked={denyDelete}
                  />
                </FormControl>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Deny purge?</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setDenyPurge(event.target.checked);
                    }}
                    isChecked={denyPurge}
                  />
                </FormControl>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Allow Direct?</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setAllowDirect(event.target.checked);
                    }}
                    isChecked={allowDirect}
                  />
                </FormControl>
              </VStack>
              {/* */}
              <VStack>
                <FormControl>
                  <FormLabel>Retention</FormLabel>
                  <FormSelect
                    value={retention}
                    onChange={(e) => {
                      setRetention(e.target.value);
                    }}
                  >
                    <option value="limits">
                      Until threshold limits are reached
                    </option>
                    <option value="interest">
                      Until all consumers receive it
                    </option>
                    <option value="work queue">
                      Until one consumer receives it
                    </option>
                  </FormSelect>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum Consumers</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxConsumers}
                      onChange={(e) => {
                        setMaxConsumers(+e.target.value);
                      }}
                    >
                      <option value="-1">Unlimited</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxConsumers > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxConsumersNew(+e.target.value);
                        }}
                        value={maxConsumersNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum messages</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxMessages}
                      onChange={(e) => {
                        setMaxMessages(+e.target.value);
                      }}
                    >
                      <option value="-1">Unlimited</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxMessages > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxMessagesNew(+e.target.value);
                        }}
                        value={maxMessagesNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum messages per subject</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxMessagesPerSubject}
                      onChange={(e) => {
                        setMaxMessagesPerSubject(+e.target.value);
                      }}
                    >
                      <option value="-1">Unlimited</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxMessagesPerSubject > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxMessagesPerSubjectNew(+e.target.value);
                        }}
                        value={maxMessagesPerSubjectNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum message size</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxMessageSize}
                      onChange={(e) => {
                        setMaxMessageSize(+e.target.value);
                      }}
                    >
                      <option value="-1">Unlimited</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxMessageSize > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxMessageSizeNew(+e.target.value);
                        }}
                        value={maxMessageSizeNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum bytes</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxBytes}
                      onChange={(e) => {
                        setMaxBytes(+e.target.value);
                      }}
                    >
                      <option value="-1">Unlimited</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxBytes > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxBytesNew(+e.target.value);
                        }}
                        value={maxBytesNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Maximum age</FormLabel>
                  <HStack>
                    <FormSelect
                      value={maxAge}
                      onChange={(e) => {
                        setMaxAge(+e.target.value);
                      }}
                    >
                      <option value="0">No Limit</option>
                      <option value="1">Limited</option>
                    </FormSelect>
                    {maxAge > -1 && (
                      <FormInput
                        onChange={(e) => {
                          setMaxAgeNew(+e.target.value);
                        }}
                        value={maxAgeNew}
                      ></FormInput>
                    )}
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Discard policy</FormLabel>
                  <FormSelect
                    value={discard}
                    onChange={(e) => {
                      setDiscard(e.target.value);
                    }}
                  >
                    <option value="old">Discard Oldest</option>
                    <option value="new">Discard Newest</option>
                  </FormSelect>
                </FormControl>
              </VStack>
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px 20px 40px 20px"}
              gap={"20px"}
            >
              <SectionHeader
                title="Subjects"
                description="Think of subjects as the data pipes that streams manage."
              />
              <CreatableSelect
                isMulti
                options={subjectOptions}
                onChange={handleChange}
                value={selectedSubjects?.map((s) => {
                  return { value: s, label: s };
                })}
              ></CreatableSelect>
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px 20px 40px 20px"}
              gap={"20px"}
            >
              <SectionHeader
                title="State"
                description="This is the current state of your stream. It includes things like total messages, last published date/time, and more."
              />
              {stream && (
                <VStack alignItems={"stretch"} spacing={"2"}>
                  <HStack spacing={"2"}>
                    <Text variant={"titleLarge"}>
                      {stream.natsInfo?.state?.messages}
                    </Text>
                    <VStack alignItems={"stretch"}>
                      <Text variant={"labelMedium"} lineHeight={"1"}>
                        Messages
                      </Text>
                    </VStack>
                  </HStack>
                  <Divider borderColor={"black.300"} />
                  <VStack alignItems={"stretch"} spacing={"0"}>
                    <HStack spacing={"2"}>
                      <Text variant={"titleLarge"}>
                        {stream.natsInfo?.state?.first_seq}
                      </Text>
                      <VStack alignItems={"stretch"} spacing={"0.5"}>
                        <Text variant={"labelMedium"} lineHeight={"1"}>
                          First Index
                        </Text>
                        <HStack spacing={"1"}>
                          <Text variant={"bodySmall"} lineHeight={"1em"}>
                            {" "}
                            Published
                          </Text>
                          <Text variant={"bodySmall"} lineHeight={"1em"}>
                            {dayjs(stream.natsInfo?.state?.first_ts).format(
                              " MMM DD YYYY hh:mm a"
                            )}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                  <Divider borderColor={"black.300"} />
                  <VStack alignItems={"stretch"} spacing={"0"}>
                    <HStack spacing={"2"}>
                      <Text variant={"titleLarge"}>
                        {stream.natsInfo?.state?.last_seq}
                      </Text>
                      <VStack alignItems={"stretch"} spacing={"0.5"}>
                        <Text variant={"labelMedium"} lineHeight={"1"}>
                          Last Index
                        </Text>
                        <HStack spacing={"1"}>
                          <Text variant={"bodySmall"} lineHeight={"1em"}>
                            {" "}
                            Published
                          </Text>
                          <Text variant={"bodySmall"} lineHeight={"1em"}>
                            {dayjs(stream.natsInfo?.state?.last_ts).format(
                              " MMM DD YYYY hh:mm a"
                            )}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                  <Divider borderColor={"black.300"} />
                  <HStack spacing={"2"}>
                    <Text variant={"titleLarge"}>
                      {stream.natsInfo?.state?.bytes}
                    </Text>
                    <VStack alignItems={"stretch"} spacing={"-0.5"}>
                      <Text variant={"labelMedium"} lineHeight={"1"}>
                        Bytes
                      </Text>
                    </VStack>
                  </HStack>
                  <Divider borderColor={"black.300"} />
                  <HStack spacing={"2"}>
                    <Text variant={"titleLarge"}>
                      {stream.natsInfo?.state?.consumer_count}
                    </Text>
                    <VStack alignItems={"stretch"} spacing={"-0.5"}>
                      <Text variant={"labelMedium"} lineHeight={"1"}>
                        Consumers
                      </Text>
                    </VStack>
                  </HStack>

                  <VStack alignItems={"stretch"} spacing={"1"}>
                    {stream.natsInfo?.consumers &&
                      stream.natsInfo.consumers.map((c) => (
                        <FormBadge backgroundColor={"green.500"}>
                          {c.name}
                        </FormBadge>
                      ))}
                  </VStack>
                </VStack>
              )}
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px 20px 40px 20px"}
              gap={"20px"}
            >
              <SectionHeader
                title="Associations"
                description="Associations are ways to make it easier to organize your stream."
              />
              <VStack alignItems={"stretch"}>
                <FormControl>
                  <FormLabel>Business Area</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={businessDomains?.map((bd) => {
                      return { value: bd, label: bd };
                    })}
                    options={businessAreaOptions.map((b) => {
                      return { value: b, label: b };
                    })}
                    onChange={handleBusinessAreaChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={tags?.map((t) => {
                      return { value: t, label: t };
                    })}
                    options={tagOptions.map((t) => {
                      return { value: t, label: t };
                    })}
                    onChange={handleTagChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel my="10px">Associated Products</FormLabel>
                  <Select
                    isMulti
                    value={selectedProducts?.map((p) => {
                      const product = products.find((s2) => s2.id === p);
                      return { value: product?.id, label: product?.name };
                    })}
                    options={products.map((p) => {
                      return { value: p.id, label: p.name };
                    })}
                    onChange={handleProductChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel my="10px">Associated Services</FormLabel>
                  <Select
                    isMulti
                    value={selectedServices?.map((s) => {
                      const service = services.find((s2) => s2.client_id === s);
                      return {
                        value: service?.client_id,
                        label: service?.client_name,
                      };
                    })}
                    options={services.map((s) => {
                      return { value: s.client_id, label: s.client_name };
                    })}
                    onChange={handleServiceChange}
                  />
                </FormControl>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
};
