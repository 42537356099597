import React from "react";
import { AuthProvider } from "./providers/authProvider";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes/routes";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { WindowsProvider } from "./providers/windowsProvider";

// This is where the magic happens

function App() {
  return (
    <AuthProvider>
      <ChakraProvider resetCSS theme={theme}>
        <WindowsProvider>
          <BrowserRouter children={Routes} basename={"/"} />
        </WindowsProvider>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
