import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";

interface UserAccessProps {
  org: any;
  user: any;
}
export const UserAccess = ({ org, user }: UserAccessProps) => {
  const {
    getOrganizationUserAccess,
    getDomains,
    getProductRoles,
    updateOrganizationUserAccess,
    getOrganizationDomainProducts,
  } = React.useContext(AuthContext);
  const [userAccess, setUserAccess] = React.useState<any>(null);
  const [domains, setDomains] = React.useState<any>(null);
  const [products, setProducts] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const [roles, setRoles] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getDomains(org.id)
      .then((res) => {
        setDomains(res.data.data);
      })
      .catch((error) => console.log(error));
    getOrganizationUserAccess(org.id, user.id)
      .then((res) => {
        setUserAccess(res.data.data);

        // we need to list the products (deduped) now
        const domains = res.data.data.domains;
        if (domains.length > 0) {
          let reqs: any[] = [];
          domains.forEach((domain: string) => {
            reqs.push(getOrganizationDomainProducts(org.id, domain));
          });

          Promise.all(reqs)
            .then((res) => {
              res.forEach((r: any) => {
                if (r.data.data) {
                  console.log(r.data.data);
                  setProducts((products: any) => [...products, ...r.data.data]);
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((error) => console.log(error));
  }, [
    getDomains,
    getOrganizationDomainProducts,
    getOrganizationUserAccess,
    org,
    user,
  ]);

  const handleProductClicked = async (product: any) => {
    setSelectedProduct(product);
    const res = await getProductRoles(product.id);
    setRoles(res.data.data);
  };

  const updateAccess = (type: string, action: string, value: string) => {
    console.log("updateAccess", type, action, value);
    if (type === "role") {
      if (action === "add") {
        const newRoles = [...userAccess.roles, value];
        setUserAccess({ ...userAccess, roles: newRoles });
      } else if (action === "remove") {
        const newRoles = userAccess.roles.filter((role: any) => role !== value);
        setUserAccess({ ...userAccess, roles: newRoles });
      }
    } else if (type === "domain") {
      let newDomains;
      if (action === "add") {
        newDomains = [...userAccess.domains, value];
        setUserAccess({ ...userAccess, domains: newDomains });
      } else if (action === "remove") {
        newDomains = userAccess.domains.filter(
          (domain: any) => domain !== value
        );
        setUserAccess({ ...userAccess, domains: newDomains });
      } else {
        throw new Error("Invalid type");
      }

      setProducts([]);
      setRoles([]);
      if (newDomains.length > 0) {
        let reqs: any[] = [];
        newDomains.forEach((domain: string) => {
          reqs.push(getOrganizationDomainProducts(org.id, domain));
        });

        Promise.all(reqs)
          .then((res) => {
            let productsToAdd: any[] = [];
            res.forEach((r: any) => {
              if (r.data.data) {
                const newProducts = r.data.data;
                if (newProducts.length > 0) {
                  newProducts.forEach((product: any) => {
                    if (!productsToAdd.find((p: any) => p.id === product.id)) {
                      productsToAdd.push(product);
                    }
                  });
                }
              }
              setProducts([...productsToAdd]);
              handleProductClicked(productsToAdd[0]);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const saveUserAccess = async () => {
    try {
      setIsLoading(true);
      const res = await updateOrganizationUserAccess(
        org.id,
        user.id,
        userAccess
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  /*const handleDomainClicked = async (domain: any) => {
    try {
      setSelectedDomain(domain);
      setSelectedProduct(null);
      setRoles(null);
      const res = await getOrganizationDomainProducts(org.id, domain.id);
      setProducts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }*/

  return (
    <>
      <VStack
        spacing="20px"
        alignItems="stretch"
        overflow={"hidden"}
        flex={"1"}
      >
        <VStack
          alignItems="stretch"
          gap={"4px"}
        >
          <Text variant="contentsubheader">Domain Access</Text>
          {domains &&
            userAccess &&
            domains
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((domain: any) => {
                return (
                  <FormControl
                    display="flex"
                    flexDirection="row-reverse"
                    justifyContent="flex-end"
                    alignItems="stretch"
                    gap={"8px"}
                    key={domain.id}
                  >
                    <FormLabel
                      htmlFor={domain.id}
                      width={"100%"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                    >
                      {domain.name}
                    </FormLabel>
                    <Switch
                      id={domain.id}
                      isChecked={userAccess.domains.includes(domain.id)}
                      value={domain.id}
                      onChange={(e: any) => {
                        updateAccess(
                          "domain",
                          e.target.checked ? "add" : "remove",
                          domain.id
                        );
                      }}
                    />
                  </FormControl>
                );
              })}
        </VStack>
        <VStack
          alignItems="stretch"
          overflow={"hidden"}
          flex={"1"}
        >
          <Text variant="contentsubheader">Role Assignments</Text>
          <HStack
            align="stretch"
            spacing="0"
            borderRadius={"4px"}
            border={"1px solid"}
            borderColor={"neutral.300"}
            overflowY={"scroll"}
            flex={"1"}
          >
            <VStack
              backgroundColor="neutral.100"
              alignItems={"stretch"}
              flex={"1"}
              spacing={"0"}
              overflow={"hidden"}
            >
              <VStack
                height={"32px"}
                minHeight={"32px"}
                alignItems={"stretch"}
                justifyContent={"center"}
                padding={"0 20px"}
                background={"neutral.100"}
                borderBottom={"1px solid"}
                borderBottomColor={"neutral.300"}
              >
                <Text
                  variant="titleSmall"
                >
                  Products
                </Text>
              </VStack>
              <VStack
                padding={"16px 20px"}
                alignItems={"stretch"}
                overflowY={"scroll"}
                spacing={"1"}
              >
                {products &&
                  products
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .map((product: any) => {
                      return (
                        <WindowColumnSecondaryButton
                          key={product.id}
                          onClick={() => {
                            handleProductClicked(product);
                          }}
                          active={selectedProduct?.id === product.id}
                        >
                          <Text
                            width={"100%"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                          >
                            {product.name}
                          </Text>
                        </WindowColumnSecondaryButton>
                      );
                    })}
              </VStack>
            </VStack>
            <VStack
              backgroundColor="neutral.200"
              borderLeft="1px solid"
              borderLeftColor="neutral.300"
              alignItems={"stretch"}
              flex={"1"}
              spacing={"0"}
              overflow={"hidden"}
            >
              <VStack
                height={"32px"}
                minHeight={"32px"}
                alignItems={"stretch"}
                justifyContent={"center"}
                padding={"0 20px"}
                background={"neutral.200"}
                borderBottom={"1px solid"}
                borderBottomColor={"neutral.300"}
              >
                <Text
                  variant="titleSmall"
                >
                  Assignments
                </Text>
              </VStack>
              <VStack
                padding={"16px 20px"}
                alignItems={"stretch"}
                overflowY={"scroll"}
                spacing={"1"}
                gap={"8px"}
              >
                {selectedProduct &&
                  roles &&
                  roles
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .map((role: any) => {
                      return (
                        <FormControl
                          display="flex"
                          flexDirection={"row-reverse"}
                          alignItems="stretch"
                          key={role.id}
                          justifyContent="flex-end"
                          gap={"8px"}
                        >
                          <FormLabel
                            htmlFor={role.id}
                            width={"100%"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                          >
                            {role.name}
                          </FormLabel>
                          <Switch
                            id={role.id}
                            isChecked={userAccess.roles.includes(role.id)}
                            value={role.id}
                            onChange={(e: any) => {
                              updateAccess(
                                "role",
                                e.target.checked ? "add" : "remove",
                                role.id
                              );
                            }}
                          />
                        </FormControl>
                      );
                    })}
              </VStack>
            </VStack>
          </HStack>
        </VStack>
        <Button
          variant="primaryBlue"
          size="sm"
          onClick={saveUserAccess}
          alignSelf={"start"}
          loadingText="Updating"
          isLoading={isLoading}
        >
          Update access
        </Button>
      </VStack>
    </>
  );
};
