import React, { useState, useContext, useCallback, useEffect } from "react";
import axios from "axios";
import {
  Input,
  Image,
  Button,
  VStack,
  useToast,
  Text,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Link,
  Flex,
  SimpleGrid,
  FormHelperText,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../providers/authProvider";
import { useLocation } from "react-router-dom";
import { HelmetGeneric } from "../HelmetGeneric";
import { customAlphabet } from "nanoid";
import { validateEmail } from "../../util/email-validator";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SignupPage = () => {
  let query = useQuery();
  const toast = useToast();
  const [submitting, setSubmitting] = useState(false);

  const [platformName, setPlatformName] = useState("");
  const [platformShortName, setPlatformShortName] = useState("");
  const [email, setEmail] = useState("");
  const [canContinue, setCanContinue] = useState(false);
  const [step, setStep] = useState(0);
  const [taken, setTaken] = useState(false);
  const [domain, setDomain] = useState("");

  const { init, signinRedirect } = useContext(AuthContext);

  const handleContinue = () => {
    if (!canContinue) return;
    setStep(1);
  };

  const handleBack = () => {
    setStep(0);
  };

  const checkPrettyName = async (prettyName) => {
    setCanContinue(false);
    try {
      const testPrettyName = prettyName.replace(/\s+/g, "-").toLowerCase();
      const result = await axios.get(
        `${process.env.REACT_APP_API_ROOT}/api/groupcheck/${testPrettyName}`
      );

      if (result.data.data.available === true) {
        setPlatformShortName(testPrettyName);
        setTaken(false);
      } else {
        const nanoid = customAlphabet("1234567890", 10);
        const id = nanoid(5); //=> "f01a2"
        setPlatformShortName(testPrettyName + "-" + id);
        setTaken(true);
      }

      setCanContinue(true);
    } catch (error) {
      showErrorToast(error);
      setSubmitting(false);
    }
  };

  const handlePlatformNameChange = async (e) => {
    setPlatformName(e.target.value);

    if (e.target.value) {
      await checkPrettyName(e.target.value);
    } else {
      setPlatformShortName("");
    }
  };

  const handlePlatformShortNameChange = async (e) => {
    setPlatformShortName(e.target.value);

    if (e.target.value) {
      await checkPrettyName(e.target.value);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    let fullDomain = "https://example.com";

    if (email.includes("@") && email.split("@")[1]) {
      const domain = email.split("@")[1];
      fullDomain = `https://${domain}`;
    }

    setEmail(email);
    setDomain(fullDomain);
  };

  const handleCreateAccount = async () => {
    setSubmitting(true);

    // validate email address
    const validEmail = await validateEmail(email);
    if (!validEmail) {
      showErrorToast(new Error("Invalid email address, please try again."));
      setSubmitting(false);
      return;
    }

    // fire and forget; don't want to hang if this errors for some reason
    const url = window.location.hostname;
    const data = {
      email: email,
      //fullName: `${values.firstName} ${values.lastName}`,
      platform: platformName,
      lead: true,
    };
    if (url !== "core.unitedeffects.com") {
      fetch("https://hooks.zapier.com/hooks/catch/12812081/bw1kigg", {
        method: "POST",
        body: JSON.stringify(data),
        mode: "no-cors",
      });
    }
    try {
      setSubmitting(true);

      const newGroup = {
        name: platformName,
        prettyName: platformShortName,
        owner: email,
        primaryDomain: domain,
        primaryEmail: email,
        config: {
          requireVerified: true,
          autoVerify: true,
          passwordLessSupport: true,
        },
        pluginOptions: {
          notification: {
            enabled: true,
          },
        },
      };

      const newUser = {
        username: email,
        email: email,
        generatePassword: true,
      };

      const groupResult = await axios.post(
        `${process.env.REACT_APP_API_ROOT}/api/group`,
        newGroup
      );

      const authority = process.env.REACT_APP_AUTHORITY_ROOT;
      const clientRoot = process.env.REACT_APP_CLIENT_ROOT;
      const { prettyName, initialAccessToken } = groupResult.data.data;

      await axios.post(
        `${process.env.REACT_APP_API_ROOT}/api/${prettyName}/account`,
        newUser,
        {
          headers: {
            Authorization: `Bearer ${initialAccessToken}`,
          },
        }
      );

      const groupInfo = await axios.get(
        `${process.env.REACT_APP_API_ROOT}/api/group-info/${prettyName}`
      );

      const { id, groupId, group } = groupInfo.data.data;

      init(`${authority}/${groupId}`, id, clientRoot, groupId, group);

      signinRedirect();
    } catch (error) {
      setSubmitting(false);
      showErrorToast(error);
    }
  };

  const showErrorToast = useCallback(
    (error) => {
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: error.message,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  useEffect(() => {
    if (query.get("accountName")) {
      setPlatformName(query.get("accountName"));
      setStep(1);
    }

    if (window && window.pendo) {
      window.pendo.initialize();
    }
  }, [query]);

  return (
    <>
      <HelmetGeneric />

      <Flex
        minH={"100vh"}
        minW={"100%"}
        background={"#FFFDFA"}
        color={"neutral.800"}
      >
        {step === 0 && (
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            padding={{ base: "48px 36px 20px", md: "120px 120px 80px" }}
            minWidth={{ base: "none", md: "600px" }}
            gap={"28px"}
            flex={"1"}
            shadow={"default.500"}
            background={"neutral.0"}
            userSelect={"none"}
          >
            <Image
              src="images/ue-logo-orange.svg"
              alt="United Effects Logo"
              width="128px"
            />
            <Text variant="titleLarge">Create a new company</Text>
            <Text variant="bodySmall">
              When you create your own personal account, you will be assigned as
              the admin of this new company.{" "}
            </Text>
            <FormControl>
              <FormLabel>Company name</FormLabel>
              <Input value={platformName} onChange={handlePlatformNameChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Company URL (used for sign in)</FormLabel>
              <HStack>
                <Flex
                  height="40px"
                  background={"neutral.200"}
                  borderRadius="4px"
                  flexDirection="row"
                  alignItems="center"
                  padding="0px 16px"
                >
                  <Text variant="bodySmall">core.unitedeffects.com/</Text>
                </Flex>
                <Input
                  value={platformShortName}
                  onChange={handlePlatformShortNameChange}
                />
              </HStack>
              <FormHelperText>
                Please keep track of your company URL for future signins.
              </FormHelperText>
            </FormControl>
            {taken && (
              <Flex
                height="28px"
                background="neutral.100"
                border="1px solid"
                borderColor="neutral.200"
                borderRadius="4px"
                justifyContent="center"
                alignItems="center"
                gap="4px"
              >
                <Text variant="bodySmall">
                  {`${platformName
                    .replace(/\s+/g, "-")
                    .toLowerCase()} is already taken`}
                </Text>
              </Flex>
            )}

            <Stack>
              <Text variant="bodySmall">
                Your company name and URL can be changed after you create your
                account. You can also use a custom domain.
              </Text>
            </Stack>
            <Button
              variant="primaryBlue"
              size="xl"
              borderRadius={"44px"}
              onClick={() => {
                handleContinue();
              }}
            >
              Continue
            </Button>
            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text variant="bodySmall">Already have an account?</Text>
              <Link href="/" fontWeight="500" fontSize="14px" color="blue.500">
                Sign In
              </Link>
            </VStack>
            <HStack>
              <ArrowBackIcon color={"neutral.600"} />
              <Link
                href="https://www.unitedeffects.com"
                fontWeight="700"
                fontSize="14px"
                color="neutral.600"
                leftIcon={<ArrowBackIcon />}
              >
                Back to United Effects
              </Link>
            </HStack>
          </VStack>
        )}
        {step === 1 && (
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            padding={{ base: "48px 48px 20px", md: "120px 120px 80px" }}
            minWidth={{ base: "none", md: "600px" }}
            gap={"28px"}
            flex={"1"}
            shadow={"default.500"}
            background={"neutral.0"}
            userSelect={"none"}
          >
            <Image
              src="images/ue-logo-orange.svg"
              alt="United Effects Logo"
              width="128px"
            />
            <Text variant="titleLarge">Create your account</Text>
            <Text variant="bodySmall">
              Your email address will be used to create an admin account to
              manage your company.
            </Text>
            <Text variant="bodySmall">
              We are currently only accepting business domain registrations. If
              you need to register with a public domain like GMAIL or HOTMAIL,
              please contact us.
            </Text>
            <FormControl>
              <FormLabel>Your email</FormLabel>
              <Input value={email} onChange={handleEmailChange} />
            </FormControl>

            <Button
              variant="primaryBlue"
              size="xl"
              borderRadius={"44px"}
              onClick={() => {
                handleCreateAccount();
              }}
              isLoading={submitting}
            >
              Create Account
            </Button>
            <Flex>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleBack}
                leftIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Flex>
          </VStack>
        )}
        <VStack
          flex={"3"}
          display={{ base: "none", lg: "flex" }}
          mt="120px"
          justifyContent={"space-between"}
          userSelect={"none"}
        >
          <VStack
            alignItems={"stretch"}
            gap={"48px"}
            padding={"0 28px"}
            visibility={"hidden"}
          >
            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text
                variant={"labelSmall"}
                color={"slate.700"}
                textAlign={"center"}
              >
                The World's Best Real-time
              </Text>
              <Text
                variant={"titleLarge"}
                color={"slate.700"}
                textAlign={"center"}
              >
                Enterprise Integration Hub
              </Text>
            </VStack>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing="28px"
              maxWidth={"500px"}
            >
              <VStack alignItems={"stretch"}>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Data
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  Real-time, many-2-many, streamed anywhere
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Connectivity
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  On-demand, secured access to any system
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Identity
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  Enterprise SSO with FGA, all customers & systems
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Security
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  API & device auth, full audit, & anomaly detection
                </Text>
              </VStack>
            </SimpleGrid>
          </VStack>
          <Image
            src="images/monument-valley.svg"
            alt="United Effects Header"
            blendMode={"multiply"}
            width={"100%"}
            maxHeight={"60vh"}
          />
        </VStack>
      </Flex>
    </>
  );
};

export default SignupPage;
