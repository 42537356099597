import { Badge, BadgeProps } from "@chakra-ui/react";

export const FormBadge = (props: BadgeProps) => {
  return (
      <Badge
        {...props}
        display={"flex"}
        padding="4px 12px 5px 12px"
        borderRadius={"40px"}
        color="white.900"
        textTransform={"none"}
        fontWeight={"600"}
        fontSize={"14px"}
        letterSpacing={".001em"}
        minWidth={"48px"}
        alignItems={"center"}
        backgroundImage={"linear-gradient(180deg,rgba(255,255,255,.1),rgba(255,255,255,0))"}
      >
        {props.children}
      </Badge>
  );
};
