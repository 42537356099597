import {
  Button,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Image,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Product, Service, Subject } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { CustomTab } from "../CustomTab";
import { FormBadge } from "./FormBadge";
import { SectionHeader } from "./SectionHeader";

export interface SubjectProps {
  client: NattyLightService;
  id: string;
  onEditClicked: () => void;
  onRequestAccessClicked: () => void;
}

export const ViewSubject = ({
  client,
  id,
  onEditClicked,
  onRequestAccessClicked,
}: SubjectProps) => {
  const [subject, setSubject] = React.useState<Subject>();
  const [products, setProducts] = React.useState<Product[]>([]);
  const [services, setServices] = React.useState<Service[]>([]);

  useEffect(() => {
    client.getSubject(id).then((res) => {
      setSubject(res);
    });

    client.getProducts().then((products) => {
      setProducts(products);
    });

    client.getServices().then((services) => {
      setServices(
        services.filter(
          (s: Service) => !s.client_name.startsWith("stream-access-")
        )
      );
    });
  }, [client, id]);

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      {subject && (
        <VStack
          alignItems="stretch"
          height={"100%"}
          spacing={"0"}
        >
          <VStack
            alignItems={"stretch"}
            padding={"20px 20px 0 20px"}
            background={"white.900"}
            spacing={"0"}
          >
            <Text variant="titleLarge" lineHeight={"1.2em"}>
              {subject.name}
            </Text>
            {subject.stream && (
              <HStack
                spacing={"1"}
              >
                <Image
                  src={"/images/stream-card-logo.svg"}
                  marginTop={"2px"}
                />

                <Text variant="bodySmall" lineHeight={"1em"}>
                  {subject.stream}
                </Text>
              </HStack>
            )}
          </VStack>
          <HStack
            padding={"12px 20px 4px 20px"}
            background={"white.900"}
          >
            <Button
              variant={"primaryBlack"}
              size="sm"
              onClick={onEditClicked}
            >
              Edit
            </Button>
            <Button
              variant="primaryOrange"
              size="sm"
              onClick={onRequestAccessClicked}
            >
              Request Access
            </Button>
          </HStack>

          <Tabs
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            overflow={"hidden"}
          >
            <TabList
              background={"white.900"}
              padding={"5px 20px 0 20px"}
              gap={"12px"}
              borderBottom={"1px solid"}
              borderBottomColor={"black.300"}
            >
              <CustomTab>General</CustomTab>
              <CustomTab>Queue Groups</CustomTab>
              <CustomTab>Associations</CustomTab>
            </TabList>

            <TabPanels
              background={"white.800"}
              display={"flex"}
              flexDirection={"column"}
              height={"100%"}
              overflow={"hidden"}
            >
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="General"
                  description={subject.description}
                />
                <HStack
                  spacing={"1"}
                >
                  <Text variant="labelSmall">ID</Text>
                  <Text variant="bodySmall">{subject.id}</Text>
                </HStack>
                <VStack
                  alignItems={"stretch"}
                  spacing={"0"}
                >
                  <HStack
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Created</Text>
                    <Text variant="bodySmall">
                      {dayjs(subject.createdAt).format("MMM DD YYYY h:mm a")}
                    </Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Last Modified</Text>
                    <Text variant="bodySmall">
                      {dayjs(subject.modifiedAt).format("MMM DD YYYY h:mm a")}
                    </Text>
                  </HStack>
                </VStack>

                <VStack
                  alignItems={"stretch"}
                  spacing={"0"}
                >
                  <HStack>
                    <Image src={"/images/stream-card-subscribers.svg"} />
                    <Text variant={"bodyMedium"}>
                      {subject.accessRecords.sub} Subscribers
                    </Text>
                  </HStack>
                  <HStack>
                    <Image
                      src={"/images/stream-card-publishers.svg"} />
                    <Text variant={"bodyMedium"}>
                      {subject.accessRecords.pub} Publishers
                    </Text>
                  </HStack>
                </VStack>
                <SectionHeader
                  title={"Config"}
                  description="Enable bi-directional request/reply usage or allow accessing systems to specify wildcards in their permissions."
                />
                <VStack
                  alignItems={"stretch"}
                  spacing={"2"}
                >
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {subject.service ? "Yes" : "No"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Request/reply Service</Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {subject.allowWildcardAccess ? "Yes" : "No"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Allow Wildcard Access</Text>
                  </HStack>
                </VStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="Queue Groups"
                  description="Queue groups allow you to load balance message consumption across listeners. To use it, specify the queue group when requesting access."
                />
                <Tabs>
                  <TabList
                    gap={"12px"}
                    borderBottom={"1px solid"}
                    borderBottomColor={"black.300"}
                  >
                    <CustomTab>Live Queue Groups</CustomTab>
                    <CustomTab>Archived</CustomTab>
                  </TabList>

                  <TabPanels>
                    <TabPanel padding={"20px 0"}>
                      <VStack
                        alignItems={"stretch"}
                        spacing={"1"}
                      >
                        {subject.queueGroups &&
                          subject.queueGroups.map((qg) => {
                            return (
                              <FormBadge
                                key={qg}
                                backgroundColor={"black.800"}
                              >
                                {qg}
                              </FormBadge>
                            );
                          })}
                      </VStack>
                    </TabPanel>
                    <TabPanel padding={"20px 0"}>
                      <VStack
                        alignItems={"stretch"}
                        spacing={"1"}
                      >
                        {subject.archivedQueueGroups &&
                          subject.archivedQueueGroups.map((qg) => {
                            return (
                              <FormBadge
                                key={qg}
                                backgroundColor={"black.800"}
                              >
                                {qg}
                              </FormBadge>
                            );
                          })}
                      </VStack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </TabPanel>

              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="Associations"
                  description="Associations are ways to make it easier to organize your stream."
                />
                <VStack
                  alignItems={"stretch"}
                  gap={"12px"}
                >
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant="labelSmall">Business Area</Text>
                    {subject.businessDomains &&
                      subject.businessDomains.length === 0 && (
                        <Text variant="bodySmall">
                          No business domains associated with this stream.
                        </Text>
                      )}
                    {subject.businessDomains &&
                      subject.businessDomains.map((bd) => (
                        <Text key={bd} variant="bodySmall">
                          {bd}
                        </Text>
                      ))}
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Tags</Text>
                    {subject.tags && subject.tags.length === 0 && (
                      <Text variant="bodySmall">
                        No tags associated with this stream.
                      </Text>
                    )}
                    <HStack>
                      {subject.tags &&
                        subject.tags.map((tag) => (
                          <FormBadge key={tag} backgroundColor={"orange.500"} mr="2">
                            {tag}
                          </FormBadge>
                        ))}
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Products</Text>
                    {subject.associatedProducts &&
                      subject.associatedProducts.length === 0 && (
                        <Text variant="bodySmall">
                          No products associated with this stream.
                        </Text>
                      )}
                    <HStack>
                      {subject.associatedProducts &&
                        subject.associatedProducts.map((p) => {
                          const name = products.find((prod) => prod.id === p)?.name;
                          return (
                            <FormBadge backgroundColor={"orange.500"}>{name}</FormBadge>
                          );
                        })}
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Services</Text>
                    {subject.associatedServices &&
                      subject.associatedServices.length === 0 && (
                        <Text variant="bodySmall">
                          No services associated with this stream.
                        </Text>
                      )}
                    <HStack>
                      {subject.tags &&
                        subject.associatedServices.map((s) => {
                          const name = services.find(
                            (serv) => serv.client_id === s
                          )?.client_name;
                          return (
                            <FormBadge backgroundColor={"orange.500"}>{name}</FormBadge>
                          );
                        })}
                    </HStack>
                  </VStack>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )
      }
    </VStack >
  );
};
