import axios from "axios";

/*
{  
  "email": "johnsmith@gmail.com",  
  "autocorrect": "",
  "deliverability": "DELIVERABLE",  
  "quality_score": 0.90,  
  "is_valid_format": {
        "value": true,
        "text": "TRUE"
        },
  "is_free_email": {
        "value": true,
        "text": "TRUE"
        },
  "is_disposable_email": {
        "value": false,
        "text": "FALSE"
        },
  "is_role_email": {
        "value": false,
        "text": "FALSE"
        },
  "is_catchall_email": {
        "value": false,
        "text": "FALSE"
        },
  "is_mx_found": {
        "value": true,
        "text": "TRUE"
        },
  "is_smtp_valid": {
        "value": true,
        "text": "TRUE"
        },
  "quality_score": 0.90,  
}
*/

export const validateEmail = async (email: string) => {
  const apiKey =
    process.env.REACT_APP_ABSTRACT_API_KEY ||
    "80cdf860108045938fd7700e92a2eec9";
  const url = `https://emailvalidation.abstractapi.com/v1/?api_key=${apiKey}&email=${email}`;
  const response = await axios.get(url);
  if (
    response.data.deliverability !== "DELIVERABLE" ||
    response.data.is_disposable_email.value ||
    response.data.is_free_email.value
  ) {
    return false;
  } else {
    return true;
  }
};
