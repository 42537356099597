import React from "react";
import styled from "@emotion/styled";


const Button = styled.button`
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  color: #2A2B42;
  border-radius: 4px;
  padding: 2px 6px;
  transition: all 80ms ease-in-out;
  &:hover {
    background-color: #DCDDFF;
    color: #0A0A1E;
  }
`;

const ActiveButton = styled.button`
  font-weight: 500;
  font-size: 13px;
  text-align: left;
  padding: 2px 6px;
  background-color: #7678DB;
  color: #FAFAFF;
  border-radius: 4px;
`;

export const WindowColumnSecondaryButton = (props: any) => {
  if (props.active) {
    return <ActiveButton {...props}>{props.children}</ActiveButton>;
  } else {
    return <Button {...props}>{props.children}</Button>;
  }
};
