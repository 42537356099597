export interface Subject {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date;
  modifiedBy: string;
  authGroup: string;
  cluster: string;
  account: string;
  accessRecords: AccessRecords;
  stream: string;
  name: string;
  description: string;
  service: boolean;
  queueGroups: string[];
  archivedQueueGroups: string[];
  allowWildcardAccess: boolean;
  tags: string[];
  businessDomains: string[];
  associatedProducts: string[];
  associatedServices: string[];
  archived: boolean;
}

export interface NewSubject {
  cluster?: string;
  account?: string;
  accessRecords?: AccessRecords;
  stream?: string;
  name: string;
  description?: string;
  service?: boolean;
  queueGroups?: string[];
  allowWildcardAccess?: boolean;
  tags?: string[];
  businessDomains?: string[];
  associatedProducts?: string[];
  associatedServices?: string[];
}

export interface AccessRecords {
  pub: number;
  sub: number;
}

export interface Stream {
  id: string;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date;
  modifiedBy: string;
  authGroup: string;
  cluster: string;
  account: string;
  accessRecords: number;
  natsInfo: NatsInfo;
  name: string;
  subjects: string[];
  description: string;
  tags: string[];
  businessDomains: string[];
  associatedProducts: string[];
  associatedServices: string[];
  sealed: boolean;
  denyDelete: boolean;
  denyPurge: boolean;
  config: StreamConfig;
  core: boolean;
  imported: boolean;
  archived: boolean;
  fullSubs: Subject[];
}

export interface NewStream {
  name: string;
  subjects: string[];
  description?: string;
  tags?: string[];
  businessDomains?: string[];
  associatedProducts?: string[];
  associatedServices?: string[];
  config?: {
    sealed?: boolean;
    denyDelete?: boolean;
    denyPurge?: boolean;
    allowDirect?: boolean;
  };
}

export interface NewAccess {
  streamConsumers?: NewStreamConsumers;
  stream?: string;
  subscribeSubjectTemplates?: string[];
  requestedSubAccess?: string[];
  publishSubjectTemplates?: string[];
  requestedPubAccess?: string[];
  allowResponses?: boolean;
  responseMax?: number;
  responseTTL?: number;
  receiveResponses?: boolean;
  brief: string;
  description?: string;
  tags?: string[];
  businessDomains?: string[];
  associatedProducts?: string[];
  associatedServices?: string[];
}

export interface NewStreamConsumers {
  potentialPoolCount: number;
}

export interface Access {
  active: boolean;
  userSeed: string;
  tempJwt: TempJwt;
  id: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  owner: string;
  email: string;
  authGroup: string;
  cluster: string;
  account: string;
  userPublicKey: string;
  coreClientId: string;
  coreClientSecret: string;
  responseInboxPrefix: string[];
  streamConsumers: StreamConsumers;
  stream: string;
  subscribeSubjectTemplates: string[];
  requestedSubAccess: string[];
  publishSubjectTemplates: string[];
  requestedPubAccess: string[];
  allowResponses: boolean;
  responseMax: number;
  responseTTL: number;
  receiveResponses: boolean;
  brief: string;
  description: string;
  tags: string[];
  businessDomains: string[];
  associatedProducts: string[];
  associatedServices: string[];
}

export interface TempJwt {
  type: string;
  jwt: string;
  exp: number;
  apk: string;
  upk: string;
  authGroup: string;
}

export interface StreamConsumers {
  potentialPoolNames: string[];
  definedConsumers: string[];
}

export interface StreamConfig {
  sealed: boolean;
  denyDelete: boolean;
  denyPurge: boolean;
  allowDirect: boolean;
  retention: string;
  maxConsumers: number;
  maxMsgs: number;
  maxMsgsPerSubject: number;
  maxMsgSize: number;
  maxBytes: number;
  maxAge: number;
  discard: string;
  performance: string;
}

export interface NatsInfo {
  type: string;
  config: NatsInfoConfig;
  created: Date;
  state: State;
  consumers: Consumer[];
}

export interface NatsInfoConfig {
  name: string;
  subjects: string[];
  retention: string;
  max_consumers: number;
  max_msgs: number;
  max_bytes: number;
  max_age: number;
  max_msgs_per_subject: number;
  max_msg_size: number;
  discard: string;
  storage: string;
  num_replicas: number;
  duplicate_window: number;
  sealed: boolean;
  deny_delete: boolean;
  deny_purge: boolean;
  allow_rollup_hdrs: boolean;
}

export interface Consumer {
  stream_name: string;
  name: string;
  created: Date;
  config: ConsumerConfig;
  delivered: ACKFloor;
  ack_floor: ACKFloor;
  num_ack_pending: number;
  num_redelivered: number;
  num_waiting: number;
  num_pending: number;
  cluster: Cluster;
}

export interface ACKFloor {
  consumer_seq: number;
  stream_seq: number;
}

export interface Cluster {
  name: string;
  replicas: Replica[];
}

export interface Replica {
  name: string;
  current: boolean;
  offline: boolean;
  active: number;
}

export interface ConsumerConfig {
  durable_name: string;
  deliver_policy: string;
  ack_policy: string;
  ack_wait: number;
  max_deliver: number;
  replay_policy: string;
  max_waiting: number;
  max_ack_pending: number;
  num_replicas: number;
}

export interface State {
  messages: number;
  bytes: number;
  first_seq: number;
  first_ts: Date;
  last_seq: number;
  last_ts: Date;
  consumer_count: number;
}

export interface Product {
  id: string;
  name: string;
  description: string;
}

export interface Service {
  client_id: string;
  client_name: string;
}

export interface NewConsumer {
  durable_name: string;
  description: string;
  deliver_policy?: string;
  opt_start_seq?: number;
  opt_start_time?: string;
  ack_policy?: string;
  max_deliver?: number;
  max_ack_pending?: number;
  max_waiting?: number;
  ack_wait?: number;
  filter_subject?: string;
  replay_policy?: string;
  sample_freq?: string;
  rate_limit_bps?: number;
  deliver_group?: string;
}

export interface AccessConsumer {
  type: string;
  stream_name: string;
  name: string;
  created: string;
  config: AccessConsumerConfig;
  delivered: Delivered;
  ack_floor: AckFloor;
  num_ack_pending: number;
  num_redelivered: number;
  num_waiting: number;
  num_pending: number;
  cluster: Cluster;
}

export enum DeliverPolicy {
  All = "all",
  Last = "last",
  New = "new",
  StartSequence = "start-sequence",
  StartTime = "start-time",
  LastPerSubject = "last-per-subject",
}

export enum AckPolicy {
  All = "all",
  Explicit = "explicit",
  None = "none",
  NotSet = "not-set",
}

export enum ReplayPolicy {
  Instant = "instant",
  Original = "original",
}

export interface AccessConsumerConfig {
  durable_name: string;
  description: string;
  deliver_policy: DeliverPolicy;
  ack_policy: AckPolicy;
  max_deliver: number;
  replay_policy: ReplayPolicy;
  deliver_subject: string;
  num_replicas: number;
}

export interface Delivered {
  consumer_seq: number;
  stream_seq: number;
  last_active: string;
}

export interface AckFloor {
  consumer_seq: number;
  stream_seq: number;
}

export interface Cluster {
  name: string;
  leader: string;
}
