import React, { useState } from "react";

const WindowsContext = React.createContext([{}, () => {}]);

const WindowsProvider = (props) => {
  const [windows, setWindows] = useState([]);

  return (
    <WindowsContext.Provider value={[windows, setWindows]}>
      {props.children}
    </WindowsContext.Provider>
  );
}

export { WindowsContext, WindowsProvider };