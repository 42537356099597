import React from "react";
import styled from "@emotion/styled";

const Button = styled.button`
  font-weight: 500;
  font-size: 13px;
  line-height: 1.8em;
  display: block;
  width: 100%;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FAFAFF;
  transition: all 80ms ease-in;
  &:hover {
    color: #B0B2F8;
  }
`;

const ActiveButton = styled.button`
  font-weight: 500;
  display: block;
  font-size: 13px;
  line-height: 1.8em;
  width: 100%;
  letter-spacing: 0.02em;
  text-align: left;
  color: #B0B2F8;
`;

export const WindowColumnPrimaryButton = (props: any) => {
  if (props.active) {
    return <ActiveButton {...props}>{props.children}</ActiveButton>;
  } else {
    return <Button {...props}>{props.children}</Button>;
  }
};
