import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  VStack,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors } from "formik";
import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormInput } from "../FormInput";

interface NewUserProps {
  result: any;
  org?: any;
  newUserEmail?: string;
}

export const NewUser = ({ result, org, newUserEmail }: NewUserProps) => {
  const { createUser, createOrganizationUser } = useContext(AuthContext);
  const [autoGeneratePassword, setAutoGeneratePassword] = React.useState(true);
  interface FormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }

  const initialValues: FormValues = {
    email: newUserEmail ? newUserEmail : "",
    password: "",
    firstName: "",
    lastName: "",
  };

  return (
    <VStack
      alignItems={"stretch"}
      spacing={"0"}
      gap={"20px"}
    >
      <Text variant={"contentheader"}>Create new user</Text>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          let errors: FormikErrors<FormValues> = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password && !autoGeneratePassword) {
            errors.password = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log("values: ", values);
          if (org) {
            try {
              const userBody = {
                email: values.email,
                profile: {
                  givenName: values.firstName,
                  familyName: values.lastName,
                },
              };

              const userResult: any = await createOrganizationUser(
                org,
                userBody
              );
              const newUser: any = userResult.data.data;

              result(newUser);
            } catch (error) {
              console.error(error);
            }
          } else {
            try {
              let user: any = {
                email: values.email,
                username: values.email,
                profile: {
                  givenName: values.firstName,
                  familyName: values.lastName,
                },
              };
              if (!autoGeneratePassword) {
                user.password = values.password;
              } else {
                user.generatePassword = true;
              }

              const userResult = await createUser(user);
              const newUser = userResult.data.data;
              result(newUser);
            } catch (error) {
              console.error(error);
            }

            setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Form>
            <VStack
              spacing={"0"}
              gap={"20px"}
              alignItems={"stretch"}
            >
              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <FormInput
                      {...field}
                      id="email"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="firstName">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.firstName && form.touched.firstName}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <FormInput
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="lastName">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.lastName && form.touched.lastName}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <FormInput
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FormControl
                display="flex"
                flexDirection={"row-reverse"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={"8px"}
              >
                <FormLabel
                  htmlFor="autogeneratePassword"
                >
                  Auto-generate Password
                </FormLabel>
                <Switch
                  id="autogeneratePassword"
                  isChecked={autoGeneratePassword}
                  onChange={(e) => {
                    setAutoGeneratePassword(e.target.checked);
                  }}
                />
              </FormControl>

              {!autoGeneratePassword && (
                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <FormInput
                        {...field}
                        id="password"
                        placeholder="Password"
                        type="password"
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              <Button
                variant="primaryBlue"
                type="submit"
                alignSelf={"flex-start"}
                disabled={props.isSubmitting}
              >
                Create user
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
