import { Avatar, Box, Text, SimpleGrid, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/authProvider";

export const Desktop = () => {
  const { getMyOrganizationProducts, getMyOrganizations } =
    useContext(AuthContext);
  const [products, setProducts] = useState<any>([]);

  useEffect(() => {
    getMyOrganizations()
      .then((res) => {
        const orgs = res.data.data.organizations;
        if (orgs.length > 0) {
          let reqs: any[] = [];
          orgs.forEach((org: any) => {
            reqs.push(getMyOrganizationProducts(org.id));
          });

          // TODO: don't need the additional b2c's
          Promise.all(reqs)
            .then((res) => {
              res.forEach((r: any) => {
                const b2c = r.data.data.b2c;
                const b2b = r.data.data.b2b;
                setProducts((products: any) => [...products, ...b2c, ...b2b]);
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (orgs.length === 0) {
          getMyOrganizationProducts("b2c")
            .then((res) => {
              const b2c = res.data.data.b2c;
              const b2b = res.data.data.b2b;
              setProducts((products: any) => [...products, ...b2c, ...b2b]);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getMyOrganizations, getMyOrganizationProducts]);

  return (
    <SimpleGrid
      columns={2}
      spacing={8}
      maxW="400"
      ml="200"
      position="absolute"
      right="10"
      top="100"
    >
      {products &&
        products.length > 0 &&
        products
          .filter(
            (product: any, index: any, self: any) =>
              self.findIndex((p: any) => p.id === product.id) === index
          )
          .filter((product: any) => product.name && product.url)
          .map((product: any) => (
            <Box key={product.id}>
              <VStack
                onDoubleClick={(e) => {
                  e.preventDefault();
                  window.open(product.url);
                }}
              >
                <Avatar borderRadius={"lg"} name={product.name} />

                <Text
                  lineHeight={1}
                  align={"center"}
                  color="white"
                  _hover={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(product.url);
                  }}
                >
                  {product.name}
                </Text>
              </VStack>
            </Box>
          ))}
    </SimpleGrid>
  );
};
