import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  VStack,
  Text,
  useToast,
  FormHelperText,
} from "@chakra-ui/react";
import {
  ActionMeta,
  OnChangeValue,
  Select,
} from "chakra-react-select";
import React, { useCallback, useEffect } from "react";
import {
  NewStream,
  NewSubject,
  Subject,
} from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormTextarea } from "../FormTextArea";
import { FormInput } from "../FormInput";
import { SectionHeader } from "./SectionHeader";
import { isValidStreamName } from "../../util/streams";

export interface CreateStreamProps {
  client: NattyLightService;
  onDone: () => void;
}

export const CreateStream = ({ client, onDone }: CreateStreamProps) => {
  const toast = useToast();
  const [name, setName] = React.useState("");
  const [subjects, setSubjects] = React.useState<Subject[]>([]);
  const [selectedSubjects, setSelectedSubjects] = React.useState<string[]>([]);
  const [description, setDescription] = React.useState("");
  const [streamNameError, setStreamNameError] = React.useState(false);

  useEffect(() => {
    client.getAvailableSubjects().then((subjects) => {
      setSubjects(subjects);
    });
  }, [client]);

  const subjectOptions = subjects.map((s) => ({
    value: s.name,
    label: s.name,
  }));

  const saveNewStream = async () => {
    try {
      if (!selectedSubjects || selectedSubjects.length === 0) {
        let newSubject: NewSubject = {
          name,
          description,
        };

        const resp = await client.createSubject(newSubject);
        selectedSubjects.push(resp.name);
      }

      let newStream: NewStream = {
        name,
        subjects: selectedSubjects,
        description,
      };

      const resp = await client.createStream(newStream);
      if (!resp) {
        toast({
          title: `Validation Error`,
          description: resp.message,
          status: "error",
          isClosable: true,
        });
      } else {
        onDone();
      }
    } catch (error) {
      console.log(error);
      showErrorToast(error);
    }
  };

  const handleChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedSubjects(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      <VStack
        alignItems="stretch"
        height={"100%"}
        spacing={"0"}
      >
        <HStack
          padding={"20px 20px 0px 20px"}
          background={"white.900"}
          spacing={"1"}
        >
          <Text variant="titleLarge">Create New Stream</Text>
        </HStack>
        <HStack
          padding={"20px 20px 53px 20px"}
          background={"white.900"}
          borderBottom={"1px solid"}
          borderBottomColor={"black.300"}
        >
          <Button
            variant="primaryGreen"
            size="sm"
            onClick={saveNewStream}
            disabled={!name || streamNameError}
          >
            Create New Stream
          </Button>
          <Button
            onClick={onDone}
            variant="primaryBlack"
            size="sm"
          >
            Cancel
          </Button>
        </HStack>
        <VStack
          background={"white.800"}
          alignItems={"stretch"}
          height={"100%"}
          overflowY={"scroll"}
          padding={"20px 20px 240px 20px"}
          gap={"20px"}
        >
          <SectionHeader
            title="General"
          />
          <FormControl isInvalid={streamNameError}>
            <FormLabel>Stream Name (Required)</FormLabel>
            <FormInput
              type="text"
              onChange={(e) => {
                if (!isValidStreamName(e.target.value)) {
                  setStreamNameError(true);
                } else {
                  setStreamNameError(false);
                }
                setName(e.target.value);
              }}
              value={name}
            ></FormInput>
            <FormHelperText>Stream names are case sensitive and alphameric. Other allowed characters are - _</FormHelperText>
            <FormErrorMessage>
              You may not use spaces and the name can only contain characters A-Z
              a-z 0-9 - _
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <FormTextarea
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
            ></FormTextarea>
          </FormControl>
          <SectionHeader
            title="Subjects"
            description="Think of subjects as the data pipes that streams manage. 
            At least one subject must be associated to a stream upon creation. You
            can either create one on the fly with default settings, or select from
            a list of subjects that are not already associated to a stream."
          />
          <Select
            isMulti
            options={subjectOptions}
            onChange={handleChange}
            value={selectedSubjects?.map((s) => {
              return { value: s, label: s };
            })}
          ></Select>
        </VStack>
      </VStack>
    </VStack>
  );
};
