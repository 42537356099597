import React, { useContext, useEffect } from "react";
import axios from "axios";
import { JsonPointer } from "json-ptr";

import {
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Text,
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useClipboard,
  Switch,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { ChevronDownIcon, CopyIcon, MinusIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../providers/authProvider";
import { UEClient } from "../../types";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { DeleteIcon } from "@chakra-ui/icons";

interface ProductProps {
  group: string;
  product: any;
  result: any;
  newClientCreatingResult: any;
  clientSelected: any;
  clientRemoved: any;
  productDeleted: any;
}

export const Product = ({
  group,
  product,
  result,
  newClientCreatingResult,
  clientSelected,
  clientRemoved,
  productDeleted,
}: ProductProps) => {
  const [productData, setProductData] = React.useState(product);
  const [clients, setClients] = React.useState<UEClient[]>([]);
  const [name, setName] = React.useState(product.name);
  const [description, setDescription] = React.useState(product.description);
  const [url, setUrl] = React.useState(product.url);
  const [b2c, setB2C] = React.useState(product.b2c);
  const [selectedClient, setSelectedClient] = React.useState<any>(null);
  const { getClients, deleteProduct, getUser } = useContext(AuthContext);
  const { onCopy } = useClipboard(product?.id);
  const { onCopy: onCopyCodedId } = useClipboard(product?.codedId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = React.useState<any>(null);

  useEffect(() => {
    getClients().then((res: any) => {
      const clients: UEClient[] = res.data.data;
      setClients(clients);
      console.log(clients);
      if (clients.length > 0) {
        const filteredClients = clients.filter((client) =>
          product.associatedClients.includes(client.client_id)
        );
        console.log(filteredClients.length);
        if (filteredClients.length > 0) {
          setSelectedClient(filteredClients[0]);
          clientSelected(filteredClients[0]);
        }
      }
    });
  }, [getClients, product.associatedClients, clientSelected]);

  const addNewClientClicked = (type: any) => {
    newClientCreatingResult(type);
  };

  /*const clientToggled = (clientId, checked) => {
    let associatedClients = JsonPointer.get(productData, "/associatedClients");

    if (checked) {
      // we are trying to add a client to the product
      // get associatedProducts array and push the clientId
      associatedClients.push(clientId);
    } else if (!checked) {
      // we are trying to remove a client from the product
      // get associatedProducts array and push the clientId
      associatedClients = associatedClients.filter(
        (client) => client !== clientId
      );
    }

    updateProduct("/associatedClients", associatedClients);
  };*/

  const updateProduct = async (key: any, value: any) => {
    try {
      const exists = JsonPointer.get(productData, key);
      const op = exists ? "replace" : "add";

      console.log(op, key, value);

      const authUser = await getUser();
      const res: any = axios.patch(
        `${process.env.REACT_APP_API_ROOT}/api/${group}/products/${product.id}`,
        [
          {
            op: op,
            path: key,
            value: value,
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${authUser.access_token}`,
          },
        }
      );

      console.log(res);
      setProductData(res.data.data);
      result(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(product.id);
      productDeleted();
    } catch (error: any) {
      console.log(error);
      setError(error.response.data);
      onOpen();
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="name" color="black.900">
          Name
        </FormLabel>
        <FormInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => {
            updateProduct("/name", e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateProduct("/name", e.currentTarget.value);
            }
          }}
          id="name"
          placeholder=""
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description" color="black.900">
          Description
        </FormLabel>
        <FormInput
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={(e) => updateProduct("/description", e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              updateProduct("/description", e.currentTarget.value);
            }
          }}
          id="description"
          placeholder=""
        />
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start">
          <FormLabel htmlFor="id" color="black.900">
            ID
          </FormLabel>
          <HStack>
            <Text>{product.id}</Text>
            <IconButton
              aria-label="Copy ID"
              icon={<CopyIcon />}
              onClick={onCopy}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start">
          <FormLabel htmlFor="codedId" color="black.900">
            Coded ID
          </FormLabel>
          <HStack>
            <Text>{product.codedId}</Text>
            <IconButton
              aria-label="Copy Coded ID"
              icon={<CopyIcon />}
              onClick={onCopyCodedId}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>
      {!productData?.core && (
        <>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="locked" mb="0" marginRight="6px">
              B2C
            </FormLabel>
            <Switch
              id="b2c"
              isChecked={b2c}
              onChange={(e) => {
                updateProduct("/b2c", e.target.checked);
                setB2C(e.target.checked);
              }}
              height="18px"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="url" color="black.900">
              URL
            </FormLabel>
            <FormInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onBlur={(e) => updateProduct("/url", e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  updateProduct("/url", e.currentTarget.value);
                }
              }}
              id="url"
              placeholder=""
            />
          </FormControl>
        </>
      )}
      <FormControl as="fieldset">
        <HStack pb="10px">
          <Text>Associated Services</Text>
          <Menu>
            <MenuButton
              variant="primaryBlue"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size={"sm"}
            >
              Add
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => addNewClientClicked("new")}>
                Create new
              </MenuItem>
              <MenuItem onClick={() => addNewClientClicked("existing")}>
                Choose existing
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>

        <Stack spacing="10px" direction="column">
          {clients &&
            clients
              .filter((client) =>
                product.associatedClients.includes(client.client_id)
              )
              .sort((a, b) => (a.client_name > b.client_name ? 1 : -1))
              .map((client) => (
                <HStack
                  key={client.client_id}
                  spacing="10px"
                  justifyContent={"space-between"}
                >
                  <WindowColumnSecondaryButton
                    onClick={() => {
                      clientSelected(client);
                      setSelectedClient(client);
                    }}
                    active={selectedClient?.client_id === client.client_id}
                  >
                    {client.client_name}
                  </WindowColumnSecondaryButton>
                  <IconButton
                    variant="primaryRed"
                    size="xs"
                    aria-label="Remove client"
                    icon={<MinusIcon />}
                    onClick={() => {
                      clientRemoved(client);
                    }}
                  />
                </HStack>
              ))}
        </Stack>
      </FormControl>
      <Stack alignItems={"flex-start"}>
        {!productData?.core && (
          <Button
            leftIcon={<DeleteIcon />}
            variant="primaryRed"
            onClick={() => {
              handleDeleteProduct();
            }}
          >
            Delete Product
          </Button>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{error?.message}</Text>
            {error?.data &&
              error.data.map((error: any) => (
                <Text key={error.id}>{error.name}</Text>
              ))}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
