import { extendTheme } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "600",
    fontSize: "13px",
    color: "black.800",
    margin: "0",
  },
};

const Link: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    utilitynavlink: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      padding: "0 12px",
      borderLeft: "1px solid",
      borderLeftColor: "black.0",
      borderRight: "1px solid",
      borderRightColor: "black.0",
      letterSpacing: "0.01em",
      fontWeight: "500",
      color: "neutral.900",
      userSelect: "none",
      transition: "all 80ms ease-in-out",
      _hover: {
        bg: "neutral.300",
        borderLeftColor: "neutral.400",
        borderRightColor: "neutral.400",
        color: "neutral.1000",
        textDecoration: "none",
      },
    },
    mobileutilitynavlink: {
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      borderRadius: "4px",
      padding: "4px 12px",
      border: "1px solid",
      borderColor: "black.0",
      letterSpacing: "0.01em",
      fontWeight: "500",
      color: "neutral.900",
      userSelect: "none",
      transition: "all 80ms ease-in-out",
      _hover: {
        bg: "neutral.300",
        borderColor: "neutral.400",
        color: "neutral.1000",
        textDecoration: "none",
      },
    },
  },
};

const Text: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  variants: {
    windowheader: {
      fontSize: "18px",
      color: "neutral.300",
      margin: "0 0 12px 0",
      fontWeight: "700",
    },
    windowsubheader: {
      color: "neutral.500",
      fontSize: "12px",
      fontWeight: "700",
      margin: "8px 0 0 0",
      padding: "0 0 2px 0",
      borderBottom: "1px solid",
      borderBottomColor: "neutral.700",
    },
    windowsubmenuheader: {
      color: "neutral.900",
      fontSize: "20px",
      marginBottom: "2px",
    },
    windowsubmenusubheader: {
      color: "neutral.700",
      fontSize: "14px",
      fontWeight: "700",
    },
    contentheader: {
      fontSize: "18px",
      color: "neutral.900",
      lineHeight: "20px",
      letterSpacing: "0.015em",
      marginBottom: "4px",
      fontWeight: "600",
    },
    contentsubheader: {
      fontWeight: "600",
      fontSize: "17px",
      borderBottom: "1px solid",
      borderBottomColor: "neutral.300",
      display: "block",
      width: "100%",
    },
    contentsubheader2: {
      fontSize: "16px",
      color: "neutral.800",
      lineHeight: "18px",
      letterSpacing: "0.015em",
      textTransform: "uppercase",
      display: "block",
      width: "100%",
    },
    headline: {
      fontWeight: "400",
      fontSize: "34px",
      letterSpacing: ".005em",
      lineHeight: "1em",
    },
    titleLarge: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "1em",
    },
    titleMedium: {
      fontWeight: "600",
      fontSize: "20px",
      letterSpacing: ".0015em",
      lineHeight: "1em",
    },
    titleSmall: {
      fontWeight: "800",
      fontSize: "12px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      userSelect: "none",
    },
    labelLarge: {
      fontWeight: "700",
      fontSize: "17px",
      letterSpacing: ".0025em",
    },
    labelMedium: {
      fontWeight: "600",
      fontSize: "15px",
    },
    labelSmall: {
      fontWeight: "600",
      fontSize: "13px",
      color: "neutral.800"
    },
    bodyLarge: {
      fontWeight: "500",
      fontSize: "17px",
      letterSpacing: ".0075em",
      lineHeight: "1.3em",
    },
    bodyMedium: {
      fontWeight: "500",
      fontSize: "15px",
      letterSpacing: ".005em",
      lineHeight: "1.3em",
    },
    bodySmall: {
      fontWeight: "500",
      fontSize: "13px",
      letterSpacing: ".0025em",
      lineHeight: "1.3em",
    },
    bodyXsmall: {
      fontWeight: "500",
      fontSize: "11px",
      letterSpacing: ".0025em",
      lineHeight: "1.3em",
    },
  },
};

const Switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      background: "black.500",
      backgroundImage:
        "linear-gradient(180deg,rgba(255,255,255,0),rgba(255,255,255,.1))",
      _checked: {
        background: "blue.500",
      },
    },
    thumb: {
      background: "white.900",
      borderRadius: "16px",
    },
  },
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    lineHeight: "inherit",
    backgroundImage:
      "linear-gradient(180deg,rgba(255,255,255,.1),rgba(255,255,255,0))",
    borderRadius: "4px",
    color: "neutral.100",
    transition: "all 100ms ease-in",
    fontWeight: "500",
    _hover: {
      _disabled: {
        background: "neutral.500",
      },
    },
    _disabled: {
      background: "neutral.500",
      borderColor: "neutral.600",
      color: "neutral.700",
      cursor: "default",
    },
    _focus: {
      boxShadow: "none",
    },
  },
  sizes: {
    xs: {
      padding: "0 10px",
      height: "24px",
      minHeight: "24px",
    },
    sm: {
      fontSize: "13px",
      padding: "0 12px",
      height: "28px",
      minHeight: "28px",
    },
    md: {
      fontSize: "15px",
      padding: "0 14px",
      height: "32px",
      minHeight: "32px",
    },
    lg: {
      fontSize: "17px",
      padding: "0 16px",
      height: "36px",
      minHeight: "36px",
    },
    xl: {
      fontSize: "16px",
      padding: "0 18px",
      height: "44px",
      minHeight: "44px",
    },
  },
  variants: {
    primarynavlink: {
      backgroundImage: "none",
      borderRadius: "4px",
      width: "100%",
      justifyContent: "left",
      height: "28px",
      padding: "0 8px",
      margin: "0",
      transitionDuration: "10ms",
      fontWeight: "400",
      fontSize: "15px",
      letterSpacing: "0.02em",
      border: "1px solid",
      borderColor: "white.0",
      color: "neutral.200",
      _hover: {
        color: "neutral.100",
        backgroundColor: "neutral.800",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "neutral.700",
      },
    },
    primaryBlack: {
      backgroundColor: "neutral.800",
      _hover: {
        backgroundColor: "neutral.700",
      },
    },
    unstyledBlack: {
      backgroundImage: "none",
      color: "neutral.600",
      fontWeight: "700",
      textTransform: "uppercase",
      _hover: {
        color: "neutral.800",
        backgroundColor: "neutral.200",
      },
    },
    unstyledWhite: {
      backgroundImage: "none",
      color: "neutral.200",
      textTransform: "uppercase",
      _hover: {
        color: "neutral.100",
      },
    },
    primaryBlue: {
      backgroundColor: "blue.500",
      border: "1px solid",
      borderColor: "blue.600",
      _hover: {
        backgroundColor: "blue.600",
      },
    },
    primaryOrange: {
      backgroundColor: "orange.500",
      border: "1px solid",
      borderColor: "orange.600",
      _hover: {
        backgroundColor: "orange.600",
      },
    },
    primaryGreen: {
      backgroundColor: "green.400",
      border: "1px solid",
      borderColor: "green.500",
      _hover: {
        backgroundColor: "green.500",
      },
    },
    primaryRed: {
      backgroundColor: "red.500",
      border: "1px solid",
      borderColor: "red.600",
      _hover: {
        backgroundColor: "red.600",
      },
    },
    copyButton: {
      backgroundImage: "none",
      color: "black.700",
      transition: "all 50ms ease-in",
      _hover: {
        color: "black.900",
      },
      _active: {
        backgroundColor: "black.200",
      },
    },
    vaporwaveBlue: {
      background: "linear-gradient(180deg, #464CE8 0%, #3620C0 100%)",
      border: "1px solid #1300EF",
      borderRadius: "28px",
    },
    vaporwavePink: {
      background: "linear-gradient(180deg, #F96D7E 0%, #FC4062 100%)",
      border: "1px solid #EE364C",
      borderRadius: "28px",
    },
    vaporwaveGhost: {
      background: "#FFFFFF",
      border: "1px solid rgba(24, 23, 89, 0.2)",
      borderRadius: "32px",
      color: "#000",
    },
  },
};

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  fonts: {
    heading: `"Inter", system-ui, sans-serif`,
    body: `"Inter", system-ui, sans-serif`,
  },
  shadows: {
    default: {
      500: "0px 24px 22px rgb(30 30 50 / 4%), 0px 9px 8px rgb(30 30 50 / 4%), 0px 5px 4px rgb(30 30 50 / 8%), 0px 3px 2px rgb(30 30 50 / 8%), 0px 1px 0.75px rgb(30 30 50 / 12%)",
      700: "0px 24px 22px rgb(30 30 50 / 16%), 0px 9px 8px rgb(30 30 50 / 16%), 0px 5px 4px rgb(30 30 50 / 36%), 0px 3px 2px rgb(30 30 50 / 16%), 0px 1px 0.75px rgb(30 30 50 / 48%)",
    },
  },
  colors: {
    white: {
      0: "rgba(250, 250, 255, 0)",
      50: "rgba(250, 250, 255, .04)",
      100: "rgba(250, 250, 255, .06)",
      200: "rgba(250, 250, 255, .08)",
      300: "rgba(250, 250, 255, .16)",
      400: "rgba(250, 250, 255, .24)",
      500: "rgba(250, 250, 255, .36)",
      600: "rgba(250, 250, 255, .48)",
      700: "rgba(250, 250, 255, .64)",
      800: "rgba(250, 250, 255, .8)",
      900: "rgba(250, 250, 255, .92)",
      1000: "rgba(250, 250, 255, 1)",
    },
    black: {
      0: "rgba(30, 30, 50, 0)",
      50: "rgba(30, 30, 50, .04)",
      100: "rgba(30, 30, 50, .06)",
      200: "rgba(30, 30, 50, .08)",
      300: "rgba(30, 30, 50, .16)",
      400: "rgba(30, 30, 50, .24)",
      500: "rgba(30, 30, 50, .36)",
      600: "rgba(30, 30, 50, .48)",
      700: "rgba(30, 30, 50, .64)",
      800: "rgba(30, 30, 50, .8)",
      900: "rgba(30, 30, 50, .92)",
      1000: "rgba(30, 30, 50, 1)",
    },
    neutral: {
      0: "#FFFFFF",
      100: "#FAFAFF",
      200: "#E7E8F0",
      300: "#CFD0DF",
      400: "#B2B3C6",
      500: "#9394AB",
      600: "#696A83",
      700: "#45475E",
      800: "#2A2B42",
      900: "#1E1E32",
      1000: "#0A0A1E",
    },
    slate: {
      100: "#F8F8FF",
      200: "#E7E7FF",
      300: "#CECDFF",
      400: "#AFAEE4",
      500: "#9392CF",
      600: "#6463A6",
      700: "#434281",
      800: "#2A2962",
      900: "#1D1C47",
      1000: "#0B0A22",
    },
    blue: {
      200: "#CACCFF",
      300: "#9CA0FF",
      400: "#666BF9",
      500: "#464CE8",
      600: "#242AC3",
      700: "#131896",
    },
    green: {
      200: "#94ECEC",
      300: "#50C9C9",
      400: "#06A7A7",
      500: "#008989",
      600: "#006D6D",
      700: "#016060",
    },
    orange: {
      200: "#FFD2CC",
      300: "#FFAF9D",
      400: "#FF886D",
      500: "#FF6240",
      600: "#F53F18",
      700: "#005353",
    },
    red: {
      200: "#FFC3CE",
      300: "#FF899E",
      400: "#FF637F",
      500: "#FC4062",
      600: "#EB2045",
      700: "#BE0D2D",
    },
  },
  layerStyles: {
    windowmenu: {
      padding: "16px 20px 0 20px",
      flex: "1",
      backgroundColor: "black.900",
      borderLeft: "1px solid",
      borderLeftColor: "neutral.800",
      borderBottom: "1px solid",
      borderBottomColor: "neutral.800",
      width: "200px",
      minWidth: "200px",
      maxWidth: "200px",
    },
    windowsubmenu: {
      align: "left",
      flex: "1",
      width: "240px",
      minWidth: "240px",
      maxWidth: "240px",
      overflowY: "scroll",
      bg: "neutral.100",
    },
    windowheader: {
      flex: "0 0 32px",
      color: "neutral.800",
      fontWeight: "700",
      bg: "neutral.100",
      fontSize: "14px",
      borderBottom: "1px solid",
      borderBottomColor: "neutral.200",
      borderTop: "1px solid",
      borderTopColor: "neutral.0",
      textTransform: "capitalize",
      alignItems: "center",
      padding: "0 12px",
    },
    windowcontentarea: {
      flex: "1",
      overflow: "scroll",
      bg: "neutral.100",
      borderTop: "1px solid",
      borderTopColor: "neutral.0",
      borderBottom: "1px solid",
      borderBottomColor: "neutral.0",
      borderRight: "1px solid",
      borderRightColor: "neutral.0",
      borderLeft: "1px solid",
      borderLeftColor: "neutral.200",
      padding: "28px 32px",
      minWidth: "280px",
    },
    windowsubcontentarea: {
      overflow: "scroll",
      bg: "neutral.100",
      border: "1px solid",
      borderColor: "neutral.200",
      padding: "28px 32px",
      minWidth: "280px",
    },
  },
  styles: {
    global: {
      h1: {},
      h2: {},
      h3: {},
      h4: {},
      h5: {},
      h6: {},
    },
  },
  components: {
    FormLabel,
    Button,
    Text,
    Link,
    Switch,
  },
});

export default theme;
