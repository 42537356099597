import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ActionMeta, AsyncSelect, OnChangeValue } from "chakra-react-select";
import React from "react";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { WindowsContext } from "../../providers/windowsProvider";
import { Products } from "../products/products";
import { Roles } from "../roles/roles";
import { Permissions } from "../permissions/permissions";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface OrganizationProductsProps {
  org: any;
  group: any;
  orgAdmin?: boolean;
}

export const OrganizationProducts = ({
  org,
  group,
  orgAdmin = false,
}: OrganizationProductsProps) => {
  const {
    getOrganizationProducts,
    getProducts,
    updateOrganization,
    getOrganization,
  } = useContext(AuthContext);
  const [products, setProducts] = React.useState<any>([]);
  const [newProduct, setNewProduct] = React.useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = React.useState("");
  const [windows, setWindows] = useContext<any>(WindowsContext);

  useEffect(() => {
    getOrganizationProducts(org.id)
      .then((res) => {
        console.log(res);
        setProducts(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [getOrganizationProducts, org.id]);

  const refreshOrganiztionProducts = async () => {
    try {
      const res = await getOrganizationProducts(org.id);
      setProducts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  async function openWindow(label: string, selectedProduct: any) {
    let children;
    switch (label) {
      case "products":
        children = (
          <Products
            group={group}
            selectedProduct={selectedProduct}
          />
        );
        break;
      case "roles":
        children = (
          <Roles selectedOrg={org} selectedProduct={selectedProduct} />
        );
        break;
      case "permissions":
        children = (
          <Permissions selectedOrg={org} selectedProduct={selectedProduct} orgAdmin={orgAdmin} />
        );
        break;
      default:
        children = <div>{label}</div>;
    }

    const windowsLocal = windows.length > 0 ? [...windows] : [];

    let existingWindow = windowsLocal.find((w) => w.label === label);

    if (existingWindow) {
      // const index = windowsLocal.indexOf(existingWindow);
      // TODO: figure out how to bring the window to the front
    } else {
      windowsLocal.push({
        children,
        isResizable: true,
        isDraggable: true,
        initialPosition: "center",
        label: label,
      });
    }

    setWindows(windowsLocal);
  }

  const loadOptions = async (type: string, inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      try {
        let res;
        if (type === "products") {
          res = await getProducts();
        }
        const response = res.data.data;
        const filtered = response.filter((t: any) =>
          t.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        const options = filtered.map((product: any) => ({
          value: product.id,
          label: product.name,
        }));
        resolve(options);
      } catch (error) {
        console.log(error);
      }
    });

  const loadProductOptions = async (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      return loadOptions("products", inputValue).then((options) =>
        resolve(options)
      );
    });

  const handleProductChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    setNewProduct(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleAddProduct = async () => {
    try {
      const res = await updateOrganization(
        org.id,
        "add",
        "/associatedProducts/-",
        newProduct.value
      );
      console.log(res);
      refreshOrganiztionProducts();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveProduct = async (productId: string) => {
    try {
      const orgData = await getOrganization(org.id);
      const index = orgData.data.data?.associatedProducts?.findIndex(
        (p: string) => p === productId
      );
      const res = await updateOrganization(
        org.id,
        "remove",
        `/associatedProducts/${index}`,
        null
      );
      console.log(res);
      refreshOrganiztionProducts();
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.message);
      onOpen();
    }
  };

  return (
    <VStack
      alignItems={"stretch"}
      spacing={"4"}
    >
      <Text variant="contentheader">
        Licensed Products
      </Text>
      {!orgAdmin && (
        <FormControl>
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            gap={"6px"}
            borderRadius={"6px"}
            backgroundColor={"neutral.200"}
            padding={"6px"}
          >
            <VStack
              borderRadius={"4px"}
              backgroundColor={"neutral.100"}
              padding={"8px"}
              alignItems={"stretch"}
            >
              <VStack
                alignItems={"stretch"}
              >
                <FormLabel>Add products</FormLabel>
                <AsyncSelect
                  defaultOptions
                  isClearable
                  loadOptions={loadProductOptions}
                  onChange={handleProductChange}
                  placeholder="Select a product..."
                />
              </VStack>
              <Button
                variant="primaryBlue"
                disabled={!newProduct}
                onClick={handleAddProduct}
              >
                Add this product
              </Button>
            </VStack>
          </VStack>
        </FormControl>
      )}
      <VStack
        alignItems={"stretch"}
        spacing={"0"}
        gap={"6px"}
        borderRadius={"6px"}
        backgroundColor={"neutral.200"}
        padding={"6px"}
      >
        {products &&
          products.map((product: any) => (
            <VStack
              alignItems={"stretch"}
              spacing={"0"}
              gap={"1"}
              padding={"12px 12px 16px 16px"}
              backgroundColor={"neutral.100"}
              borderRadius={"4px"}
              key={product.id}
            >
              <HStack
                alignItems={"center"}
              >
                <Text flex={"1"} variant="labelLarge">{product.name}</Text>
                {!orgAdmin && !product.core && (
                  <Button
                    variant={"unstyledBlack"}
                    size={"sm"}
                    onClick={() => handleRemoveProduct(product.id)}
                  >
                    Remove
                  </Button>
                )}
              </HStack>
              <Text variant={"bodySmall"}>{product.description}</Text>
              <HStack
                paddingTop={"8px"}
              >
                {!orgAdmin && (
                  <Button
                    variant={"unstyledBlack"}
                    size={"sm"}
                    rightIcon={<ExternalLinkIcon />}
                    iconSpacing={".3rem"}
                    onClick={() => {
                      openWindow("products", product);
                    }}
                  >
                    Admin
                  </Button>
                )}
                <Button
                  variant={"unstyledBlack"}
                  size={"sm"}
                  rightIcon={<ExternalLinkIcon />}
                  iconSpacing={".3rem"}
                  onClick={() => {
                    openWindow("roles", product);
                  }}
                >
                  Roles
                </Button>
                <Button
                  variant={"unstyledBlack"}
                  size={"sm"}
                  rightIcon={<ExternalLinkIcon />}
                  iconSpacing={".3rem"}
                  onClick={() => {
                    openWindow("permissions", product);
                  }}
                >
                  Permissions
                </Button>
              </HStack>
            </VStack>
          ))}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{error}</ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
