import React, { useEffect } from "react";
import axios from "axios";
import { JsonPointer } from "json-ptr";

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  VStack,
  Switch,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { CopyIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../providers/authProvider";
import { FormBadge } from "../streams/FormBadge";

export const User = ({
  user,
  group,
  org,
  onUserRemoved,
  onUserDeleted,
  agAdminView = false,
}) => {
  const [familyName, setFamilyName] = React.useState();
  const [givenName, setGivenName] = React.useState();
  const [externalId, setExternalId] = React.useState();
  const [displayName, setDisplayName] = React.useState();
  const [personalHeader, setPersonalHeader] = React.useState();
  const [bio, setBio] = React.useState();
  const [picture, setPicture] = React.useState();
  const [addressStreet1, setAddressStreet1] = React.useState();
  const [addressStreet2, setAddressStreet2] = React.useState();
  const [addressCity, setAddressCity] = React.useState();
  const [addressState, setAddressState] = React.useState();
  const [addressZip, setAddressZip] = React.useState();
  const [addressCountry, setAddressCountry] = React.useState();
  const [employer, setEmployer] = React.useState();
  const [title, setTitle] = React.useState();
  const [position, setPosition] = React.useState();
  const [createNewProfile, setCreateNewProfile] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [hasSecureProfileAccess, setHasSecureProfileAccess] =
    React.useState(false);
  const [accessRequested, setAccessRequested] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userData, setUserData] = React.useState(null);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [verificationRequested, setVerificationRequested] =
    React.useState(false);
  const { hasCopied, onCopy } = useClipboard(user?.id);
  const { removeOrganizationUserAccess, updateUser, getUserById, getUser } =
    React.useContext(AuthContext);

  useEffect(() => {
    if (org && org.id) {
      getUser().then((authUser) => {
        axios
          .get(
            `${process.env.REACT_APP_API_ROOT}/api/${group}/organization/${org.id}/profile/${user.id}`,
            {
              headers: {
                Authorization: `Bearer ${authUser.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.data) {
              setProfile(res.data.data);
              setFamilyName(res.data.data.familyName);
              setGivenName(res.data.data.givenName);
              setExternalId(res.data.data.externalId);
              setDisplayName(res.data.data.displayName);
              setPersonalHeader(res.data.data.personalHeader);
              setBio(res.data.data.bio);
              setPicture(res.data.data.picture);
              setAddressStreet1(res.data.data.address?.street1);
              setAddressStreet2(res.data.data.address?.street2);
              setAddressCity(res.data.data.address?.city);
              setAddressState(res.data.data.address?.state);
              setAddressZip(res.data.data.address?.postalCode);
              setAddressCountry(res.data.data.address?.country);
              setEmployer(res.data.data.employer);
              setTitle(res.data.data.title);
              setPosition(res.data.data.position);
            }
          })
          .catch((error) => {
            console.log(error.response.status);
            if (error.response.status === 404) {
              setCreateNewProfile(true);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    } else {
      getUserById(user.id).then((res) => {
        setUserData(res.data.data);
        getUser()
          .then((authUser) => {
            axios
              .get(
                `${process.env.REACT_APP_API_ROOT}/api/${group}/profiles/${user.id}`,
                {
                  headers: {
                    Authorization: `Bearer ${authUser.access_token}`,
                  },
                }
              )
              .then((res) => {
                if (res.data.data) {
                  setProfile(res.data.data);
                  setHasSecureProfileAccess(true);
                  setFamilyName(res.data.data.familyName);
                  setGivenName(res.data.data.givenName);
                  setExternalId(res.data.data.externalId);
                  setDisplayName(res.data.data.displayName);
                  setPersonalHeader(res.data.data.personalHeader);
                  setBio(res.data.data.bio);
                  setPicture(res.data.data.picture);
                  setAddressStreet1(res.data.data.address?.street1);
                  setAddressStreet2(res.data.data.address?.street2);
                  setAddressCity(res.data.data.address?.city);
                  setAddressState(res.data.data.address?.state);
                  setAddressZip(res.data.data.address?.postalCode);
                  setAddressCountry(res.data.data.address?.country);
                  setEmployer(res.data.data.employer);
                  setTitle(res.data.data.title);
                  setPosition(res.data.data.position);
                }
              })
              .catch((error) => {
                console.log(error.response.status);
                if (error.response.status === 403) {
                  setHasSecureProfileAccess(false);
                }
              })
              .finally(() => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, [user, group, org, getUserById, getUser]);

  const updateProfile = (key, value) => {
    key = key.startsWith("/") ? key : `/${key}`;
    const exists = JsonPointer.get(org, key);
    const op = exists ? "replace" : "add";

    if (createNewProfile) {
      const strippedKey = key.split("/").pop();
      let body = { accountId: user.id };
      body[strippedKey] = value;
      getUser().then((user) => {
        axios
          .post(
            `${process.env.REACT_APP_API_ROOT}/api/${group}/organization/${org.id}/profiles`,
            body,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setCreateNewProfile(false);
          });
      });
    } else {
      console.log(key.split("/")[1]);
      if (key.split("/")[1] === "address" && !profile.address) {
        let address = {};
        const newKey = key.split("/")[2];
        address[newKey] = value;
        getUser().then((user) => {
          axios
            .patch(
              `${process.env.REACT_APP_API_ROOT}/api/${group}/organization/${org.id}/profile/${user.id}`,
              [
                {
                  op: op,
                  path: "/address",
                  value: address,
                },
              ],
              {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                },
              }
            )
            .then((res) => {
              console.log(res);
            });
        });
      } else {
        getUser().then((user) => {
          axios
            .patch(
              `${process.env.REACT_APP_API_ROOT}/api/${group}/organization/${org.id}/profile/${user.id}`,
              [
                {
                  op: op,
                  path: key,
                  value: value,
                },
              ],
              {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                },
              }
            )
            .then((res) => {
              console.log(res);
            });
        });
      }
    }
  };

  return (
    <>
      <VStack
        alignItems={"stretch"}
        spacing={"0"}
      >
        <Text variant="labelSmall">User</Text>
        <Text variant="labelLarge">{user.email}</Text>
      </VStack>

      <VStack alignItems={"flex-start"} spacing={"0"}>
        <HStack spacing={"1"}>
          <Text variant="labelSmall">User ID</Text>
          <IconButton
            aria-label="Copy User ID"
            icon={<CopyIcon />}
            onClick={onCopy}
            size="xs"
            variant="copyButton"
          />
          <Text variant="labelSmall">{hasCopied && "Copied!"}</Text>
        </HStack>
        <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
          {user.id}
        </Text>
      </VStack>

      <VStack
        alignItems={"stretch"}
        spacing="0"
        gap="24px"
      >
        {agAdminView ? (
          <>
            <VStack
              alignItems={"stretch"}
              spacing="0"
              gap="12px"
            >
              <Text variant="contentsubheader" mb="8px">Account Status</Text>
              <FormControl
                display="flex"
                flexDirection="row-reverse"
                justifyContent={"flex-end"}
                gap={"8px"}
                alignItems={"center"}
              >
                {verificationRequested && <Text variant="bodySmall">Success!</Text>}
                {!userData?.verified && (
                  <Button
                    size="xs"
                    variant="primaryBlue"
                    onClick={async () => {
                      try {
                        const user = await getUser();
                        const res = await axios.post(
                          `${process.env.REACT_APP_API_ROOT}/api/${group}/operations/user/${userData.id}`,
                          {
                            operation: "verify_account",
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${user.access_token}`,
                            },
                          }
                        );
                        console.log(res.data);
                        setVerificationRequested(true);
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Send Request
                  </Button>
                )}
                <FormLabel htmlFor="verified">Verified</FormLabel>
                <Switch
                  isChecked={userData?.verified}
                  onChange={async (e) => {
                    console.log(e.target.checked);
                    const res = await updateUser(
                      user,
                      "verified",
                      e.target.checked
                    );
                    setUserData(res.data.data);
                  }}
                  id="verified"
                  placeholder=""
                />
              </FormControl>
              <FormControl
                display="flex"
                flexDirection="row-reverse"
                justifyContent={"flex-end"}
                gap={"8px"}
                alignItems={"center"}
              >
                <FormLabel htmlFor="locked">Locked</FormLabel>
                <Switch
                  isChecked={userData?.userLocked}
                  onChange={async (e) => {
                    const res = await updateUser(
                      user,
                      "userLocked",
                      e.target.checked
                    );
                    setUserData(res.data.data);
                  }}
                  id="locked"
                  placeholder=""
                />
              </FormControl>
              <FormControl
                display="flex"
                flexDirection="row-reverse"
                justifyContent={"flex-end"}
                gap={"8px"}
                alignItems={"center"}
              >
                <FormLabel htmlFor="blocked">Blocked</FormLabel>
                <Switch
                  isChecked={userData?.blocked}
                  onChange={async (e) => {
                    const res = await updateUser(user, "blocked", e.target.checked);
                    setUserData(res.data.data);
                  }}
                  id="blocked"
                  placeholder=""
                />
              </FormControl>
              <HStack
                alignItems={"center"}
                paddingTop={"12px"}
              >
                <Button
                  variant="primaryBlue"
                  size="xs"
                  onClick={async () => {
                    try {
                      const user = await getUser();
                      const res = await axios.post(
                        `${process.env.REACT_APP_API_ROOT}/api/${group}/operations/reset-user-password`,
                        {
                          email: userData.email,
                          formats: ["email"],
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${user.access_token}`,
                          },
                        }
                      );
                      console.log(res.data);
                      setResetPassword(true);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Reset Password
                </Button>
                {resetPassword && <Text variant="bodySmall">Success!</Text>}
              </HStack>
            </VStack>
          </>
        ) : (
          <>
            <VStack
              alignItems={"stretch"}
              spacing="0"
              gap="12px"
            >
              <Text variant="contentsubheader">Account Status</Text>
              <FormControl
                display="flex"
                alignItems="center"
                flexDirection="row"
                gap={"6px"}
              >
                <FormLabel htmlFor="verified">
                  Verified
                </FormLabel>
                <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                  {user.verified ? "Yes" : "No"}
                </FormBadge>
              </FormControl>
              <FormControl
                display="flex"
                alignItems="center"
                flexDirection="row"
                gap={"6px"}
              >
                <FormLabel htmlFor="locked">
                  Locked
                </FormLabel>
                <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                  {user.userLocked ? "Yes" : "No"}
                </FormBadge>
              </FormControl>
              <FormControl
                display="flex"
                alignItems="center"
                flexDirection="row"
                gap={"6px"}
              >
                <FormLabel htmlFor="blocked" mb="0">
                  Blocked
                </FormLabel>
                <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                  {user.blocked ? "Yes" : "No"}
                </FormBadge>
              </FormControl>
            </VStack>
          </>
        )}

        {!hasSecureProfileAccess && !isLoading && !org && (
          <HStack>
            <Button
              variant="primaryBlue"
              size="xs"
              onClick={async () => {
                try {
                  const authUser = await getUser();
                  const res = await axios.post(
                    `${process.env.REACT_APP_API_ROOT}/api/${group}/profile/request/${user.id}`,
                    {
                      type: "access",
                      requestDetails:
                        "This is a request to access your secure profile",
                      accessExpirationTime: "30", // TODO: '1', '7', '30', '90', '180', '360', 'unlimited'
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${authUser.access_token}`,
                      },
                    }
                  );
                  console.log(res.data);
                  setAccessRequested(true);
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Request secure profile access
            </Button>
            {accessRequested && <Text variant={"bodySmall"}>Access Requested</Text>}
          </HStack>
        )}
        {!org && hasSecureProfileAccess && (
          <>
            <Text variant="contentsubheader" mb="8px">User Information</Text>
            <FormControl>
              <FormLabel htmlFor="givenName">First Name (Given Name)</FormLabel>
              <FormInput
                value={givenName}
                readOnly
                id="givenName"
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="familyName">Last Name (Family Name)</FormLabel>
              <FormInput
                value={familyName}
                readOnly
                id="familyName"
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="externalId">External ID</FormLabel>
              <FormInput
                value={externalId}
                readOnly
                id="externalId"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="displayName">Display Name</FormLabel>
              <FormInput
                value={displayName}
                readOnly
                id="displayName"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="personalHeader">Personal Header</FormLabel>
              <FormInput
                value={personalHeader}
                readOnly
                id="personalHeader"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="bio">Bio</FormLabel>
              <FormInput value={bio} readOnly id="bio" placeholder="" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="picture">Picture</FormLabel>
              <FormInput value={picture} readOnly id="picture" placeholder="" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressStreet1">Street Address</FormLabel>
              <FormInput
                value={addressStreet1}
                readOnly
                id="addressStreet1"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressStreet2">Street Address 2</FormLabel>
              <FormInput
                value={addressStreet2}
                readOnly
                id="addressStreet2"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressCity">City</FormLabel>
              <FormInput
                value={addressCity}
                readOnly
                id="addressCity"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressState">State</FormLabel>
              <FormInput
                value={addressState}
                readOnly
                id="addressState"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
              <FormInput
                value={addressZip}
                readOnly
                id="addressZip"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressCountry">Country</FormLabel>
              <FormInput
                value={addressCountry}
                readOnly
                id="addressCountry"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="employer">Employer</FormLabel>
              <FormInput value={employer} readOnly id="employer" placeholder="" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="title">Title</FormLabel>
              <FormInput value={title} readOnly id="title" placeholder="" />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="position">Position</FormLabel>
              <FormInput value={position} readOnly id="position" placeholder="" />
            </FormControl>
          </>
        )}
        {!org && (
          <HStack>
            <Button
              variant="primaryRed"
              size="xs"
              onClick={async () => {
                try {
                  const authUser = await getUser();
                  const res = await axios.delete(
                    `${process.env.REACT_APP_API_ROOT}/api/${group}/account/${user.id}`,
                    {
                      headers: {
                        Authorization: `Bearer ${authUser.access_token}`,
                      },
                    }
                  );
                  console.log(res.data);
                  onUserDeleted();
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Delete User
            </Button>
          </HStack>
        )}
        {org && org.id && (
          <>
            <Text variant="contentsubheader" mb="8px">User Information</Text>
            <FormControl>
              <FormLabel htmlFor="givenName">First Name (Given Name)</FormLabel>
              <FormInput
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                onBlur={(e) => updateProfile("givenName", e.target.value)}
                id="givenName"
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="familyName">Last Name (Family Name)</FormLabel>
              <FormInput
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                onBlur={(e) => updateProfile("familyName", e.target.value)}
                id="familyName"
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="externalId">External ID</FormLabel>
              <FormInput
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
                onBlur={(e) => updateProfile("externalId", e.target.value)}
                id="externalId"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="displayName">Display Name</FormLabel>
              <FormInput
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                onBlur={(e) => updateProfile("displayName", e.target.value)}
                id="displayName"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="personalHeader">Personal Header</FormLabel>
              <FormInput
                value={personalHeader}
                onChange={(e) => setPersonalHeader(e.target.value)}
                onBlur={(e) => updateProfile("personalHeader", e.target.value)}
                id="personalHeader"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="bio">Bio</FormLabel>
              <FormInput
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                onBlur={(e) => updateProfile("bio", e.target.value)}
                id="bio"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="picture">Picture</FormLabel>
              <FormInput
                value={picture}
                onChange={(e) => setPicture(e.target.value)}
                onBlur={(e) => updateProfile("picture", e.target.value)}
                id="picture"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressStreet1">Street Address</FormLabel>
              <FormInput
                value={addressStreet1}
                onChange={(e) => setAddressStreet1(e.target.value)}
                onBlur={(e) => updateProfile("address/street1", e.target.value)}
                id="addressStreet1"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressStreet2">Street Address 2</FormLabel>
              <FormInput
                value={addressStreet2}
                onChange={(e) => setAddressStreet2(e.target.value)}
                onBlur={(e) => updateProfile("address/street2", e.target.value)}
                id="addressStreet2"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressCity">City</FormLabel>
              <FormInput
                value={addressCity}
                onChange={(e) => setAddressCity(e.target.value)}
                onBlur={(e) => updateProfile("address/city", e.target.value)}
                id="addressCity"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressState">State</FormLabel>
              <FormInput
                value={addressState}
                onChange={(e) => setAddressState(e.target.value)}
                onBlur={(e) => updateProfile("address/state", e.target.value)}
                id="addressState"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
              <FormInput
                value={addressZip}
                onChange={(e) => setAddressZip(e.target.value)}
                onBlur={(e) =>
                  updateProfile("address/postalCode", e.target.value)
                }
                id="addressZip"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="addressCountry">Country</FormLabel>
              <FormInput
                value={addressCountry}
                onChange={(e) => setAddressCountry(e.target.value)}
                onBlur={(e) => updateProfile("address/country", e.target.value)}
                id="addressCountry"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="employer">Employer</FormLabel>
              <FormInput
                value={employer}
                onChange={(e) => setEmployer(e.target.value)}
                onBlur={(e) => updateProfile("employer", e.target.value)}
                id="employer"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="title">Title</FormLabel>
              <FormInput
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={(e) => updateProfile("title", e.target.value)}
                id="title"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="position">Position</FormLabel>
              <FormInput
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                onBlur={(e) => updateProfile("position", e.target.value)}
                id="position"
                placeholder=""
              />
            </FormControl>
            <HStack>
              <Button
                variant="primaryRed"
                size="sm"
                onClick={() => {
                  try {
                    const res = removeOrganizationUserAccess(org.id, user.id);
                    console.log(res);
                    onUserRemoved();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Remove user from organization
              </Button>
            </HStack>
          </>
        )}
      </VStack>
    </>
  );
};
