import React, { useContext, useEffect } from "react";

import { Button, FormControl, FormLabel, HStack, Text } from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { FormTextarea } from "../FormTextArea";
import { AuthContext } from "../../providers/authProvider";
import { DeleteIcon } from "@chakra-ui/icons";

export const SecureProfile = () => {
  const [profile, setProfile] = React.useState(null as any);
  const [familyName, setFamilyName] = React.useState();
  const [givenName, setGivenName] = React.useState();
  const [displayName, setDisplayName] = React.useState();
  const [personalHeader, setPersonalHeader] = React.useState();
  const [bio, setBio] = React.useState();
  const [picture, setPicture] = React.useState();
  const [addressStreet1, setAddressStreet1] = React.useState();
  const [addressStreet2, setAddressStreet2] = React.useState();
  const [addressCity, setAddressCity] = React.useState();
  const [addressState, setAddressState] = React.useState();
  const [addressZip, setAddressZip] = React.useState();
  const [addressCountry, setAddressCountry] = React.useState();
  const [employer, setEmployer] = React.useState();
  const [title, setTitle] = React.useState();
  const [position, setPosition] = React.useState();

  const {
    getSecureProfile,
    updateSecureProfile,
    createSecureProfile,
    deleteSecureProfile,
  } = useContext(AuthContext);

  useEffect(() => {
    getSecureProfile()
      .then((res) => {
        setProfile(res.data.data);

        if (res.data.data) {
          setFamilyName(res.data.data.familyName);
          setGivenName(res.data.data.givenName);
          setDisplayName(res.data.data.displayName);
          setPersonalHeader(res.data.data.personalHeader);
          setBio(res.data.data.bio);
          setPicture(res.data.data.picture);
          setAddressStreet1(res.data.data.address?.street1);
          setAddressStreet2(res.data.data.address?.street2);
          setAddressCity(res.data.data.address?.city);
          setAddressState(res.data.data.address?.state);
          setAddressZip(res.data.data.address?.postalCode);
          setAddressCountry(res.data.data.address?.country);
          setEmployer(res.data.data.employer);
          setTitle(res.data.data.title);
          setPosition(res.data.data.position);
        }
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          setProfile(null);
        } else {
          console.error(err);
        }
      });
  }, [getSecureProfile]);

  const updateProfile = async (key: string, value: any) => {
    if (profile) {
      try {
        if (key.split("/")[0] === "address" && !profile.address) {
          let address = {} as any;
          const newKey = key.split("/")[1];
          address[newKey] = value;
          const res = await updateSecureProfile(profile, "/address", address);
          setProfile(res.data.data);
        } else {
          const res = await updateSecureProfile(profile, key, value);
          setProfile(res.data.data);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        let profile = {} as any;
        profile[key] = value;
        const res = await createSecureProfile(profile);
        setProfile(res.data.data);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const deleteProfile = async () => {
    if (profile) {
      try {
        await deleteSecureProfile();
        setProfile(null);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <Text variant="contentsubheader">
        Name
      </Text>

      <FormControl width="300px">
        <FormLabel htmlFor="givenName">First Name (Given Name)</FormLabel>
        <FormInput
          value={givenName}
          onChange={(e: any) => setGivenName(e.target.value)}
          onBlur={(e) => updateProfile("givenName", e.target.value)}
          id="givenName"
          placeholder=""
        />
      </FormControl>
      <FormControl width="300px">
        <FormLabel htmlFor="familyName">Last Name (Family Name)</FormLabel>
        <FormInput
          value={familyName}
          onChange={(e: any) => setFamilyName(e.target.value)}
          onBlur={(e) => updateProfile("familyName", e.target.value)}
          id="familyName"
          placeholder=""
        />
      </FormControl>

      <FormControl width="300px">
        <FormLabel htmlFor="displayName">Display Name (Nickname)</FormLabel>
        <FormInput
          value={displayName}
          onChange={(e: any) => setDisplayName(e.target.value)}
          onBlur={(e) => updateProfile("displayName", e.target.value)}
          id="displayName"
          placeholder=""
        />
      </FormControl>

      <Text variant="contentsubheader">
        Profile
      </Text>

      <FormControl width="480px">
        <FormLabel htmlFor="personalHeader">Personal Header</FormLabel>
        <FormInput
          value={personalHeader}
          onChange={(e: any) => setPersonalHeader(e.target.value)}
          onBlur={(e) => updateProfile("personalHeader", e.target.value)}
          id="personalHeader"
          placeholder=""
        />
      </FormControl>

      <FormControl width="480px">
        <FormLabel htmlFor="bio">Bio</FormLabel>
        <FormTextarea
          background="#fff"
          value={bio}
          onChange={(e: any) => setBio(e.target.value)}
          onBlur={(e) => updateProfile("bio", e.target.value)}
          id="bio"
          placeholder=""
        ></FormTextarea>
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="picture">Picture</FormLabel>
        <FormInput
          value={picture}
          onChange={(e: any) => setPicture(e.target.value)}
          onBlur={(e) => updateProfile("picture", e.target.value)}
          id="picture"
          placeholder=""
        />
      </FormControl>

      <Text variant="contentsubheader">
        Address
      </Text>

      <FormControl width="480px">
        <FormLabel htmlFor="addressStreet1">Street Address</FormLabel>
        <FormInput
          value={addressStreet1}
          onChange={(e: any) => setAddressStreet1(e.target.value)}
          onBlur={(e) => updateProfile("address/street1", e.target.value)}
          id="addressStreet1"
          placeholder=""
        />
      </FormControl>

      <FormControl width="480px">
        <FormLabel htmlFor="addressStreet2">Street Address 2</FormLabel>
        <FormInput
          value={addressStreet2}
          onChange={(e: any) => setAddressStreet2(e.target.value)}
          onBlur={(e) => updateProfile("address/street2", e.target.value)}
          id="addressStreet2"
          placeholder=""
        />
      </FormControl>

      <FormControl width="300px">
        <FormLabel htmlFor="addressCity">City</FormLabel>
        <FormInput
          value={addressCity}
          onChange={(e: any) => setAddressCity(e.target.value)}
          onBlur={(e) => updateProfile("address/city", e.target.value)}
          id="addressCity"
          placeholder=""
        />
      </FormControl>

      <FormControl width="240px">
        <FormLabel htmlFor="addressState">State</FormLabel>
        <FormInput
          value={addressState}
          onChange={(e: any) => setAddressState(e.target.value)}
          onBlur={(e) => updateProfile("address/state", e.target.value)}
          id="addressState"
          placeholder=""
        />
      </FormControl>

      <FormControl width="120px">
        <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
        <FormInput
          value={addressZip}
          onChange={(e: any) => setAddressZip(e.target.value)}
          onBlur={(e) => updateProfile("address/postalCode", e.target.value)}
          id="addressZip"
          placeholder=""
        />
      </FormControl>

      <FormControl width="200px">
        <FormLabel htmlFor="addressCountry">Country</FormLabel>
        <FormInput
          value={addressCountry}
          onChange={(e: any) => setAddressCountry(e.target.value)}
          onBlur={(e) => updateProfile("address/country", e.target.value)}
          id="addressCountry"
          placeholder=""
        />
      </FormControl>

      <Text variant="contentsubheader">
        Employment
      </Text>

      <FormControl width="300px">
        <FormLabel htmlFor="employer">Company</FormLabel>
        <FormInput
          value={employer}
          onChange={(e: any) => setEmployer(e.target.value)}
          onBlur={(e) => updateProfile("employer", e.target.value)}
          id="employer"
          placeholder=""
        />
      </FormControl>

      <FormControl width="300px">
        <FormLabel htmlFor="title">Title</FormLabel>
        <FormInput
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          onBlur={(e) => updateProfile("title", e.target.value)}
          id="title"
          placeholder=""
        />
      </FormControl>

      <FormControl width="300px">
        <FormLabel htmlFor="position">Position</FormLabel>
        <FormInput
          value={position}
          onChange={(e: any) => setPosition(e.target.value)}
          onBlur={(e) => updateProfile("position", e.target.value)}
          id="position"
          placeholder=""
        />
      </FormControl>

      {profile && (
        <HStack>
          <Button
            leftIcon={<DeleteIcon />}
            variant="primaryRed"
            size="sm"
            flex="none"
            onClick={deleteProfile}
          >
            Delete Secure Profile
          </Button>
        </HStack>
      )}
    </>
  );
};
