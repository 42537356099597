import { Helmet } from "react-helmet";

export const HelmetGeneric = () => {
  return (
    <Helmet>
      <title>{`United Effects - Core EOS`}</title>
      <link rel="icon" href="favicon.ico" />
    </Helmet>
  );
};
