import axios, { AxiosInstance } from "axios";
import { NewAccess, NewConsumer, NewStream, NewSubject } from "./Models";

export class NattyLightService {
  private authToken: string;
  private group: string;
  private apiRoot =
    process.env.REACT_APP_NATTY_LITE_API_ROOT ||
    "https://stream.dev.uecore.io/api";
  private client: AxiosInstance;

  constructor(authToken: string, group: string) {
    this.authToken = authToken;
    this.group = group;

    this.client = axios.create({ baseURL: this.apiRoot });
    if (this.client.defaults.headers) {
      this.client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.authToken}`;
    }

    this.client.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.data.data) {
          return response.data.data;
        } else {
          return Promise.reject(response.data);
        }
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  /* Mapping */

  public async createMapping(productId: string): Promise<any> {
    return this.client.post(`${this.apiRoot}/${this.group}/map`, {
      simpleStreamProductId: productId,
    });
  }

  public async getMapping(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/map`);
  }

  public async updateMapping(): Promise<any> {}

  public async executeMappingOperation(): Promise<any> {}

  /* Subjects */

  public async createSubject(newSubject: NewSubject): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/subject`,
      newSubject
    );
  }

  public async getSubjects(): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/subject`
    );
  }

  public async searchSubjects(term: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/subject?search=${term}`
    );
  }

  public async getAvailableSubjects(): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/subject?$filter=stream eq null`
    );
  }

  public async updateSubject(id: string, patch: Array<any>): Promise<any> {
    return this.client.patch(
      `${this.apiRoot}/${this.group}/shared/simple/subject/${id}`,
      patch
    );
  }

  public async getSubject(id: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/subject/${id}`
    );
  }

  public async deleteSubject(id: string): Promise<any> {
    return this.client.delete(
      `${this.apiRoot}/${this.group}/shared/simple/subject/${id}`
    );
  }

  /* Streams */

  public async createStream(newStream: NewStream): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/stream`,
      newStream
    );
  }

  public async getStreams(): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/stream`
    );
  }

  public async searchStreams(term: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/stream?search=${term}`
    );
  }

  public async updateStream(id: string, patch: Array<any>): Promise<any> {
    return this.client.patch(
      `${this.apiRoot}/${this.group}/shared/simple/stream/${id}`,
      patch
    );
  }

  public async getStream(id: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/stream/${id}`
    );
  }

  public async deleteStream(id: string): Promise<any> {
    return this.client.delete(
      `${this.apiRoot}/${this.group}/shared/simple/stream/${id}`
    );
  }

  public async sendOpToStream(
    stream: string,
    operation: string,
    data: any
  ): Promise<any> {
    /*

    {
      "stId": "minimal-2",
      "op": "publish-message",
      "data": {"subject": "minimal-2",
          "payload": {
            "message": "the payload can be an object like this or just a string"
          }}
    }
    */

    const payload = {
      stId: stream,
      op: operation,
      data: data,
    };

    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/stream-op`,
      payload
    );
  }

  /* Metadata */

  public async getServices(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/metadata/services`);
  }

  public async getProducts(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/metadata/products`);
  }

  public async getTags(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/metadata/tags`);
  }

  public async getBusinessDomains(): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/metadata/business-domains`
    );
  }

  /* Access */

  public async getAccesses(): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/access`
    );
  }

  public async getAccess(id: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/access/${id}`
    );
  }

  public async createAccess(newAccess: NewAccess): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/access`,
      newAccess
    );
  }

  public async deleteAccess(id: string): Promise<any> {
    return this.client.delete(
      `${this.apiRoot}/${this.group}/shared/simple/access/${id}`
    );
  }

  public async runAccessOperation(id: string, operation: string): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/access-op`,
      {
        aId: id,
        op: operation,
      }
    );
  }

  /* Consumers */

  public async getConsumers(accessId: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/access/${accessId}/consumer`
    );
  }

  public async getConsumer(accessId: string, id: string): Promise<any> {
    return this.client.get(
      `${this.apiRoot}/${this.group}/shared/simple/access/${accessId}/consumer/${id}`
    );
  }

  public async createConsumer(
    accessId: string,
    newAccess: NewConsumer
  ): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/access/${accessId}/consumer`,
      newAccess
    );
  }

  public async deleteConsumer(accessId: string, id: string): Promise<any> {
    return this.client.delete(
      `${this.apiRoot}/${this.group}/shared/simple/access/${accessId}/consumer/${id}`
    );
  }

  public async requestNewJWT(
    publicKey: string,
    coreClientId: string,
    expires: number
  ): Promise<any> {
    return this.client.post(
      `${this.apiRoot}/${this.group}/shared/simple/access-op/jwt`,
      {
        publicKey: publicKey,
        coreClientId: coreClientId,
        expires: expires,
      }
    );
  }
}

/*private static instance: NattyLightService;
    
    private constructor() {}
    
    public static getInstance(): NattyLightService {
        if (!NattyLightService.instance) {
        NattyLightService.instance = new NattyLightService();
        }
    
        return NattyLightService.instance;
    }
    
    public getNattyLight(): NattyLight {
        return {
        name: "Natty Light",
        price: 2.99,
        alcoholPercentage: 4.2,
        };
    }*/
