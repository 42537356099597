import React, { useContext, useEffect } from "react";
import * as dayjs from "dayjs";

import {
  VStack,
  Flex,
  Text,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  useClipboard,
  Switch,
  FormHelperText,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Link,
  Skeleton,
  SimpleGrid,
  Box,
  Divider,
} from "@chakra-ui/react";

import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { AuthContext } from "../../providers/authProvider";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { FormInput } from "../FormInput";
import { SecureProfile } from "./secureProfile";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";

const primaryColumnOptions = [
  { name: "access", label: "Credentials" },
  { name: "profile", label: "Secure Profile" },
  { name: "notifications", label: "Requests & Notifications" },
  { name: "orgs", label: "My Organizations" },
  { name: "settings", label: "Settings" },
];

export const Profile = ({ group, agMfaEnabled }) => {
  const {
    getUserMe,
    updateUser,
    getMyNotifications,
    getMyProfileRequests,
    getOrganizationsAccess,
    accountPanic,
    getRecoveryCodes,
    organizationTerms,
    updateProfileRequest,
    deleteProfileRequest,
    validateAccount,
    getUser,
  } = useContext(AuthContext);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState(primaryColumnOptions[0]);
  const [loading, setLoading] = React.useState(false);
  const [access, setAccess] = React.useState(null);
  const [account, setAccount] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [profileRequests, setProfileRequests] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [acceptingProfileRequests, setAcceptingProfileRequests] =
    React.useState(true);
  const [sendProfileRequestsToMyDevice, setSendProfileRequestsToMyDevice] =
    React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState(null);
  const [selectedNotification, setSelectedNotification] = React.useState(null);
  const [selectedNotificationType, setSelectedNotificationType] =
    React.useState(null);
  const [mfaEnabled, setMfaEnabled] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordMessage, setPasswordMessage] = React.useState("");
  const { onCopy: onCopyUserId } = useClipboard(account?.id);
  const [accessToken, setAccessToken] = React.useState("");
  const { onCopy: onCopyToken } = useClipboard(accessToken);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRecoveryCodes,
    onOpen: onOpenRecoveryCodes,
    onClose: onCloseRecoveryCodes,
  } = useDisclosure();
  const [recoveryCodes, setRecoveryCodes] = React.useState([]);
  const { onCopy: onCopyRecoveryCodes } = useClipboard(
    recoveryCodes.join("\n")
  );

  async function primaryColumnOptionSelected(selectedOption) {
    setPrimaryColumnSelectedOption(selectedOption);

    if (selectedOption.name === "notifications") {
      if (notifications.length > 0) {
        handleNotificationChange(notifications[0], "notification");
      } else if (profileRequests.length > 0) {
        handleNotificationChange(profileRequests[0], "profileRequest");
      }
    }
  }

  async function updateUserInfo(key, value, overrideOp = null) {
    try {
      const res = await updateUser(account, key, value, overrideOp);
      if (res.data.data) {
        setAccount(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }

    if (key === "password") {
      setPassword("");
      setPasswordMessage("Successfully updated password.");
    }
  }

  useEffect(() => {
    setLoading(true);
    validateAccount().then((res) => {
      const access = res.data.data;
      console.log("access: ", access);
      setAccess(access);
    });

    getUserMe()
      .then((res) => {
        setAccount(res.data.data);
        setUsername(res.data.data.username);
        setPhone(res.data.data.phone.txt);
        setEmail(res.data.data.email);
        setMfaEnabled(res.data.data.mfa?.enabled);
        setSendProfileRequestsToMyDevice(
          res.data.data.myNotifications?.profileRequests
        );

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    getMyNotifications()
      .then((res) => {
        setNotifications(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });

    getMyProfileRequests()
      .then((res) => {
        setProfileRequests(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });

    getOrganizationsAccess()
      .then((res) => {
        setOrgs(res.data?.data);
        if (res.data?.data?.length > 0) {
          setSelectedOrg(res.data?.data[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => { };
  }, [
    group,
    getUserMe,
    getMyNotifications,
    getOrganizationsAccess,
    getMyProfileRequests,
    validateAccount,
  ]);

  const handleOrgChange = (org) => {
    setSelectedOrg(org);
  };

  const handleNotificationChange = (notification, type) => {
    setSelectedNotification(notification);
    setSelectedNotificationType(type);
  };

  const handleProfileRequestAction = async (action) => {
    try {
      const res = await updateProfileRequest(selectedNotification.id, action);
      console.log(res.data);
      if (res.data.data) {
        console.log(res.data.data);
        setSelectedNotification(res.data.data);
      }
      if (action === "denied") {
        await deleteProfileRequest(selectedNotification.id);
        const newProfileRequests = profileRequests.filter((p) => {
          return p.id !== selectedNotification.id;
        });
        setProfileRequests(newProfileRequests);
        if (notifications.length > 0) {
          handleNotificationChange(notifications[0], "notification");
        } else if (profileRequests.length > 0) {
          handleNotificationChange(profileRequests[0], "profileRequest");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onTermsAction = async (action) => {
    try {
      await organizationTerms(selectedOrg.organization._id, { action });
      const res = await getOrganizationsAccess();
      setOrgs(res.data.data);
      if (action === "decline") {
        setSelectedOrg(res.data.data[0]);
      } else {
        setSelectedOrg(
          res.data.data.find(
            (x) => x.organization._id === selectedOrg.organization._id
          )
        );
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex h="100%" alignItems="stretch">
      {/* Primary Column */}
      <VStack layerStyle="windowmenu" alignItems="stretch" flex="1" spacing="0">
        <VStack alignItems={"stretch"} spacing="0">
          <Text variant="windowheader">Account</Text>
          <Divider opacity={".4"} />
        </VStack>
        <Skeleton overflow={"hidden"} overflowY={"scroll"} isLoaded={!loading}>
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            paddingTop={"8px"}
            paddingBottom={"8px"}
          >
            {primaryColumnOptions.map((option) => {
              return (
                <WindowColumnPrimaryButton
                  key={option.name}
                  onClick={() => {
                    const optionSelected = {
                      name: option.name,
                      label: option.label,
                    };
                    primaryColumnOptionSelected(optionSelected);
                  }}
                  active={primaryColumnSelectedOption.name === option.name}
                >
                  <Text
                    width={"162px"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.label}
                  </Text>
                </WindowColumnPrimaryButton>
              );
            })}
          </VStack>
        </Skeleton>
      </VStack>
      {/* Secondary Column */}
      {primaryColumnSelectedOption.name === "access" && loading && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Credentials</Text>
          <Skeleton width="100%" isLoaded={!loading}></Skeleton>
        </VStack>
      )}

      {primaryColumnSelectedOption.name === "access" && !loading && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Credentials</Text>
          <VStack alignItems={"flex-start"} spacing={"3"}>
            <Text variant="contentsubheader">General</Text>
            <VStack alignItems={"flex-start"} spacing={"0"}>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">ID</Text>
                <IconButton
                  aria-label="Copy Client Secret"
                  icon={<CopyIcon />}
                  onClick={onCopyUserId}
                  size="xs"
                  variant="copyButton"
                />
              </HStack>
              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {account?.id}
              </Text>
            </VStack>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => updateUserInfo("email", e.target.value)}
                  id="email"
                  placeholder=""
                />
              </HStack>
              <FormHelperText>
                Changing your email address can result in a loss of access to
                organizations that have restrictions for email domain.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="username">Username</FormLabel>
              <FormInput
                border={0}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={(e) => updateUserInfo("username", e.target.value)}
                id="username"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <FormInput
                border={0}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => updateUserInfo("phone", { txt: e.target.value })}
                id="phone"
                placeholder=""
                autoComplete="off"
              />
            </FormControl>
          </VStack>

          <VStack alignItems={"flex-start"} spacing={"3"}>
            <Text variant="contentsubheader">Danger Zone</Text>
            <FormControl>
              <FormLabel htmlFor="password">Change Password</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  type="password"
                  autoComplete="off"
                />
                <Button
                  variant="primaryBlue"
                  size="md"
                  onClick={() => {
                    updateUserInfo("password", password, "replace");
                  }}
                >
                  Save
                </Button>
              </HStack>
              {passwordMessage && <Text fontSize={12}>{passwordMessage}</Text>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="recoveryCodes">Recovery Codes</FormLabel>
              <Button
                variant="primaryBlue"
                size="sm"
                onClick={async () => {
                  try {
                    const res = await getRecoveryCodes();
                    setRecoveryCodes(res.data.data);
                    console.log(isOpenRecoveryCodes);
                    onOpenRecoveryCodes();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Get Recovery Codes
              </Button>
            </FormControl>

            <Modal isOpen={isOpenRecoveryCodes} onClose={onCloseRecoveryCodes}>
              <ModalOverlay />
              <ModalContent overflow={"hidden"}>
                <ModalHeader
                  backgroundColor={"blue.600"}
                  borderBottom="1px solid"
                  borderBottomColor={"blue.700"}
                >
                  <HStack justifyContent={"space-between"} spacing={"0"}>
                    <Text variant="titleSmall" color="white.900">
                      Recovery Codes
                    </Text>
                    <Button
                      variant="unstyledWhite"
                      size={"xs"}
                      height={"13px"}
                      padding={"0"}
                      onClick={onCloseRecoveryCodes}
                    >
                      Close
                    </Button>
                  </HStack>
                </ModalHeader>
                <ModalBody background={"white.900"} paddingTop={"20px"}>
                  <Text variant={"bodyMedium"}>
                    You can use these recovery code in the event that your
                    account is locked.
                  </Text>
                  <SimpleGrid
                    columns={2}
                    spacing={2}
                    mt={"4"}
                    mb={"2"}
                    padding={"20px"}
                    borderRadius={"8px"}
                    background={"black.100"}
                    border={"1px solid"}
                    borderColor={"black.200"}
                  >
                    {recoveryCodes.map((code) => {
                      return (
                        <Box key={code}>
                          <Text variant="labelSmall">{code}</Text>
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </ModalBody>

                <ModalFooter justifyContent="flex-start">
                  <Button
                    variant="primaryBlue"
                    mr={3}
                    onClick={onCopyRecoveryCodes}
                  >
                    Copy
                  </Button>
                  <Button variant="primaryBlack" onClick={onCloseRecoveryCodes}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <FormControl>
              <FormLabel htmlFor="panic">Lock Your Account</FormLabel>
              <Button
                variant="primaryRed"
                size="sm"
                onClick={async () => {
                  await accountPanic();
                  window.location.replace("/logout");
                }}
              >
                Lock
              </Button>
              <FormHelperText>
                If you have noticed unusual activity, you can immediately lock
                your account. <br />
                This action requires your recovery codes to undo. <br />
                If you have not generated recovery codes, you will need to
                contact an administrator for assistance.
              </FormHelperText>
            </FormControl>
          </VStack>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "profile" && (
        <VStack
          alignItems={"stretch"}
          layerStyle={"windowcontentarea"}
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Secure Profile</Text>
          <SecureProfile />
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "notifications" && (
        <VStack
          alignItems={"stretch"}
          layerStyle={"windowsubmenu"}
          spacing={"1"}
          padding={"20px"}
        >
          <Text variant="windowsubmenusubheader">Requests &amp; Notifications</Text>
          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification) => {
              return (
                <WindowColumnSecondaryButton
                  key={notification.id}
                  onClick={() => {
                    handleNotificationChange(notification, "notification");
                  }}
                  active={selectedNotification?.id === notification.id}
                >
                  <Text
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {notification.subject}
                  </Text>
                </WindowColumnSecondaryButton>
              );
            })}
          {profileRequests &&
            profileRequests.length > 0 &&
            profileRequests.map((profileRequest) => {
              return (
                <WindowColumnSecondaryButton
                  key={profileRequest.id}
                  onClick={() => {
                    handleNotificationChange(profileRequest, "profileRequest");
                  }}
                  active={selectedNotification?.id === profileRequest.id}
                >
                  {profileRequest.requestDetails || "Profile Request"}
                </WindowColumnSecondaryButton>
              );
            })}
          {(!notifications || notifications.length < 1) && (
            <Text>You do not have any notifications</Text>
          )}
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "notifications" &&
        selectedNotification &&
        selectedNotificationType &&
        selectedNotificationType === "notification" && (
          <VStack
            alignItems={"stretch"}
            layerStyle={"windowcontentarea"}
            spacing={"0"}
            gap={"24px"}
          >
            <Text variant={"contentheader"}>{selectedNotification.subject}</Text>
            <Text variant={"bodySmall"}>{selectedNotification.message}</Text>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Delivered</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {dayjs(selectedNotification.createdAt).format(
                  "MMM DD YYYY hh:mm a"
                )}
              </Text>
            </FormControl>

            {/*<Flex>
              <Button
                onClick={onOpen}
                bg="#D77740"
                borderRadius="6px"
                color="#fff"
                padding="4px 12px 6px 10px"
                fontSize="14px"
                mt={2}
              >
                View Terms
              </Button>
                </Flex>*/}
          </VStack>
        )}
      {primaryColumnSelectedOption.name === "notifications" &&
        selectedNotification &&
        selectedNotificationType === "profileRequest" && (
          <VStack
            alignItems={"stretch"}
            layerStyle={"windowcontentarea"}
            spacing={"0"}
            gap={"24px"}
          >
            <Text fontSize="20px">
              {selectedNotification.requestDetails || "Profile Request"}
            </Text>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Requested By</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {selectedNotification.requestingEmail}
              </Text>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Requested At</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {dayjs(selectedNotification.createdAt).format(
                  "MMM DD YYYY hh:mm a"
                )}
              </Text>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Status</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {selectedNotification.state}
              </Text>
            </FormControl>
            {selectedNotification.state === "new" && (
              <HStack>
                <Flex>
                  <Button
                    onClick={() => {
                      handleProfileRequestAction("approved");
                    }}
                    variant="primaryBlue"
                    mt={2}
                  >
                    Approve
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    onClick={() => {
                      handleProfileRequestAction("denied");
                    }}
                    variant="primaryBlue"
                    mt={2}
                  >
                    Deny
                  </Button>
                </Flex>
              </HStack>
            )}
          </VStack>
        )}
      {primaryColumnSelectedOption.name === "orgs" && (
        <VStack
          alignItems={"stretch"}
          layerStyle={"windowsubmenu"}
          spacing={"1"}
          padding={"20px"}
        >
          <Text variant="windowsubmenusubheader">My Organizations</Text>

          <VStack align="left">
            {orgs &&
              orgs.length > 0 &&
              orgs
                .sort((a, b) =>
                  a.organization.name > b.organization.name ? 1 : -1
                )
                .map((org) => {
                  return (
                    <WindowColumnSecondaryButton
                      key={org.organization._id}
                      onClick={() => {
                        handleOrgChange(org);
                      }}
                      active={
                        selectedOrg.organization?._id === org.organization._id
                      }
                    >
                      <Text
                        width={"100%"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {org.organization.name}
                      </Text>
                    </WindowColumnSecondaryButton>
                  );
                })}
          </VStack>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "orgs" && selectedOrg && (
        <VStack
          alignItems={"stretch"}
          layerStyle={"windowcontentarea"}
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="contentheader">{selectedOrg.organization?.name}</Text>
          <Text fontSize="14px">{selectedOrg.organization?.description}</Text>
          <Text variant="contentsubheader">Domains</Text>
          <Text fontSize="14px">
            {selectedOrg.domains && selectedOrg.domains.length > 0
              ? selectedOrg.domains.map((domain) => {
                return <Text key={domain.id}>{domain.name}</Text>;
              })
              : "You do not have access to any domains in this organization"}
          </Text>
          <Text variant="contentsubheader">Terms</Text>
          {!selectedOrg.terms?.termsOfAccess && (
            <Text fontSize="14px">
              There are no terms of access in this organization.
            </Text>
          )}
          {selectedOrg.terms?.termsOfAccess && (
            <>
              {selectedOrg.terms?.termsVersion && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb="0">Latest Version</FormLabel>
                  <Text
                    border="1px solid"
                    borderColor="black.100"
                    backgroundColor="black.50"
                    padding="6px 12px"
                    borderRadius="20px"
                    color="black.900"
                  >
                    {selectedOrg.terms?.termsVersion}
                  </Text>
                </FormControl>
              )}
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Delivered</FormLabel>
                <Text
                  border="1px solid"
                  borderColor="black.100"
                  backgroundColor="black.50"
                  padding="6px 12px"
                  borderRadius="20px"
                  color="black.900"
                >
                  {dayjs(selectedOrg.terms?.termsDeliveredOn).format(
                    "MMM DD YYYY hh:mm a"
                  )}
                </Text>
              </FormControl>
              {selectedOrg.terms?.termsAcceptedOn && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb="0">Accepted</FormLabel>
                  <Text
                    border="1px solid"
                    borderColor="black.100"
                    backgroundColor="black.50"
                    padding="6px 12px"
                    borderRadius="20px"
                    color="black.900"
                  >
                    {dayjs(selectedOrg.terms?.termsAcceptedOn).format(
                      "MMM DD YYYY hh:mm a"
                    )}
                  </Text>
                </FormControl>
              )}
              <Flex>
                <Button
                  onClick={onOpen}
                  bg="#D77740"
                  borderRadius="6px"
                  color="#fff"
                  padding="4px 12px 6px 10px"
                  fontSize="14px"
                  mt={2}
                >
                  View Terms
                </Button>
              </Flex>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Terms of Access for {selectedOrg.organization?.name}
                  </ModalHeader>
                  <ModalCloseButton />
                  {/(((https?:\/\/)|(www\.))[^\s]+)/g.test(
                    selectedOrg.terms?.termsOfAccess
                  ) ? (
                    <ModalBody>
                      <Link href={selectedOrg.terms?.termsOfAccess} isExternal>
                        View Terms <ExternalLinkIcon mx="2px" />
                      </Link>
                    </ModalBody>
                  ) : (
                    <ModalBody>{selectedOrg.terms?.termsOfAccess}</ModalBody>
                  )}

                  <ModalFooter justifyContent="flex-start">
                    <Button
                      colorScheme="black"
                      variant="outline"
                      mr={3}
                      onClick={() => onTermsAction("accept")}
                    >
                      Accept
                    </Button>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      onClick={() => onTermsAction("decline")}
                    >
                      Decline
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "settings" && (
        <VStack
          alignItems={"stretch"}
          layerStyle={"windowcontentarea"}
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="contentheader">Settings</Text>
          <Text variant="contentsubheader">Password-free Configurations</Text>
          <FormControl>
            {mfaEnabled ? (
              <FormControl display="flex" flexDirection="column">
                <FormLabel htmlFor="mfaEnabled">Device Login and MFA</FormLabel>
                <Switch
                  marginTop="2px"
                  id="mfaEnabled"
                  isChecked={mfaEnabled}
                  onChange={(e) => {
                    updateUserInfo("mfa/enabled", e.target.checked);
                    setMfaEnabled(e.target.checked);
                  }}
                />
              </FormControl>
            ) : (
              <>
                <FormLabel htmlFor="panic">
                  Multifactor Authentication
                </FormLabel>
                <Button
                  variant="primaryBlue"
                  size="md"
                  onClick={() => {
                    const url = `${process.env.REACT_APP_API_ROOT}/${group}/recover-mfa`;
                    window.open(url);
                  }}
                >
                  Enable MFA
                </Button>
              </>
            )}
          </FormControl>
          <FormControl>
            <Button
              variant="primaryBlue"
              size="md"
              onClick={async () => {
                const url = `${process.env.REACT_APP_API_ROOT}/${group}/set-passkey`;
                window.open(url);
              }}
            >
              Configure Passkey
            </Button>
            <FormHelperText>
              This configures your local device for Passkey. You can reconfigure
              as often as you like and it will overwrite the previous setup.
            </FormHelperText>
          </FormControl>
          <Text variant="contentsubheader">Profile Requests</Text>
          <FormControl display="flex" flexDirection="column">
            <FormLabel htmlFor="acceptingProfileRequests">
              Accept profile requests
            </FormLabel>
            <Switch
              marginTop="2px"
              id="acceptingProfileRequests"
              isChecked={acceptingProfileRequests}
              onChange={(e) => {
                updateUserInfo("acceptingProfileRequests", e.target.checked);
                setAcceptingProfileRequests(e.target.checked);
              }}
            />
          </FormControl>
          {mfaEnabled && (
            <FormControl display="flex" flexDirection={"column"}>
              <FormLabel htmlFor="sendProfileRequestsToMyDevice">
                Send profile requests to my device
              </FormLabel>
              <Switch
                id="sendProfileRequestsToMyDevice"
                isChecked={sendProfileRequestsToMyDevice}
                onChange={async (e) => {
                  try {
                    setSendProfileRequestsToMyDevice(e.target.checked);

                    if (!account.myNotifications) {
                      await updateUserInfo("myNotifications", {
                        profileRequests: true,
                      });
                    } else {
                      updateUserInfo(
                        "myNotifications/profileRequests",
                        e.target.value
                      );
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
            </FormControl>
          )}
          <Text variant="contentsubheader">Token Information</Text>
          <FormControl>
            <HStack spacing={"1"}>
              <Text variant="labelSmall">My Token</Text>
              {accessToken && (
                <IconButton
                  aria-label="Copy Access Token"
                  icon={<CopyIcon />}
                  onClick={onCopyToken}
                  size="xs"
                  variant="copyButton"
                />
              )}
            </HStack>
            {!accessToken && (
              <Button
                variant="primaryBlue"
                size="md"
                onClick={async () => {
                  const res = await getUser();
                  setAccessToken(res.access_token);
                }}
              >
                Show my token
              </Button>
            )}
            {accessToken && (
              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {accessToken}
              </Text>
            )}
          </FormControl>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "debug" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="contentheader">Info</Text>
          {access && (
            <>
              <Text>Auth Group {access.authGroup.id}</Text>
              <Text>Member? {access.authGroup.member ? "Yes" : "No"}</Text>
              <Text>Owner? {access.authGroup.owner ? "Yes" : "No"}</Text>
              <Text>Permissions</Text>
              {access.authGroup.memberPermissions.map((permission) => {
                return <Text key={permission}>{permission}</Text>;
              })}
            </>
          )}
        </VStack>
      )}
    </Flex>
  );
};
