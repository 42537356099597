import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormSelect } from "../FormSelect";

interface BulkUserEditProps {
  users: any[];
}
export const BulkUserEdit = ({ users }: BulkUserEditProps) => {
  const { getOrganizations, createOrganizationUsers } =
    React.useContext(AuthContext);
  const [organizations, setOrganizations] = React.useState([] as any[]);
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<any>(null);

  useEffect(() => {
    getOrganizations()
      .then((res) => {
        setOrganizations(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [getOrganizations]);

  const addUsersToOrganization = async () => {
    try {
      const usersToAdd = users.map((u) => {
        return u.id;
      });
      const res = await createOrganizationUsers(
        selectedOrganization,
        usersToAdd
      );
      console.log(res.data.data);
      setSelectedOrganization(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/*<Text maxW="200">
        {JSON.stringify(
          users.map((u) => {
            return u.id;
          }),
          null,
          2
        )}
        </Text>*/}
      <FormControl>
        <FormLabel>Organization</FormLabel>
        <HStack
          alignItems={"stretch"}
        >
          <FormSelect
            id="org"
            value={selectedOrganization}
            onChange={(e) => {
              setSelectedOrganization(e.target.value);
            }}
          >
            <option value="">Select Organization</option>
            {organizations &&
              organizations.map((org) => {
                return (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                );
              })}
          </FormSelect>
          <Button variant={"primaryBlue"} size={"md"} onClick={addUsersToOrganization}>
            Add {users.length} users
          </Button>
        </HStack>
        <FormHelperText>
          Select the organization that you want to assign the {users.length}{" "}
          selected users to.
        </FormHelperText>
      </FormControl>
    </>
  );
};
