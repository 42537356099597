import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../providers/authProvider";
import {
  Box,
  Flex,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../nav/Navbar";
import { useParams } from "react-router-dom";

const GroupPage = () => {
  const { init, signinRedirect } = useContext(AuthContext);
  let { group } = useParams();
  const toast = useToast();
  const authority = process.env.REACT_APP_AUTHORITY_ROOT;
  const clientRoot = process.env.REACT_APP_CLIENT_ROOT;

  useEffect(() => {
    if (group) {
      axios
        .get(`${process.env.REACT_APP_API_ROOT}/api/group-info/${group}`)
        .then((response) => {
          console.log(response);

          const { id, groupId, group } = response.data.data;

          console.log(id, groupId);

          if (response.data.data.id) {
            init(
              `${authority}/${groupId}`,
              id,
              clientRoot,
              groupId,
              group
            );

            signinRedirect();
          }
        }).catch((error) => {
          toast({
            title: `Sorry, we've encounter an error. Please try again!`,
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        });
    }
  });

  return (
    <>
      <Box
        minH={"100vh"}
        bgImg={
          "/images/core-eos-background.jpg"
        }
        bgSize={"cover"}
        bgPos={"center"}
        minW={"100%"}
      >
        <Box backdropFilter="blur(24px)">
          <Navbar />
          <Flex minH={"100vh"} align={"top"} justify={"center"}>{""}</Flex>
        </Box>
      </Box>
    </>
  );
};

export default GroupPage;
