import styled from "styled-components";
/*
import {
  // @ts-ignore: unused variable
  HTMLAttributes,
  // @ts-ignore: unused variable
  ClassAttributes,
} from "react";*/

export interface WrapperProps {
  isActive: boolean;
  ref?: any;
}

export interface HeaderProps {
  isDraggable?: boolean;
  ref?: any;
}

export const minWidth = 1200;
export const minHeight = 300;
export const padding = 0;

const wrapperStyles = ({ isActive }: WrapperProps) => {
  if (isActive) {
    return `
      
    `;
  }

  return `opacity: 1;`;
};

export const Wrapper = styled.div`
  ${wrapperStyles} position: fixed;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 24px 22px rgb(30 30 50 / 4%), 0px 9px 8px rgb(30 30 50 / 4%), 0px 5px 4px rgb(30 30 50 / 8%), 0px 3px 2px rgb(30 30 50 / 8%), 0px 1px 0.75px rgb(30 30 50 / 12%);
  Height: 600px;
  min-width: ${minWidth}px;
  min-height: ${minHeight}px;
  max-height: 95vh;
  max-width: 95vw;
  user-select: none;
  backdrop-filter: blur(24px);
  color: "#2A2B42";
`;

export const Header = styled.div`
  cursor: ${({ isDraggable }: HeaderProps) =>
    !isDraggable ? `-webkit-grab` : `default`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #2A2B42;
  padding: 6px;
`;

export const DragIcon = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  height: 6px;
  margin-left: 6px;
`;

export const DragLine = styled.div`
  background-color: #45475E;
  height: 1px;
  width: 100%;
`;

export const BottomRightResizeHandle = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const CloseIcon = styled.div`
  background-color: #ff0008;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0));
  width: 12px;
  height: 12px;
  box-shadow: inset 1px 1px 1px #FF8540, inset -1px -1px 1px #C52F00;
  border-radius: 2px;
  display: block;
  cursor: pointer;
  transition: background-color 40ms ease-in-out;
  &:hover {
    background-color: #FF4046;
  }
`;

export const MinimizeIcon = styled.div`
  background: #f4c47d;
  font-size: 9pt;
  width: 11px;
  height: 11px;
  border: 1px solid #f4c47d;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export const MaximizeIcon = styled.div`
  background: #68958a;
  font-size: 9pt;
  width: 11px;
  height: 11px;
  border: 1px solid #68958a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export const Title = styled.div`
  white-space: nowrap;
  max-width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RightResizeHandle = styled.div`
  width: 20px;
  height: calc(100% - 50px);
  position: absolute;
  bottom: 20px;
  right: 0;
  cursor: ew-resize;
`;

export const BottomResizeHandle = styled.div`
  width: calc(100% - 40px);
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 20px;
  cursor: ns-resize;
`;
