import * as jsonpatch from "fast-json-patch";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ActionMeta,
  CreatableSelect,
  OnChangeValue,
  Select,
} from "chakra-react-select";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { Product, Service, Subject } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormSwitch } from "../FormSwitch";
import { FormTextarea } from "../FormTextArea";
import { SectionHeader } from "./SectionHeader";
import { Observer } from "fast-json-patch";
import { FormBadge } from "./FormBadge";
import { CustomTab } from "../CustomTab";

export interface EditSubjectProps {
  client: NattyLightService;
  id: string;
  onDone: () => void;
}

export const EditSubject = ({ client, id, onDone }: EditSubjectProps) => {
  const toast = useToast();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [service, setService] = React.useState<boolean>(false);
  const [allowWildcardAccess, setAllowWildcardAccess] =
    React.useState<boolean>(false);
  const [businessAreaOptions, setBusinessAreaOptions] = React.useState<
    string[]
  >([]);
  const [businessDomains, setBusinessDomains] = React.useState<string[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [services, setServices] = React.useState<Service[]>([]);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [tags, setTags] = React.useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
  const [selectedQueueGroups, setSelectedQueueGroups] = React.useState<
    string[]
  >([]);
  const [subject, setSubject] = React.useState<Subject>();

  useEffect(() => {
    client.getBusinessDomains().then((domains) => {
      setBusinessAreaOptions(domains);
      console.log(domains);
    });

    client.getProducts().then((products) => {
      setProducts(products);
    });

    client.getServices().then((services) => {
      setServices(
        services.filter(
          (s: Service) => !s.client_name.startsWith("stream-access-")
        )
      );
    });

    client.getTags().then((tags) => {
      setTagOptions(tags);
    });

    client.getSubject(id).then((subject: Subject) => {
      console.log(subject);
      setName(subject.name);
      setDescription(subject.description);
      setService(subject.service);
      setAllowWildcardAccess(subject.allowWildcardAccess);
      setBusinessDomains(subject.businessDomains);
      setSelectedProducts(subject.associatedProducts);
      setSelectedServices(subject.associatedServices);
      setSelectedQueueGroups(subject.queueGroups);
      setTags(subject.tags);

      // save the existing subject for comparison when updating
      setSubject(subject);
    });
  }, [client, id]);

  const updateSubject = async () => {
    try {
      if (subject) {
        const observer: Observer<Subject> = jsonpatch.observe(subject);
        subject.service = service;
        subject.allowWildcardAccess = allowWildcardAccess;
        subject.associatedProducts = selectedProducts;
        subject.associatedServices = selectedServices;
        subject.queueGroups = selectedQueueGroups;
        subject.tags = tags;
        subject.businessDomains = businessDomains;
        subject.description = description;

        const patch = jsonpatch.generate(observer);
        console.log(patch);

        await client.updateSubject(subject.id, patch);
        onDone();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleArchiveSubject = async () => {
    try {
      console.log("archiving subject");
      if (subject) {
        const observer: Observer<Subject> = jsonpatch.observe(subject);
        subject.archived = true;

        const patch = jsonpatch.generate(observer);
        console.log(patch);

        await client.updateSubject(subject.id, patch);
        onDone();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleDeleteSubject = async () => {
    try {
      if (subject && subject.archived) {
        await client.deleteSubject(subject.id);
        onDone();
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleBusinessAreaChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setBusinessDomains(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleTagChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setTags(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleProductChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedProducts(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleServiceChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedServices(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleQueueGroupChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedQueueGroups(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.response ? error.response.data.message : error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      <VStack alignItems="stretch" height={"100%"} spacing={"0"}>
        <VStack
          alignItems={"stretch"}
          padding={"20px 20px 0 20px"}
          background={"white.900"}
          spacing={"0"}
        >
          <Text variant="labelMedium" lineHeight={"1em"}>
            Edit
          </Text>
          <Text variant="titleLarge" lineHeight={"1.2em"}>
            {name}
          </Text>
        </VStack>
        <HStack padding={"12px 20px 4px 20px"} background={"white.900"}>
          <Button
            variant="primaryOrange"
            size="sm"
            onClick={updateSubject}
            disabled={!name}
          >
            Save
          </Button>
          <Button onClick={onDone} variant="primaryBlack" size="sm">
            Cancel
          </Button>
          {subject && subject.archived ? (
            <Button
              onClick={handleDeleteSubject}
              variant="unstyledBlack"
              size="sm"
            >
              Delete
            </Button>
          ) : (
            <Button
              onClick={handleArchiveSubject}
              variant="unstyledBlack"
              size="sm"
            >
              Archive
            </Button>
          )}
        </HStack>

        <Tabs
          display={"flex"}
          flexDirection={"column"}
          height={"100%"}
          overflow={"hidden"}
        >
          <TabList
            background={"white.900"}
            padding={"2px 20px 0 20px"}
            gap={"12px"}
            borderBottom={"1px solid"}
            borderBottomColor={"black.300"}
          >
            <CustomTab>General</CustomTab>
            <CustomTab>Queue Groups</CustomTab>
            <CustomTab>Associations</CustomTab>
          </TabList>

          <TabPanels
            background={"white.800"}
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            overflow={"hidden"}
          >
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px"}
              gap={"20px"}
            >
              <SectionHeader title={"General"} />
              <VStack alignItems={"stretch"} spacing={"0.5"}>
                <FormLabel>Description</FormLabel>
                <FormTextarea
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                ></FormTextarea>
              </VStack>
              <SectionHeader
                title={"Config"}
                description={
                  "Enable bi-directional request/reply usage or allow accessing systems to specify wildcards in their permissions."
                }
              />
              <VStack spacing={"3"}>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Request/reply Service</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setService(event.target.checked);
                    }}
                    isChecked={service}
                  ></FormSwitch>
                </FormControl>
                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Allow Wildcard Access</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setAllowWildcardAccess(event.target.checked);
                    }}
                    isChecked={allowWildcardAccess}
                  />
                </FormControl>
              </VStack>
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px"}
              gap={"20px"}
            >
              <SectionHeader
                title={"Queue Groups"}
                description={
                  "Queue groups allow you to load balance message consumption across listeners. To use it, specify the queue group when requesting access. Queue groups are case sensitive and alphameric. Other allowed characters are  - _ . * >"
                }
              />

              <Tabs>
                <TabList
                  gap={"12px"}
                  borderBottom={"1px solid"}
                  borderBottomColor={"black.300"}
                >
                  <CustomTab>Live Queue Groups</CustomTab>
                  <CustomTab>Archived</CustomTab>
                </TabList>
                <TabPanels>
                  <TabPanel padding={"20px 0"}>
                    <CreatableSelect
                      value={selectedQueueGroups?.map((qg) => {
                        return { value: qg, label: qg };
                      })}
                      components={{ DropdownIndicator: null }}
                      placeholder="Create one or more queue groups"
                      isMulti
                      isClearable
                      onChange={handleQueueGroupChange}
                    />
                  </TabPanel>
                  <TabPanel padding={"20px 0"}>
                    {subject &&
                      subject.archivedQueueGroups &&
                      subject.archivedQueueGroups.map((qg) => {
                        return (
                          <FormBadge
                            w="100%"
                            backgroundColor={"black.700"}
                          >
                            {qg}
                          </FormBadge>
                        );
                      })}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
            <TabPanel
              display={"flex"}
              flexDirection={"column"}
              overflowY={"scroll"}
              padding={"20px 20px 40px 20px"}
              gap={"20px"}
            >
              <SectionHeader
                title={"Associations"}
                description={
                  "Associate this subject to appropriate business areas, products, services, and tags to make it easier to find."
                }
              />
              <VStack alignItems={"stretch"}>
                <FormControl>
                  <FormLabel>Business Area</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={businessDomains?.map((bd) => {
                      return { value: bd, label: bd };
                    })}
                    options={businessAreaOptions.map((b) => {
                      return { value: b, label: b };
                    })}
                    onChange={handleBusinessAreaChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={tags?.map((t) => {
                      return { value: t, label: t };
                    })}
                    options={tagOptions.map((t) => {
                      return { value: t, label: t };
                    })}
                    onChange={handleTagChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Associated Products</FormLabel>
                  <Select
                    isMulti
                    value={selectedProducts?.map((p) => {
                      const product = products.find((s2) => s2.id === p);
                      return { value: product?.id, label: product?.name };
                    })}
                    options={products.map((p) => {
                      return { value: p.id, label: p.name };
                    })}
                    onChange={handleProductChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Associated Services</FormLabel>
                  <Select
                    isMulti
                    value={selectedServices?.map((s) => {
                      const service = services.find((s2) => s2.client_id === s);
                      return {
                        value: service?.client_id,
                        label: service?.client_name,
                      };
                    })}
                    options={services.map((s) => {
                      return { value: s.client_id, label: s.client_name };
                    })}
                    onChange={handleServiceChange}
                  />
                </FormControl>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
};
