import * as React from "react";
import { Component } from "react";

export interface WindowContentProps {}

export interface WindowContentState {}

export class WindowContent extends Component<
  WindowContentProps,
  WindowContentState
> {
  render() {
    return <div>Custom Content</div>;
  }
}
