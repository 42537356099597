import React, { useContext, useEffect } from "react";
import { JsonPointer } from "json-ptr";

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  IconButton,
  Text,
  useClipboard,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { AuthContext } from "../../providers/authProvider";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { ActionMeta, AsyncSelect, OnChangeValue } from "chakra-react-select";

interface DomainProps {
  org: any;
  domain: any;
  result: any;
  domainDeleted: any;
}

export const Domain = ({ org, domain, result, domainDeleted }: DomainProps) => {
  const [domainData, setDomainData] = React.useState(domain);
  const [name, setName] = React.useState(domain.name);
  const [description, setDescription] = React.useState(domain.description);
  const [products, setProducts] = React.useState([] as any);
  const [newProduct, setNewProduct] = React.useState<any>(null);
  const { onCopy } = useClipboard(domain?.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = React.useState<any>(null);
  const {
    updateDomain,
    getOrganizationDomainProducts,
    getDomain,
    getOrganizationProducts,
    deleteDomain
  } = useContext(AuthContext);

  useEffect(() => {
    getOrganizationDomainProducts(org.id, domainData.id)
      .then((res) => {
        console.log(res);
        setProducts(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [domainData.id, getOrganizationDomainProducts, org]);

  const updateDomainData = async (key: string, value: any) => {
    const exists = JsonPointer.get(domainData, key);
    const op = exists ? "replace" : "add";

    try {
      const updatedDomain = await updateDomain(
        domain.id,
        org.id,
        op,
        key,
        value
      );
      setDomainData(updatedDomain.data.data);
      result(updatedDomain.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveProduct = async (productId: string) => {
    try {
      const orgData = await getDomain(org.id, domainData.id);
      const index = orgData.data.data?.associatedOrgProducts?.findIndex(
        (p: string) => p === productId
      );
      const res = await updateDomain(
        domainData.id,
        org.id,
        "remove",
        `/associatedOrgProducts/${index}`,
        null
      );
      console.log(res);
      refreshOrganiztionDomainProducts();
    } catch (error: any) {
      console.log(error);
    }
  };

  const refreshOrganiztionDomainProducts = async () => {
    try {
      const res = await getOrganizationDomainProducts(org.id, domainData.id);
      setProducts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = async (type: string, inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      try {
        let res;
        if (type === "products") {
          res = await getOrganizationProducts(org.id);
        }
        const response = res.data.data;
        const filtered = response.filter((t: any) =>
          t.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        const options = filtered.map((product: any) => ({
          value: product.id,
          label: product.name,
        }));
        resolve(options);
      } catch (error) {
        console.log(error);
      }
    });

  const loadProductOptions = async (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      return loadOptions("products", inputValue).then((options) =>
        resolve(options)
      );
    });

  const handleProductChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    setNewProduct(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleAddProduct = async () => {
    try {
      const res = await updateDomain(
        domainData.id,
        org.id,
        "add",
        "/associatedOrgProducts/-",
        newProduct.value
      );
      console.log(res);
      refreshOrganiztionDomainProducts();
      setNewProduct(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteDomain(org.id, domain.id);
      console.log(res);
      domainDeleted(domain.id);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data);
      onOpen();
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => updateDomainData("/name", e.target.value)}
          id="name"
          placeholder=""
        />
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="name">ID</FormLabel>
          <HStack>
            <Text>{domain.id}</Text>
            <IconButton
              aria-label="Copy ID"
              icon={<CopyIcon />}
              onClick={onCopy}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Description</FormLabel>
        <FormInput
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={(e) => updateDomainData("/description", e.target.value)}
          id="description"
          placeholder=""
        />
      </FormControl>
      <FormControl>
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          gap={"6px"}
          borderRadius={"6px"}
          backgroundColor={"neutral.200"}
          padding={"6px"}
        >
          <VStack
            borderRadius={"4px"}
            backgroundColor={"neutral.100"}
            padding={"8px"}
            alignItems={"stretch"}
          >
            <VStack
              alignItems={"stretch"}
            >
              <FormLabel>Add products</FormLabel>
              <AsyncSelect
                defaultOptions
                isClearable
                loadOptions={loadProductOptions}
                onChange={handleProductChange}
                placeholder="Select a product..."
              />
            </VStack>
            <Button
              variant="primaryBlue"
              disabled={!newProduct}
              onClick={handleAddProduct}
              size={"lg"}
            >
              Add this product
            </Button>
          </VStack>
        </VStack>
      </FormControl>
      <Text variant={"contentsubheader"}>Products</Text>
      <VStack
        alignItems={"stretch"}
        spacing={"0"}
        gap={"6px"}
        borderRadius={"6px"}
        backgroundColor={"neutral.200"}
        padding={"6px"}
      >
        {products &&
          products.map((product: any) => (
            <VStack
              alignItems={"stretch"}
              spacing={"0"}
              gap={"1"}
              padding={"12px 12px 16px 16px"}
              backgroundColor={"neutral.100"}
              borderRadius={"4px"}
            >
              <HStack
                alignItems={"center"}
              >
                <Text flex={"1"} variant="labelLarge">{product.name}</Text>
                {!product.core && (
                  <Button
                    variant={"unstyledBlack"}
                    size={"sm"}
                    onClick={() => handleRemoveProduct(product.id)}
                  >
                    Remove
                  </Button>
                )}
              </HStack>
              <Text variant={"bodySmall"}>{product.description}</Text>
            </VStack>
          ))}
      </VStack>
      {!domain.core && (
        <Button
          leftIcon={<DeleteIcon />}
          variant="primaryRed"
          alignSelf={"flex-start"}
          onClick={handleDelete}
        >
          Delete Domain
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{error?.message}</Text>
            {error?.data &&
              error.data.map((error: any) => (
                <Text key={error.id}>{error.name}</Text>
              ))}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
