import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Radio,
  RadioGroup,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useRadioGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { Stream, Subject } from "../../services/natty-lite/Models";
import JSONInput from "react-json-editor-ajrm";
import { locale } from "../../json-locale";
import { FormInput } from "../FormInput";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormSelect } from "../FormSelect";
import { SegmentedButton } from "../SegmentedButton";
import { CustomTab } from "../CustomTab";

export interface ActionModalProps extends ModalProps {
  type: "subject" | "stream";
  subject?: Subject;
  stream?: Stream;
  client: NattyLightService;
  action: "publish" | "query" | "delete";
}

export const ActionModal = ({
  subject,
  stream,
  type,
  client,
  action,
  ...props
}: ActionModalProps) => {
  const toast = useToast();
  const [title, setTitle] = React.useState("Query");
  const [payload, setPayload] = React.useState({});
  const [subjectName, setSubjectName] = React.useState("");
  const [publishResponse, setPublishResponse] = React.useState<any>(null);
  const [queryResponse, setQueryResponse] = React.useState<any>(null);
  const [deleteResponse, setDeleteResponse] = React.useState<any>(null);
  const [queryMethod, setQueryMethod] = React.useState<string>("byIndex");
  const [indexNumber, setIndexNumber] = React.useState("");
  const [querySubject, setQuerySubject] = React.useState("");
  const [deleteMode, setDeleteMode] = React.useState<"single" | "multiple">(
    "single"
  );
  const [deleteSubjectSelected, setDeleteSubjectSelected] = React.useState("");
  const [deleteSubjectMode, setDeleteSubjectMode] = React.useState("");
  const [deleteSeq, setDeleteSeq] = React.useState("");
  const [deleteKeep, setDeleteKeep] = React.useState("");
  const [deleteSeqOrKeep, setDeleteSeqOrKeep] = React.useState("seq");

  const defaultIndex = React.useMemo(() => {
    if (action === "query") {
      setTitle("Query");
      return 0;
    } else if (action === "publish") {
      setTitle("Publish");
      return 1;
    } else if (action === "delete") {
      setTitle("Delete");
      return 2;
    } else {
      return 0;
    }
  }, [action]);

  const handlePublishClicked = async () => {
    try {
      if (stream) {
        const opData = {
          subject: subjectName,
          payload,
        };
        const resp = await client.sendOpToStream(
          stream.name,
          "publish-message",
          opData
        );
        setPublishResponse(resp);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleQueryClicked = async () => {
    try {
      if (stream) {
        let op, opData;
        if (queryMethod === "byIndex") {
          op = "get-message";
          opData = {
            seq: +indexNumber,
          };
        } else if (queryMethod === "lastMessage") {
          op = "get-last-message";
          opData = {};
        } else if (queryMethod === "afterIndex") {
          op = "get-message";
          opData = { next_by_subj: querySubject, seq: +indexNumber };
        } else if (queryMethod === "TODO") {
          op = "get-last-x-messages";
          opData = { subject: querySubject, x: +indexNumber };
        } else {
          throw new Error("Invalid query method");
        }
        const resp = await client.sendOpToStream(stream.name, op, opData);
        setQueryResponse(resp);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleDeleteClicked = async () => {
    try {
      if (stream) {
        let op, opData: any;
        if (deleteMode === "single") {
          op = "delete-message-by-seq";
          opData = {
            seq: +deleteSeq,
          };
        } else if (deleteMode === "multiple") {
          op = "purge";

          if (deleteSeqOrKeep === "seq") {
            opData = {
              seq: +deleteSeq,
            };
          } else if (deleteSeqOrKeep === "keep") {
            opData = {
              keep: +deleteKeep,
            };
          }

          if (deleteSubjectSelected) {
            opData.filter = deleteSubjectSelected;
          }
        } else {
          throw new Error("Invalid delete method");
        }
        const resp = await client.sendOpToStream(stream.name, op, opData);
        setDeleteResponse(resp);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.response ? error.response.data.message : error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  const clearAndClose = async () => {
    console.log("clear and close");
    setIndexNumber("");
    setQueryMethod("byIndex");
    setQueryResponse(null);
    setPublishResponse(null);
    setPayload({});
    setSubjectName("");
    props.onClose();
  };

  const {
    getRootProps: getRootPropsDeleteMode,
    getRadioProps: getRadioPropsDeleteMode,
  } = useRadioGroup({
    name: "deleteMode",
    defaultValue: "single",
    onChange: (value: any) => {
      console.log("value", value);
      setDeleteMode(value);
    },
  });

  const {
    getRootProps: getRootPropsDeleteSubjectMode,
    getRadioProps: getRadioPropsDeleteSubjectMode,
  } = useRadioGroup({
    name: "deleteSubjectMode",
    defaultValue: "all",
    onChange: (value: any) => {
      console.log("value", value);
      setDeleteSubjectMode(value);
    },
  });

  const groupDeleteMode = getRootPropsDeleteMode();
  const groupDeleteSubjectMode = getRootPropsDeleteSubjectMode();

  return (
    <Modal {...props} size="6xl">
      <ModalOverlay />
      <ModalContent overflow={"hidden"} minH="85vh">
        <ModalHeader
          backgroundColor={"blue.600"}
          borderBottom="1px solid"
          borderBottomColor={"blue.700"}
        >
          <HStack justifyContent={"space-between"} spacing={"0"}>
            <Text variant="titleSmall" color="white.900">
              {title} Messages
            </Text>
            <Button
              variant="unstyledWhite"
              size={"xs"}
              height={"13px"}
              padding={"0"}
              onClick={clearAndClose}
            >
              Close
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody background={"white.900"}>
          <Tabs defaultIndex={defaultIndex}>
            <TabList
              gap={"12px"}
              padding={"16px 0 0 0"}
              borderBottom={"1px solid"}
              borderBottomColor={"black.300"}
            >
              <CustomTab
                onClick={() => {
                  setTitle("Query");
                }}
              >
                Query
              </CustomTab>
              <CustomTab
                onClick={() => {
                  setTitle("Publish");
                }}
              >
                Publish
              </CustomTab>
              <CustomTab
                onClick={() => {
                  setTitle("Delete");
                }}
              >
                Delete
              </CustomTab>
            </TabList>
            <TabPanels>
              <TabPanel
                display={"flex"}
                alignItems={"stretch"}
                padding={"16px 0"}
                height={"500px"}
              >
                <HStack alignItems={"stretch"} flex={"1"} spacing={"6"}>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"4"}
                    flexBasis={"320px"}
                    justifyContent={"space-between"}
                  >
                    <VStack alignItems={"stretch"} spacing={"4"}>
                      <VStack alignItems={"stretch"} spacing={"0"}>
                        <Text variant="labelMedium">Query a message from</Text>
                        <Text variant="titleLarge">
                          {subject?.name ?? stream?.name}
                        </Text>
                      </VStack>

                      <FormControl>
                        <FormLabel>Query Method</FormLabel>
                        <FormSelect
                          value={queryMethod}
                          onChange={(e) => {
                            setIndexNumber("");
                            setQueryMethod(e.target.value);
                          }}
                        >
                          <option value="byIndex">
                            By message by index number
                          </option>
                          <option value="lastMessage">
                            A stream's last message
                          </option>
                          <option value="afterIndex">
                            A subject's message after an index
                          </option>
                        </FormSelect>
                      </FormControl>

                      {queryMethod === "byIndex" && (
                        <FormControl>
                          <FormLabel>Index Number</FormLabel>
                          <FormInput
                            value={indexNumber}
                            onChange={(e) => setIndexNumber(e.target.value)}
                          />
                        </FormControl>
                      )}

                      {queryMethod === "afterIndex" && (
                        <>
                          <FormControl>
                            <FormLabel>Subject</FormLabel>
                            <FormSelect
                              value={querySubject}
                              onChange={(e) => setQuerySubject(e.target.value)}
                            >
                              {stream?.subjects &&
                                stream.subjects.map((sub) => {
                                  return (
                                    <option key={sub} value={sub}>
                                      {sub}
                                    </option>
                                  );
                                })}
                            </FormSelect>
                          </FormControl>

                          <FormControl>
                            <FormLabel>Index Number</FormLabel>
                            <FormInput
                              value={indexNumber}
                              onChange={(e) => setIndexNumber(e.target.value)}
                            />
                          </FormControl>
                        </>
                      )}
                    </VStack>

                    <HStack alignItems={"flex-end"}>
                      <Button
                        variant={"primaryBlue"}
                        size="md"
                        onClick={handleQueryClicked}
                      >
                        Query
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={() => {
                          setQueryResponse({});
                          setIndexNumber("");
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={clearAndClose}
                      >
                        Close
                      </Button>
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    padding={"20px"}
                    flex={"1"}
                    background={"black.100"}
                    border={"1px solid"}
                    borderColor={"black.200"}
                    borderRadius={"8px"}
                  >
                    {queryResponse ? (
                      <VStack
                        alignItems={"stretch"}
                        spacing={"4"}
                        height={"100%"}
                      >
                        <Text variant="bodyMedium">
                          The following message has been successfully queried
                          and retrieved
                        </Text>
                        <VStack
                          borderRadius="4px"
                          overflow={"scroll"}
                          flex={"1"}
                        >
                          <JSONInput
                            id="queryResponse"
                            placeholder={queryResponse}
                            theme="dark_vscode_tribute"
                            width={"100%"}
                            locale={locale}
                            viewOnly={true}
                            reset={true}
                          />
                        </VStack>
                      </VStack>
                    ) : (
                      <VStack alignItems={"stretch"} spacing={"1"}>
                        <Text variant="titleMedium">Query Results</Text>
                        <Text variant={"bodySmall"}>
                          Results will show here.
                        </Text>
                      </VStack>
                    )}
                  </VStack>
                </HStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                alignItems={"stretch"}
                padding={"16px 0"}
                height={"500px"}
              >
                <HStack alignItems={"stretch"} flex={"1"} spacing={"6"}>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"4"}
                    flexBasis={"320px"}
                    justifyContent={"space-between"}
                  >
                    <VStack alignItems={"stretch"} spacing={"4"}>
                      <VStack alignItems={"stretch"} spacing={"0"}>
                        <Text variant="labelMedium">Publish a message to</Text>
                        <Text variant="titleLarge">
                          {subject?.name ?? stream?.name}
                        </Text>
                      </VStack>

                      <FormControl>
                        <FormLabel>Subject</FormLabel>
                        <FormInput
                          placeholder="Subject name"
                          value={subjectName}
                          onChange={(e) => setSubjectName(e.target.value)}
                        />
                      </FormControl>
                    </VStack>
                    <HStack>
                      <Button
                        variant={"primaryBlue"}
                        size="md"
                        onClick={handlePublishClicked}
                      >
                        Publish
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={() => {
                          setPayload({});
                          setSubjectName("");
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={clearAndClose}
                      >
                        Close
                      </Button>
                      {publishResponse && (
                        <Text>
                          Your message was successfully published to{" "}
                          {subjectName} with index number {publishResponse.seq}
                        </Text>
                      )}
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    padding={"20px"}
                    flex={"1"}
                    background={"black.100"}
                    border={"1px solid"}
                    borderColor={"black.200"}
                    borderRadius={"8px"}
                    spacing={"4"}
                  >
                    <VStack alignItems={"stretch"} spacing={"1"}>
                      <Text variant="titleMedium">Message payload</Text>{" "}
                      <Text variant={"bodySmall"}>
                        Copy and paste your payload below. JSON will be
                        automatically validated and formatted. Strings will be
                        sent as is.
                      </Text>
                    </VStack>
                    <VStack borderRadius="4px" overflow={"hidden"} flex={"1"}>
                      <JSONInput
                        id="a_unique_id"
                        placeholder={{}}
                        theme="dark_vscode_tribute"
                        locale={locale}
                        width={"100%"}
                        onChange={(json: any) => {
                          setPayload(json.jsObject);
                        }}
                      />
                    </VStack>
                  </VStack>
                </HStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                alignItems={"stretch"}
                padding={"16px 0"}
                height={"500px"}
              >
                <HStack alignItems={"stretch"} flex={"1"} spacing={"6"}>
                  <VStack
                    alignItems={"flex-start"}
                    spacing={"4"}
                    flexBasis={"320px"}
                    justifyContent={"space-between"}
                  >
                    <VStack alignItems={"flex-start"} spacing={"4"}>
                      <VStack alignItems={"stretch"} spacing={"0"}>
                        <Text variant="labelMedium">Delete messages from</Text>
                        <Text variant="titleLarge">
                          {subject?.name ?? stream?.name}
                        </Text>
                      </VStack>

                      <FormControl
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                      >
                        <FormLabel>
                          How many messages would you like to delete?
                        </FormLabel>
                        <HStack
                          {...groupDeleteMode}
                          marginTop="4px"
                          spacing={"2"}
                          border={"1px solid"}
                          borderColor="black.300"
                          padding="4px"
                          borderRadius="20px"
                        >
                          {["single", "multiple"].map((value) => {
                            const radio = getRadioPropsDeleteMode({ value });
                            return (
                              <SegmentedButton key={value} {...radio}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </SegmentedButton>
                            );
                          })}
                        </HStack>
                      </FormControl>

                      {deleteMode === "single" && (
                        <FormControl maxW={200}>
                          <FormLabel>Index Number</FormLabel>
                          <FormInput
                            value={deleteSeq}
                            onChange={(e) => setDeleteSeq(e.target.value)}
                          />
                        </FormControl>
                      )}
                      {deleteMode === "multiple" && (
                        <VStack alignItems={"flex-start"} spacing={"4"}>
                          <FormControl
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-start"}
                          >
                            <FormLabel>
                              Are you deleting messages across the entire stream
                              of subjects, or for one specific subject?
                            </FormLabel>
                            <HStack
                              {...groupDeleteSubjectMode}
                              marginTop="4px"
                              spacing={"2"}
                              border={"1px solid"}
                              borderColor="black.300"
                              padding="4px"
                              borderRadius="20px"
                            >
                              {["all", "one"].map((value) => {
                                const radio = getRadioPropsDeleteSubjectMode({
                                  value,
                                });
                                return (
                                  <SegmentedButton key={value} {...radio}>
                                    {value === "all"
                                      ? "From all subjects"
                                      : "From one Subject"}
                                  </SegmentedButton>
                                );
                              })}
                            </HStack>
                          </FormControl>
                          {deleteSubjectMode === "one" && (
                            <FormControl>
                              <FormLabel>Subject</FormLabel>
                              <FormSelect
                                value={deleteSubjectSelected}
                                onChange={(e) =>
                                  setDeleteSubjectSelected(e.target.value)
                                }
                              >
                                <option key={"blank"} value={""}>
                                  {"Select a subject"}
                                </option>
                                {stream?.subjects &&
                                  stream.subjects.map((sub) => {
                                    return (
                                      <option key={sub} value={sub}>
                                        {sub}
                                      </option>
                                    );
                                  })}
                              </FormSelect>
                            </FormControl>
                          )}

                          <FormControl maxW={"300px"}>
                            <FormLabel>
                              How would you like to delete messages?
                            </FormLabel>
                            <RadioGroup
                              onChange={setDeleteSeqOrKeep}
                              value={deleteSeqOrKeep}
                            >
                              <Stack>
                                <Radio value="seq" size={"sm"}>
                                  Delete up to a specific index
                                </Radio>
                                <Radio value="keep" size={"sm"}>
                                  Delete all messages, except for a specific
                                  amount
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </FormControl>
                          {deleteSeqOrKeep === "seq" && (
                            <FormControl maxW={"300px"}>
                              <FormLabel>
                                Messages from all subjects will be deleted up
                                to, but not including the number you specify.
                              </FormLabel>
                              <FormInput
                                maxW={100}
                                value={deleteSeq}
                                onChange={(e) => setDeleteSeq(e.target.value)}
                              />
                            </FormControl>
                          )}
                          {deleteSeqOrKeep === "keep" && (
                            <FormControl maxW={"300px"}>
                              <FormLabel>
                                How many messages would you like to keep? Note:
                                Messages kept are as sequential as possible from
                                first available message.
                              </FormLabel>
                              <FormInput
                                maxW={100}
                                value={deleteKeep}
                                onChange={(e) => setDeleteKeep(e.target.value)}
                              />{" "}
                            </FormControl>
                          )}
                        </VStack>
                      )}
                    </VStack>
                    <HStack>
                      <Button
                        variant={"primaryRed"}
                        size="md"
                        onClick={handleDeleteClicked}
                      >
                        Delete
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={() => {
                          setQueryResponse({});
                          setIndexNumber("");
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant={"primaryBlack"}
                        size="md"
                        onClick={clearAndClose}
                      >
                        Close
                      </Button>
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    padding={"20px"}
                    flex={"1"}
                    background={"black.100"}
                    border={"1px solid"}
                    borderColor={"black.200"}
                    borderRadius={"8px"}
                  >
                    <VStack
                      alignItems={"stretch"}
                      spacing={"4"}
                      height={"100%"}
                    >
                      <VStack alignItems={"stretch"} spacing={"1"}>
                        <Text variant="titleMedium">Deletion Results</Text>
                        <Text variant={"bodySmall"}>
                          Results will show here.
                        </Text>
                      </VStack>
                      {deleteResponse && (
                        <Text variant="bodyMedium">
                          {JSON.stringify(deleteResponse)}
                        </Text>
                      )}
                    </VStack>
                  </VStack>
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
