import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormInput } from "../FormInput";

interface NewRoleProps {
  productId: string;
  selectedOrg: any;
  result: any;
}

export const NewRole = ({ productId, selectedOrg, result }: NewRoleProps) => {
  const { createRole, createOrganizationRole } = useContext(AuthContext);
  interface FormValues {
    name: string;
    description: string;
    permissions: string[];
  }

  const initialValues: FormValues = {
    name: "",
    description: "",
    permissions: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        let errors: FormikErrors<FormValues> = {};
        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.description) {
          errors.description = "Required";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const role = {
            name: values.name,
            description: values.description,
          };

          const roleResult: any = selectedOrg
            ? await createOrganizationRole(selectedOrg.id, productId, role)
            : await createRole(productId, role);
          const newRole: any = roleResult.data.data;

          result(newRole);
        } catch (error) {
          console.error(error);
        }

        setSubmitting(false);
      }}
    >
      {(props) => (
        <Form>
          <VStack
          alignItems="stretch"
          spacing={"4"}
          >
          <Text variant={"contentheader"}>Add New Role</Text>
            <Field name="name">
              {({ field, form }: any) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <FormInput {...field} id="name" placeholder="Name" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="description">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <FormInput
                    {...field}
                    id="description"
                    placeholder="Description"
                  />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex>
              <Button
                type="submit"
                disabled={props.isSubmitting}
                variant={"primaryBlue"}
              >
                Add New Role
              </Button>
            </Flex>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
