export interface UEClient {
  request_uris: string[];
  client_id_issued_at?: number;
  client_id: string;
  client_secret_expires_at?: number;
  client_secret: string;
  auth_group: string;
  registration_client_uri: string;
  registration_access_token?: string;
  application_type: string;
  grant_types: string[];
  id_token_signed_response_alg: string;
  post_logout_redirect_uris: string[];
  require_auth_time: boolean;
  response_types: string[];
  subject_type: string;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  client_name: string;
  redirect_uris: string[];
  client_skip_consent: boolean;
  client_optional_skip_logout_prompt: boolean;
  associated_product: string;
  register_url: string;
  tos_url: string;
  policy_url: string;
  client_federation_options: string[];
  client_allow_org_federation: boolean;
  client_label: string;
}

export interface UEProduct {
  id: string;
  codedId: string;
  modifiedAt: Date;
  createdAt: Date;
  createdBy: string;
  modifiedBy: string;
  name: string;
  description: string;
  associatedClients: string[];
  type: string;
}

export interface UEProductResponse {
  type: string;
  data: UEProduct;
}

export interface UEAuthGroup {
  id: string;
  createdAt: Date;
  modifiedAt: Date;
  modifiedBy: string;
  active: string;
  name: string;
  prettyName: string;
  owner: string;
  locked: boolean;
  metadata: {};
  config: {
    ttl: {
      accessToken: number;
      authorizationCode: number;
      clientCredentials: number;
      deviceCode: number;
      idToken: number;
      refreshToken: number;
      interaction: number;
      session: number;
      grant: number;
    };
    requireVerified: boolean;
    autoVerify: boolean;
    passwordLessSupport: boolean;
    centralPasswordReset: boolean;
    scopes: string[];
    acrValues: string[];
    deviceFlow: boolean;
    pkceRequired: boolean;
    mfaChallenge: {
      enable: boolean;
      required: boolean;
      type: string;
      meta: {};
    };
    federate: {
      oidc: UEProvider[];
      oauth2: UEProvider[];
    };
    ui: {
      meta: {};
      skin: {
        backgroundImage: string;
        splashImage: string;
        logo: string;
        loginOrientation: string;
        bgGradientLow: string;
        bgGradientHigh: string;
      };
    };
  };
  pluginOptions: {
    notification: {
      enabled: boolean;
      ackRequiredOnOptional: boolean;
    };
  };
  primaryDomain: string;
  primaryTOS: string;
  primaryPrivacyPolicy: string;
  primaryEmail: string;
  registerUrl: string;
  aliasDnsUi: string;
  aliasDnsOIDC: string;
}

export interface UEProvider {
  provider: string;
  buttonType: string;
  name: string;
  buttonText: string;
  PKCE: boolean;
  client_id: string;
  client_secret: string;
  response_type: string;
  grant_type: string;
  scopes: string[];
  accessTokenUri?: string;
  authorizationUri?: string;
  profileUri?: string;
  discovery_url?: string;
}

export enum RequestAccessViewType {
  ReadOnly,
  SubjectOnly,
  QueueGroupOnly,
  SubjectAndQueueGroup,
}
