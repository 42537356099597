import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import {
  Box,
  Button,
  Text,
  CloseButton,
  useDisclosure,
  IconButton,
  HStack,
  Stack,
  Link,
  Center,
  VStack,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import Window from "../Window";
import { WindowsContext } from "../../providers/windowsProvider";
import { Users } from "../users/users";
import { Organizations } from "../organizations/organizations";
import { Products } from "../products/products";
import { AuthGroup } from "../authGroups/authGroup";
import { Profile } from "../accountSettings/accountSettings";
import { Roles } from "../roles/roles";
import { Permissions } from "../permissions/permissions";
import { Desktop } from "../desktop/Desktop";
import { HelmetBranded } from "../HelmetBranded";
import { Streams } from "../streams/Streams";
import { AccessManagement } from "../streams/AccessManagement";
import { SolutionLoaderService } from "../../services/solution-loader/SolutionLoaderService";
import { MobileProfile } from "../accountSettings/mobileAccountSettings";
import { MenuItemSubMenu, CloseSubMenuOverlay, InnerMenu } from "../";
//import { Rnd } from "react-rnd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          minH={"100vh"}
          bgImg={"/images/core-eos-background.jpg"}
          bgSize={"cover"}
          bgPos={"center"}
        >
          <Center>
            <Button onClick={() => window.location.reload()} mt="100">
              Sorry, something went wrong. Refresh?
            </Button>
          </Center>
        </Box>
      );
    }

    return this.props.children;
  }
}

export const EOSPage = () => {
  const cx = useContext(AuthContext);
  const group = cx.getGroup();
  const dataSolutionId =
    process.env.REACT_APP_SOLUTION_STREAMING_ID ||
    "d98549bd-6cab-47cb-a11d-8a7efb2bd304";
  const [authUser, setAuthUser] = useState(null);
  const [windows, setWindows] = useContext(WindowsContext);
  const [groupInfo, setGroupInfo] = useState("");
  const [hasAGAccess, setHasAGAccess] = useState(false);
  const [hasOrgAdminAccess, setHasOrgAdminAccess] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [logo, setLogo] = useState("");
  const [agMfaEnabled, setAgMfaEnabled] = useState(false);
  const [showData, setShowData] = useState(false);
  const [eosBackground, setEosBackground] = useState();

  const [innerMenusActive, setInnerMenusActive] = useState(true);

  const closeSubMenus = () => {
    setInnerMenusActive(false);
  };

  useEffect(() => {
    cx.getUser().then((user) => {
      window.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          cx.validateAccount()
            .then(() => {})
            .catch((error) => {
              console.log("error: ", error);
              if (error.response.status === 401) {
                window.location.replace("login");
              }
            });
        }
      });

      setAuthUser(user);

      cx.getAuthGroup()
        .then((res) => {
          const group = res.data.data;
          setGroupInfo(group);
          setGroupName(group.name);
          const bg = group.config?.ui?.skin?.backgroundImage
            ? group.config?.ui?.skin?.backgroundImage
            : "/images/core-eos-background.jpg";
          setEosBackground(bg);
          setLogo(group.config?.ui?.skin?.favicon);
          setHasAGAccess(true);
          setAgMfaEnabled(group.config?.mfaChallenge?.enable);

          const solutionsService = new SolutionLoaderService(
            user.access_token,
            group.id
          );

          solutionsService
            .getSolutions()
            .then((res) => {
              const solutions = res;
              const dataSolution = solutions.find(
                (s) => s.globalId === dataSolutionId
              );
              if (dataSolution && dataSolution.active === true) {
                setShowData(true);
              } else {
                solutionsService.addSolution(dataSolutionId).then(() => {
                  cx.forceSigninSilent().then(() => {
                    setShowData(true);
                  });
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status === 403) {
            setHasAGAccess(false);
            cx.getAuthGroupInfo()
              .then((res) => {
                setGroupName(res.data.data.name);
                setLogo(res.data.data.details.config.ui.skin.favicon);
                setAgMfaEnabled(res.data.data.details?.mfaChallenge?.enable);
              })
              .catch((error) => console.log(error));
            cx.validateAccount()
              .then((res) => {
                const access = res.data.data.access;
                if (access.length > 0) {
                  access.map((a) => {
                    console.log(a);
                    if (a.organization.productRoles.length > 0) {
                      if (
                        a.organization.productRoles.some(
                          (pr) => pr.name === "OrganizationAdmin"
                        )
                      ) {
                        return setHasOrgAdminAccess(true);
                      }
                    }
                    return setHasOrgAdminAccess(false);
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    });

    return () => {};
  }, [cx, group, dataSolutionId]);

  const createWindow = (label) => {
    let children;
    let initialSize;

    switch (label) {
      case "users":
        children = <Users group={group} orgAdmin={hasOrgAdminAccess} />;
        break;
      case "organizations":
        children = <Organizations group={group} orgAdmin={hasOrgAdminAccess} />;
        break;
      case "products":
        children = <Products group={groupInfo} />;
        break;
      case "profile":
        children = <Profile group={group} agMfaEnabled={agMfaEnabled} />;
        break;
      case "roles":
        children = <Roles />;
        break;
      case "permissions":
        children = <Permissions />;
        break;
      case "settings":
        children = (
          <AuthGroup
            authGroup={groupInfo}
            result={(group) => {
              setGroupInfo(group);
            }}
            onRefresh={() => {
              const solutionsService = new SolutionLoaderService(
                authUser.access_token,
                groupInfo.id
              );

              solutionsService
                .getSolutions()
                .then((res) => {
                  const solutions = res;

                  const dataSolution = solutions.find(
                    (s) => s.globalId === dataSolutionId
                  );
                  if (dataSolution && dataSolution.active === true) {
                    setShowData(true);
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          />
        );
        break;
      case "streams":
        children = <Streams />;
        initialSize = { width: 1400, height: 600 };
        break;
      case "management":
        children = <Streams />;
        initialSize = { width: 1400, height: 600 };
        break;
      case "access":
        children = <AccessManagement />;
        initialSize = { width: 1400, height: 600 };
        break;
      case "access control":
        children = <AccessManagement />;
        initialSize = { width: 1400, height: 600 };
        break;
      default:
        children = <div>{label}</div>;
    }

    const windowsLocal = windows.length > 0 ? [...windows] : [];

    let existingWindow = windowsLocal.find((w) => w.label === label);

    if (existingWindow) {
      // const index = windowsLocal.indexOf(existingWindow);
      // TODO: figure out how to bring the window to the front
    } else {
      windowsLocal.push({
        children,
        isResizable: true,
        isDraggable: true,
        initialPosition: "center",
        label: label,
        initialSize,
      });
    }

    setWindows(windowsLocal);
  };

  const NavItem = ({ children, label, ...rest }) => {
    return (
      <Button
        variant="primarynavlink"
        onClick={() => {
          createWindow(label);
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  };

  const SidebarContent = ({ ...rest }) => {
    return (
      <>
        {hasAGAccess && (
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            gap={"24px"}
            pos={"fixed"}
            top={"52px"}
            left={"8px"}
            background={"black.400"}
            backdropFilter={"blur(24px)"}
            borderRadius={"4px"}
            border={"1px solid"}
            borderColor={"white.100"}
            shadow={"default.500"}
            p="16px 12px 12px 12px"
            {...rest}
          >
            <VStack
              width={"100%"}
              alignItems={"stretch"}
              spacing={"0"}
              gap={"2px"}
            >
              <Text variant="titleSmall" color={"white.600"} marginLeft={"8px"}>
                Identity
              </Text>
              <CloseButton display={{ base: "flex", md: "none" }} />

              <NavItem key={"Users"} label={"users"}>
                {"Users"}
              </NavItem>
              <NavItem key={"Organizations"} label={"organizations"}>
                {"Organizations"}
              </NavItem>
            </VStack>
            <VStack
              width={"100%"}
              alignItems={"stretch"}
              spacing={"0"}
              gap={"2px"}
            >
              <Text variant="titleSmall" color={"white.600"} marginLeft={"8px"}>
                Access
              </Text>
              <NavItem key={"Products"} label={"products"}>
                {"Products"}
              </NavItem>
              <NavItem key={"Roles"} label={"roles"}>
                {"Roles"}
              </NavItem>
              <NavItem key={"Permissions"} label={"permissions"}>
                {"Permissions"}
              </NavItem>
            </VStack>
            {showData && (
              <>
                <VStack
                  width={"100%"}
                  alignItems={"stretch"}
                  spacing={"0"}
                  gap={"2px"}
                >
                  <Text
                    variant="titleSmall"
                    color={"white.600"}
                    marginLeft={"8px"}
                  >
                    Data
                  </Text>

                  <NavItem key={"streams"} label={"streams"}>
                    Simple Streams
                  </NavItem>
                  <NavItem key={"access"} label={"access"}>
                    Access Management
                  </NavItem>
                </VStack>
              </>
            )}
          </VStack>
        )}
        {hasOrgAdminAccess && (
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            gap={"24px"}
            pos={"fixed"}
            top={"52px"}
            left={"8px"}
            background={"black.400"}
            backdropFilter={"blur(24px)"}
            borderRadius={"4px"}
            border={"1px solid"}
            borderColor={"white.100"}
            shadow={"default.500"}
            p="16px 12px 12px 12px"
            {...rest}
          >
            <VStack
              width={"100%"}
              alignItems={"stretch"}
              spacing={"0"}
              gap={"2px"}
            >
              <Text variant="titleSmall" color={"white.600"} marginLeft={"8px"}>
                Identity
              </Text>
              <CloseButton display={{ base: "flex", md: "none" }} />

              {LinkItems.filter((link) => {
                return link.label === "users" || link.label === "organizations";
              }).map((link) => (
                <NavItem key={link.name} label={link.label}>
                  {link.name}
                </NavItem>
              ))}
            </VStack>
          </VStack>
        )}
      </>
    );
  };

  const Navbar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <Box pos={"fixed"} left={"0"} right={"0"}>
          <HStack
            margin={"6px"}
            borderRadius={"4px"}
            spacing={"0"}
            height={"32px"}
            alignItems={"stretch"}
            justifyContent={{ base: "left", md: "space-between" }}
            background={"neutral.200"}
            shadow={"default.500"}
            padding={"0 0 0 2px"}
            border={"1px solid"}
            borderColor={"neutral.300"}
          >
            <IconButton
              height={"95%"}
              size={"sm"}
              icon={
                isOpen ? (
                  <CloseIcon color="neutral.500" boxSize={"10px"} />
                ) : (
                  <HamburgerIcon color="neutral.500" />
                )
              }
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
              background={"none"}
              _hover={{
                background: "none",
              }}
              _active={{
                background: "none",
              }}
            />
            <HStack spacing={"0"} alignItems={"stretch"}>
              <Text
                padding={"0 12px"}
                alignSelf={"center"}
                variant="labelSmall"
                color={"neutral.900"}
                cursor="default"
                userSelect={"none"}
              >
                {groupInfo ? groupInfo.name : groupName}
              </Text>
              {hasAGAccess && (
                <HStack
                  alignItems={"stretch"}
                  as={"nav"}
                  userSelect="none"
                  display={{ base: "none", md: "flex" }}
                >
                  {Links.map((link) => {
                    if (link.newWindow) {
                      return (
                        <Link
                          variant="utilitynavlink"
                          key={link.name}
                          onClick={() => {
                            createWindow(link.newWindow);
                          }}
                        >
                          {link.label}
                        </Link>
                      );
                    } else {
                      return (
                        <NavLink key={link.label} href={link.href}>
                          {link.label}
                        </NavLink>
                      );
                    }
                  })}
                </HStack>
              )}
            </HStack>
            <HStack
              alignItems={"stretch"}
              spacing={"0"}
              flex={"1"}
              justifyContent={"flex-end"}
            >
              <Text
                alignSelf={"center"}
                variant="labelSmall"
                color={"neutral.900"}
                cursor="default"
                userSelect={"none"}
                key={"email"}
                padding={"0 12px"}
              >
                {authUser?.profile?.email}
              </Text>
              <HStack
                alignItems={"stretch"}
                spacing={"0"}
                as={"nav"}
                display={{ base: "none", md: "flex" }}
              >
                {authUser && (
                  <>
                    <Link
                      variant="utilitynavlink"
                      key={"profile"}
                      onClick={() => {
                        createWindow("profile");
                      }}
                    >
                      Account
                    </Link>
                  </>
                )}
                <Link
                  variant="utilitynavlink"
                  href="https://docs.unitedeffects.com/docs"
                  isExternal
                >
                  Help
                </Link>
                {Links2.map((link) => (
                  <NavLink key={link.label} href={link.href}>
                    {link.label}
                  </NavLink>
                ))}
              </HStack>
            </HStack>
          </HStack>

          {isOpen ? (
            <Box
              pb={4}
              display={{ md: "none" }}
              background="neutral.200"
              borderRadius={"8px"}
              border="1px solid"
              borderColor="neutral.300"
              shadow={"default.500"}
              m="4px"
              p="4px"
            >
              <Stack as={"nav"} spacing={4}>
                {Links2.map((link) => (
                  <MobileNavLink key={link.label} href={link.href}>
                    {link.label}
                  </MobileNavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      </>
    );
  };

  const LinkItems = [
    { name: "Users", label: "users" },
    { name: "Organizations", label: "organizations" },
    { name: "Products", label: "products" },
    { name: "Roles", label: "roles" },
    { name: "Permissions", label: "permissions" },
  ];

  const Links = [
    //{ name: "file", label: "File", href: "#" },
    //{ name: "edit", label: "Edit", href: "#" },
    //{ name: "view", label: "View", href: "#" },
    {
      name: "settings",
      label: "Settings",
      href: "#",
      newWindow: "settings",
    },
    /*{ name: "help", label: "Help", href: "#" },*/
  ];
  const Links2 = [{ name: "signout", label: "Log out", href: "/logout" }];

  const NavLink = ({ children, href }) => (
    <Link variant="utilitynavlink" href={href}>
      {children}
    </Link>
  );

  const MobileNavLink = ({ children, href }) => (
    <Link variant={"mobileutilitynavlink"} href={href}>
      {children}
    </Link>
  );

  const removeWindow = (index) => () => {
    const windowsLocal = [...windows];

    windowsLocal.splice(index, 1);

    setWindows(windowsLocal);
  };

  return (
    <ErrorBoundary>
      <HelmetBranded groupName={groupName} logo={logo} />
      <Box
        overflow="clip"
        bgImg={eosBackground || ""}
        backgroundColor={eosBackground ? "transparent" : "neutral.900"}
        transition="background-image 60ms ease-in"
        bgSize={"cover"}
        bgPos={"center"}
        width={"100%"}
        minHeight={"100%"}
        /** pos={"fixed"}
        top={"0"}
        bottom={"0"}
        left={"0"}
        right={"0"} **/
      >
        <Navbar />
        <SidebarContent
          display={{ base: "none", md: "none" }}
          flexDirection="column"
          alignItems="flex-start"
        />
        <Desktop />
        <MobileProfile
          group={group}
          agMfaEnabled={agMfaEnabled}
          display={{ base: "flex", md: "none" }}
        />
        {windows &&
          windows.length > 0 &&
          windows.map((window, index) => {
            const { children } = window;
            return (
              <Window
                key={index}
                onClose={removeWindow(index)}
                //onMove={this.onMove}
                {...window}
              >
                <Box h="full">{children}</Box>
              </Window>
            );
          })}
        {/*<Rnd
          default={{
            x: 300,
            y: 100,
            width: 1400,
            height: 800,
          }}
          bounds="window"
          dragHandleClassName="header"
        >
          <HStack>
            <Box
              className="header"
              sx={{
                minH: "100vh",
                width: "20px",
                border: "solid 1px red",
                cursor: "move",
              }}
            ></Box>
            <Streams />
          </HStack>
            </Rnd>*/}
        <HStack
          display={{ base: "none", md: "flex" }}
          spacing={"0"}
          position={"fixed"}
          bottom={"56px"}
          left={"24px"}
          right={"0"}
          height={"72px"}
          alignItems={"stretch"}
          zIndex={"999"}
        >
          {(hasAGAccess || hasOrgAdminAccess) && (
            <Menu>
              <CloseSubMenuOverlay
                isActive={innerMenusActive}
                closeSubMenu={closeSubMenus}
              />
              <MenuButton
                transition="all 100ms ease-in-out"
                color="neutral.800"
                backgroundImage={
                  "linear-gradient(180deg,rgba(146, 235, 164, 1),rgba(146, 235, 164, 0))"
                }
                backgroundColor={"green.400"}
                height={"72px"}
                width={"72px"}
                fontSize={"17px"}
                fontWeight={"600"}
                border={"5px solid"}
                borderColor={"green.500"}
                borderRadius={"60px"}
                _hover={{
                  backgroundColor: "green.500",
                  borderColor: "green.600",
                  color: "neutral.1000",
                }}
                _expanded={{
                  backgroundColor: "green.500",
                  borderColor: "green.600",
                  color: "neutral.1000",
                }}
                _focus={
                  {
                    //boxShadow: "0 0 4px 2px rgba(146, 235, 164, .4)"
                  }
                }
              >
                Start
              </MenuButton>
              <MenuList
              //backgroundColor={"black.800"}
              //borderColor={"slate.900"}
              //backdropFilter={"blur(24px)"}
              >
                <MenuItemSubMenu>
                  <InnerMenu
                    title="Auth"
                    childrenItems={
                      hasAGAccess
                        ? [
                            "Users",
                            "Organizations",
                            "Products",
                            "Roles",
                            "Permissions",
                          ]
                        : [
                            "Users",
                            "Organizations",
                          ]
                    }
                    onClick={(item) => {
                      createWindow(item.toLowerCase());
                    }}
                  />
                </MenuItemSubMenu>
                {hasAGAccess && (
                  <MenuItemSubMenu>
                    <InnerMenu
                      title="Streaming"
                      childrenItems={["Management", "Access Control"]}
                      onClick={(item) => {
                        createWindow(item.toLowerCase());
                      }}
                    />
                  </MenuItemSubMenu>
                )}
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Box>
    </ErrorBoundary>
  );
};
