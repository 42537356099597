import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Skeleton,
  Divider,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { Organization } from "./organization";
import { NewOrganization } from "./newOrganization";
import { AuthContext } from "../../providers/authProvider";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { NewDomain } from "../domains/newDomain";
import { Domain } from "../domains/domain";
import { OrganizationProducts } from "./organizationProducts";
import { NewCustomProvider, NewCustomProviderParentType } from "../authGroups/newCustomProvider";
import { AddIcon } from "@chakra-ui/icons";

interface OrganizationsProps {
  orgId: any;
  group: any;
  orgAdmin?: boolean;
}

export const Organizations = ({
  group,
  orgAdmin = false,
}: OrganizationsProps) => {
  const [organizations, setOrganizations] = React.useState<any>([]);
  const [domains, setDomains] = React.useState<any>([]);
  const [newOrganization, setNewOrganization] = React.useState<boolean>(false);
  const [newDomain, setNewDomain] = React.useState<boolean>(false);
  const [loadingOrgs, setLoadingOrgs] = React.useState<boolean>(true);
  const [loadingDomains, setLoadingDomains] = React.useState<boolean>(true);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState<any>(null);
  const [secondaryColumnSelectedOption, setSecondaryColumnSelectedOption] =
    React.useState<any>(null);
  const { getOrganizations, getDomains, getMyOrganizations, getOrganization } =
    React.useContext(AuthContext);

  const orgSelected = useCallback(
    async (selectedOrg: any) => {
      setLoadingDomains(true);
      try {
        const res = await getDomains(selectedOrg.id);
        const domains = res.data.data;

        setDomains(domains);
      } catch (err) {
        console.log(err);
      }

      setLoadingDomains(false);
    },
    [getDomains]
  );

  const setupOrgs = useCallback(
    (orgs: any) => {
      setOrganizations(orgs);
      setLoadingOrgs(false);
      setSecondaryColumnSelectedOption({ name: "admin" });

      if (orgs.length > 0) {
        setPrimaryColumnSelectedOption(orgs[0]);
        orgSelected(orgs[0]);
      }
    },
    [orgSelected]
  );

  React.useEffect(() => {
    if (orgAdmin) {
      getMyOrganizations()
        .then((res) => {
          const orgList = res.data.data.organizations.map((org: any) => {
            return org.id;
          });
          console.log(orgList);

          if (orgList.length > 0) {
            let reqs: any[] = [];
            let orgs: any[] = [];
            orgList.forEach((org: any) => {
              reqs.push(getOrganization(org));
            });

            Promise.all(reqs)
              .then((res) => {
                res.forEach((r: any) => {
                  const newOrg = r.data.data;
                  orgs = [...orgs, newOrg];
                });
                setupOrgs(orgs);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getOrganizations()
        .then((res) => {
          const orgs = res.data.data;
          setupOrgs(orgs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    getOrganizations,
    orgSelected,
    setupOrgs,
    getMyOrganizations,
    getOrganization,
    orgAdmin,
  ]);

  const primaryColumnOptionSelected = async (selectedOption: any) => {
    setPrimaryColumnSelectedOption(selectedOption);
    setNewOrganization(false);
    setSecondaryColumnSelectedOption({ name: "admin" });
  };

  return (
    <Flex h="100%" alignItems="stretch">
      <VStack
        layerStyle="windowmenu"
        alignItems="stretch"
        flex="1"
        spacing="0"
      >
        <Text variant="windowheader">
          Organizations
        </Text>
        <Flex paddingBottom={"8px"}>
          <Button
            width={"100%"}
            variant="primaryBlue"
            size="sm"
            onClick={() => {
              setNewOrganization(true);
              setPrimaryColumnSelectedOption(null);
              setSecondaryColumnSelectedOption(null);
            }}
          >
            Add
          </Button>
        </Flex>
        <Divider opacity={".4"} />
        <Skeleton
          overflow={"hidden"}
          overflowY={"scroll"}
          isLoaded={!loadingOrgs}>
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            paddingTop={"8px"}
            paddingBottom={"8px"}
          >
            {newOrganization && (
              <WindowColumnPrimaryButton
                key={"newOrganization"}
                onClick={() => {
                  //this.primaryColumnOptionSelected(option);
                }}
                active={true}
              >
                <Text
                  width={"100%"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                >
                  New Organization
                </Text>
              </WindowColumnPrimaryButton>
            )}
            {organizations?.map((option: any) => {
              return (
                <WindowColumnPrimaryButton
                  key={option.name}
                  onClick={() => {
                    primaryColumnOptionSelected(option);
                    orgSelected(option);
                  }}
                  active={primaryColumnSelectedOption?.name === option.name}
                >
                  <Text
                    width={"100%"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.name}
                  </Text>
                </WindowColumnPrimaryButton>
              );
            })}
          </VStack>
        </Skeleton>
      </VStack>
      <VStack
        flex={"1"}
        alignItems={"stretch"}
        spacing={"0"}
      >
        <Flex layerStyle="windowheader">
          {primaryColumnSelectedOption?.name ? (primaryColumnSelectedOption?.name) : ("New Organization")}
        </Flex>
        <HStack
          spacing={"0"}
          alignItems={"stretch"}
          overflow={"hidden"}
          flex={"1"}
        >
          {!newOrganization && (
            <VStack
              layerStyle="windowsubmenu"
              alignItems="stretch"
              spacing={"0"}
              padding={"8px 8px 0 8px"}
            >
              <Text variant="windowsubmenusubheader">
                Settings
              </Text>
              <VStack
                alignItems={"stretch"}
                spacing={"0"}
                gap={"4px"}
                paddingTop={"4px"}
              >
                <Divider borderColor={"black.400"} />
                <WindowColumnSecondaryButton
                  onClick={() => {
                    setSecondaryColumnSelectedOption({ name: "admin" });
                  }}
                  active={secondaryColumnSelectedOption?.name === "admin"}
                >
                  Administration
                </WindowColumnSecondaryButton>
                <WindowColumnSecondaryButton
                  onClick={() => {
                    setSecondaryColumnSelectedOption({ name: "products" });
                  }}
                  active={secondaryColumnSelectedOption?.name === "products"}
                >
                  Licensed Products
                </WindowColumnSecondaryButton>
                <WindowColumnSecondaryButton
                  onClick={() => {
                    setSecondaryColumnSelectedOption({ name: "sso" });
                  }}
                  active={secondaryColumnSelectedOption?.name === "sso"}
                >
                  SSO
                </WindowColumnSecondaryButton>
              </VStack>
              <VStack
                alignItems={"stretch"}
                spacing={"0"}
                gap={"4px"}
                paddingTop={"16px"}
              >
                <HStack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {" "}
                  <Text variant="windowsubmenusubheader">Domains</Text>
                  <Button
                    leftIcon={<AddIcon boxSize={"10px"} />}
                    iconSpacing={".3rem"}
                    variant="unstyledBlack"
                    size="xs"
                    onClick={() => {
                      setNewDomain(true);
                      setNewOrganization(false);
                      setSecondaryColumnSelectedOption({ name: "newDomain" });
                    }}
                  >
                    <Text>New Domain</Text>
                  </Button>
                </HStack>
                <Divider borderColor={"black.400"} />
                <VStack
                  alignItems={"stretch"}
                  gap={"4px"}
                  spacing={"0"}
                  paddingBottom={"8px"}
                >
                  {newDomain && (
                    <WindowColumnSecondaryButton key={"newDomain"} active={true}>
                      New Domain
                    </WindowColumnSecondaryButton>
                  )}
                  {domains &&
                    domains.length > 0 &&
                    domains.map((option: any) => {
                      return (
                        <WindowColumnSecondaryButton
                          key={option.name}
                          onClick={() => {
                            setSecondaryColumnSelectedOption(option);
                            setNewDomain(false);
                            setNewOrganization(false);
                          }}
                          active={
                            secondaryColumnSelectedOption?.name === option.name
                          }
                        >                <Skeleton
                          overflow={"hidden"}
                          overflowY={"scroll"}
                          isLoaded={!loadingDomains}
                        >
                            <Text
                              width={"100%"}
                              whiteSpace={"nowrap"}
                              textOverflow={"ellipsis"}
                              overflow={"hidden"}
                            >
                              {option.name}
                            </Text>
                          </Skeleton>
                        </WindowColumnSecondaryButton>
                      );
                    })}
                </VStack>
              </VStack>
            </VStack>
          )}
          <VStack
            alignItems={"stretch"}
            layerStyle="windowcontentarea"
            spacing={"0"}
            gap={"24px"}
          >
            {secondaryColumnSelectedOption?.name === "admin" &&
              primaryColumnSelectedOption?.id && (
                <Organization
                  key={primaryColumnSelectedOption.id}
                  org={primaryColumnSelectedOption}
                  orgAdmin={orgAdmin}
                  result={(org: any) => {
                    const index = organizations.findIndex(
                      (p: any) => p.id === org.id
                    );
                    const newOrganizations = [...organizations];
                    newOrganizations[index] = org;
                    setOrganizations([...newOrganizations]);
                    setPrimaryColumnSelectedOption(org);
                  }}
                  orgDeleted={(id: string) => {
                    const newOrganizations = organizations.filter((o: any) => o.id !== id);
                    setOrganizations(newOrganizations);
                    setPrimaryColumnSelectedOption(newOrganizations[0]);
                  }}
                />
              )}
            {secondaryColumnSelectedOption?.name === "products" && (
              <OrganizationProducts
                org={primaryColumnSelectedOption}
                group={group}
                orgAdmin={orgAdmin}
              />
            )}
            {secondaryColumnSelectedOption?.name === "sso" && (
              <NewCustomProvider
                parentId={primaryColumnSelectedOption?.id}
                parentType={NewCustomProviderParentType.Organization}
                result={(provider: any) => {
                  console.log(provider);
                }}
                title="SSO"
              />
            )}
            {newOrganization && (
              <Box w="full" h="100%" minW="500px" overflowX="auto">
                <NewOrganization
                  result={(org: any) => {
                    if (org) {
                      console.log("new org", org);
                      setOrganizations([...organizations, org]);
                      setNewOrganization(false);
                      setPrimaryColumnSelectedOption(org);
                      setSecondaryColumnSelectedOption({ name: "admin" });
                    } else {
                      setNewOrganization(false);
                    }
                  }}
                />
              </Box>
            )}
            {newDomain && (
              <Box w="full" h="100%" minW="300px" overflowX="auto">
                <NewDomain
                  org={primaryColumnSelectedOption}
                  result={(domain: any) => {
                    if (domain) {
                      setDomains([...domains, domain]);
                      setNewDomain(false);
                      setSecondaryColumnSelectedOption(domain);
                    } else {
                      setNewOrganization(false);
                    }
                  }}
                />
              </Box>
            )}
            {!newDomain &&
              !newOrganization &&
              secondaryColumnSelectedOption &&
              secondaryColumnSelectedOption.id && (
                <Domain
                  key={secondaryColumnSelectedOption.id}
                  org={primaryColumnSelectedOption}
                  domain={secondaryColumnSelectedOption}
                  result={(domain: any) => {
                    const index = domains.findIndex((p: any) => p.id === domain.id);
                    const newDomains = [...domains];
                    newDomains[index] = domain;
                    setDomains(newDomains);
                  }}
                  domainDeleted={(id: string) => {
                    const newDomains = domains.filter((o: any) => o.id !== id);
                    setDomains(newDomains);
                    setSecondaryColumnSelectedOption(newDomains[0]);
                  }}
                />
              )}
          </VStack>
        </HStack>
      </VStack >
    </Flex >
  );
};