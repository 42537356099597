import {
  VStack,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
  Spinner,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { useState, useCallback, } from "react";

export const SearchInput = ({
  loading,
  options,
  requests,
  onClickFunction,
  placeholder,
  org = null,
  onFocus,
  onBlur,
}: any) => {
  const [inputValue, setInputValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((newValue, org) => requests(newValue, org), 300),
    []
  );

  const updateValue = (newValue: any) => {
    setInputValue(newValue);
    debouncedSave(newValue, org);
  };

  return (
    <VStack
      spacing={"0"}
      position={"relative"}
    >
      <InputGroup>
        <Input
          value={inputValue}
          onChange={(input) => updateValue(input.target.value)}
          placeholder={placeholder}
          border={"1px solid #151829"}
          boxSizing={"border-box"}
          backgroundColor={"white"}
          size={"sm"}
          borderRadius={"sm"}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {loading && (
          <InputRightElement
            children={<Spinner size={"sm"} marginTop={"-8px"} color="blue.500" />}
          />
        )}
      </InputGroup>
      {options?.like?.length > 0 && !loading && (
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          backgroundColor={"white"}
          border={"1px solid"}
          borderColor={"neutral.200"}
          fontWeight={"500"}
          fontSize={"13px"}
          letterSpacing={".0025em"}
          lineHeight={"1.3em"}
          p={"8px"}
          paddingTop={"0"}
          paddingBottom={"0"}
          top={"34px"}
          borderRadius={"sm"}
          zIndex={1}
          position={"absolute"}
          width={"100%"}
          boxShadow={"default.500"}
          maxHeight={"340px"}
        >
          <VStack
            onClick={() => {
              const emailToAdd = inputValue;
              setInputValue("");
              onClickFunction(emailToAdd);
            }}
            _hover={{
              bg: "blue.700",
              color: "white",
              borderColor: "blue.700",
            }}
            display={"block"}
            padding={"8px 12px"}
            borderBottom={"1px solid"}
            borderBottomColor={"neutral.200"}
            backgroundColor={"white"}
            borderRadius={"2px 2px 0 0"}
            position={"absolute"}
            top={"0"}
            left={"0"}
            width={"100%"}
            zIndex={"2"}
          ><Text>
              <Text variant={"titleSmall"}>Create new user</Text> {inputValue}
            </Text>
          </VStack>
          <List
            spacing={"2px"}
            overflow={"scroll"}
            position={"relative"}
            paddingTop={"58px"}
          >

            {options?.like?.map((value: any, index: any) => (
              <ListItem
                key={`${value.email}-${index}`}
                onClick={() => {
                  setInputValue(value.email);
                  onClickFunction(value);
                }}
                _hover={{
                  bg: "blue.700",
                  color: "white",
                  borderRadius: "sm",
                }}
                display={"block"}
                padding={"6px"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
              >
                {value.email}
              </ListItem>
            ))}
          </List>
        </VStack>
      )}
      {options?.like?.length === 0 && !loading && inputValue && (
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          backgroundColor={"white"}
          fontWeight={"500"}
          fontSize={"13px"}
          letterSpacing={".0025em"}
          lineHeight={"1.3em"}
          top={"34px"}
          borderRadius={"sm"}
          zIndex={1}
          position={"absolute"}
          width={"100%"}
          boxShadow={"default.500"}
        >
          <List
            spacing={"2px"}
            overflow={"scroll"}
            position={"relative"}
          >
            <ListItem
              onClick={() => {
                const emailToAdd = inputValue;
                setInputValue("");
                onClickFunction(emailToAdd);
              }}
              _hover={{
                bg: "blue.700",
                color: "white",
                borderColor: "blue.800",
              }}
              display={"block"}
              padding={"12px"}
              backgroundColor={"white"}
              borderRadius={"sm"}
              border={"1px solid"}
              borderColor={"neutral.200"}
            >
              <Text variant={"titleSmall"}>Create new user</Text> {inputValue}
            </ListItem>
          </List>
        </VStack>
      )}
    </VStack>
  );
};
