import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";
import axios from "axios";
import { UEClient } from "../types";
import { JsonPointer } from "json-ptr";

export class AuthService {
  public userManager!: UserManager;
  private clientId: string = "";
  private authority: string = "";
  private group: string = "";
  private SCOPES = "openid email username offline_access access";
  private apiRoot = process.env.REACT_APP_API_ROOT;

  constructor() {
    const authority = localStorage.getItem("authority") || "";
    const clientId = localStorage.getItem("clientId") || "";
    const clientRoot = localStorage.getItem("clientRoot") || "";
    const group = localStorage.getItem("group") || "";
    const groupName = localStorage.getItem("groupName") || "";
    const apiRoot = localStorage.getItem("apiRoot") || process.env.REACT_APP_API_ROOT;

    this.setupUserManager(
      authority,
      clientId,
      clientRoot,
      group,
      groupName,
      apiRoot
    );
  }

  init = (
    authority: string,
    clientId: string,
    clientRoot: string,
    group: string,
    groupName: string,
    apiRoot?: string
  ) => {
    this.setupUserManager(
      authority,
      clientId,
      clientRoot,
      group,
      groupName,
      apiRoot
    );
  };

  setupUserManager = (
    authority: string,
    clientId: string,
    clientRoot: string,
    group: string,
    groupName: string,
    apiRoot?: string
  ) => {
    const settings = {
      authority,
      client_id: clientId,
      post_logout_redirect_uri: `${clientRoot}/signout-oidc`,
      redirect_uri: `${clientRoot}/signin-oidc`,
      silent_redirect_uri: `${clientRoot}/silentrenew`,
      loadUserInfo: true,
      response_type: "code",
      scope: this.SCOPES,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    this.userManager = new UserManager(settings);

    this.clientId = clientId;
    this.authority = authority;
    this.group = group;
    this.apiRoot = apiRoot || this.apiRoot;

    Log.setLogger(console);
    Log.setLevel(Log.INFO);

    this.userManager.events.addUserLoaded((user) => {
      console.log("User loaded", user);
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.userManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.userManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      localStorage.setItem("redirectAfterSilentLogin", window.location.href);

      this.signinSilent();
    });

    localStorage.setItem("clientId", clientId);
    localStorage.setItem("authority", authority);
    localStorage.setItem("clientRoot", clientRoot);
    localStorage.setItem("group", group);
    localStorage.setItem("groupName", groupName);
    localStorage.setItem("apiRoot", apiRoot || "");

    this.userManager.getUser().then((user) => {
      if (user && user.profile.email) {
        localStorage.setItem("lastKnownEmail", user.profile.email);
        this.setupPendo(user);
      }
    });
  };

  signinRedirectCallback = () => {
    this.userManager
      .signinRedirectCallback()
      .then(() => {
        ("");
      })
      .catch((error) => {
        console.log("error signing in", error);
        window.location.replace("/login");
      });
  };

  setupPendo = (user: any) => {
    if ((window as any) && (window as any).pendo) {
      console.log("setting up pendo");
      (window as any).pendo.initialize({
        visitor: {
          id: user.profile.sub, // Required if user is logged in
          email: user.profile.email, // Recommended if using Pendo Feedback, or NPS Email
          // full_name:                   // Recommended if using Pendo Feedback
          // role:                        // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: user.profile.sub, // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }
  };

  getUser = async () => {
    const user = await this.userManager.getUser();

    if (!user) {
      return await this.userManager.signinRedirectCallback();
    }
    return user;
  };

  getGroup = () => {
    return this.group;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    console.log("settings", this.userManager.settings);
    this.userManager.signinRedirect();
  };

  navigateToScreen = async () => {
    const user = await this.userManager.getUser();

    // check to see if the user is authGroup admin
    const response: any | null = await axios.get(
      `${this.apiRoot}/api/${this.group}/access/validate`,
      {
        headers: {
          Authorization: `Bearer ${user!.access_token}`,
        },
      }
    );

    const authGroupOwner = response.data.data.authGroup.owner;

    console.log("authGroupOwner", authGroupOwner);

    window.location.replace("/eos");
  };

  isAuthenticated = () => {
    let oidcStorage;
    const storageItem = sessionStorage.getItem(
      `oidc.user:${this.authority}:${this.clientId}`
    );
    if (storageItem) {
      oidcStorage = JSON.parse(storageItem);
    }

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = () => {
    this.userManager
      .signinSilent()
      .then((user) => {
        console.log("signed in", user);
        const redirectAfterSilentLogin = localStorage.getItem(
          "redirectAfterSilentLogin"
        );
        if (redirectAfterSilentLogin) {
          window.location.replace(redirectAfterSilentLogin);
        }
      })
      .catch((err) => {
        console.log("signinSilent error");
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
  };

  forceSigninSilent = () => {
    return this.userManager.signinSilent();
  };

  logout = () => {
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token") || "",
      extraQueryParams: {
        onCancel: `${process.env.REACT_APP_CLIENT_ROOT}/eos`,
      },
    });
    this.userManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.userManager.signoutRedirectCallback().then(() => {
      const group = localStorage.getItem("group");
      const groupName = localStorage.getItem("groupName");
      const email = localStorage.getItem("lastKnownEmail");

      localStorage.clear();

      if (group) localStorage.setItem("lastKnownGroup", group);
      if (groupName) localStorage.setItem("lastKnownGroupName", groupName);
      if (email) localStorage.setItem("lastKnownEmail", email);

      window.location.replace("/");
    });
    this.userManager.clearStaleState();
  };

  getAuthGroup = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.get(`${this.apiRoot}/api/group/${this.group}`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  getAuthGroupInfo = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.get(`${this.apiRoot}/api/group-info/${this.group}`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  updateAuthGroup = async (
    id: any,
    op: any,
    key: any,
    value: any
  ): Promise<any> => {
    const user = await this.getUser();

    console.log("updateAuthGroup", id, op, key, value);

    return axios.patch(
      `${this.apiRoot}/api/group/${id}`,
      [
        {
          op: op,
          path: key,
          value: value,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );
  };

  /*
    Products
    Manage Products within your Auth Group
  */
  getProducts = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/products`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  createProduct = async (product: any): Promise<any> => {
    const user = await this.getUser();
    return axios.post(`${this.apiRoot}/api/${this.group}/products`, product, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  deleteProduct = async (id: string): Promise<any> => {
    const user = await this.getUser();
    return axios.delete(`${this.apiRoot}/api/${this.group}/products/${id}`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  /*
    Domains 
  */

  getDomains = async (orgId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}/domains`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getDomain = async (orgId: string, domainId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}/domains/${domainId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  createDomain = async (orgId: string, domain: any): Promise<any> => {
    const user = await this.getUser();
    return axios.post(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}/domains`,
      domain,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  updateDomain = async (
    id: string,
    orgId: string,
    op: string,
    key: string,
    value: any
  ): Promise<any> => {
    const user = await this.getUser();

    return axios.patch(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}/domains/${id}`,
      [
        {
          op: op,
          path: key,
          value: value,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );
  };

  deleteDomain = async (orgId: string, domainId: string): Promise<any> => {
    const user = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}/domains/${domainId}`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  /*
    Organizations
  */

  getOrganizations = async (): Promise<any> => {
    const user = await this.getUser();

    return axios.get(`${this.apiRoot}/api/${this.group}/organizations`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };

  getOrganization = async (orgId: string): Promise<any> => {
    const user = await this.getUser();

    return axios.get(
      `${this.apiRoot}/api/${this.group}/organizations/${orgId}`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  createOrganization = async (organization: any): Promise<any> => {
    const user = await this.getUser();
    return axios.post(
      `${this.apiRoot}/api/${this.group}/organizations`,
      organization,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  updateOrganization = async (
    id: string,
    op: string,
    key: string,
    value: any
  ): Promise<any> => {
    const user = await this.getUser();

    return axios.patch(
      `${this.apiRoot}/api/${this.group}/organizations/${id}`,
      [
        {
          op: op,
          path: key,
          value: value,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );
  };

  deleteOrganization = async (id: string): Promise<any> => {
    const user = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/organizations/${id}`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  /*
    Clients
    Manage Clients within your Auth Group
  */
  getClients = async (filter?: string): Promise<any> => {
    const user = await this.getUser();
    let url = `${this.apiRoot}/api/${this.group}/clients`;
    if (filter) {
      url = url + `?${filter}`;
    }
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    });
  };

  createClient = async (client: UEClient): Promise<any> => {
    const user = await this.getUser();

    const iatResult: any = await axios.post(
      `${this.apiRoot}/${this.group}/token/initial-access`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );

    const jti = iatResult.data.jti;

    return await axios.post(`${this.apiRoot}/${this.group}/reg`, client, {
      headers: {
        Authorization: `Bearer ${jti}`,
      },
    });
  };

  updateClient = async (client: UEClient) => {
    const user = await this.getUser();

    if ("client_secret_expires_at" in client) {
      delete client.client_secret_expires_at;
    }
    if ("client_id_issued_at" in client) {
      delete client.client_id_issued_at;
    }

    const resp: any = await axios.post(
      `${this.apiRoot}/api/${this.group}/operations/client/${client.client_id}`,
      {
        operation: "rotate_registration_access_token",
      },
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );

    const registrationAccessToken = resp.data.data;

    return await axios.put(
      `${this.apiRoot}/${this.group}/reg/${client.client_id}`,
      client,
      {
        headers: {
          Authorization: `Bearer ${registrationAccessToken}`,
        },
      }
    );
  };

  getClientSecret = async (client: UEClient): Promise<any> => {
    const user = await this.getUser();

    const resp: any = await axios.post(
      `${this.apiRoot}/api/${this.group}/operations/client/${client.client_id}`,
      {
        operation: "rotate_registration_access_token",
      },
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );

    const registrationAccessToken = resp.data.data;

    return await axios.get(
      `${this.apiRoot}/${this.group}/reg/${client.client_id}`,
      {
        headers: {
          Authorization: `Bearer ${registrationAccessToken}`,
        },
      }
    );
  };

  deleteClient = async (client: UEClient) => {
    const user = await this.getUser();
    return axios
      .delete(`${this.apiRoot}/api/${this.group}/client/${client.client_id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  /*
    Users 
  */

  createUser = async (user: any): Promise<any> => {
    const userData = await this.getUser();
    return axios.post(`${this.apiRoot}/api/${this.group}/account`, user, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  createUsers = async (users: any[]): Promise<any> => {
    const userData = await this.getUser();
    return axios.post(`${this.apiRoot}/api/${this.group}/accounts`, users, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getUserMe = async (): Promise<any> => {
    return this.getUserById("me");
  };

  getUsers = async (usersSkip: number, usersTop: number): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/accounts?$orderby=email&$skip=${usersSkip}&$top=${usersTop}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getUserById = async (id: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/account/${id}`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  updateUser = async (
    user: any,
    key: string,
    value: any,
    overrideOp?: string
  ): Promise<any> => {
    const userData = await this.getUser();
    key = key.startsWith("/") ? key : `/${key}`;

    let op;
    if (overrideOp) {
      op = overrideOp;
    } else {
      const exists = JsonPointer.get(user, key);
      op = exists ? "replace" : "add";
    }

    console.log(op, key, value);
    if (value !== "") {
      return axios.patch(
        `${this.apiRoot}/api/${this.group}/account/${user.id}`,
        [
          {
            op: op,
            path: key,
            value: value,
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );
    }
  };

  getMyNotifications = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/access/my/notifications`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getMyProfileRequests = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/profile/requests/received`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getMyProfileRequestsSent = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/profile/requests/sent`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  updateProfileRequest = async (id: string, action: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.patch(
      `${this.apiRoot}/api/${this.group}/profile/request/${id}`,
      { action: action },
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  deleteProfileRequest = async (id: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/profile/request/${id}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getOrganizationsAccess = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/access/organizations`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getMyOrganizations = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/access/my/organizations`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getMyOrganizationProducts = async (orgId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/access/my/organizations/${orgId}/products`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  searchUsers = async (q: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/accounts/search?q=${q}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /*
    Organization Users & Access
  */

  createOrganizationUser = async (orgId: string, user: any): Promise<any> => {
    const userData = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/account`,
      user,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  createOrganizationUsers = async (
    orgId: string,
    users: string[]
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/accounts`,
      users,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getOrganizationUser = async (orgId: string, userId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/account/${userId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getOrganizationUsers = async (orgId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/accounts`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  searchOrganizationUsers = async (orgId: string, q: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/accounts/search?q=${q}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getOrganizationUserAccess = async (
    orgId: string,
    userId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/access/organization/${orgId}/account/${userId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  updateOrganizationUserAccess = async (
    orgId: string,
    userId: string,
    access: any
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/access/organization/${orgId}/account/${userId}`,
      access,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  updateOrganizationUsersAccess = async (
    orgId: string,
    access: any
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/access/organization/${orgId}/accounts`,
      access,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  removeOrganizationUserAccess = async (
    orgId: string,
    userId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/access/organization/${orgId}/account/${userId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  removeOrganizationUsersAccess = async (
    orgId: string,
    access: any
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/access/organization/${orgId}/accounts`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
        data: access,
      }
    );
  };

  organizationTerms = async (orgId: string, action: any): Promise<any> => {
    const userData = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/access/organizations/${orgId}/terms`,
      action,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /*
    Organization User Profiles
  */

  createOrganizationUserProfile = async (
    orgId: string,
    profile: any
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.post(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/profiles`,
      profile,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /*
    Secure Profile 
  */

  createSecureProfile = async (profile: any): Promise<any> => {
    const userData = await this.getUser();
    profile = { ...profile, accountId: userData.profile.sub };
    return axios.post(`${this.apiRoot}/api/${this.group}/profile`, profile, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getSecureProfile = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/profile`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  updateSecureProfile = async (
    profile: any,
    key: string,
    value: any
  ): Promise<any> => {
    const userData = await this.getUser();
    key = key.startsWith("/") ? key : `/${key}`;
    const exists = JsonPointer.get(profile, key);
    const op = exists ? "replace" : "add";

    console.log(op, key, value);
    if (value !== "") {
      return axios.patch(
        `${this.apiRoot}/api/${this.group}/profile`,
        [
          {
            op: op,
            path: key,
            value: value,
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );
    }
  };

  deleteSecureProfile = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(`${this.apiRoot}/api/${this.group}/profile`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getRoles = async (): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/roles`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getRole = async (roleId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/roles/${roleId}`, {
      headers: { Authorization: `Bearer ${userData.access_token}` },
    });
  };

  getProductRoles = async (productId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  createRole = async (productId: string, role: any): Promise<any> => {
    const user = await this.getUser();
    return axios.post(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles`,
      role,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  updateRole = async (
    productId: string,
    roleId: string,
    op: string,
    key: string,
    value: any
  ): Promise<any> => {
    const user = await this.getUser();

    return axios.patch(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles/${roleId}`,
      [
        {
          op: op,
          path: key,
          value: value,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );
  };

  deleteRole = async (productId: string, roleId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles/${roleId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getPermissions = async (productId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permissions`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getPermissionActions = async (productId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permission/actions`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getPermissionTargets = async (productId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permission/targets`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getPermissionTags = async (productId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permission/tags`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  createPermissions = async (
    productId: string,
    permissions: any[]
  ): Promise<any> => {
    const user = await this.getUser();
    return axios.post(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permissions`,
      permissions,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  updatePermission = async (
    productId: string,
    permissionId: string,
    op: string,
    key: string,
    value: any
  ): Promise<any> => {
    const user = await this.getUser();

    return axios.patch(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles/${permissionId}`,
      [
        {
          op: op,
          path: key,
          value: value,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      }
    );
  };

  getPermissionRoleReferences = async (
    productId: string,
    permissionId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permissions/${permissionId}/reference-check/role`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  deletePermission = async (
    productId: string,
    permissionId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.delete(
      `${this.apiRoot}/api/${this.group}/products/${productId}/permissions/${permissionId}`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getPermissionsForRole = async (
    productId: string,
    roleId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/products/${productId}/roles/${roleId}/permissions`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /* Organization Products */

  getOrganizationProducts = async (orgId: string): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/products`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  getOrganizationDomainProducts = async (
    orgId: string,
    domainId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/domain/${domainId}/products`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /* Organization Permissions */

  getOrganizationPermissions = async (
    orgId: string,
    productId: string
  ): Promise<any> => {
    const userData = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organization/${orgId}/products/${productId}/permissions`,
      {
        headers: { Authorization: `Bearer ${userData.access_token}` },
      }
    );
  };

  /* Organization Roles */

  createOrganizationRole = async (
    organizationId: string,
    productId: string,
    role: any
  ): Promise<any> => {
    const user = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/organizations/${organizationId}/products/${productId}/roles`,
      role,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  getOrganizationRoles = async (
    organizationId: string,
    productId: string
  ): Promise<any> => {
    const user = await this.getUser();
    return axios.get(
      `${this.apiRoot}/api/${this.group}/organizations/${organizationId}/products/${productId}/roles`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  accountPanic = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/account/panic`,
      { email: user.profile.email },
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  getRecoveryCodes = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.put(
      `${this.apiRoot}/api/${this.group}/account/codes`,
      {},
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
      }
    );
  };

  validateAccount = async (): Promise<any> => {
    const user = await this.getUser();
    return axios.get(`${this.apiRoot}/api/${this.group}/access/validate`, {
      headers: { Authorization: `Bearer ${user.access_token}` },
    });
  };
}
