import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";

interface NewOrganizationProps {
  result: any;
}

export const NewOrganization = ({ result }: NewOrganizationProps) => {
  const { createOrganization } = useContext(AuthContext);
  interface FormValues {
    name: string;
    description: string;
    contactEmail: string;
  }

  const initialValues: FormValues = {
    name: "",
    description: "",
    contactEmail: "",
  };

  return (
    <Container maxW={"2xl"}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          let errors: FormikErrors<FormValues> = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.description) {
            errors.description = "Required";
          }
          if (!values.contactEmail) {
            errors.contactEmail = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              values.contactEmail
            )
          ) {
            errors.contactEmail = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const org = {
              name: values.name,
              description: values.description,
              contactEmail: values.contactEmail,
            };

            const orgResult: any = await createOrganization(org);
            const newOrg: any = orgResult.data.data?.organization;

            result(newOrg);
          } catch (error) {
            console.error(error);
          }

          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <VStack mt="20px" alignItems="left">

              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Input {...field} id="name" placeholder="Name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                  >
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <Input
                      {...field}
                      id="description"
                      placeholder="Description"
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="contactEmail">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.contactEmail && form.touched.contactEmail
                    }
                  >
                    <FormLabel htmlFor="contactEmail">Contact Email</FormLabel>
                    <Input
                      {...field}
                      id="contactEmail"
                      placeholder="Contact Email"
                    />
                    <FormErrorMessage>
                      {form.errors.contactEmail}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Button variant={"primaryBlue"} type="submit" disabled={props.isSubmitting}>
                Submit
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
