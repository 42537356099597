import React, { Component, createContext } from "react";
import { AuthService } from "../services/AuthService";
import { UEClient } from "../types";

interface UEAuthContext {
  init: (
    authority: string,
    clientId: string,
    clientRoot: string,
    group: string,
    groupName: string
  ) => void;
  signinRedirectCallback: () => void;
  forceSigninSilent: () => Promise<any>;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: () => void;
  signinSilentCallback: () => void;
  getUser: () => any;
  getGroup: () => any;
  updateAuthGroup: (id: any, op: any, key: any, value: any) => Promise<any>;
  getClients: (filter?: string) => Promise<any>;
  createClient: (client: UEClient) => Promise<any>;
  updateClient: (client: UEClient) => Promise<any>;
  getClientSecret: (client: UEClient) => Promise<any>;
  deleteClient: (client: UEClient) => Promise<any>;
  getProducts: () => Promise<any>;
  createProduct: (product: any) => Promise<any>;
  deleteProduct: (id: string) => Promise<any>;
  getDomains: (orgId: string) => Promise<any>;
  getDomain: (orgId: string, domainId: string) => Promise<any>;
  createDomain: (orgId: string, domain: any) => Promise<any>;
  updateDomain: (
    id: string,
    orgId: string,
    op: string,
    key: string,
    value: any
  ) => Promise<any>;
  deleteDomain: (orgId: string, domainId: string) => Promise<any>;
  getAuthGroup: () => Promise<any>;
  getAuthGroupInfo: () => Promise<any>;
  getOrganizations: () => Promise<any>;
  getOrganization: (orgId: string) => Promise<any>;
  createOrganization: (organization: any) => Promise<any>;
  updateOrganization: (
    id: string,
    op: string,
    key: string,
    value: any
  ) => Promise<any>;
  deleteOrganization: (id: string) => Promise<any>;
  createUser: (user: any) => Promise<any>;
  createUsers: (users: any[]) => Promise<any>;
  searchUsers: (query: string) => Promise<any>;
  getUserMe: () => Promise<any>;
  getUsers: (usersSkip: number, usersTop: number) => Promise<any>;
  getUserById: (id: string) => Promise<any>;
  updateUser: (user: any, key: string, value: string) => Promise<any>;
  getMyNotifications: () => Promise<any>;
  getMyProfileRequests: () => Promise<any>;
  getMyProfileRequestsSent: () => Promise<any>;
  updateProfileRequest: (id: string, action: string) => Promise<any>;
  deleteProfileRequest: (id: string) => Promise<any>;
  getOrganizationsAccess: () => Promise<any>;
  getMyOrganizations: () => Promise<any>;
  getMyOrganizationProducts: (orgId: string) => Promise<any>;
  createOrganizationUser: (orgId: string, user: any) => Promise<any>;
  createOrganizationUsers: (orgId: string, users: string[]) => Promise<any>;
  getOrganizationUser: (orgId: string, userId: string) => Promise<any>;
  getOrganizationUsers: (orgId: string) => Promise<any>;
  searchOrganizationUsers: (orgId: string, q: string) => Promise<any>
  getOrganizationUserAccess: (orgId: string, userId: string) => Promise<any>;
  updateOrganizationUserAccess: (
    orgId: string,
    userId: string,
    access: any
  ) => Promise<any>;
  updateOrganizationUsersAccess: (orgId: string, access: any) => Promise<any>;
  removeOrganizationUserAccess: (orgId: string, userId: string) => Promise<any>;
  removeOrganizationUsersAccess: (orgId: string, access: any) => Promise<any>;
  organizationTerms: (orgId: string, action: any) => Promise<any>;
  createOrganizationUserProfile: (orgId: string, profile: any) => Promise<any>;
  createSecureProfile: (profile: any) => Promise<any>;
  getSecureProfile: () => Promise<any>;
  updateSecureProfile: (profile: any, key: string, value: any) => Promise<any>;
  deleteSecureProfile: () => Promise<any>;
  getRoles: () => Promise<any>;
  getRole: (roleId: string) => Promise<any>;
  getProductRoles: (productId: string) => Promise<any>;
  createRole: (productId: string, role: any) => Promise<any>;
  updateRole: (
    productId: string,
    roleId: string,
    op: string,
    key: string,
    value: any
  ) => Promise<any>;
  deleteRole: (productId: string, roleId: string) => Promise<any>;
  getPermissions: (productId: string) => Promise<any>;
  createPermissions: (productId: string, permissions: any[]) => Promise<any>;
  updatePermission: (
    productId: string,
    permissionId: string,
    op: string,
    key: string,
    value: any
  ) => Promise<any>;
  getPermissionActions: (productId: string) => Promise<any>;
  getPermissionTargets: (productId: string) => Promise<any>;
  getPermissionTags: (productId: string) => Promise<any>;
  getPermissionRoleReferences: (
    productId: string,
    permissionId: string
  ) => Promise<any>;
  deletePermission: (productId: string, permissionId: string) => Promise<any>;
  getPermissionsForRole: (productId: string, roleId: string) => Promise<any>;
  getOrganizationProducts: (orgId: string) => Promise<any>;
  getOrganizationDomainProducts: (
    orgId: string,
    domainId: string
  ) => Promise<any>;
  getOrganizationPermissions: (orgId: string, productId: string) => Promise<any>;
  createOrganizationRole: (
    organizationId: string,
    productId: string,
    role: any
  ) => Promise<any>;
  getOrganizationRoles: (
    organizationId: string,
    productId: string
  ) => Promise<any>;
  accountPanic: (email: string) => Promise<any>;
  getRecoveryCodes: () => Promise<any>;
  validateAccount: () => Promise<any>;
}

const initalValue = (): never => {
  throw new Error("AuthProvider must be initialized");
};

const initialContext: UEAuthContext = {
  init: initalValue,
  signinRedirectCallback: initalValue,
  forceSigninSilent: initalValue,
  logout: initalValue,
  signoutRedirectCallback: initalValue,
  isAuthenticated: initalValue,
  signinRedirect: initalValue,
  signinSilentCallback: initalValue,
  getUser: initalValue,
  getGroup: initalValue,
  updateAuthGroup: initalValue,
  getClients: initalValue,
  createClient: initalValue,
  updateClient: initalValue,
  getClientSecret: initalValue,
  deleteClient: initalValue,
  getProducts: initalValue,
  createProduct: initalValue,
  deleteProduct: initalValue,
  getDomains: initalValue,
  getDomain: initalValue,
  createDomain: initalValue,
  updateDomain: initalValue,
  deleteDomain: initalValue,
  getAuthGroup: initalValue,
  getAuthGroupInfo: initalValue,
  getOrganizations: initalValue,
  getOrganization: initalValue,
  createOrganization: initalValue,
  updateOrganization: initalValue,
  deleteOrganization: initalValue,
  createUser: initalValue,
  createUsers: initalValue,
  searchUsers: initalValue,
  getUserMe: initalValue,
  getUsers: initalValue,
  getUserById: initalValue,
  updateUser: initalValue,
  getMyNotifications: initalValue,
  getMyProfileRequests: initalValue,
  getMyProfileRequestsSent: initalValue,
  updateProfileRequest: initalValue,
  deleteProfileRequest: initalValue,
  getOrganizationsAccess: initalValue,
  getMyOrganizations: initalValue,
  getMyOrganizationProducts: initalValue,
  createOrganizationUser: initalValue,
  createOrganizationUsers: initalValue,
  getOrganizationUser: initalValue,
  getOrganizationUsers: initalValue,
  searchOrganizationUsers: initalValue,
  getOrganizationUserAccess: initalValue,
  updateOrganizationUsersAccess: initalValue,
  removeOrganizationUserAccess: initalValue,
  removeOrganizationUsersAccess: initalValue,
  organizationTerms: initalValue,
  updateOrganizationUserAccess: initalValue,
  createOrganizationUserProfile: initalValue,
  createSecureProfile: initalValue,
  getSecureProfile: initalValue,
  updateSecureProfile: initalValue,
  deleteSecureProfile: initalValue,
  getRoles: initalValue,
  getRole: initalValue,
  getProductRoles: initalValue,
  createRole: initalValue,
  updateRole: initalValue,
  deleteRole: initalValue,
  getPermissions: initalValue,
  createPermissions: initalValue,
  updatePermission: initalValue,
  getPermissionActions: initalValue,
  getPermissionTargets: initalValue,
  getPermissionTags: initalValue,
  getPermissionRoleReferences: initalValue,
  deletePermission: initalValue,
  getPermissionsForRole: initalValue,
  getOrganizationProducts: initalValue,
  getOrganizationDomainProducts: initalValue,
  getOrganizationPermissions: initalValue,
  createOrganizationRole: initalValue,
  getOrganizationRoles: initalValue,
  accountPanic: initalValue,
  getRecoveryCodes: initalValue,
  validateAccount: initalValue,
};

export const AuthContext = createContext<UEAuthContext>(initialContext);

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
  authService;
  constructor(props: any) {
    super(props);
    this.authService = new AuthService();
  }

  render() {
    return (
      <AuthContext.Provider value={this.authService}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
