import {
  Flex,
  VStack,
  HStack,
  Skeleton,
  Text,
  Button,
  Divider,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { UEProduct } from "../../types";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { NewPermissions } from "./newPermissions";
import { Permission } from "./permission";

interface PermissionsProps {
  selectedOrg?: any;
  selectedProduct?: any;
  orgAdmin?: boolean;
}

export const Permissions = ({
  selectedOrg,
  selectedProduct,
  orgAdmin = false,
}: PermissionsProps) => {
  const [products, setProducts] = React.useState([] as UEProduct[]);
  const [loading, setLoading] = React.useState(true);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState(null as any);
  const [secondaryColumnSelectedOption, setSecondaryColumnSelectedOption] =
    React.useState(null as any);
  const [newPermission, setNewPermission] = React.useState(false);
  const [loadingPermissions, setLoadingPermissions] = React.useState(false);
  const [permissions, setPermissions] = React.useState([] as any[]);

  const {
    getProducts,
    getPermissions,
    getOrganizationProducts,
    deletePermission,
    getOrganizationPermissions,
  } = useContext(AuthContext);

  const productSelected = useCallback(
    async (selectedProduct: any) => {
      try {
        setLoadingPermissions(true);
        setNewPermission(false);
        setSecondaryColumnSelectedOption(null);

        if (selectedOrg) {
          const res = await getOrganizationPermissions(
            selectedOrg.id,
            selectedProduct.id
          );
          const permissions = res.data.data;

          setPermissions(permissions);
        } else {
          const res = await getPermissions(selectedProduct.id);
          const permissions = res.data.data;

          setPermissions(permissions);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingPermissions(false);
      }
    },
    [getPermissions, getOrganizationPermissions, selectedOrg]
  );

  useEffect(() => {
    if (selectedOrg) {
      getOrganizationProducts(selectedOrg.id).then((res: any) => {
        const products: UEProduct[] = res.data.data;
        setProducts(products);
        setLoading(false);

        if (selectedProduct) {
          const product = products.find(
            (p: UEProduct) => p.id === selectedProduct.id
          );
          productSelected(product);
          setPrimaryColumnSelectedOption(product);
        } else {
          productSelected(products[0]);
          setPrimaryColumnSelectedOption(products[0]);
        }
      });
    } else {
      getProducts().then((res: any) => {
        const products: UEProduct[] = res.data.data;
        setProducts(products);
        setLoading(false);

        if (selectedProduct) {
          const product = products.find(
            (p: UEProduct) => p.id === selectedProduct.id
          );
          productSelected(product);
          setPrimaryColumnSelectedOption(product);
        } else {
          productSelected(products[0]);
          setPrimaryColumnSelectedOption(products[0]);
        }
      });
    }
  }, [
    getOrganizationProducts,
    getProducts,
    productSelected,
    selectedOrg,
    selectedProduct,
  ]);

  const handleDeletePermission = async (id: string) => {
    console.log(`Deleting permission ${id}`);
    try {
      await deletePermission(primaryColumnSelectedOption.id, id);
      setSecondaryColumnSelectedOption(null);
      productSelected(primaryColumnSelectedOption);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Flex h="100%" alignItems="stretch">
      {/* Primary Column */}
      <VStack
        layerStyle="windowmenu"
        alignItems="stretch"
        flex="1"
        spacing="0"
      >
        <VStack
          alignItems={"stretch"}
          spacing="0"
        >
          <Text variant="windowheader">
            Permissions
          </Text>
          <Text variant="windowsubheader">
            {selectedOrg && "Licensed "}By Product
          </Text>
          <Divider opacity={".4"} />
        </VStack>
        <Skeleton
          overflow={"hidden"}
          overflowY={"scroll"}
          isLoaded={!loading}
        >
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            paddingTop={"8px"}
          >
            {products.map((option: any) => {
              return (
                <WindowColumnPrimaryButton
                  key={option.name}
                  onClick={() => {
                    productSelected(option);
                    setPrimaryColumnSelectedOption(option);
                  }}
                  active={primaryColumnSelectedOption?.name === option.name}
                >
                  <Text
                    width={"162px"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.name}
                  </Text>
                </WindowColumnPrimaryButton>
              );
            })}
          </VStack>
        </Skeleton>
      </VStack>
      {/* Secondary Column */}
      <VStack
        flex={"1"}
        alignItems={"stretch"}
        spacing={"0"}
      >
        <Flex layerStyle="windowheader">
          {primaryColumnSelectedOption?.name} Permissions
        </Flex>
        <HStack
          spacing={"0"}
          alignItems={"stretch"}
          overflow={"hidden"}
          flex={"1"}
        >
          <VStack
            layerStyle="windowsubmenu"
            alignItems="stretch"
            spacing={"0"}
            padding={"8px 8px 0 8px"}
          >
            <VStack alignItems={"stretch"}>
              {" "}
              <Text variant="windowsubmenusubheader">Permissions</Text>
              {primaryColumnSelectedOption && !primaryColumnSelectedOption.core && (
                <Button
                  variant="primaryBlue"
                  size="sm"
                  onClick={() => {
                    setNewPermission(true);
                    setSecondaryColumnSelectedOption({ name: "newPermission" });
                  }}
                >
                  Add
                </Button>
              )}
              <Divider borderColor={"neutral.400"} />
            </VStack>
            <VStack
              alignItems="stretch"
              spacing="0"
              gap={"4px"}
              paddingTop={"8px"}
              paddingBottom={"8px"}
            >
              {newPermission && (
                <WindowColumnSecondaryButton key={"newPermission"} active={true}>
                  New Permissions
                </WindowColumnSecondaryButton>
              )}
              {permissions &&
                permissions.length > 0 &&
                permissions.map((option: any) => {
                  return (
                    <WindowColumnSecondaryButton
                      key={option.coded}
                      onClick={() => {
                        setSecondaryColumnSelectedOption(option);
                        setNewPermission(false);
                      }}
                      active={
                        secondaryColumnSelectedOption?.coded === option.coded
                      }
                    >
                      <Skeleton
                        overflow={"hidden"}
                        overflowY={"scroll"}
                        isLoaded={!loadingPermissions}
                      >
                        <Text
                          width={"216px"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          textTransform={"capitalize"}
                        >
                          {option.action} {option.ownershipRequired ? <Text display="inline" textTransform={"none"}>your</Text> : <Text display="inline" textTransform={"none"}>any</Text>} {option.target}
                        </Text>
                      </Skeleton>
                    </WindowColumnSecondaryButton>
                  );
                })}
            </VStack>
          </VStack>
          {/* Tertiary Column */}
          <VStack
            alignItems={"stretch"}
            layerStyle="windowcontentarea"
            spacing={"0"}
            gap={"24px"}
          >
            {secondaryColumnSelectedOption?.id && (
              <Permission
                key={secondaryColumnSelectedOption.id}
                productId={primaryColumnSelectedOption.id}
                permissionData={secondaryColumnSelectedOption}
                showDelete={!primaryColumnSelectedOption.core}
                handleDelete={(id: string) => handleDeletePermission(id)}
              />
            )}
            {newPermission && (
              <NewPermissions
                productId={primaryColumnSelectedOption?.id}
                result={(newPermissions: any[]) => {
                  if (newPermissions) {
                    setPermissions([...permissions, ...newPermissions]);
                    setNewPermission(false);
                    setSecondaryColumnSelectedOption(newPermissions[0]);
                  } else {
                    setNewPermission(false);
                  }
                }}
              />
            )}
          </VStack>
        </HStack>
      </VStack>
    </Flex>
  );
};
