/*

This is a service to manage custom domains in UE Core

*/

import axios from "axios";

export class CustomDomainService {
  private authToken: string;
  private group: string;
  private apiRoot = process.env.REACT_APP_CUSTOM_DOMAIN_API_ROOT || "https://cd.qa.ueauth.io";
  constructor(authToken: string, group: string) {
    this.authToken = authToken;
    this.group = group;
  }

  /* 
        Custom Domain
        Manage Custom Domains for UE Core 
  */

  // Write a new custom domain for an authgroup. Make sure to indiciate the target.
  public async writeCustomDomain(
    domain: string,
    target: string
  ): Promise<void> {
    return axios.post(
      `${this.apiRoot}/${this.group}/cd`,
      { domain, target },
      {
        headers: { Authorization: `Bearer ${this.authToken}` },
      }
    );
  }

  // Get all custom domains for an authgroup
  public async getCustomDomains(): Promise<any> {
    return axios.get(`${this.apiRoot}/${this.group}/cds`, {
      headers: { Authorization: `Bearer ${this.authToken}` },
    });
  }

  // Return a custom domain from the authgroup with either uuid or target
  public async getCustomDomain(uuid?: string, target?: string): Promise<any> {
    let id;
    if (uuid) {
      id = uuid;
    } else if (target) {
      id = target;
    } else {
      throw new Error("Must provide either uuid or target");
    }
    return axios.get(`${this.apiRoot}/${this.group}/cd/${id}`, {
      headers: { Authorization: `Bearer ${this.authToken}` },
    });
  }

  // Remove a custom domain from the authgroup using only its uuid
  public async deleteCustomDomain(uuid: string): Promise<any> {
    return axios.delete(`${this.apiRoot}/${this.group}/cd/${uuid}`, {
      headers: { Authorization: `Bearer ${this.authToken}` },
    });
  }

  /* 
        Async Jobs
        These are interfaces to run async operations via cron jobs
  */

  // Run a batch process to verify records across authgroups
  public async runProcessJob(): Promise<any> {
    return axios.post(
      `${this.apiRoot}/jobs/process`,
      {},
      {
        headers: { Authorization: `Bearer ${this.authToken}` },
      }
    );
  }

  // Run a batch process that attempts to verify records for a single authgroup
  public async runVerifyJob(): Promise<any> {
    return axios.post(
      `${this.apiRoot}/${this.group}/jobs/verify`,
      {},
      {
        headers: { Authorization: `Bearer ${this.authToken}` },
      }
    );
  }

  // Run a batch process to process removal of any records marked for deletion
  public async runDeleteJob(): Promise<any> {
    return axios.post(
      `${this.apiRoot}/jobs/delete`,
      {},
      {
        headers: { Authorization: `Bearer ${this.authToken}` },
      }
    );
  }
}
