import { Flex, VStack, HStack, Skeleton, Text, Button, Divider } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { UEProduct } from "../../types";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import { NewRole } from "./newRole";
import { Role } from "./role";

interface RolesProps {
  selectedOrg?: any;
  selectedProduct?: any;
}

export const Roles = ({ selectedOrg, selectedProduct }: RolesProps) => {
  const [products, setProducts] = React.useState([] as UEProduct[]);
  const [loading, setLoading] = React.useState(true);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState(null as any);
  const [secondaryColumnSelectedOption, setSecondaryColumnSelectedOption] =
    React.useState(null as any);
  const [newRole, setNewRole] = React.useState(false);
  const [loadingRoles, setLoadingRoles] = React.useState(false);
  const [roles, setRoles] = React.useState([] as any[]);
  const {
    getProducts,
    getProductRoles,
    getOrganizationProducts,
    getOrganizationRoles,
  } = useContext(AuthContext);

  const productSelected = useCallback(
    async (selectedProduct: any) => {
      setLoadingRoles(true);
      try {
        setNewRole(false);
        setSecondaryColumnSelectedOption(null);
        const res = selectedOrg
          ? await getOrganizationRoles(selectedOrg.id, selectedProduct.id)
          : await getProductRoles(selectedProduct.id);
        const roles = res.data.data;

        setRoles(roles);
      } catch (err) {
        console.log(err);
      }

      setLoadingRoles(false);
    },
    [getOrganizationRoles, getProductRoles, selectedOrg]
  );

  const handleRoleDeleted = () => {
    console.log(primaryColumnSelectedOption);
    productSelected(primaryColumnSelectedOption);
  };

  const handleRoleUpdated = (updatedRole: any) => {
    console.log(primaryColumnSelectedOption);
    const index = roles.findIndex((r: any) => r.id === updatedRole.id);
    const newRoles = [...roles];
    newRoles[index] = updatedRole;
    setRoles(newRoles);
  };

  useEffect(() => {
    if (selectedOrg) {
      getOrganizationProducts(selectedOrg.id).then((res: any) => {
        const products: UEProduct[] = res.data.data;
        setProducts(products);
        setLoading(false);

        if (selectedProduct) {
          const product = products.find(
            (p: UEProduct) => p.id === selectedProduct.id
          );
          productSelected(product);
          setPrimaryColumnSelectedOption(product);
        } else {
          productSelected(products[0]);
          setPrimaryColumnSelectedOption(products[0]);
        }
      });
    } else {
      getProducts().then((res: any) => {
        const products: UEProduct[] = res.data.data;
        setProducts(products);
        setLoading(false);

        if (selectedProduct) {
          const product = products.find(
            (p: UEProduct) => p.id === selectedProduct.id
          );
          productSelected(product);
          setPrimaryColumnSelectedOption(product);
        } else {
          productSelected(products[0]);
          setPrimaryColumnSelectedOption(products[0]);
        }
      });
    }
  }, [
    getOrganizationProducts,
    getProducts,
    productSelected,
    selectedOrg,
    selectedProduct,
  ]);

  return (
    <Flex h="100%" alignItems="stretch">
      {/* Primary Column */}
      <VStack
        layerStyle="windowmenu"
        alignItems="stretch"
        flex="1"
        spacing="0"
      >
        <VStack
          alignItems={"stretch"}
          spacing="0"
        >
          <Text variant="windowheader">Roles</Text>
          <Text variant="windowsubheader">
            {selectedOrg && "Licensed "}By Product
          </Text>
          <Divider opacity={".4"} />
        </VStack>
        <Skeleton
          overflow={"hidden"}
          overflowY={"scroll"}
          isLoaded={!loading}
        >
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            paddingTop={"8px"}
            paddingBottom={"8px"}
          >
            {products.map((option: any) => {
              return (
                <WindowColumnPrimaryButton
                  key={option.name}
                  onClick={() => {
                    productSelected(option);
                    setPrimaryColumnSelectedOption(option);
                  }}
                  active={primaryColumnSelectedOption?.name === option.name}
                >
                  <Text
                    width={"162px"}
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                  >
                    {option.name}
                  </Text>
                </WindowColumnPrimaryButton>
              );
            })}
          </VStack>
        </Skeleton>
      </VStack>
      {/* Secondary Column */}
      <VStack
        flex={"1"}
        alignItems={"stretch"}
        spacing={"0"}
      >
        <Flex layerStyle="windowheader">
          {primaryColumnSelectedOption?.name} Roles
        </Flex>
        <HStack
          spacing={"0"}
          alignItems={"stretch"}
          overflow={"hidden"}
          flex={"1"}
        >
          <VStack
            layerStyle="windowsubmenu"
            alignItems="stretch"
            spacing={"0"}
            padding={"8px 8px 0 8px"}
          >
            <VStack alignItems={"stretch"}>
              {" "}
              <Text variant="windowsubmenusubheader">Roles</Text>
              <Button
                variant="primaryBlue"
                size="sm"
                onClick={() => {
                  setNewRole(true);
                  setSecondaryColumnSelectedOption({ name: "newRole" });
                }}
              >
                Add
              </Button>
              <Divider borderColor={"black.400"} />
            </VStack>

            <VStack
              alignItems="stretch"
              spacing="1"
              paddingTop={"8px"}
              paddingBottom={"8px"}
            >

              {newRole && (
                <WindowColumnSecondaryButton key={"newRole"} active={true}>
                  New Role
                </WindowColumnSecondaryButton>
              )}
              {roles &&
                roles.length > 0 &&
                roles.map((option: any) => {
                  return (
                    <WindowColumnSecondaryButton
                      key={option.name}
                      onClick={() => {
                        setSecondaryColumnSelectedOption(option);
                        setNewRole(false);
                      }}
                      active={secondaryColumnSelectedOption?.name === option.name}
                    >
                      <Skeleton
                        overflow={"hidden"}
                        overflowY={"scroll"}
                        isLoaded={!loadingRoles}
                      >
                        <Text
                          width={"176px"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                        >
                          {option.name}
                        </Text>
                      </Skeleton>
                      <Text
                        width={"176px"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {option.custom && "(custom)"}
                      </Text>

                    </WindowColumnSecondaryButton>
                  );
                })}

            </VStack>
          </VStack>
          {/* Tertiary Column */}
          <VStack
            spacing="4"
            align="left"
            flex="3"
            overflowY={"scroll"}
            bg="neutral.100"
            padding="28px 32px"
            borderLeft={"1px solid"}
            borderColor={"neutral.200"}
          >
            {secondaryColumnSelectedOption?.id && (
              <Role
                key={secondaryColumnSelectedOption.id}
                productId={primaryColumnSelectedOption.id}
                roleData={secondaryColumnSelectedOption}
                roleDeleted={handleRoleDeleted}
                roleUpdated={(updatedRole: any) => {
                  handleRoleUpdated(updatedRole);
                }}
              />
            )}
            {newRole && (
              <NewRole
                productId={primaryColumnSelectedOption?.id}
                selectedOrg={selectedOrg}
                result={(role: any) => {
                  if (role) {
                    setRoles([...roles, role]);
                    setNewRole(false);
                    setSecondaryColumnSelectedOption(role);
                  } else {
                    setNewRole(false);
                  }
                }}
              />
            )}
          </VStack>
        </HStack>
      </VStack>
    </Flex>
  );
};
