import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ActionMeta,
  CreatableSelect,
  OnChangeValue,
  Select,
} from "chakra-react-select";
import React, { useCallback, useEffect } from "react";
import { ChangeEvent } from "react";
import {
  Access,
  NewAccess,
  Product,
  Service,
  Subject,
} from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormInput } from "../FormInput";
import { FormSwitch } from "../FormSwitch";
import { FormTextarea } from "../FormTextArea";
import { SectionHeader } from "./SectionHeader";
import dayjs from "dayjs";
import { CloseIcon, CopyIcon } from "@chakra-ui/icons";
import { getRequestAccessViewType, isWildcard } from "../../util/streams";
import { RequestAccessViewType } from "../../types";
import { CustomTab } from "../CustomTab";

export interface RequestAccessSubjectProps {
  client: NattyLightService;
  id: string;
  onCancel: () => void;
}

export const RequestAccessSubject = ({
  client,
  id,
  onCancel,
}: RequestAccessSubjectProps) => {
  const toast = useToast();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [allowServicesResponses, setAllowServicesResponses] =
    React.useState(false);
  const [limitMaximumNumberOfResponses, setLimitMaximumNumberOfResponses] =
    React.useState("");
  const [responseTimeout, setResponseTimeout] = React.useState("");
  const [allowMeToReceiveResponses, setAllowMeToReceiveResponses] =
    React.useState(false);
  const [businessDomains, setBusinessDomains] = React.useState<string[]>([]);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [services, setServices] = React.useState<Service[]>([]);
  const [tagOptions, setTagOptions] = React.useState<string[]>([]);
  const [tags, setTags] = React.useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
  const [businessAreaOptions, setBusinessAreaOptions] = React.useState<
    string[]
  >([]);
  const [selectedSubscribeSubjects, setSelectedSubscribeSubjects] =
    React.useState<string[]>([]);
  const [selectedPublishSubjects, setSelectedPublishSubjects] = React.useState<
    string[]
  >([]);
  const [access, setAccess] = React.useState<Access>();
  const { onCopy: onSeedCopy } = useClipboard(access ? access.userSeed : "");
  const { onCopy: onJWTCopy } = useClipboard(access ? access.tempJwt.jwt : "");
  const { onCopy: onClientIdCopy } = useClipboard(
    access ? access.coreClientId : ""
  );
  const { onCopy: onClientSecretCopy } = useClipboard(
    access ? access.coreClientSecret : ""
  );
  const { onCopy: onPublicKeyCopy } = useClipboard(
    access ? access.userPublicKey : ""
  );
  const [pendingSubSubject, setSubPendingSubject] = React.useState<string>();
  const [pendingSubQueueGroup, setSubPendingQueueGroup] =
    React.useState<string>("");
  const [pendingPubSubject, setPubPendingSubject] = React.useState<string>();
  const [pendingPubQueueGroup, setPubPendingQueueGroup] =
    React.useState<string>("");
  const [viewType, setViewType] = React.useState<RequestAccessViewType>();
  const [subject, setSubject] = React.useState<Subject>();
  const [readOnlyViewSelection, setReadOnlyViewSelection] = React.useState<any>(
    []
  );
  const { onCopy: onInboxCopy } = useClipboard(
    access ? access.responseInboxPrefix.join(",") : ""
  );

  const handleRequestAccessClicked = async () => {
    try {
      let newAccess: NewAccess = {
        brief: name,
        description: description,
        subscribeSubjectTemplates: [id],
        publishSubjectTemplates: [id],
      };

      if (businessDomains.length > 0) {
        newAccess.businessDomains = businessDomains;
      }

      if (tags.length > 0) {
        newAccess.tags = tags;
      }

      if (selectedProducts.length > 0) {
        newAccess.associatedProducts = selectedProducts;
      }

      if (selectedServices.length > 0) {
        newAccess.associatedServices = selectedServices;
      }

      if (selectedSubscribeSubjects.length > 0) {
        newAccess.requestedSubAccess = selectedSubscribeSubjects;
      }

      if (selectedPublishSubjects.length > 0) {
        newAccess.requestedPubAccess = selectedPublishSubjects;
      }

      if (subject && readOnlyViewSelection.includes("publish")) {
        newAccess.requestedPubAccess = [subject.name];
      }

      if (subject && readOnlyViewSelection.includes("subscribe")) {
        newAccess.requestedSubAccess = [subject.name];
      }

      const access = await client.createAccess(newAccess);
      setAccess(access);
      //onDone(); }
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleBusinessAreaChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setBusinessDomains(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleTagChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setTags(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleProductChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedProducts(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleServiceChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedServices(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleSubscribeSubjectChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    console.log(actionMeta);
    const selected = newValue.map((s: any) => s.value);
    setSelectedSubscribeSubjects(selected);

    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handlePublishSubjectChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    const selected = newValue.map((s: any) => s.value);
    setSelectedPublishSubjects(selected);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  useEffect(() => {
    client.getSubject(id).then((s) => {
      const subject = s;
      setSubject(subject);

      if (!isWildcard(id) && subject.queueGroups.length > 0) {
        setSubPendingSubject(subject.name);
        setPubPendingSubject(subject.name);
      }

      client.getBusinessDomains().then((domains) => {
        setBusinessAreaOptions(domains);
      });

      client.getProducts().then((products) => {
        setProducts(products);
      });

      client.getServices().then((services) => {
        setServices(
          services.filter(
            (s: Service) => !s.client_name.startsWith("stream-access-")
          )
        );
      });

      client.getTags().then((tags) => {
        setTagOptions(tags);
      });

      const vt = getRequestAccessViewType([subject]);
      setViewType(vt);

      if (viewType === RequestAccessViewType.QueueGroupOnly) {
        setSelectedPublishSubjects([subject.name]);
        setSelectedSubscribeSubjects([subject.name]);
      }
    });
  }, [client, id, viewType]);

  const handleSubAddClicked = () => {
    if (pendingSubSubject && !pendingSubQueueGroup) {
      setSelectedSubscribeSubjects([
        ...selectedSubscribeSubjects,
        pendingSubSubject,
      ]);
      setSubPendingSubject("");
    } else if (pendingSubSubject && pendingSubQueueGroup) {
      setSelectedSubscribeSubjects([
        ...selectedSubscribeSubjects,
        pendingSubSubject + " " + pendingSubQueueGroup,
      ]);

      setSubPendingQueueGroup("");
    }
  };

  const handlePubAddClicked = () => {
    console.log("handlePubAddClicked");
    console.log(pendingPubSubject);
    console.log(pendingPubQueueGroup);
    if (pendingPubSubject && !pendingPubQueueGroup) {
      setSelectedPublishSubjects([
        ...selectedPublishSubjects,
        pendingPubSubject,
      ]);
      setPubPendingSubject("");
    } else if (pendingPubSubject && pendingPubQueueGroup) {
      setSelectedPublishSubjects([
        ...selectedPublishSubjects,
        pendingPubSubject + " " + pendingPubQueueGroup,
      ]);

      setPubPendingQueueGroup("");
    }
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      {access ? (
        <VStack alignItems="stretch" height={"100%"} spacing={"0"}>
          <VStack
            alignItems={"stretch"}
            padding={"20px 20px 0 20px"}
            background={"white.900"}
            spacing={"0"}
          >
            <Text variant="titleLarge">Success!</Text>
            <Text variant="bodySmall">
              You've successfully created access to this subject.
            </Text>
            <Text variant="bodySmall">
              Below is all the information you need to continue.
            </Text>
            <HStack
              padding={"16px 20px 17px 20px"}
              background={"white.900"}
              borderBottom={"1px solid"}
              borderBottomColor={"black.300"}
            >
              <Button onClick={onCancel} variant="primaryBlack" size="sm">
                Back
              </Button>
            </HStack>
          </VStack>
          <VStack
            background={"white.800"}
            alignItems={"stretch"}
            height={"100%"}
            overflowY={"scroll"}
            padding={"20px 20px 40px 20px"}
            gap={"12px"}
          >
            <SectionHeader title="Access Details" />
            <FormControl>
              <FormLabel>Access Name</FormLabel>
              <Text variant="bodySmall">{access.brief}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>ID</FormLabel>
              <Text variant="bodySmall">{access.id}</Text>
            </FormControl>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text variant="labelSmall">Publish Access</Text>

              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {access.requestedPubAccess.length > 0
                  ? access.requestedPubAccess.join(", ")
                  : "No Publish Access"}
              </Text>
            </VStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text variant="labelSmall">Subscribe Access</Text>

              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {access.requestedSubAccess.length > 0
                  ? access.requestedSubAccess.join(", ")
                  : "No Subscribe Access"}
              </Text>
            </VStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <HStack>
                <FormLabel>Public Key</FormLabel>
                <IconButton
                  aria-label="Copy Public Key"
                  icon={<CopyIcon />}
                  onClick={onPublicKeyCopy}
                  variant="copyButton"
                  size="xs"
                />
              </HStack>
              <Text variant="bodySmall">{access.userPublicKey}</Text>
            </VStack>

            {access.responseInboxPrefix &&
              access.responseInboxPrefix.length > 0 && (
                <VStack alignItems={"stretch"} spacing={"0"}>
                  <HStack spacing={"1"}>
                    <Text variant="labelSmall">Custom Inboxes</Text>
                    <IconButton
                      aria-label="Copy Inbox Prefixes"
                      icon={<CopyIcon />}
                      onClick={onInboxCopy}
                      variant="copyButton"
                      size="xs"
                    />
                  </HStack>
                  <Text
                    variant="bodySmall"
                    cursor={"default"}
                    userSelect={"all"}
                  >
                    {access.responseInboxPrefix.join(", ")}
                  </Text>
                </VStack>
              )}

            <VStack alignItems={"stretch"} spacing={"2"}>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <HStack>
                  <FormLabel>JWT</FormLabel>
                  <IconButton
                    aria-label="Copy Access JWT"
                    icon={<CopyIcon />}
                    onClick={onJWTCopy}
                    ml={2}
                    variant="copyButton"
                    size="xs"
                  />
                </HStack>
                <Text variant="bodySmall">
                  This is a temporary access JWT to test your access.
                </Text>
                <Text variant="bodySmall">
                  It will expire at{" "}
                  {dayjs(access.tempJwt.exp * 1000).format(
                    "MMM DD YYYY hh:mm a"
                  )}
                  .
                </Text>
              </VStack>
              <FormTextarea
                height={"160px"}
                isReadOnly
                value={access.tempJwt.jwt}
              ></FormTextarea>
            </VStack>
            <SectionHeader
              title="Secrets"
              description="Do not share these values, and record them before you leave
              this window. These values will not be shown again without an explicit
              request from you."
            />
            <VStack
              alignItems={"stretch"}
              background="white.800"
              border={"1px solid"}
              borderColor={"black.400"}
              shadow={"default.500"}
              borderRadius="4px"
              padding="20px"
              gap="16px"
            >
              <VStack alignItems={"stretch"} spacing={"0"}>
                <HStack>
                  <FormLabel>Seed</FormLabel>
                  <IconButton
                    aria-label="Copy Client Secret"
                    icon={<CopyIcon />}
                    onClick={onSeedCopy}
                    variant="copyButton"
                    size="xs"
                  />
                </HStack>
                <Text variant="bodySmall">{access.userSeed}</Text>
              </VStack>
              <Text variant={"labelMedium"}>
                Below are client credentials to programmatically request a new
                JWT
              </Text>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <HStack>
                  <FormLabel>Client Secret</FormLabel>
                  <IconButton
                    aria-label="Copy Client Secret"
                    icon={<CopyIcon />}
                    onClick={onClientSecretCopy}
                    variant="copyButton"
                    size="xs"
                  />
                </HStack>
                <Text variant="bodySmall">{access.coreClientSecret}</Text>
              </VStack>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <HStack>
                  <FormLabel>Client ID</FormLabel>
                  <IconButton
                    aria-label="Copy Client ID"
                    icon={<CopyIcon />}
                    onClick={onClientIdCopy}
                    variant="copyButton"
                    size="xs"
                  />
                </HStack>
                <Text variant="bodySmall">{access.coreClientId}</Text>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <VStack alignItems="stretch" height={"100%"} spacing={"0"}>
          <VStack
            alignItems={"stretch"}
            padding={"20px 20px 0 20px"}
            background={"white.900"}
            spacing={"0"}
          >
            <Text variant="labelMedium" lineHeight={"1em"}>
              Request access to
            </Text>
            <Text variant="titleLarge" lineHeight={"1.2em"}>
              {id}
            </Text>
          </VStack>
          <HStack padding={"12px 20px 4px 20px"} background={"white.900"}>
            <Button
              onClick={handleRequestAccessClicked}
              disabled={!name}
              variant="primaryOrange"
              size="sm"
            >
              Request Access
            </Button>
            <Button onClick={onCancel} variant="primaryBlack" size="sm">
              Cancel
            </Button>
          </HStack>

          <Tabs
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            overflow={"hidden"}
          >
            <TabList
              background={"white.900"}
              padding={"2px 20px 0 20px"}
              gap={"12px"}
              borderBottom={"1px solid"}
              borderBottomColor={"black.300"}
            >
              <CustomTab>General</CustomTab>
              <CustomTab>Associations</CustomTab>
            </TabList>
            <TabPanels
              background={"white.800"}
              display={"flex"}
              flexDirection={"column"}
              height={"100%"}
              overflow={"hidden"}
            >
              {/* General Tab */}
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader title={"General"} />
                <FormControl>
                  <FormLabel>Name of Access (Required)</FormLabel>
                  <FormInput
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  ></FormInput>
                </FormControl>
                <FormControl>
                  <FormLabel>Description of Access</FormLabel>
                  <FormTextarea
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    value={description}
                  ></FormTextarea>
                </FormControl>

                {viewType === RequestAccessViewType.ReadOnly ? (
                  <>
                    <SectionHeader title={`Access to ${subject?.name}`} />
                    <VStack alignItems={"stretch"} spacing={"0"}>
                      <CheckboxGroup
                        onChange={(e) => {
                          setReadOnlyViewSelection(e);
                        }}
                      >
                        <Checkbox
                          border={"1px black"}
                          colorScheme="blackAlpha"
                          value="subscribe"
                        >
                          Subscribe
                        </Checkbox>
                        <Checkbox
                          border={"1px black"}
                          colorScheme="blackAlpha"
                          value="publish"
                        >
                          Publish
                        </Checkbox>
                      </CheckboxGroup>
                    </VStack>
                  </>
                ) : (
                  <>
                    <SectionHeader
                      title={"Subscribe Access"}
                      description={
                        "Subject names are unique identifiers, sometimes with wildcards to allow flexibility. Type in the variant of the name from which you are attempting to subscribe data. If wildcard access is enabled for this subject, you can enter (or select) matching wildcard patterns using the * and > tokens. If this subject name does not have a wildcard, you will be given access to the only available option."
                      }
                    />

                    {viewType ===
                      RequestAccessViewType.SubjectAndQueueGroup && (
                      <VStack alignItems={"stretch"}>
                        <FormInput
                          value={pendingSubSubject}
                          onChange={(e) => {
                            setSubPendingSubject(e.target.value);
                          }}
                          placeholder="Define a subject"
                        />

                        <FormInput
                          value={pendingSubQueueGroup}
                          onChange={(e) => {
                            setSubPendingQueueGroup(e.target.value);
                          }}
                          placeholder="Associate to a queue group (optional)"
                        />

                        <Button
                          variant={"primaryOrange"}
                          size={"sm"}
                          onClick={handleSubAddClicked}
                        >
                          Add
                        </Button>

                        {selectedSubscribeSubjects.map((sub: any) => (
                          <HStack
                            key={sub}
                            background="black.800"
                            border="1px solid"
                            borderColor="black.900"
                            borderRadius="4px"
                            color="white"
                            spacing="0"
                            justifyContent={"space-between"}
                            padding="0 2px 0 10px"
                          >
                            <Text
                              variant={"bodyMedium"}
                              lineHeight={"1em"}
                              marginTop={"-3px"}
                            >
                              {sub}
                            </Text>
                            <Button
                              name={sub}
                              onClick={() => {
                                setSelectedSubscribeSubjects(
                                  selectedSubscribeSubjects.filter(
                                    (s: any) => s !== sub
                                  )
                                );
                              }}
                              variant="unstyled"
                              size={"sm"}
                              leftIcon={<CloseIcon boxSize={3} />}
                              iconSpacing={"0"}
                              paddingTop={"1px"}
                            ></Button>
                          </HStack>
                        ))}
                      </VStack>
                    )}

                    {viewType === RequestAccessViewType.QueueGroupOnly && (
                      <VStack alignItems={"stretch"} gap={"12px"}>
                        <VStack alignItems={"stretch"} spacing={"0"}>
                          <Text variant="bodySmall">
                            You will receive subscribe access to the following
                            subject:
                          </Text>
                          <Text variant="labelLarge">{subject?.name}</Text>
                        </VStack>
                        <VStack alignItems={"stretch"} spacing={"1"}>
                          <HStack>
                            <FormInput
                              value={pendingSubQueueGroup}
                              onChange={(e) => {
                                setSubPendingQueueGroup(e.target.value);
                              }}
                              placeholder="Associate to a queue group"
                            />
                            <Button
                              onClick={handleSubAddClicked}
                              variant={"primaryOrange"}
                              size={"md"}
                              width={"100px"}
                              disabled={pendingSubQueueGroup === ""}
                            >
                              Add
                            </Button>
                          </HStack>
                          <HStack>
                            <Text variant="bodySmall">
                              The queue group must match the patterns:
                            </Text>
                            {subject?.queueGroups.map((qg: string) => (
                              <Text key={qg} variant="labelSmall">
                                {qg}
                              </Text>
                            ))}
                          </HStack>
                        </VStack>

                        <VStack alignItems={"stretch"}>
                          {selectedSubscribeSubjects.map((sub: any) => (
                            <HStack
                              key={sub}
                              background="black.800"
                              border="1px solid"
                              borderColor="black.900"
                              borderRadius="4px"
                              color="white"
                              spacing="0"
                              justifyContent={"space-between"}
                              padding="0 2px 0 10px"
                            >
                              <Text
                                variant={"bodyMedium"}
                                lineHeight={"1em"}
                                marginTop={"-3px"}
                              >
                                {sub}
                              </Text>
                              <Button
                                name={sub}
                                onClick={() => {
                                  setSelectedSubscribeSubjects(
                                    selectedSubscribeSubjects.filter(
                                      (s: any) => s !== sub
                                    )
                                  );
                                }}
                                variant="unstyled"
                                size={"sm"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                iconSpacing={"0"}
                                paddingTop={"1px"}
                              ></Button>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    )}

                    {viewType === RequestAccessViewType.SubjectOnly && (
                      <VStack alignItems={"stretch"}>
                        {selectedSubscribeSubjects.length >= 0 && (
                          <CreatableSelect
                            isMulti
                            options={[{ value: id, label: id }]}
                            onChange={handleSubscribeSubjectChange}
                            placeholder="Select or define a subject"
                            value={selectedSubscribeSubjects?.map((s) => {
                              return { value: s, label: s };
                            })}
                            controlShouldRenderValue={false}
                            isClearable={false}
                          />
                        )}

                        <VStack alignItems={"stretch"}>
                          {selectedSubscribeSubjects.map((sub: any) => (
                            <HStack
                              key={sub}
                              background="black.800"
                              border="1px solid"
                              borderColor="black.900"
                              borderRadius="4px"
                              color="white"
                              spacing="0"
                              justifyContent={"space-between"}
                              padding="0 2px 0 10px"
                            >
                              <Text
                                variant={"bodyMedium"}
                                lineHeight={"1em"}
                                marginTop={"-3px"}
                              >
                                {sub}
                              </Text>
                              <Button
                                name={sub}
                                onClick={() => {
                                  setSelectedSubscribeSubjects(
                                    selectedSubscribeSubjects.filter(
                                      (s: any) => s !== sub
                                    )
                                  );
                                }}
                                variant="unstyled"
                                size={"sm"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                iconSpacing={"0"}
                                paddingTop={"1px"}
                              ></Button>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    )}

                    <SectionHeader
                      title={"Publish Access"}
                      description={
                        "Type in the variant of the name to which you are attempting to publish data. If wildcard access is enabled for this subject, you can enter (or select) matching wildcard patterns using the * and > tokens. If this subject name does not have a wildcard, you may request access to the only available option."
                      }
                    />

                    {viewType ===
                      RequestAccessViewType.SubjectAndQueueGroup && (
                      <VStack alignItems={"stretch"}>
                        <FormInput
                          value={pendingPubSubject}
                          onChange={(e) => {
                            setPubPendingSubject(e.target.value);
                          }}
                          placeholder="Define a subject"
                        />

                        <FormInput
                          value={pendingPubQueueGroup}
                          onChange={(e) => {
                            setPubPendingQueueGroup(e.target.value);
                          }}
                          placeholder="Associate to a queue group (optional)"
                        />

                        <Button
                          variant={"primaryOrange"}
                          size={"lg"}
                          onClick={handlePubAddClicked}
                          mt="10px"
                        >
                          Add
                        </Button>

                        <VStack alignItems={"stretch"}>
                          {selectedPublishSubjects.map((sub: any) => (
                            <HStack
                              key={sub}
                              background="black.800"
                              border="1px solid"
                              borderColor="black.900"
                              borderRadius="4px"
                              color="white"
                              spacing="0"
                              justifyContent={"space-between"}
                              padding="0 2px 0 10px"
                            >
                              <Text
                                variant={"bodyMedium"}
                                lineHeight={"1em"}
                                marginTop={"-3px"}
                              >
                                {sub}
                              </Text>
                              <Button
                                name={sub}
                                onClick={() => {
                                  setSelectedPublishSubjects(
                                    selectedPublishSubjects.filter(
                                      (s: any) => s !== sub
                                    )
                                  );
                                }}
                                variant="unstyled"
                                size={"sm"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                iconSpacing={"0"}
                                paddingTop={"1px"}
                              ></Button>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    )}

                    {viewType === RequestAccessViewType.QueueGroupOnly && (
                      <VStack alignItems={"stretch"} gap={"12px"}>
                        <VStack alignItems={"stretch"} spacing={"0"}>
                          <Text variant="bodySmall">
                            You will recieve publish access to the following
                            subject:
                          </Text>
                          <Text variant="labelLarge">{subject?.name}</Text>
                        </VStack>
                        <VStack alignItems={"stretch"} spacing={"1"}>
                          <HStack>
                            <FormInput
                              value={pendingPubQueueGroup}
                              onChange={(e) => {
                                setPubPendingQueueGroup(e.target.value);
                              }}
                              placeholder="Associate to a queue group"
                            />
                            <Button
                              variant={"primaryOrange"}
                              size={"md"}
                              width={"100px"}
                              onClick={handlePubAddClicked}
                              disabled={pendingPubQueueGroup === ""}
                            >
                              Add
                            </Button>
                          </HStack>
                          <HStack>
                            <Text variant="bodySmall">
                              The queue group must match the patterns:
                            </Text>
                            {subject?.queueGroups.map((qg: string) => (
                              <Text key={qg} variant="labelSmall">
                                {qg}
                              </Text>
                            ))}
                          </HStack>
                        </VStack>
                        <VStack alignItems={"stretch"}>
                          {selectedPublishSubjects.map((sub: any) => (
                            <HStack
                              key={sub}
                              background="black.800"
                              border="1px solid"
                              borderColor="black.900"
                              borderRadius="4px"
                              color="white"
                              spacing="0"
                              justifyContent={"space-between"}
                              padding="0 2px 0 10px"
                            >
                              <Text
                                variant={"bodyMedium"}
                                lineHeight={"1em"}
                                marginTop={"-3px"}
                              >
                                {sub}
                              </Text>
                              <Button
                                name={sub}
                                onClick={() => {
                                  setSelectedPublishSubjects(
                                    selectedPublishSubjects.filter(
                                      (s: any) => s !== sub
                                    )
                                  );
                                }}
                                variant="unstyled"
                                size={"sm"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                iconSpacing={"0"}
                                paddingTop={"1px"}
                              ></Button>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    )}

                    {viewType === RequestAccessViewType.SubjectOnly && (
                      <VStack alignItems={"stretch"}>
                        {selectedPublishSubjects.length >= 0 && (
                          <CreatableSelect
                            isMulti
                            options={[{ value: id, label: id }]}
                            onChange={handlePublishSubjectChange}
                            placeholder="Select or define a subject"
                            value={selectedPublishSubjects?.map((s) => {
                              return { value: s, label: s };
                            })}
                            controlShouldRenderValue={false}
                            isClearable={false}
                          />
                        )}
                        {/*selectedSubscribeSubjects.length > 0 && (
                      <CreatableSelect
                        isMulti
                        options={[]}
                        onChange={handleSubscribeSubjectChange}
                        placeholder="Define a subject"
                      />
                    )*/}
                        <VStack alignItems={"stretch"}>
                          {selectedPublishSubjects.map((sub: any) => (
                            <HStack
                              key={sub}
                              background="black.800"
                              border="1px solid"
                              borderColor="black.900"
                              borderRadius="4px"
                              color="white"
                              spacing="0"
                              justifyContent={"space-between"}
                              padding="0 2px 0 10px"
                            >
                              <Text
                                variant={"bodyMedium"}
                                lineHeight={"1em"}
                                marginTop={"-3px"}
                              >
                                {sub}
                              </Text>
                              <Button
                                name={sub}
                                onClick={() => {
                                  setSelectedPublishSubjects(
                                    selectedPublishSubjects.filter(
                                      (s: any) => s !== sub
                                    )
                                  );
                                }}
                                variant="unstyled"
                                size={"sm"}
                                leftIcon={<CloseIcon boxSize={3} />}
                                iconSpacing={"0"}
                                paddingTop={"1px"}
                              ></Button>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    )}
                  </>
                )}

                <SectionHeader
                  title={"Advanced Requests"}
                  description={
                    "Advanced settings to your access allow you to ensure your client can send and receive responses if you are connecting a request/reply consumer, the max number of those responses allowed, and how long a request should wait before timing out."
                  }
                />

                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Allow me to send responses</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setAllowServicesResponses(event.target.checked);
                    }}
                    isChecked={allowServicesResponses}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Limit maximum number of responses</FormLabel>
                  <FormInput
                    onChange={(e) => {
                      setLimitMaximumNumberOfResponses(e.target.value);
                    }}
                    value={limitMaximumNumberOfResponses}
                  ></FormInput>
                </FormControl>

                <FormControl>
                  <FormLabel>Response timeout</FormLabel>
                  <FormInput
                    onChange={(e) => {
                      setResponseTimeout(e.target.value);
                    }}
                    value={responseTimeout}
                  ></FormInput>
                </FormControl>

                <FormControl
                  display={"flex"}
                  flexDirection={"row-reverse"}
                  justifyContent={"flex-end"}
                >
                  <FormLabel>Allow me to receive service responses</FormLabel>
                  <FormSwitch
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setAllowMeToReceiveResponses(event.target.checked);
                    }}
                    isChecked={allowMeToReceiveResponses}
                  />
                </FormControl>
              </TabPanel>

              {/* Associations Tab */}
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="Associations"
                  description="Associations are ways to make it easier to organize your stream."
                />
                <FormControl>
                  <FormLabel>Business Area</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={businessDomains?.map((bd) => {
                      return { value: bd, label: bd };
                    })}
                    options={businessAreaOptions.map((b) => {
                      return { value: b, label: b };
                    })}
                    onChange={handleBusinessAreaChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <CreatableSelect
                    isMulti
                    isClearable
                    value={tags?.map((t) => {
                      return { value: t, label: t };
                    })}
                    options={tagOptions.map((t) => {
                      return { value: t, label: t };
                    })}
                    onChange={handleTagChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Associated Products</FormLabel>
                  <Select
                    isMulti
                    options={products.map((p) => {
                      return { value: p.id, label: p.name };
                    })}
                    onChange={handleProductChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Associated Services</FormLabel>
                  <Select
                    isMulti
                    value={selectedServices?.map((s) => {
                      return { value: s, label: s };
                    })}
                    options={services.map((s) => {
                      return { value: s.client_id, label: s.client_name };
                    })}
                    onChange={handleServiceChange}
                  />
                </FormControl>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
    </VStack>
  );
};
