import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Product, Service, Stream } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { FormBadge } from "./FormBadge";
import { SectionHeader } from "./SectionHeader";
import dayjs from "dayjs";
import { ChevronDownIcon, InfoIcon } from "@chakra-ui/icons";
import { ActionModal } from "./ActionsModal";
import { CustomTab } from "../CustomTab";

export interface StreamProps {
  client: NattyLightService;
  id: string;
  onEditClicked: () => void;
  onRequestAccessClicked: () => void;
}

export const ViewStream = ({
  client,
  id,
  onEditClicked,
  onRequestAccessClicked,
}: StreamProps) => {
  const [stream, setStream] = React.useState<Stream>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [messageAction, setMessageAction] = React.useState<
    "publish" | "query" | "delete"
  >("query");
  const [products, setProducts] = React.useState<Product[]>([]);
  const [services, setServices] = React.useState<Service[]>([]);

  useEffect(() => {
    client.getStream(id).then((res) => {
      setStream(res);
    });

    client.getProducts().then((products) => {
      setProducts(products);
    });

    client.getServices().then((services) => {
      setServices(
        services.filter(
          (s: Service) => !s.client_name.startsWith("stream-access-")
        )
      );
    });
  }, [client, id]);

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      {stream && (
        <VStack
          alignItems="stretch"
          height={"100%"}
          spacing={"0"}
        >
          <VStack
            alignItems={"stretch"}
            padding={"20px 20px 0 20px"}
            background={"white.900"}
            spacing={"0"}
          >
            <Text variant="labelMedium" lineHeight={"1em"}>Stream</Text>
            <Text variant="titleLarge" lineHeight={"1.2em"}>{stream.name}</Text>
          </VStack>
          <HStack
            padding={"12px 20px 4px 20px"}
            background={"white.900"}
          >
            <Button
              onClick={onEditClicked}
              variant="primaryBlack"
              size="sm"
            >
              Edit
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                variant="primaryBlack"
                size="sm"
                rightIcon={<ChevronDownIcon />}
              >
                Messages
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setMessageAction("query");
                    onOpen();
                  }}
                >
                  Query
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setMessageAction("publish");
                    onOpen();
                  }}
                >
                  Publish
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setMessageAction("delete");
                    onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              variant="primaryGreen"
              size="sm"
              onClick={onRequestAccessClicked}
            >
              Request Access
            </Button>
          </HStack>
          <Tabs
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            overflow={"hidden"}
          >
            <TabList
              background={"white.900"}
              padding={"2px 20px 0 20px"}
              gap={"12px"}
              borderBottom={"1px solid"}
              borderBottomColor={"black.300"}
            >
              <CustomTab>General</CustomTab>
              <CustomTab>Subjects</CustomTab>
              <CustomTab>State</CustomTab>
              <CustomTab>Associations</CustomTab>
            </TabList>

            <TabPanels
              background={"white.800"}
              display={"flex"}
              flexDirection={"column"}
              height={"100%"}
              overflow={"hidden"}
            >
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="General"
                  description={stream.description}
                />
                <HStack
                  spacing={"1"}
                >
                  <Text variant="labelSmall">ID</Text>
                  <Text variant="bodySmall">{stream.id}</Text>
                </HStack>

                <VStack
                  alignItems={"stretch"}
                  spacing={"0"}
                >
                  <HStack
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Created</Text>
                    <Text variant="bodySmall">
                      {dayjs(stream.createdAt).format("MMM DD YYYY h:mm a")}
                    </Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Last Modified</Text>
                    <Text variant="bodySmall">
                      {dayjs(stream.modifiedAt).format("MMM DD YYYY h:mm a")}
                    </Text>
                  </HStack>
                </VStack>
                <SectionHeader
                  title="Config"
                  description="Manage your stream access, limits, retention, and performance for all consumers and producers."
                />
                {stream.imported && (
                  <HStack
                    spacing={"1"}
                  >
                    <InfoIcon />
                    <Text variant="labelMedium">This stream was imported.</Text>
                  </HStack>
                )}
                {stream.core && (
                  <HStack
                    spacing={"1"}
                  >
                    <InfoIcon />
                    <Text variant="labelMedium">This stream is managed by Core.</Text>
                  </HStack>
                )}
                <VStack
                  alignItems={"stretch"}
                  spacing={"2"}
                >
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {stream.config.sealed ? "On" : "Off"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Stream Sealed</Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {stream.config.denyDelete ? "On" : "Off"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Deny Delete</Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {stream.config.denyPurge ? "On" : "Off"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Deny Purge</Text>
                  </HStack>
                  <HStack
                    spacing={"1"}
                  >
                    <FormBadge backgroundColor={"black.900"} justifyContent={"center"}>
                      {stream.config.allowDirect ? "On" : "Off"}
                    </FormBadge>
                    <Text variant={"labelSmall"}>Allow Direct</Text>
                  </HStack>
                </VStack>
                <VStack
                  alignItems={"stretch"}
                  gap={"12px"}
                >
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum Consumers</Text>
                    <Text variant={"bodySmall"}>How many consumers will this stream allow?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxConsumers < 0
                        ? "Unlimited"
                        : stream.config.maxConsumers}{" "}
                      Consumers
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum Messages</Text>
                    <Text variant={"bodySmall"}>How many messages will this stream allow?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxMsgs < 0
                        ? "Unlimited"
                        : stream.config.maxMsgs}{" "}
                      Messages
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum messages per subject</Text>
                    <Text variant={"bodySmall"}>How many messages per subject will this stream allow?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxMsgsPerSubject < 0
                        ? "Unlimited"
                        : stream.config.maxMsgsPerSubject}{" "}
                      Messages per Subject
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum message size</Text>
                    <Text variant={"bodySmall"}>What is the largest size allowed in the stream in bytes?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxMsgSize < 0
                        ? "Unlimited"
                        : stream.config.maxMsgSize}{" "}
                      Message size
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum bytes</Text>
                    <Text variant={"bodySmall"}>What is the maximum amount of data allowed in this stream?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxBytes < 0
                        ? "Unlimited"
                        : stream.config.maxBytes}{" "}
                      Bytes
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Maximum age</Text>
                    <Text variant={"bodySmall"}>What is the maximum age of a message before it is discarded?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.maxAge < 1 ? "No Max" : stream.config.maxAge}{" "}
                      Age
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Discard policy</Text>
                    <Text variant={"bodySmall"}>When a maximum threshold is reached, which messages are discarded first?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.retention[0].toUpperCase() +
                        stream.config.retention.slice(1)}{" "}
                      Retention
                    </Text>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant={"labelSmall"}>Performance & Reliability</Text>
                    <Text variant={"bodySmall"}>Do you want this stream to go the fastest, or be the most reliable?</Text>
                    <Text variant={"bodyMedium"} paddingTop={"8px"}>
                      {stream.config.performance[0].toUpperCase() +
                        stream.config.performance.slice(1)}{" "}
                      Performance
                    </Text>
                  </VStack>
                </VStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="Subjects"
                  description="Think of subjects as the data pipes that streams manage."
                />
                <VStack
                  alignItems={"stretch"}
                >
                  {stream.subjects &&
                    stream.subjects.map((s) => (
                      <FormBadge
                        key={s}
                        backgroundColor={"green.500"}
                      >
                        {s}
                      </FormBadge>
                    ))}
                </VStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="State"
                  description="This is the current state of your stream. It includes things like total messages, last published date/time, and more."
                />
                <VStack
                  alignItems={"stretch"}
                  spacing={"2"}
                >
                  <HStack
                    spacing={"2"}
                  >
                    <Text variant={"titleLarge"}>{stream.natsInfo?.state?.messages}</Text>
                    <VStack
                      alignItems={"stretch"}
                    >
                      <Text variant={"labelMedium"} lineHeight={"1"}>Messages</Text>
                    </VStack>
                  </HStack>
                  <Divider borderColor={"black.300"} />
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <HStack
                      spacing={"2"}
                    >
                      <Text variant={"titleLarge"}>{stream.natsInfo?.state?.first_seq}</Text>
                      <VStack
                        alignItems={"stretch"}
                        spacing={"0.5"}
                      >
                        <Text variant={"labelMedium"} lineHeight={"1"}>First Index</Text>
                        <HStack
                          spacing={"1"}
                        >
                          <Text
                            variant={"bodySmall"}
                            lineHeight={"1em"}
                          >
                            {" "}
                            Published
                          </Text>
                          <Text
                            variant={"bodySmall"}
                            lineHeight={"1em"}
                          >
                            {dayjs(stream.natsInfo?.state?.first_ts).format(" MMM DD YYYY hh:mm a")}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                  <Divider borderColor={"black.300"} />
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <HStack
                      spacing={"2"}
                    >
                      <Text variant={"titleLarge"}>{stream.natsInfo?.state?.last_seq}</Text>
                      <VStack
                        alignItems={"stretch"}
                        spacing={"0.5"}
                      >
                        <Text variant={"labelMedium"} lineHeight={"1"}>Last Index</Text>
                        <HStack
                          spacing={"1"}
                        >
                          <Text
                            variant={"bodySmall"}
                            lineHeight={"1em"}
                          >
                            {" "}
                            Published
                          </Text>
                          <Text
                            variant={"bodySmall"}
                            lineHeight={"1em"}
                          >
                            {dayjs(stream.natsInfo?.state?.last_ts).format(" MMM DD YYYY hh:mm a")}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                  <Divider borderColor={"black.300"} />
                  <HStack
                    spacing={"2"}
                  >
                    <Text variant={"titleLarge"}>{stream.natsInfo?.state?.bytes}</Text>
                    <VStack
                      alignItems={"stretch"}
                      spacing={"-0.5"}
                    >
                      <Text variant={"labelMedium"} lineHeight={"1"}>Bytes</Text>
                    </VStack>
                  </HStack>
                  <Divider borderColor={"black.300"} />
                  <HStack
                    spacing={"2"}
                  >
                    <Text variant={"titleLarge"}>{stream.natsInfo?.state?.consumer_count}</Text>
                    <VStack
                      alignItems={"stretch"}
                      spacing={"-0.5"}
                    >
                      <Text variant={"labelMedium"} lineHeight={"1"}>Consumers</Text>
                    </VStack>
                  </HStack>

                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    {stream.natsInfo?.consumers &&
                      stream.natsInfo.consumers.map((c) => (
                        <FormBadge backgroundColor={"green.500"}>{c.name}</FormBadge>
                      ))}
                  </VStack>
                </VStack>
              </TabPanel>
              <TabPanel
                display={"flex"}
                flexDirection={"column"}
                overflowY={"scroll"}
                padding={"20px 20px 40px 20px"}
                gap={"20px"}
              >
                <SectionHeader
                  title="Associations"
                  description="Associations are ways to make it easier to organize your stream."
                />
                <VStack
                  alignItems={"stretch"}
                  gap={"12px"}
                >
                  <VStack
                    alignItems={"stretch"}
                    spacing={"0"}
                  >
                    <Text variant="labelSmall">Business Area</Text>
                    {stream.businessDomains &&
                      stream.businessDomains.length === 0 && (
                        <Text variant="bodySmall">
                          No business domains associated with this stream.
                        </Text>
                      )}
                    {stream.businessDomains &&
                      stream.businessDomains.map((bd) => (
                        <Text variant="bodyLarge">{bd}</Text>
                      ))}
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Tags</Text>
                    {stream.tags && stream.tags.length === 0 && (
                      <Text variant="bodySmall">
                        No tags associated with this stream.
                      </Text>
                    )}
                    <HStack>
                      {stream.tags &&
                        stream.tags.map((tag) => (
                          <FormBadge backgroundColor={"green.600"}>
                            {tag}
                          </FormBadge>
                        ))}
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Products</Text>
                    {stream.associatedProducts &&
                      stream.associatedProducts.length === 0 && (
                        <Text variant="bodySmall">
                          No products associated with this stream.
                        </Text>
                      )}
                    <HStack>
                      {stream.tags &&
                        stream.associatedProducts.map((p) => {
                          const name = products.find((prod) => prod.id === p)?.name;
                          return (
                            <FormBadge backgroundColor={"green.600"}>{name}</FormBadge>
                          );
                        })}
                    </HStack>
                  </VStack>
                  <VStack
                    alignItems={"stretch"}
                    spacing={"1"}
                  >
                    <Text variant="labelSmall">Services</Text>
                    {stream.associatedServices &&
                      stream.associatedServices.length === 0 && (
                        <Text variant="bodySmall">
                          No services associated with this stream.
                        </Text>
                      )}
                    <HStack>
                      {stream.tags &&
                        stream.associatedServices.map((s) => {
                          const name = services.find((serv) => serv.client_id === s)?.client_name;
                          return (
                            <FormBadge backgroundColor={"green.600"}>{name}</FormBadge>
                          );
                        })}
                    </HStack>
                  </VStack>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      )}
      <ActionModal
        type="stream"
        stream={stream}
        onClose={onClose}
        isOpen={isOpen}
        client={client}
        action={messageAction}
      >
        <Text>Test</Text>
      </ActionModal>
    </VStack>
  );
};
