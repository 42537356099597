import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors, FormikProps } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { UEProduct } from "../../types";
import { FormInput } from "../FormInput";

interface NewProductProps {
  result: any;
}

export const NewProduct = ({ result }: NewProductProps) => {
  const { createProduct } = useContext(AuthContext);

  interface FormValues {
    name: string;
    description: string;
    b2c: boolean;
    url: string;
  }

  const initialValues: FormValues = {
    name: "",
    description: "",
    b2c: false,
    url: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        let errors: FormikErrors<FormValues> = {};
        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.description) {
          errors.description = "Required";
        }
        if (
          values.url &&
          !/^(?:(https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/i.test(values.url)
        ) {
          errors.url = "Please enter a domain like https://example.com";
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          const productBody = {
            name: values.name,
            description: values.description,
            type: "app",
            b2c: values.b2c,
            url: values.url,
          };
          const productResult: any = await createProduct(productBody);
          const newProduct: UEProduct = productResult.data.data;

          result(newProduct);
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {(props: FormikProps<FormValues>) => {
        return (
          <Form>
            <VStack
              mt={"20px"}
              alignItems={"flex-start"}
              gap={"8px"}
            >
              <Text variant="contentheader">Add a new product</Text>
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel color="black.900" htmlFor="name">
                      Name
                    </FormLabel>
                    <FormInput {...field} id="name" placeholder="New Product" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                  >
                    <FormLabel color="black.900" htmlFor="description">
                      Description
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="description"
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="b2c">
                {({ field, form }: any) => (
                  <FormControl>
                    <HStack>
                      <FormLabel color="black.900" htmlFor="b2c">
                        B2C
                      </FormLabel>
                      <Switch
                        {...field}
                        isChecked={field.value}
                        onChange={(event) => {
                          props.setFieldValue("b2c", event.target.checked);
                        }}
                        id="b2c"
                        placeholder=""
                      />
                    </HStack>
                  </FormControl>
                )}
              </Field>

              <Field name="url">
                {({ field, form }: any) => (
                  <FormControl isInvalid={form.errors.url && form.touched.url}>
                    <FormLabel color="black.900" htmlFor="url">
                      URL
                    </FormLabel>
                    <FormInput {...field} id="url" placeholder="" />
                    <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Button variant="primaryBlue" type="submit" disabled={props.isSubmitting}>
                Create
              </Button>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
