import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { NewSubject } from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import { isValidSubjectName } from "../../util/streams";
import { FormInput } from "../FormInput";
import { FormSwitch } from "../FormSwitch";
import { FormTextarea } from "../FormTextArea";
import { SectionHeader } from "./SectionHeader";

export interface CreateSubjectProps {
  client: NattyLightService;
  onDone: () => void;
}

export const CreateSubject = ({ client, onDone }: CreateSubjectProps) => {
  const toast = useToast();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [service, setService] = React.useState<boolean>(false);
  const [allowWildcardAccess, setAllowWildcardAccess] =
    React.useState<boolean>(false);
  const [subjectNameError, setSubjectNameError] = React.useState(false);

  useEffect(() => { }, [client]);

  const saveNewSubject = async () => {
    try {
      let newSubject: NewSubject = {
        name: name,
        description: description,
        service: service,
        allowWildcardAccess: allowWildcardAccess,
      };

      console.log(newSubject);
      await client.createSubject(newSubject);
      onDone();
    } catch (error) {
      alert(JSON.stringify(error));

      showErrorToast(error);
    }
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      <VStack
        alignItems="stretch"
        height={"100%"}
        spacing={"0"}
      >
        <HStack
          padding={"20px 20px 0px 20px"}
          background={"white.900"}
          spacing={"1"}
        >
          <Text variant="titleLarge">Create New Subject</Text>
        </HStack>
        <HStack
          padding={"20px 20px 53px 20px"}
          background={"white.900"}
          borderBottom={"1px solid"}
          borderBottomColor={"black.300"}
        >
          <Button
            variant="primaryOrange"
            size="sm"
            onClick={saveNewSubject}
            disabled={!name || subjectNameError}
          >
            Create New Subject
          </Button>
          <Button
            onClick={onDone}
            variant="primaryBlack"
            size="sm"
          >
            Cancel
          </Button>
        </HStack>
        <VStack
          background={"white.800"}
          alignItems={"stretch"}
          height={"100%"}
          overflowY={"scroll"}
          padding={"20px 20px 240px 20px"}
          gap={"20px"}
        >
          <SectionHeader
            title="General"
          />
          <FormControl isInvalid={subjectNameError}>
            <FormLabel>Subject Name (Required)</FormLabel>
            <FormInput
              type="text"
              onChange={(e) => {
                if (!isValidSubjectName(e.target.value)) {
                  setSubjectNameError(true);
                } else {
                  setSubjectNameError(false);
                }
                setName(e.target.value);
              }}
              value={name}
            ></FormInput>
            <FormHelperText>Subject names are case sensitive and alphameric. Other allowed
              characters are - _ . * {">"}</FormHelperText>
            <FormErrorMessage>
              You can only use characters A-Z a-z 0-9 - _ * {">"} .
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <FormTextarea
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
            ></FormTextarea>
          </FormControl>
          <SectionHeader
            title={"Config"}
            description={"Enable bi-directional request/reply usage or allow accessing systems to specify wildcards in their permissions."}
          />
          <VStack
            spacing={"3"}
          >
            <FormControl
              display={"flex"}
              flexDirection={"row-reverse"}
              justifyContent={"flex-end"}
            >
              <FormLabel>Request/reply Service</FormLabel>
              <FormSwitch
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setService(event.target.checked);
                }}
                isChecked={service}
              ></FormSwitch>
            </FormControl>
            <FormControl
              display={"flex"}
              flexDirection={"row-reverse"}
              justifyContent={"flex-end"}
            >
              <FormLabel>Allow Wildcard Access</FormLabel>
              <FormSwitch
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setAllowWildcardAccess(event.target.checked);
                }}
                isChecked={allowWildcardAccess}
              />
            </FormControl>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};
