import { Divider, VStack, StackProps, Text } from "@chakra-ui/react";

export interface SectionHeaderProps extends StackProps {
  title: string;
  description?: string;
}

export const SectionHeader = ({
  title,
  description,
  ...props
}: SectionHeaderProps) => {
  return (
    <VStack
      spacing={"0"}
      alignItems={"stretch"}
      {...props}
    >
      <Text
        variant="labelLarge"
      >
        {title}
      </Text>
      <Divider borderColor={"black.500"} />
      {description && <Text variant="bodySmall" paddingTop={"4px"}>{description}</Text>}
    </VStack>
  );
};
