import { BoxProps, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Access } from "../../services/natty-lite/Models";

export interface AccessCardProps extends BoxProps {
  access?: Access;
  active?: boolean;
}

export const AccessCard = ({ active, access, ...props }: AccessCardProps) => {
  const textColor = active ? "white.900" : "black.900";

  return (
    <VStack
      {...props}
      cursor="pointer"
      flex={"1"}
      alignItems={"stretch"}
      spacing={"4"}
      justifyContent={"space-between"}
      borderRadius="4px"
      minWidth={"200px"}
      padding="20px"
      background={active ? "black.900" : "white.900"}
      backgroundImage={"linear-gradient(180deg,rgba(255,255,255,.1),rgba(255,255,255,0))"}
      shadow={"default.500"}
      transition={"all 100ms ease-in-out"}
      _hover={{
        shadow: 'default.700',
      }}
    >
      {access && (
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
        >
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
          >

            {access.stream && (
              <HStack>
                <Image
                  src={
                    active
                      ? "/images/stream-card-logo-light.svg"
                      : "/images/stream-card-logo.svg"
                  }
                />

                <Text
                  color={textColor}
                  variant="bodySmall"
                >
                  {access.stream}
                </Text>
              </HStack>
            )}
          </VStack>
          <VStack
            alignItems={"stretch"}
            spacing={"2"}
          >
            <Text
              color={textColor}
              variant="titleLarge"
              lineHeight={"1em"}
            >
              {access.brief}
            </Text>
            <Text
              color={textColor}
              variant="bodySmall"
              lineHeight={"1.4em"}
              noOfLines={3}
            >
              {access.description}
            </Text>
          </VStack>
          <VStack
            alignItems={"stretch"}
            spacing={"0"}
            paddingTop={"12px"}
          >
            <Text color={textColor} variant={"labelSmall"}>Public Key</Text>
            {access.userPublicKey && (
              <Text fontSize={"12px"} fontWeight={"500"} lineHeight={"1"} color={active ? "white.600" : "black.600"}>{access.userPublicKey}</Text>
            )}
          </VStack>
          <HStack
            alignItems="flex-start"
            flexWrap={"wrap"}
            spacing={"0"}
          >
            {access.tags.map((tag) => (
              <Text
                variant={"labelSmall"}
                fontWeight={"600"}
                marginRight={"4px"}
                color={active ? "blue.200" : "blue.600"}
              >
                #{tag}
              </Text>
            ))}
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
