import {
  Button,
  Flex,
  Divider,
  Text,
  VStack,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";
import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { UEClient } from "./../../types";
import { Client } from "./client";
import { AuthContext } from "../../providers/authProvider";

interface State {
  primaryColumnSelectedOption: any;
  clients: UEClient[];
  newClientCreating: boolean;
  loadingClients: boolean;
}

export class Clients extends React.Component {
  static contextType = AuthContext;
  private group: string;

  constructor(props: any) {
    super(props);
    this.group = props.group;
  }
  state: State = {
    primaryColumnSelectedOption: null as any,
    clients: [],
    newClientCreating: false,
    loadingClients: true,
  };

  async componentDidMount() {
    this.refreshClients();
  }

  refreshClients = () => {
    const context = this.context;
    context.getClients().then((res: any) => {
      const clients: UEClient[] = res.data.data;
      console.log(clients);
      this.setState({
        ...this.state,
        clients: clients,
        loadingClients: false,
      });
      this.primaryColumnOptionSelected(clients[0]);
    });
  };

  primaryColumnOptionSelected = async (selectedOption: any) => {
    this.setState({
      primaryColumnSelectedOption: selectedOption,
      newClientCreating: false,
    });
  };

  render() {
    return (
      <Flex h="100%" alignItems={"stretch"}>
        {/* Primary Column */}
        <VStack
          layerStyle="windowmenu"
          alignItems="stretch"
          flex="1"
          spacing="0"
        >
          <VStack
            alignItems={"stretch"}
            spacing="0"
          >
            <Text variant="windowheader">
              Clients
            </Text>
            <Button
              variant="primaryBlue"
              size="sm"
              onClick={() => {
                this.setState({
                  ...this.state,
                  newClientCreating: true,
                  primaryColumnSelectedOption: null,
                });
              }}
            >
              Add
            </Button>
            <Divider opacity={".4"} paddingTop={"8px"} />
          </VStack>
          <Skeleton
            overflow={"hidden"}
            overflowY={"scroll"}
            isLoaded={!this.state.loadingClients}
          >
            <VStack
              alignItems={"stretch"}
              spacing={"0"}
              paddingTop={"8px"}
              paddingBottom={"8px"}
            >
              {this.state.newClientCreating && (
                <WindowColumnPrimaryButton
                  key={"newClient"}
                  onClick={() => {
                    //this.primaryColumnOptionSelected(option);
                  }}
                  active={true}
                >
                  New Client
                </WindowColumnPrimaryButton>
              )}
              {this.state.clients
                .sort((a: any, b: any) =>
                  a.client_name > b.client_name ? 1 : -1
                )
                .map((option: any) => {
                  return (
                    <WindowColumnPrimaryButton
                      key={option.client_name}
                      onClick={() => {
                        this.primaryColumnOptionSelected(option);
                      }}
                      active={
                        this.state.primaryColumnSelectedOption?.client_name ===
                        option.client_name
                      }
                    >
                      <Text
                        width={"162px"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {option.client_name}
                      </Text>
                    </WindowColumnPrimaryButton>
                  );
                })}
            </VStack>
          </Skeleton>
        </VStack>

        <VStack
          spacing="4"
          align="left"
          flex="3"
          overflowY={"scroll"}
          bg="white.900"
          borderRadius="0 4px 4px 0"
          border="1px solid"
          borderColor="white.900"
          padding="28px 32px"
        >
          {this.state.primaryColumnSelectedOption && (
            <Client
              key={this.state.primaryColumnSelectedOption.client_id}
              group={this.group}
              client={this.state.primaryColumnSelectedOption}
              result={(client: UEClient) => {
                const index = this.state.clients.findIndex(
                  (p: any) => p.client_id === client.client_id
                );
                console.log(index);
                const newClients = [...this.state.clients];
                newClients[index] = client;
                console.log(newClients[index]);
                this.setState({
                  ...this.state,
                  clients: newClients,
                  primaryColumnSelectedOption: client,
                });
              }}
              clientDeleted={() => {
                this.setState({
                  primaryColumnSelectedOption: null,
                  newClientCreating: false,
                });
                this.refreshClients();
              }}
            />
          )}
          {this.state.newClientCreating && (
            <Client
              key={"newClient"}
              client={null}
              group={this.group}
              result={(client: any) => {
                if (client) {
                  this.setState({
                    ...this.state,
                    clients: [...this.state.clients, client],
                    newClientCreating: false,
                    primaryColumnSelectedOption: client,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    newClientCreating: false,
                  });
                }
              }}
              clientDeleted={() => { }}
            />
          )}
        </VStack>
      </Flex >
    );
  }
}
