import React from "react";
import { Textarea, TextareaProps } from "@chakra-ui/react";

export const FormTextarea = (props: TextareaProps) => {
  return (
    <Textarea
      {...props}
      border={"1px solid"}
      borderColor={"black.400"}
      backgroundColor={"white.900"}
      borderRadius={"4px"}
      fontWeight={"500"}
      padding={"8px 12px"}
    >
      {props.children}
    </Textarea>
  );
};
