import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  FormLabel,
  HStack,
  IconButton,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { FormInput } from "./FormInput";

export interface CustomDomainInputProps {
  id: string;
  domain: string;
  target: string;
  txt: string;
  index: number;
}

export const CustomDomainInput = ({
  id,
  domain,
  target,
  txt,
  index,
}: CustomDomainInputProps) => {
  const CNAME = target === "ui" ? process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_UI : process.env.REACT_APP_CUSTOM_DOMAIN_CNAME_OIDC;
  const { hasCopied: hasCopiedTxt, onCopy: onCopyTxt } = useClipboard(txt);
  const { hasCopied: hasCopiedSubdomainTxt, onCopy: onCopySubdomainTxt } =
    useClipboard(`${id}-${target}.${domain.split(".")[0]}`);
  const { hasCopied: hasCopiedCName, onCopy: onCopyCName } = useClipboard(CNAME || "");
  const { hasCopied: hasCopiedSubdomainCName, onCopy: onCopySubdomainCName } =
    useClipboard(`${domain.split(".")[0]}`);

  return (
    <>
      <HStack key={id}>

        <VStack alignItems={"stretch"} flex={"1"}>
          {index === 0 && <FormLabel>Host Name</FormLabel>}
          <HStack>
            <FormInput
              isReadOnly
              value={`${id}-${target}.${domain.split(".")[0]}`}
            />
            <IconButton
              aria-label="Copy Auth Group ID"
              icon={hasCopiedSubdomainTxt ? <CheckIcon /> : <CopyIcon />}
              onClick={onCopySubdomainTxt}
              size="md"
              variant="primaryBlue"
              marginLeft="8px"
            />
          </HStack>
        </VStack>

        <VStack alignItems={"stretch"} flex={"1"}>
          {index === 0 && <FormLabel>Type</FormLabel>}
          <FormInput isReadOnly value={"TXT"} />
        </VStack>

        <VStack alignItems={"stretch"} flex={"1"}>
          {index === 0 && <FormLabel>Data</FormLabel>}
          <HStack>
            <FormInput
              isReadOnly value={txt}
            />
            <IconButton
              aria-label="Copy Auth Group ID"
              icon={hasCopiedTxt ? <CheckIcon /> : <CopyIcon />}
              onClick={onCopyTxt}
              size="md"
              variant="primaryBlue"
              marginLeft="8px"
            />
          </HStack>
        </VStack>

      </HStack>

      <HStack key={id}>

        <VStack alignItems={"stretch"} flex={"1"}>
          <HStack>
            <FormInput
              isReadOnly
              value={`${domain.split(".")[0]}`}
            />
            <IconButton
              aria-label="Copy Auth Group ID"
              icon={hasCopiedSubdomainCName ? <CheckIcon /> : <CopyIcon />}
              onClick={onCopySubdomainCName}
              size="md"
              variant="primaryBlue"
              marginLeft="8px"
            />
          </HStack>
        </VStack>

        <VStack alignItems={"stretch"} flex={"1"}>
          <FormInput isReadOnly value={"CNAME"} />
        </VStack>

        <VStack alignItems={"stretch"} flex={"1"}>
          <HStack>
            <FormInput
              isReadOnly value={CNAME}
            />
            <IconButton
              aria-label="Copy Auth Group ID"
              icon={hasCopiedCName ? <CheckIcon /> : <CopyIcon />}
              onClick={onCopyCName}
              size="md"
              variant="primaryBlue"
              marginLeft="8px"
            />
          </HStack>
        </VStack>
      </HStack>
    </>
  );
};
