import {
  Text,
  Stack,
  FormControl,
  FormLabel,
  Button,
  HStack,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormSelect } from "../FormSelect";
import {
  ActionMeta,
  AsyncCreatableSelect,
  OnChangeValue,
} from "chakra-react-select";
import { FormInput } from "../FormInput";
import React from "react";

interface NewPermissionsProps {
  productId: string;
  result: any;
}

interface Row {
  action: string;
  description: string;
  ownership: string;
  tags: string[];
  index: number;
}

export const NewPermissions = ({ productId, result }: NewPermissionsProps) => {
  const {
    getPermissionTargets,
    getPermissionActions,
    getPermissionTags,
    createPermissions,
  } = useContext(AuthContext);
  const [target, setTarget] = React.useState(null as any);
  const [rows, setRows] = React.useState([
    { action: "", description: "", ownership: "everyone", tags: [], index: 0 },
  ] as Row[]);
  const [error, setError] = React.useState("");

  const loadOptions = async (type: string, inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      try {
        let res;
        if (type === "targets") {
          res = await getPermissionTargets(productId);
        } else if (type === "actions") {
          res = await getPermissionActions(productId);
        } else if (type === "tags") {
          res = await getPermissionTags(productId);
        }
        const response = res.data.data;
        const filtered = response.values.filter((t: any) =>
          t.toLowerCase().includes(inputValue.toLowerCase())
        );
        const options = filtered.map((target: string) => ({
          value: target,
          label: target,
        }));
        resolve(options);
      } catch (error) {
        console.log(error);
      }
    });

  const loadTargetOptions = async (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      return loadOptions("targets", inputValue).then((options) =>
        resolve(options)
      );
    });

  const loadActionOptions = async (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      return loadOptions("actions", inputValue).then((options) =>
        resolve(options)
      );
    });

  const loadTagOptions = async (inputValue: string) =>
    new Promise<any[]>(async (resolve) => {
      return loadOptions("tags", inputValue).then((options) =>
        resolve(options)
      );
    });

  const handleActionChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    setError("");
    console.log(actionMeta);
    console.group("Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    if (actionMeta.name && newValue) {
      const index = parseInt(actionMeta.name.split("-")[1]);
      const row: Row = rows.filter((r: Row) => r.index === index)[0];
      const v: any = newValue;
      row.action = v.value;
      const newRows = [...rows];
      newRows[index] = row;
      setRows(newRows);
      console.log(rows);
    }
  };

  const handleTargetChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    setError("");
    console.group("Value Changed");
    console.log(newValue);
    setTarget(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleTagsChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group("Value Changed");
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();

    if (actionMeta.name) {
      const index = parseInt(actionMeta.name.split("-")[1]);
      const row: Row = rows.filter((r: Row) => r.index === index)[0];
      const v: any = newValue.map((t: any) => t.value);
      console.log("v", v);
      row.tags = v;
      const newRows = [...rows];
      newRows[index] = row;
      setRows(newRows);
      console.log(rows);
    }
  };

  const savePermissions = async () => {
    try {
      setError("");
      const permissions = rows.map((r: Row) => ({
        target: target.value,
        ownershipRequired: r.ownership === "agent" ? true : false,
        action: r.action,
        description: r.description,
        tags: r.tags,
      }));

      console.log(permissions);
      const res = await createPermissions(productId, permissions);
      result(res.data.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 409) {
        setError("Permission already exists");
      } else {
        setError(error.response?.data?.message);
      }
    }
  };

  return (
    <Stack spacing={"4"} >
      <Text variant="contentheader">Add New Permissions</Text>
      <HStack
        alignItems={"stretch"}
        backgroundColor={"neutral.200"}
        borderRadius={"12px"}
        padding={"6px"}
        gap={"6px"}
        spacing={"0"}
      >
        <HStack
          flex={"1"}
          backgroundColor={"neutral.100"}
          borderRadius={"8px"}
          padding={"12px"}
          alignItems={"end"}
        >
          <FormControl>
            <FormLabel pb={"4px"} fontSize={"md"}>
              For this target resource:
            </FormLabel>
            <AsyncCreatableSelect
              defaultOptions
              isClearable
              loadOptions={loadTargetOptions}
              onChange={handleTargetChange}
              size="md"
              placeholder={"Create a resource..."}
            />
          </FormControl>
        </HStack>
      </HStack>

      <Text variant="contentsubheader">Allow the following:</Text>
      <Text variant="bodySmall">Agents are persons or machines making a request.</Text>
      <VStack
        alignItems={"stretch"}
        backgroundColor={"neutral.200"}
        borderRadius={"12px"}
        padding={"6px"}
      >
        {rows &&
          rows.map((row) => {
            return (
              <VStack
                alignItems={"stretch"}
                flex={"1"}
                backgroundColor={"neutral.100"}
                borderRadius={"8px"}
                padding={"12px"}
                spacing={"4"}
              >
                <HStack spacing={"4"} alignItems={"center"}>
                  <FormControl>
                    <FormLabel pb={"4px"}>Action</FormLabel>
                    <AsyncCreatableSelect
                      defaultOptions
                      isClearable
                      loadOptions={loadActionOptions}
                      onChange={handleActionChange}
                      size="md"
                      name={`actions-${row.index}`}
                      placeholder={"Create or select an action..."}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel pb={"4px"}>Acting agent</FormLabel>
                    <FormSelect
                      onChange={(e) => {
                        row.ownership = e.target.value;
                        const newRows = [...rows];
                        newRows[row.index] = row;
                        setRows(newRows);
                      }}
                      size="md"
                    >
                      <option value="everyone">Any instance of the resource</option>
                      <option value="agent">Resources owned by agent</option>
                    </FormSelect>
                  </FormControl>
                </HStack>
                <Accordion allowToggle>
                  <AccordionItem borderBottom={"none"} paddingTop={"4px"}>
                    <AccordionButton padding={"8px 12px"} borderRadius={"4px"}>
                      <Text variant="labelSmall" textAlign={"left"}>Additional options</Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <HStack width={"100%"} spacing={"4"}>
                        <FormControl flex={"1"}>
                          <FormLabel pb={"4px"}>Description (optional)</FormLabel>
                          <FormInput
                            placeholder=""
                            onChange={(e) => {
                              row.description = e.target.value;
                              const newRows = [...rows];
                              newRows[row.index] = row;
                              setRows(newRows);
                            }}
                            size="md"
                          />
                        </FormControl>
                        <FormControl flex={"1"}>
                          <FormLabel pb={"4px"}>Tags (optional)</FormLabel>
                          <AsyncCreatableSelect
                            isMulti
                            defaultOptions
                            isClearable
                            loadOptions={loadTagOptions}
                            onChange={handleTagsChange}
                            size="md"
                            placeholder={"Create or select a tag..."}
                            name={`tags-${row.index}`}
                          />
                        </FormControl>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
            );
          })}

        <Button
          onClick={() => {
            setRows([
              ...rows,
              {
                index: rows.length,
                action: "",
                description: "",
                ownership: "everyone",
                tags: [],
              },
            ]);
          }}
          variant="primaryBlack"
          borderRadius={"10px"}
        >
          Add another permission
        </Button>
      </VStack>
      <Button
        onClick={savePermissions}
        disabled={rows.map((r: Row) => r.action).includes("") || !target?.value}
        variant="primaryBlue"
        alignSelf={"flex-start"}
        size={"lg"}
      >
        Create Permissions
      </Button>
      {error && <Text color="red">{error}</Text>}
    </Stack>
  );
};
