import {
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormInput } from "../FormInput";

interface NewUserProps {
  result: any;
  org: any;
  newUserEmail?: string;
}

export const NewOrganizationUser = ({
  result,
  org,
  newUserEmail,
}: NewUserProps) => {
  const { createOrganizationUser, getOrganizationUser } =
    useContext(AuthContext);
  interface FormValues {
    email: string;
    externalId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    personalHeader: string;
    bio: string;
    picture: string;
    addressStreet1: string;
    addressStreet2: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    addressCountry: string;
    employer: string;
    title: string;
    position: string;
  }

  const initialValues: FormValues = {
    email: newUserEmail ? newUserEmail : "",
    externalId: "",
    firstName: "",
    lastName: "",
    displayName: "",
    personalHeader: "",
    bio: "",
    picture: "",
    addressStreet1: "",
    addressStreet2: "",
    addressCity: "",
    addressState: "",
    addressZip: "",
    addressCountry: "",
    employer: "",
    title: "",
    position: "",
  };

  return (
    <VStack
      alignItems={"stretch"}
      spacing={"0"}
      gap={"20px"}
    >
      <Text variant={"contentheader"}>Create new user</Text>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          let errors: FormikErrors<FormValues> = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log("values: ", values);

          try {
            setSubmitting(true);
            const userBody = {
              email: values.email,
              profile: {
                externalId: values.externalId,
                givenName: values.firstName,
                familyName: values.lastName,
                displayName: values.displayName,
                personalHeader: values.personalHeader,
                bio: values.bio,
                picture: values.picture,
                address: {
                  street1: values.addressStreet1,
                  street2: values.addressStreet2,
                  city: values.addressCity,
                  state: values.addressState,
                  postalCode: values.addressZip,
                  country: values.addressCountry,
                },
                employer: values.employer,
                title: values.title,
                position: values.position,
              },
            };

            const userResult: any = await createOrganizationUser(org.id, userBody);
            const newUserId: any = userResult.data.data.id;

            const newUser: any = await getOrganizationUser(org.id, newUserId);

            result(newUser.data.data);
          } catch (error) {
            console.error(error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Form>
            <VStack
              spacing={"0"}
              gap={"20px"}
              alignItems={"stretch"}
            >
              <Field name="email">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <FormInput
                      {...field}
                      id="email"
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="externalId">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="externalId">External ID</FormLabel>
                    <FormInput
                      {...field}
                      id="externalId"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="firstName">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.firstName && form.touched.firstName}
                  >
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <FormInput
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="lastName">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.lastName && form.touched.lastName}
                  >
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <FormInput
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="displayName">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="displayName">Display Name</FormLabel>
                    <FormInput
                      {...field}
                      id="displayName"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="personalHeader">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="personalHeader">
                      Personal Header
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="personalHeader"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="bio">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="bio">Bio</FormLabel>
                    <FormInput {...field} id="bio" />
                  </FormControl>
                )}
              </Field>

              <Field name="picture">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="picture">Picture URL</FormLabel>
                    <FormInput {...field} id="picture" placeholder="e.g. http://www.yoursite.com/image.jpg" />
                  </FormControl>
                )}
              </Field>

              <Field name="addressStreet1">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressStreet1">
                      Street Address
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="addressStreet1"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="addressStreet2">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressStreet2">
                      Street Address 2
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="addressStreet2"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="addressCity">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressCity">City</FormLabel>
                    <FormInput {...field} id="addressCity" />
                  </FormControl>
                )}
              </Field>

              <Field name="addressState">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressState">State</FormLabel>
                    <FormInput
                      {...field}
                      id="addressState"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="addressZip">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
                    <FormInput
                      {...field}
                      id="addressZip"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="addressCountry">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="addressCountry">Country</FormLabel>
                    <FormInput
                      {...field}
                      id="addressCountry"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="employer">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="employer">Employer</FormLabel>
                    <FormInput
                      {...field}
                      id="employer"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="title">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="title">Title</FormLabel>
                    <FormInput {...field} id="title" />
                  </FormControl>
                )}
              </Field>

              <Field name="position">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="position">Position</FormLabel>
                    <FormInput
                      {...field}
                      id="position"
                    />
                  </FormControl>
                )}
              </Field>

              <Button
                variant={"primaryBlue"}
                type="submit"
                alignSelf={"flex-start"}
                disabled={props.isSubmitting}
              >
                Create user
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
