import { VStack, useCheckbox } from "@chakra-ui/react";

export const CheckboxCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <VStack
      as="label"
      spacing="0"
      alignItems={"stretch"}
    >
      <input {...input} hidden />
      <VStack
        alignItems={"stretch"}
        padding={"12px"}
        spacing={"0"}
        {...checkbox}
        cursor="pointer"
        borderRadius="8px"
        color="neutral.700"
        backgroundColor={"neutral.100"}
        transition={"all 50ms ease-in"}
        border={"1px solid"}
        borderColor={"neutral.300"}
        _checked={{
          backgroundColor: "blue.500",
          border: "1px solid",
          borderColor: "blue.800",
          color: "neutral.100",
        }}
        _hover={{
          borderColor: "neutral.400",
          // backgroundColor: "neutral.300",
        }}
      >
        {props.children}
      </VStack>
    </VStack>
  );
};
