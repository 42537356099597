import { ViewIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik, Form, Field, FormikErrors, FieldArray } from "formik";
import { JsonPointer } from "json-ptr";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormInput } from "../FormInput";
import { FormSelect } from "../FormSelect";

export enum NewCustomProviderParentType {
  AuthGroup,
  Organization,
}

interface NewCustomProviderProps {
  parentType: NewCustomProviderParentType;
  parentId: string;
  result: any;
  title: string;
}

const CLIENT_RESPONSE_TYPES = [
  "code", // authorization code
  "id_token", // implicit
  "token id_token", // hybrid
  "code id_token", // hybrid
  "code token", // hybrid
  "code id_token token", // hybrid
];

const CLIENT_GRANT_TYPES = ["authorization_code", "implicit", "refresh_token"];

export const NewCustomProvider = ({
  parentType,
  parentId,
  result,
  title,
}: NewCustomProviderProps) => {
  const { updateAuthGroup, updateOrganization, getOrganization } =
    useContext(AuthContext);
  const [newScope, setNewScope] = useState<string>("");
  const [showClientSecret, setShowClientSecret] = useState<boolean>(false);
  const [organization, setOrganization] = useState<any>();
  const [submitMessage, setSubmitMessage] = useState<string>("");
  const [ssoOnly, setSsoOnly] = useState<boolean>(false);
  const [ssoAddAccountToOrg, setSsoAddAccountToOrg] = useState<boolean>(false);
  const [ssoEmailDomain, setSsoEmailDomain] = useState<string>("");

  interface FormValues {
    provider: string;
    type: string;
    name: string;
    buttonType: string;
    buttonText: string;
    PKCE: boolean;
    client_id: string;
    client_secret: string;
    response_type: string;
    grant_type: string;
    scopes: string[];
    accessTokenUri: string;
    authorizationUri: string;
    profileUri: string;
    discovery_url: string;
  }

  const INITIAL_VALUES: FormValues = {
    provider: "custom",
    type: "oidc",
    name: "",
    buttonType: "standard",
    buttonText: "",
    PKCE: false,
    client_id: "",
    client_secret: "",
    response_type: "code",
    grant_type: "authorization_code",
    scopes: ["email"],
    accessTokenUri: "",
    authorizationUri: "",
    profileUri: "",
    discovery_url: "",
  };
  const [initialValues, setInitialValues] = useState<any>(INITIAL_VALUES);

  useEffect(() => {
    if (parentType === NewCustomProviderParentType.Organization) {
      getOrganization(parentId)
        .then((res: any) => {
          const org = res.data.data;
          setOrganization(org);
          setSsoOnly(org.ssoLimit);
          setSsoAddAccountToOrg(org.ssoAddAccountToOrg);
          setSsoEmailDomain(org.ssoEmailDomain);
          if (res.data.data.sso) {
            let type: string, config;
            if (res.data.data.sso.oidc) {
              type = "oidc";
              config = res.data.data.sso.oidc;
            } else if (res.data.data.sso.oauth2) {
              type = "oauth2";
              config = res.data.data.sso.oauth2;
            } else {
              const error = new Error("Invalid provider type");
              throw error;
            }
            const orgValues: FormValues = {
              provider: "custom",
              type: type,
              name: config.name,
              buttonType: config.buttonType,
              buttonText: config.buttonText,
              PKCE: config.PKCE,
              client_id: config.client_id,
              client_secret: config.client_secret,
              response_type: config.response_type,
              grant_type: config.grant_type,
              scopes: config.scopes,
              accessTokenUri: config.accessTokenUri,
              authorizationUri: config.authorizationUri,
              profileUri: config.profileUri,
              discovery_url: config.discovery_url,
            };
            setInitialValues(orgValues);
          }
        })
        .catch((err: any) => console.error(err));
    }
  }, [getOrganization, parentId, parentType]);

  const updateOrg = async (key: string, value: any) => {
    try {
      const exists = JsonPointer.get(organization, key);
      const op = exists ? "replace" : "add";

      const updatedOrg = await updateOrganization(parentId, op, key, value);
      setOrganization(updatedOrg.data.data);
      result(updatedOrg.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth={"100%"} padding={"0"}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) => {
          let errors: FormikErrors<FormValues> = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.buttonText) {
            errors.buttonText = "Required";
          }
          if (!values.client_id) {
            errors.client_id = "Required";
          }
          if (values.PKCE === false && !values.client_secret) {
            errors.client_secret = "Required when PKCE is disabled";
          }
          if (values.type === "oidc" && !values.discovery_url) {
            errors.discovery_url = "Required";
          }
          if (values.type === "oauth2" && !values.accessTokenUri) {
            errors.accessTokenUri = "Required";
          }
          if (values.type === "oauth2" && !values.authorizationUri) {
            errors.authorizationUri = "Required";
          }
          if (values.type === "oauth2" && !values.profileUri) {
            errors.profileUri = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log("values: ", values);
          setSubmitting(true);

          try {
            let value: any;
            if (values.type === "oauth2") {
              value = {
                provider: "custom",
                buttonType: "standard",
                name: values.name,
                buttonText: values.buttonText,
                PKCE: values.PKCE,
                client_id: values.client_id,
                client_secret: values.client_secret,
                response_type: "code",
                grant_type: "authorization_code",
                scopes: values.scopes,
                accessTokenUri: values.accessTokenUri,
                authorizationUri: values.authorizationUri,
                profileUri: values.profileUri,
              };
            } else if (values.type === "oidc") {
              value = {
                provider: "custom",
                buttonType: "standard",
                name: values.name,
                buttonText: values.buttonText,
                PKCE: values.PKCE,
                client_id: values.client_id,
                client_secret: values.client_secret,
                response_type: "code",
                grant_type: "authorization_code",
                discovery_url: values.discovery_url,
                scopes: values.scopes,
              };
            }

            if (parentType === NewCustomProviderParentType.AuthGroup) {
              const path = `/config/federate/${values.type}/-`;
              const res = await updateAuthGroup(parentId, "add", path, value);
              result(res.data.data);
            } else if (
              parentType === NewCustomProviderParentType.Organization
            ) {
              const path = `/sso`;

              //strip provider
              if ("provider" in value) {
                delete value.provider;
              }

              let wrappedValue;
              if (values.type === "oauth2") {
                wrappedValue = { oauth2: value };
              } else if (values.type === "oidc") {
                wrappedValue = { oidc: value };
              } else {
                const error = new Error("Invalid provider type");
                throw error;
              }

              const exists = JsonPointer.get(organization, path);
              const op = exists ? "replace" : "add";

              const updatedOrg = await updateOrganization(
                parentId,
                op,
                path,
                wrappedValue
              );
              result(updatedOrg.data.data);
              setSubmitMessage("Saved successfully");
            }
          } catch (error) {
            console.error(error);
          }

          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <VStack
              alignItems={"stretch"}
              spacing={"4"}
            >
              <Text variant="contentheader">{title}</Text>
              {parentType === NewCustomProviderParentType.Organization && (
                <>
                  <Text variant="contentsubheader">Security</Text>
                  <FormControl
                    display="flex"
                    flexDirection="row-reverse"
                    justifyContent="flex-end"
                    alignItems="stretch"
                    gap={"8px"}
                  >
                    <FormLabel htmlFor="ssoOnly">SSO Only</FormLabel>
                    <Switch
                      isChecked={ssoOnly}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        updateOrg("/ssoLimit", e.target.checked);
                        setSsoOnly(e.target.checked);
                      }}
                      id="ssoOnly"
                      placeholder=""
                    />
                  </FormControl>

                  <FormControl
                  >
                    <HStack
                      display="flex"
                      flexDirection="row-reverse"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      gap={"8px"}
                      spacing={"0"}
                    >
                      <FormLabel htmlFor="ssoAddAccountToOrg">
                        Automatically add users to Organization
                      </FormLabel>
                      <Switch
                        isChecked={ssoAddAccountToOrg}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          updateOrg("/ssoAddAccountToOrg", e.target.checked);
                          setSsoAddAccountToOrg(e.target.checked);
                        }}
                        id="ssoAddAccountToOrg"
                        placeholder=""
                      />
                    </HStack>
                    <FormHelperText>
                      This should be used in combination with a specific email
                      domain only!
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="ssoEmailDomain">
                      SSO Email Domain
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="ssoEmailDomain"
                        placeholder="example.com"
                        type="text"
                        autoComplete="off"
                        value={ssoEmailDomain}
                        onChange={(e) => {
                          setSsoEmailDomain(e.target.value);
                        }}
                        onBlur={() => {
                          updateOrg("/ssoEmailDomain", ssoEmailDomain);
                        }}
                      />
                    </HStack>
                  </FormControl>
                  <Text variant="contentsubheader">Configuration</Text>
                </>
              )}
              <Field name="type">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel>Type</FormLabel>
                    <FormSelect {...field} id="type">
                      <option value="oidc">OIDC</option>
                      <option value="oauth2">OAuth2</option>
                    </FormSelect>
                    <FormErrorMessage>
                      {form.errors.organization}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="name">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel htmlFor="email">
                      Name
                    </FormLabel>
                    <FormInput {...field} id="name" placeholder="Name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="buttonText">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.buttonText && form.touched.buttonText
                    }
                  >
                    <FormLabel htmlFor="buttonText">
                      Button Text
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="buttonText"
                      placeholder="Button Text"
                    />
                    <FormErrorMessage>
                      {form.errors.buttonText}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="PKCE">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.PKCE && form.touched.PKCE}
                  >
                    <HStack
                      display="flex"
                      flexDirection="row-reverse"
                      justifyContent="flex-end"
                      alignItems="stretch"
                      gap={"8px"}
                      spacing={"0"}
                    >
                      <FormLabel htmlFor="PKCE">
                        PKCE
                      </FormLabel>
                      <Switch
                        {...field}
                        isChecked={field.value}
                        onChange={(event: any) => {
                          props.setFieldValue("PKCE", event.target.checked);
                        }}
                        id="PKCE"
                        placeholder=""
                      />
                    </HStack>

                    <FormErrorMessage>{form.errors.PKCE}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="client_id">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.client_id && form.touched.client_id}
                  >
                    <FormLabel htmlFor="client_id">
                      Client ID
                    </FormLabel>
                    <FormInput
                      {...field}
                      id="client_id"
                      placeholder="Client ID"
                    />
                    <FormErrorMessage>{form.errors.client_id}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="client_secret">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.client_secret && form.touched.client_secret
                    }
                  >
                    <FormLabel htmlFor="client_secret">
                      Client Secret
                    </FormLabel>
                    <HStack>
                      <FormInput
                        {...field}
                        id="client_secret"
                        placeholder="Client Secret"
                        type={showClientSecret ? "text" : "password"}
                      />
                      <IconButton
                        aria-label="Show Privakey Secret"
                        icon={<ViewIcon />}
                        onClick={() => {
                          setShowClientSecret(!showClientSecret);
                        }}
                        variant="primaryBlack"
                        size="lg"
                      />
                    </HStack>
                    <FormErrorMessage>
                      {form.errors.client_secret}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="response_type">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel>Response Type</FormLabel>
                    <FormSelect {...field} id="response_type">
                      {CLIENT_RESPONSE_TYPES &&
                        CLIENT_RESPONSE_TYPES.map((response_type, index) => {
                          return (
                            <option key={index} value={response_type}>
                              {response_type}
                            </option>
                          );
                        })}
                    </FormSelect>
                    <FormErrorMessage>
                      {form.errors.organization}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="grant_type">
                {({ field, form }: any) => (
                  <FormControl>
                    <FormLabel>Grant Type</FormLabel>
                    <FormSelect {...field} id="grant_type">
                      {CLIENT_GRANT_TYPES &&
                        CLIENT_GRANT_TYPES.map((grant_type, index) => {
                          return (
                            <option key={index} value={grant_type}>
                              {grant_type}
                            </option>
                          );
                        })}
                    </FormSelect>
                    <FormErrorMessage>
                      {form.errors.organization}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <FieldArray
                name="scopes"
                render={(arrayHelpers: any) => (
                  <FormControl>
                    <FormLabel htmlFor="scopes">
                      Scopes
                    </FormLabel>
                    <HStack>
                      <FormInput
                        id="newScope"
                        placeholder=""
                        value={newScope}
                        onChange={(e) => {
                          setNewScope(e.target.value);
                        }}
                      />

                      <Button
                        variant="primaryBlue"
                        size="lg"
                        onClick={() => {
                          arrayHelpers.push(newScope);
                          setNewScope("");
                        }}
                      >
                        Add
                      </Button>
                    </HStack>
                    {props.values.scopes &&
                      props.values.scopes.map((scope: any, index: any) => (
                        <Tag
                          key={index}
                          size={"lg"}
                          borderRadius="20px"
                          bgColor="blue.600"
                          color="white.900"
                          fontSize="14px"
                          m="8px 4px"
                        >
                          <TagLabel>{scope}</TagLabel>
                          <Button
                            variant="unstyled"
                            size="sm"
                            minWidth="auto"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <TagCloseButton />
                          </Button>
                        </Tag>
                      ))}
                  </FormControl>
                )}
              />
              {props.values.type === "oidc" && (
                <Field name="discovery_url">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.discovery_url && form.touched.discovery_url
                      }
                    >
                      <FormLabel htmlFor="discovery_url">
                        Discovery URI
                      </FormLabel>
                      <FormInput {...field} id="discovery_url" placeholder="" />
                      <FormErrorMessage>
                        {form.errors.discovery_url}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              {props.values.type === "oauth2" && (
                <>
                  <Field name="accessTokenUri">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.accessTokenUri &&
                          form.touched.accessTokenUri
                        }
                      >
                        <FormLabel
                          htmlFor="accessTokenUri"
                        >
                          Access Token URI
                        </FormLabel>
                        <FormInput
                          {...field}
                          id="accessTokenUri"
                          placeholder=""
                        />
                        <FormErrorMessage>
                          {form.errors.accessTokenUri}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="authorizationUri">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.authorizationUri &&
                          form.touched.authorizationUri
                        }
                      >
                        <FormLabel
                          htmlFor="authorizationUri"
                        >
                          Authorization URI
                        </FormLabel>
                        <FormInput
                          {...field}
                          id="authorizationUri"
                          placeholder=""
                        />
                        <FormErrorMessage>
                          {form.errors.authorizationUri}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="profileUri">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.profileUri && form.touched.profileUri
                        }
                      >
                        <FormLabel htmlFor="profileUri">
                          Profile URI
                        </FormLabel>
                        <FormInput {...field} id="profileUri" placeholder="" />
                        <FormErrorMessage>
                          {form.errors.profileUri}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </>
              )}

              <HStack>
                <Button
                  variant="primaryBlue"
                  size="lg"
                  type="submit"
                  isLoading={props.isSubmitting}
                  loadingText="Submitting"
                >
                  Submit
                </Button>
                {submitMessage && <Text fontSize={14}>{submitMessage}</Text>}
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
