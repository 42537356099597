import {
  Button,
  HStack,
  Stack,
  Text,
  Image,
  FormLabel,
  useToast,
  IconButton,
  useClipboard,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  FormControl,
  VStack,
  ModalHeader,
} from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import {
  Access,
  AccessConsumer,
  AckPolicy,
  DeliverPolicy,
  NewConsumer,
  ReplayPolicy,
  TempJwt,
} from "../../services/natty-lite/Models";
import { NattyLightService } from "../../services/natty-lite/NattyLiteService";
import dayjs from "dayjs";
import { FormTextarea } from "../FormTextArea";
import { CopyIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { FormInput } from "../FormInput";
import { FormSelect } from "../FormSelect";
import { SectionHeader } from "./SectionHeader";

export interface ViewAccessProps {
  client: NattyLightService;
  id: string;
  onDone: () => void;
}

export const ViewAccess = ({ client, id, onDone }: ViewAccessProps) => {
  const toast = useToast();
  const [access, setAccess] = React.useState<Access>();
  const [userSeed, setUserSeed] = React.useState<string>();
  const [clientSecret, setClientSecret] = React.useState<string>();
  const [tempJwt, setTempJwt] = React.useState<TempJwt>();
  const [consumers, setConsumers] = React.useState<AccessConsumer[] | string[]>(
    []
  );
  const { onCopy: onJWTCopy } = useClipboard(tempJwt ? tempJwt.jwt : "");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeConsumer, setActiveConsumer] = React.useState<AccessConsumer>();
  const [activeReservedConsumer, setActiveReservedConsumer] =
    React.useState<string>();
  const [creatingNewConsumer, setCreatingNewConsumer] =
    React.useState<boolean>(false);

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [deliver_policy, setDeliverPolicy] = React.useState<DeliverPolicy>(
    DeliverPolicy.All
  );
  const [opt_start_seq, setOptStartSeq] = React.useState<string>("");
  const [opt_start_time, setOptStartTime] = React.useState<string>("");
  const [ack_policy, setAckPolicy] = React.useState<AckPolicy>(AckPolicy.None);
  const [max_deliver, setMaxDeliver] = React.useState<string>("");
  const [max_ack_pending, setMaxAckPending] = React.useState<string>("");
  const [max_waiting, setMaxWaiting] = React.useState<string>("");
  const [ack_wait, setAckWait] = React.useState<string>("");
  const [filter_subject, setFilterSubject] = React.useState<string>("");
  const [replay_policy, setReplayPolicy] = React.useState<ReplayPolicy>(
    ReplayPolicy.Instant
  );
  const [sample_freq, setSampleFreq] = React.useState<string>("");
  const [rate_limit_bps, setRateLimitBps] = React.useState<string>("");
  const [deliver_group, setDeliverGroup] = React.useState<string>("");
  const { onCopy: onPublicKeyCopy } = useClipboard(
    access ? access.userPublicKey : ""
  );
  const { onCopy: onInboxCopy } = useClipboard(
    access ? access.responseInboxPrefix.join(",") : ""
  );
  const { onCopy: onClientIdCopy } = useClipboard(
    access ? access.coreClientId : ""
  );
  const { onCopy: onSeedCopy } = useClipboard(userSeed ? userSeed : "");
  const { onCopy: onClientSecretCopy } = useClipboard(
    clientSecret ? clientSecret : ""
  );

  const getConsumers = useCallback(() => {
    client.getAccess(id).then((access: Access) => {
      setAccess(access);

      if (access.stream) {
        client.getConsumers(id).then((res) => {
          if (access.streamConsumers.potentialPoolNames.length > 0) {
            setConsumers([
              ...res,
              ...access.streamConsumers.potentialPoolNames,
            ]);
          } else {
            setConsumers(res);
          }

          setCreatingNewConsumer(false);
          if (res.length > 0) {
            setActiveConsumer(res[0]);
          } else {
            setActiveConsumer(undefined);
          }
        });
      }
    });
  }, [client, id]);

  useEffect(() => {
    setUserSeed("");
    setClientSecret("");
    setTempJwt(undefined);
    getConsumers();
  }, [getConsumers]);

  const onDeactivateClicked = async () => {
    try {
      await client.runAccessOperation(id, "deactivate").then((res) => {
        client.getAccess(id).then((res) => {
          setAccess(res);
        });
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onActivateClicked = async () => {
    try {
      await client.runAccessOperation(id, "reactivate").then((res) => {
        client.getAccess(id).then((res) => {
          setAccess(res);
        });
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onDeleteClicked = async () => {
    try {
      await client.deleteAccess(id).then((res) => {
        onDone();
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onShowSeedClicked = async () => {
    try {
      await client.runAccessOperation(id, "show-seed").then((res) => {
        setUserSeed(res.userSeed);
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onRotateKeysClicked = async () => {
    try {
      await client.runAccessOperation(id, "rotate-user-keys").then((res) => {
        console.log("rotate-keys: ", res);
        setAccess(res);
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onNewClientSecretClicked = async () => {
    try {
      await client
        .runAccessOperation(id, "rotate-core-client-secret")
        .then((res) => {
          setClientSecret(res.coreClientSecret);
        });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onGenerateSampleJWTClicked = async () => {
    if (access) {
      try {
        await client
          .requestNewJWT(
            access.userPublicKey,
            access.coreClientId,
            3600
          )
          .then((res) => {
            setTempJwt(res);
          });
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const onNewConsumerSaveClicked = async () => {
    try {
      const newConsumer: NewConsumer = {
        durable_name: name,
        description: description,
      };

      if (description) {
        newConsumer.description = description;
      }

      if (deliver_policy) {
        newConsumer.deliver_policy = deliver_policy;
      }

      if (opt_start_seq) {
        newConsumer.opt_start_seq = parseInt(opt_start_seq);
      }

      if (opt_start_time) {
        newConsumer.opt_start_time = opt_start_time;
      }

      if (ack_policy) {
        newConsumer.ack_policy = ack_policy;
      }

      if (max_deliver) {
        newConsumer.max_deliver = parseInt(max_deliver);
      }

      if (max_ack_pending) {
        newConsumer.max_ack_pending = parseInt(max_ack_pending);
      }

      if (max_waiting) {
        newConsumer.max_waiting = parseInt(max_waiting);
      }

      if (ack_wait) {
        newConsumer.ack_wait = parseInt(ack_wait);
      }

      if (filter_subject) {
        newConsumer.filter_subject = filter_subject;
      }

      if (replay_policy) {
        newConsumer.replay_policy = replay_policy;
      }

      if (sample_freq) {
        newConsumer.sample_freq = sample_freq;
      }

      if (rate_limit_bps) {
        newConsumer.rate_limit_bps = parseInt(rate_limit_bps);
      }

      if (deliver_group) {
        newConsumer.deliver_group = deliver_group;
      }

      await client.createConsumer(id, newConsumer).then(() => {
        client.getConsumers(id).then((res) => {
          setConsumers(res);
          setName("");
          setDescription("");
        });
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const onNewConsumerClicked = async () => {
    setCreatingNewConsumer(true);
    setActiveConsumer(undefined);
    setActiveReservedConsumer(undefined);
  };

  const onConsumerClicked = async (consumer: AccessConsumer) => {
    setCreatingNewConsumer(false);
    setActiveConsumer(consumer);
    setActiveReservedConsumer(undefined);
  };

  const onReservedConsumerClicked = async (consumer: string) => {
    setCreatingNewConsumer(false);
    setActiveReservedConsumer(consumer);
    setActiveConsumer(undefined);
  };

  const onDeleteConsumerClicked = async (consumerName: string) => {
    try {
      await client.deleteConsumer(id, consumerName).then(() => {
        client.getConsumers(id).then((res) => {
          getConsumers();
        });
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  const showErrorToast = useCallback(
    (error) => {
      let desc = error.response ? error.response.data.message : error.message;
      if (error.response && error.response.status === 409) {
        desc = "An access object with this name already exists";
      }
      toast({
        title: `Sorry, we've encounter an error. Please try again!`,
        description: desc,
        status: "error",
        isClosable: true,
      });
    },
    [toast]
  );

  return (
    <VStack
      width={"full"}
      alignItems={"stretch"}
      overflow={"hidden"}
      minWidth={"400px"}
    >
      {access && (
        <VStack alignItems="stretch" height={"100%"} spacing={"0"}>
          <VStack
            alignItems={"stretch"}
            padding={"20px 20px 0 20px"}
            background={"white.900"}
            spacing={"0"}
          >
            <Text variant="labelMedium" lineHeight={"1em"}>
              Access
            </Text>
            <Text variant="titleLarge" lineHeight={"1.2em"}>
              {access.brief}
            </Text>
            {access.stream && (
              <HStack>
                <Image src={"/images/stream-card-logo.svg"} />
                <Text variant="bodySmall">{access.stream}</Text>
              </HStack>
            )}
          </VStack>
          <HStack
            padding={"12px 20px 12px 20px"}
            background={"white.900"}
            borderBottom={"1px solid"}
            borderBottomColor={"black.300"}
          >
            {access.stream && (
              <Button size="sm" variant="primaryBlue" onClick={onOpen}>
                Consumers
              </Button>
            )}
            {access.active && (
              <Button
                size="sm"
                variant="primaryBlack"
                onClick={onDeactivateClicked}
              >
                Deactivate
              </Button>
            )}
            {!access.active && (
              <Button
                size="sm"
                variant="primaryBlack"
                onClick={onActivateClicked}
              >
                Activate
              </Button>
            )}
            <Button size="sm" variant="primaryBlack" onClick={onDeleteClicked}>
              Delete...
            </Button>
          </HStack>

          <VStack
            alignItems={"stretch"}
            overflowY={"scroll"}
            padding={"20px 20px 40px 20px"}
            gap={"12px"}
            background={"white.800"}
          >
            {access.description && (
              <Text variant="bodySmall">{access.description}</Text>
            )}

            <HStack spacing={"1"}>
              <Text variant="labelSmall">Created</Text>
              <Text variant="bodySmall" lineHeight={"initial"}>
                {dayjs(access.createdAt).format("MMM DD YYYY h:mm a")}
              </Text>
            </HStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text variant="labelSmall">Publish Access</Text>

              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {access.requestedPubAccess.length > 0
                  ? access.requestedPubAccess.join(", ")
                  : "No Publish Access"}
              </Text>
            </VStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text variant="labelSmall">Subscribe Access</Text>

              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {access.requestedSubAccess.length > 0
                  ? access.requestedSubAccess.join(", ")
                  : "No Subscribe Access"}
              </Text>
            </VStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">Public Key</Text>
                <IconButton
                  aria-label="Copy Public Key"
                  icon={<CopyIcon />}
                  onClick={onPublicKeyCopy}
                  variant="copyButton"
                  size="xs"
                />
              </HStack>
              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {access.userPublicKey}
              </Text>
            </VStack>

            {access.responseInboxPrefix &&
              access.responseInboxPrefix.length > 0 && (
                <VStack alignItems={"stretch"} spacing={"0"}>
                  <HStack spacing={"1"}>
                    <Text variant="labelSmall">Custom Inboxes</Text>
                    <IconButton
                      aria-label="Copy Inbox Prefixes"
                      icon={<CopyIcon />}
                      onClick={onInboxCopy}
                      variant="copyButton"
                      size="xs"
                    />
                  </HStack>
                  <Text
                    variant="bodySmall"
                    cursor={"default"}
                    userSelect={"all"}
                  >
                    {access.responseInboxPrefix.join(", ")}
                  </Text>
                </VStack>
              )}

            <VStack alignItems={"stretch"} spacing={"2"}>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <Text variant="labelSmall">Secret Seed</Text>
                <Text variant="bodySmall">
                  Your secret seed is required to authenticate with your client
                  library.
                </Text>
              </VStack>
              <VStack
                alignItems={"stretch"}
                spacing={"0"}
                background={"white.800"}
                border={"1px solid"}
                borderColor={"black.300"}
                borderRadius={"4px"}
                padding={"8px 12px"}
              >
                <HStack spacing={"1"}>
                  <Button
                    variant={"unstyledBlack"}
                    size={"xs"}
                    onClick={onShowSeedClicked}
                  >
                    Show Seed
                  </Button>
                  {userSeed && (
                    <IconButton
                      aria-label="Copy Seed"
                      icon={<CopyIcon />}
                      onClick={onSeedCopy}
                      variant="copyButton"
                      size="xs"
                    />
                  )}
                </HStack>
                {userSeed && (
                  <Text
                    variant="bodySmall"
                    cursor={"default"}
                    userSelect={"all"}
                  >
                    {userSeed}
                  </Text>
                )}
              </VStack>
            </VStack>

            <VStack
              alignItems={"flex-start"}
              background="black.900"
              borderRadius="4px"
              padding="16px"
            >
              <HStack alignItems={"flex-start"} spacing={"4"}>
                <WarningTwoIcon color={"white.900"} boxSize={"5"} />
                <VStack alignItems={"flex-start"} spacing={"3"}>
                  <VStack alignItems={"flex-start"} spacing={"0"}>
                    <Text
                      variant="labelMedium"
                      lineHeight={"1em"}
                      color="white.900"
                    >
                      Rotate Keys
                    </Text>
                    <Text variant="bodySmall" color="white.900">
                      If you rotate keys, you will have to update all clients
                      using this access.
                    </Text>
                  </VStack>
                  <Button
                    variant={"primaryBlue"}
                    size={"sm"}
                    onClick={onRotateKeysClicked}
                  >
                    Rotate Keys
                  </Button>
                </VStack>
              </HStack>
            </VStack>

            <VStack alignItems={"stretch"} spacing={"0"}>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">Client ID</Text>
                <IconButton
                  aria-label="Copy Client ID"
                  icon={<CopyIcon />}
                  onClick={onClientIdCopy}
                  variant="copyButton"
                  size="xs"
                />
              </HStack>
              <Text variant="bodySmall">
                You'll need this ID with the client secret you recieved when you
                created this access to programatically request a new access JWT.
              </Text>
              <Text
                variant="bodySmall"
                paddingTop={"8px"}
                cursor={"default"}
                userSelect={"all"}
              >
                {access.coreClientId}
              </Text>
            </VStack>

            <VStack alignItems={"align-start"} spacing={"2"}>
              <VStack alignItems={"align-start"} spacing={"0"}>
                <Text variant={"labelSmall"}>Client Secret</Text>
                <Text variant="bodySmall">
                  We will never show you your client secret. If you've forgotten
                  it, click the action below to generate it.
                </Text>
              </VStack>
              <VStack
                alignItems={"stretch"}
                spacing={"0"}
                background={"white.800"}
                border={"1px solid"}
                borderColor={"black.300"}
                borderRadius={"4px"}
                padding={"8px 12px"}
              >
                <HStack spacing={"1"}>
                  <Button
                    variant={"unstyledBlack"}
                    size={"xs"}
                    onClick={onNewClientSecretClicked}
                  >
                    Generate a new client secret
                  </Button>
                  {clientSecret && (
                    <IconButton
                      aria-label="Copy Seed"
                      icon={<CopyIcon />}
                      onClick={onClientSecretCopy}
                      variant="copyButton"
                      size="xs"
                    />
                  )}
                </HStack>
                {clientSecret && (
                  <Text variant="bodySmall">{clientSecret}</Text>
                )}
              </VStack>
            </VStack>

            <VStack alignItems={"align-start"} spacing={"2"}>
              <VStack alignItems={"align-start"} spacing={"0"}>
                <Text variant="labelSmall">Temporary Access JWT</Text>
                <Text variant="bodySmall">
                  Generate a temporary access JWT to test your access.
                </Text>
              </VStack>
              <VStack
                alignItems={"flex-start"}
                spacing={"0"}
                background={"white.800"}
                border={"1px solid"}
                borderColor={"black.300"}
                borderRadius={"4px"}
                padding={"8px 12px"}
              >
                <Button
                  variant={"unstyledBlack"}
                  size={"xs"}
                  onClick={onGenerateSampleJWTClicked}
                >
                  Generate a temporary access JWT
                </Button>
                {tempJwt && (
                  <VStack alignItems={"align-start"} spacing={"2"}>
                    <HStack spacing={"1"}>
                      <Text variant="labelSmall">JWT</Text>
                      <IconButton
                        aria-label="Copy Access JWT"
                        icon={<CopyIcon />}
                        onClick={onJWTCopy}
                        variant="copyButton"
                        size="xs"
                      />
                    </HStack>
                    <Text variant="bodySmall">
                      The following access JWT will expire at{" "}
                      {dayjs(tempJwt.exp * 1000).format("MMM DD YYYY hh:mm a")}.
                    </Text>
                    <FormTextarea isReadOnly value={tempJwt.jwt}></FormTextarea>
                  </VStack>
                )}
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent overflow={"hidden"}>
          <ModalHeader
            backgroundColor={"blue.600"}
            borderBottom="1px solid"
            borderBottomColor={"blue.700"}
          >
            <HStack justifyContent={"space-between"} spacing={"0"}>
              <Text variant="titleSmall" color="white.900">
                Consumers
              </Text>
              <Button
                variant="unstyledWhite"
                size={"xs"}
                height={"13px"}
                padding={"0"}
                onClick={onClose}
              >
                Close
              </Button>
            </HStack>
          </ModalHeader>
          <ModalBody padding={"24px"}>
            <VStack
              alignItems={"stretch"}
              spacing={"4"}
              height="640px"
              overflow="hidden"
            >
              <Text variant={"titleLarge"}>Consumers for {access?.brief}</Text>
              <VStack alignItems={"stretch"} spacing={"0"}>
                <Text variant="labelSmall">Public Key</Text>
                <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                  {access?.userPublicKey}
                </Text>
              </VStack>

              <HStack
                alignItems={"stretch"}
                border="2px solid"
                borderColor={"black.300"}
                borderRadius={"8px"}
                overflow={"hidden"}
                height={"100%"}
                flex={"1"}
                spacing={"0"}
              >
                <VStack
                  alignItems={"stretch"}
                  flex={"1 0 240px"}
                  background="black.200"
                  borderRight="2px solid"
                  borderRightColor={"black.300"}
                >
                  <Stack
                    background={"white.900"}
                    borderBottom="2px solid"
                    borderBottomColor={"black.300"}
                    padding={"12px"}
                  >
                    <Button
                      onClick={onNewConsumerClicked}
                      variant="primaryBlue"
                      size="sm"
                    >
                      New Consumer
                    </Button>
                  </Stack>
                  <VStack
                    width={"240px"}
                    alignItems={"stretch"}
                    overflowY={"scroll"}
                    padding={"8px 12px"}
                    spacing={"1"}
                  >
                    {consumers &&
                      consumers.map((c) => {
                        if (typeof c === "string") {
                          const isActive = c === activeReservedConsumer;
                          return (
                            <Button
                              variant="primaryBlue"
                              justifyContent={"flex-start"}
                              key={c}
                              onClick={() => {
                                onReservedConsumerClicked(c);
                              }}
                              backgroundColor={
                                isActive ? "blue.600" : "black.800"
                              }
                              borderRadius="44px"
                              flex={"1"}
                              size={"sm"}
                            >
                              <Text
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                              >
                                {c}
                              </Text>
                            </Button>
                          );
                        } else {
                          const isActive = c.name === activeConsumer?.name;
                          return (
                            <Button
                              variant="primaryBlue"
                              justifyContent={"flex-start"}
                              key={c.name}
                              onClick={() => {
                                onConsumerClicked(c);
                              }}
                              backgroundColor={
                                isActive ? "blue.600" : "black.800"
                              }
                              borderRadius="44px"
                              flex={"1"}
                              size={"sm"}
                            >
                              <Text
                                whiteSpace={"nowrap"}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                              >
                                {c.name}
                              </Text>
                            </Button>
                          );
                        }
                      })}
                  </VStack>
                </VStack>

                <Stack overflowY="scroll" w="full" padding={"16px 28px"}>
                  {creatingNewConsumer && (
                    <VStack alignItems={"stretch"} spacing={"4"}>
                      <Text variant="titleLarge">New Consumer</Text>
                      <FormControl>
                        <FormLabel>Consumer Name</FormLabel>
                        <FormInput
                          onChange={(e) => {
                            setName(e.target.value.replace(/ /g, "-"));
                          }}
                          value={name}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Consumer Description</FormLabel>
                        <FormInput
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          value={description}
                        />
                      </FormControl>

                      <FormControl>
                        <FormLabel>Deliver Policy</FormLabel>
                        <FormSelect
                          value={deliver_policy}
                          onChange={(e) => {
                            setDeliverPolicy(e.target.value as DeliverPolicy);
                          }}
                        >
                          {Object.values(DeliverPolicy).map((dp) => {
                            return (
                              <option key={dp} value={dp}>
                                {dp}
                              </option>
                            );
                          })}
                        </FormSelect>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Opt Start Seq</FormLabel>
                        <FormInput
                          type="number"
                          value={opt_start_seq}
                          onChange={(e) => {
                            setOptStartSeq(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Opt Start Time</FormLabel>
                        <FormInput
                          type="number"
                          value={opt_start_time}
                          onChange={(e) => {
                            setOptStartTime(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Ack Policy</FormLabel>
                        <FormSelect
                          value={ack_policy}
                          onChange={(e) => {
                            setAckPolicy(e.target.value as AckPolicy);
                          }}
                        >
                          {Object.values(AckPolicy).map((ap) => {
                            return (
                              <option key={ap} value={ap}>
                                {ap}
                              </option>
                            );
                          })}
                        </FormSelect>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max Deliver</FormLabel>
                        <FormInput
                          type="number"
                          value={max_deliver}
                          onChange={(e) => {
                            setMaxDeliver(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max Ack Pending</FormLabel>
                        <FormInput
                          type="number"
                          value={max_ack_pending}
                          onChange={(e) => {
                            setMaxAckPending(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max Waiting</FormLabel>
                        <FormInput
                          type="number"
                          value={max_waiting}
                          onChange={(e) => {
                            setMaxWaiting(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Ack Wait</FormLabel>
                        <FormInput
                          type="number"
                          value={ack_wait}
                          onChange={(e) => {
                            setAckWait(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Filter Subject</FormLabel>
                        <FormInput
                          value={filter_subject}
                          onChange={(e) => {
                            setFilterSubject(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Replay Policy</FormLabel>
                        <FormSelect
                          value={replay_policy}
                          onChange={(e) => {
                            setReplayPolicy(e.target.value as ReplayPolicy);
                          }}
                        >
                          {Object.values(ReplayPolicy).map((rp) => {
                            return (
                              <option key={rp} value={rp}>
                                {rp}
                              </option>
                            );
                          })}
                        </FormSelect>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Sample Frequency</FormLabel>
                        <FormInput
                          value={sample_freq}
                          onChange={(e) => {
                            setSampleFreq(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Rate Limit BPS</FormLabel>
                        <FormInput
                          type="number"
                          value={rate_limit_bps}
                          onChange={(e) => {
                            setRateLimitBps(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Deliver Group</FormLabel>
                        <FormInput
                          value={deliver_group}
                          onChange={(e) => {
                            setDeliverGroup(e.target.value);
                          }}
                        ></FormInput>
                      </FormControl>
                      <Button
                        variant="primaryBlue"
                        size="md"
                        onClick={onNewConsumerSaveClicked}
                        disabled={!name}
                      >
                        Create Consumer
                      </Button>
                    </VStack>
                  )}
                  {activeConsumer && (
                    <VStack alignItems={"stretch"} gap={"12px"}>
                      <HStack
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                      >
                        <VStack alignItems={"stretch"} spacing="1">
                          <Text variant="titleLarge">
                            {activeConsumer.name}
                          </Text>
                          <HStack spacing={"1"}>
                            <Image
                              src={"/images/stream-card-logo.svg"}
                              marginTop={"2px"}
                            />

                            <Text variant="bodySmall" lineHeight={"1em"}>
                              {activeConsumer.stream_name}
                            </Text>
                          </HStack>
                        </VStack>
                        <Button
                          variant="unstyledBlack"
                          size="sm"
                          padding={"0"}
                          onClick={() => {
                            onDeleteConsumerClicked(activeConsumer.name);
                          }}
                        >
                          Delete
                        </Button>
                      </HStack>

                      <FormControl>
                        <FormLabel>Consumer Description</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.description}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Created</FormLabel>
                        <Text variant="bodySmall">
                          {dayjs(activeConsumer.created).format(
                            "MMM DD YYYY hh:mm A"
                          )}
                        </Text>
                      </FormControl>

                      <SectionHeader title="Config" />

                      <FormControl>
                        <FormLabel>Deliver Policy</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.deliver_policy}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Ack Policy</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.ack_policy}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Max Deliver</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.max_deliver}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Replay Policy</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.replay_policy}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Deliver Subject</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.deliver_subject}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Number of Replicas</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.config.num_replicas}
                        </Text>
                      </FormControl>

                      <SectionHeader title="Delivered Stats" />

                      <FormControl>
                        <FormLabel>Consumer Seq</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.delivered.consumer_seq}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Stream Seq</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.delivered.stream_seq}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Last Active</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.delivered.last_active}
                        </Text>
                      </FormControl>

                      <SectionHeader title="Ack Floor Stats" />

                      <FormControl>
                        <FormLabel>Consumer Seq</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.ack_floor.consumer_seq}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Stream Seq</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.ack_floor.stream_seq}
                        </Text>
                      </FormControl>

                      <SectionHeader title="Additional Stats" />

                      <FormControl>
                        <FormLabel>Number of Ack Pending</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.num_ack_pending}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Number of Redelivered</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.num_redelivered}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Number Waiting</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.num_waiting}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Number Pending</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.num_pending}
                        </Text>
                      </FormControl>

                      <SectionHeader title="Cluser" />

                      <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.cluster.name}
                        </Text>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Leader</FormLabel>
                        <Text variant="bodySmall">
                          {activeConsumer.cluster.leader}
                        </Text>
                      </FormControl>
                    </VStack>
                  )}
                  {activeReservedConsumer && (
                    <VStack alignItems={"stretch"} spacing={"6"}>
                      <HStack
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        spacing={"8"}
                      >
                        <Text variant={"titleLarge"}>
                          {activeReservedConsumer}
                        </Text>
                        <Button
                          variant="unstyledBlack"
                          size="sm"
                          onClick={() => {
                            onDeleteConsumerClicked(activeReservedConsumer);
                          }}
                        >
                          Delete
                        </Button>
                      </HStack>
                      <VStack
                        alignItems="stretch"
                        padding="12px 16px 16px"
                        spacing={"2"}
                        background="black.800"
                        border="1px solid"
                        borderColor={"black.900"}
                        borderRadius="4px"
                      >
                        <Text variant={"labelMedium"} color={"white.900"}>
                          This consumer is reserved.
                        </Text>
                        <Text variant={"bodySmall"} color={"white.900"}>
                          Even if you delete this consumer, its name will still
                          be reserved and available on the list of consumers.
                        </Text>
                      </VStack>
                    </VStack>
                  )}
                </Stack>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};
