import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import { FormSelect } from "../FormSelect";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";

interface BulkOrganizationUserEditProps {
  org: any;
  users: any[];
}
export const BulkOrganizationUserEdit = ({
  org,
  users,
}: BulkOrganizationUserEditProps) => {
  const {
    getDomains,
    getOrganizationProducts,
    getProductRoles,
    removeOrganizationUsersAccess,
    updateOrganizationUsersAccess,
  } = React.useContext(AuthContext);
  const [domains, setDomains] = React.useState<any>(null);
  const [products, setProducts] = React.useState<any>(null);
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const [roles, setRoles] = React.useState<any>(null);
  const [domainsToAdd, setDomainsToAdd] = React.useState<any>([]);
  const [domainsToRemove, setDomainsToRemove] = React.useState<any>([]);
  const [rolesToAdd, setRolesToAdd] = React.useState<any>([]);
  const [rolesToRemove, setRolesToRemove] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    getDomains(org.id)
      .then((res) => {
        setDomains(res.data.data);
      })
      .catch((error) => console.log(error));
    getOrganizationProducts(org.id)
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [getDomains, getOrganizationProducts, org.id]);

  const saveUserAccess = async () => {
    try {
      setIsLoading(true);
      let requests = [];
      const usersToAdd = users.map((u) => {
        return u.id;
      });
      if (domainsToAdd.length > 0 || rolesToAdd.length > 0) {
        requests.push(
          updateOrganizationUsersAccess(org.id, {
            accounts: usersToAdd,
            domains: domainsToAdd,
            roles: rolesToAdd,
          })
        );
      }
      if (domainsToRemove.length > 0 || rolesToRemove.length > 0) {
        requests.push(
          removeOrganizationUsersAccess(org.id, {
            accounts: usersToAdd,
            domains: domainsToRemove,
            roles: rolesToRemove,
          })
        );
      }

      const res = await Promise.all(requests);
      console.log(res);
      clearInputs();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setDomainActions = (domainId: string, action: string) => {
    if (action === "add") {
      setDomainsToAdd([...domainsToAdd, domainId]);
    } else if (action === "remove") {
      setDomainsToRemove([...domainsToRemove, domainId]);
    }
  };

  const setRoleActions = (roleId: string, action: string) => {
    if (action === "add") {
      setRolesToAdd([...rolesToAdd, roleId]);
    } else if (action === "remove") {
      setRolesToRemove([...rolesToRemove, roleId]);
    }
  };

  const clearInputs = () => {
    setDomainsToAdd([]);
    setDomainsToRemove([]);
    setRolesToAdd([]);
    setRolesToRemove([]);
  };

  return (
    <>
      <Button
        variant={"primaryBlue"}
        onClick={saveUserAccess}
        isLoading={isLoading}
        loadingText="Updating"
        alignSelf={"flex-start"}
      >
        Update Access
      </Button>
      <VStack
        spacing={"0"}
        gap={"20px"}
        alignItems={"stretch"}
      >
        <VStack
          flex={"1"}
          alignItems={"stretch"}
        >
          <Text variant="contentsubheader">Domain Access</Text>
          <VStack
            alignItems={"stretch"}
          >
            {domains &&
              domains
                .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                .map((domain: any) => {
                  let formValue = "";
                  if (domainsToAdd.includes(domain.id)) {
                    formValue = "add";
                  } else if (domainsToRemove.includes(domain.id)) {
                    formValue = "remove";
                  }
                  return (
                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      my={2}
                      key={domain.id}
                    >
                      <FormLabel
                        htmlFor={domain.name}
                        color="#5F5C55"
                        overflow={"hidden"}
                        whiteSpace="nowrap"
                        textOverflow={"ellipsis"}
                      >
                        {domain.name}
                      </FormLabel>
                      <FormSelect
                        maxW={100}
                        size="sm"
                        value={formValue}
                        onChange={(e) => {
                          setDomainActions(domain.id, e.target.value);
                        }}
                        id="type"
                        placeholder='Select'
                      >
                        <option value={`add`}>Add</option>
                        <option value={`remove`}>Remove</option>
                      </FormSelect>
                    </FormControl>
                  );
                })}
          </VStack>
        </VStack>
        <VStack
          flex={"1"}
          alignItems={"stretch"}
          gap={"16px"}
        >
          <Text variant="contentsubheader">Role Assignments</Text>
          <VStack
            alignItems={"stretch"}
          >
            <HStack
              alignItems={"stretch"}
              flex={"1"}
              spacing={"0"}
            >
              <VStack
                alignItems={"stretch"}
                flex={"1"}
                background={"neutral.100"}
                padding={"20px"}
                borderRadius={"8px 0 0 8px"}
                border={"1px solid"}
                borderColor={"neutral.300"}
                borderRight={"none"}
              >
                <Text variant={"titleSmall"}>Products</Text>

                {products &&
                  products
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .map((product: any) => {
                      return (
                        <WindowColumnSecondaryButton
                          key={product.id}
                          onClick={async () => {
                            setSelectedProduct(product);
                            const res = await getProductRoles(product.id);
                            setRoles(res.data.data);
                          }}
                          active={selectedProduct?.id === product.id}
                        >
                          {product.name}
                        </WindowColumnSecondaryButton>
                      );
                    })}
              </VStack>
              <VStack
                alignItems={"stretch"}
                flex={"1"}
                background={"neutral.200"}
                padding={"20px"}
                borderRadius={"0px 8px 8px 0"}
                border={"1px solid"}
                borderColor={"neutral.300"}
              >
                <Text variant={"titleSmall"}>{selectedProduct?.name} </Text>
                {selectedProduct &&
                  roles &&
                  roles
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .map((role: any) => {
                      let formValue = "";
                      if (rolesToAdd.includes(role.id)) {
                        formValue = "add";
                      } else if (rolesToRemove.includes(role.id)) {
                        formValue = "remove";
                      }
                      return (
                        <FormControl
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          my={2}
                          key={role.id}
                        >
                          <FormLabel
                            htmlFor={role.name}
                            overflow={"hidden"}
                            whiteSpace="nowrap"
                            textOverflow={"ellipsis"}
                          >
                            {role.name}
                          </FormLabel>
                          <FormSelect
                            maxW={100}
                            size="sm"
                            value={formValue}
                            onChange={(e) => {
                              setRoleActions(role.id, e.target.value);
                            }}
                            id="type"
                            placeholder='Select'
                          >
                            <option value={`add`}>Add</option>
                            <option value={`remove`}>Remove</option>
                          </FormSelect>
                        </FormControl>
                      );
                    })}
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </>
  );
};
