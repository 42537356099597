import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../providers/authProvider";
import {
  Input,
  Image,
  Button,
  HStack,
  VStack,
  Text,
  Link,
  SimpleGrid,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { HelmetGeneric } from "../HelmetGeneric";

const LoginPage = () => {
  const { init, signinRedirect } = useContext(AuthContext);
  const [lastKnownEmail, setLastKnownEmail] = React.useState(null);
  const [lastKnownGroup, setLastKnownGroup] = React.useState(null);
  const [lastKnownGroupName, setLastKnownGroupName] = React.useState(null);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [prettyName, setPrettyName] = React.useState("");

  useEffect(() => {
    const lastKnownEmail = localStorage.getItem("lastKnownEmail");
    const lastKnownGroup = localStorage.getItem("lastKnownGroup");
    const lastKnownGroupName = localStorage.getItem("lastKnownGroupName");

    setLastKnownEmail(lastKnownEmail);
    setLastKnownGroup(lastKnownGroup);
    setLastKnownGroupName(lastKnownGroupName);

    if (window && window.pendo) {
      console.log("setting up pendo");
      window.pendo.initialize();
    }
  }, []);

  const loginButtonClicked = (group) => {
    setLoggingIn(true);
    loginRedirect(group);
  };

  const loginRedirect = (group) => {
    console.log("loginRedirect", group);
    const authority = process.env.REACT_APP_AUTHORITY_ROOT;
    const clientRoot = process.env.REACT_APP_CLIENT_ROOT;
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/api/group-info/${group}`)
      .then((response) => {
        console.log(response);

        const { id, groupId, group } = response.data.data;

        console.log(id, groupId);
        if (response.data.data.id) {
          init(`${authority}/${groupId}`, id, clientRoot, groupId, group);

          signinRedirect();
        } else {
          setLoggingIn(false);
        }
      });
  };

  return (
    <>
      <HelmetGeneric />
      <Flex
        minH={"100vh"}
        minW={"100%"}
        background={"#FFFDFA"}
        color={"neutral.800"}
      >
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          padding={{ base: "48px 48px 20px", md: "120px 120px 80px" }}
          minWidth={{ base: "none", md: "600px" }}
          gap={"28px"}
          flex={"1"}
          shadow={"default.500"}
          background={"neutral.0"}
          userSelect={"none"}
        >
          <Image
            src="images/ue-logo-orange.svg"
            alt="United Effects Logo"
            width="128px"
          />

          {lastKnownEmail && lastKnownGroup && (
            <>
              <Text variant="titleLarge">Sign in to an existing company</Text>
              <Button
                variant="primaryRed"
                size="xl"
                borderRadius={"44px"}
                onClick={() => {
                  loginButtonClicked(lastKnownGroup);
                }}
                isLoading={loggingIn}
                loadingText="Logging you in..."
              >
                Sign in to {lastKnownGroupName}
              </Button>
              <HStack>
                <Divider />
                <Text variant={"titleSmall"} color={"neutral.600"}>
                  or
                </Text>
                <Divider />
              </HStack>
            </>
          )}

          <Text variant="titleLarge">Sign in to another company</Text>
          <HStack>
            <Flex
              height="40px"
              background={"neutral.200"}
              borderRadius="4px"
              flexDirection="row"
              alignItems="center"
              padding="0px 16px"
            >
              <Text variant="bodySmall" color={"neutral.600"}>
                core.unitedeffects.com/
              </Text>
            </Flex>
            <Input
              placeholder="your-url"
              value={prettyName}
              onChange={(e) => {
                setPrettyName(e.target.value);
              }}
            />
          </HStack>
          <Button
            variant="primaryBlue"
            size="xl"
            borderRadius={"44px"}
            onClick={() => {
              loginButtonClicked(prettyName);
            }}
            isLoading={loggingIn}
            loadingText="Logging you in..."
          >
            Continue
          </Button>
          <VStack alignItems={"stretch"} spacing={"0"}>
            <Text variant="bodySmall">Don't have an account?</Text>
            <Link
              href="/register"
              fontWeight="500"
              fontSize="14px"
              color="blue.500"
            >
              Register a new company for free
            </Link>
          </VStack>
          <HStack>
            <ArrowBackIcon color={"neutral.600"} />
            <Link
              href="https://www.unitedeffects.com"
              fontWeight="700"
              fontSize="14px"
              color="neutral.600"
              leftIcon={<ArrowBackIcon />}
            >
              Back to United Effects
            </Link>
          </HStack>
        </VStack>
        <VStack
          flex={"3"}
          display={{ base: "none", lg: "flex" }}
          mt="120px"
          justifyContent={"space-between"}
          userSelect={"none"}
        >
          <VStack
            alignItems={"stretch"}
            gap={"48px"}
            padding={"0 28px"}
            visibility={"hidden"}
          >
            <VStack alignItems={"stretch"} spacing={"0"}>
              <Text
                variant={"labelSmall"}
                color={"slate.700"}
                textAlign={"center"}
              >
                The World's Best Real-time
              </Text>
              <Text
                variant={"titleLarge"}
                color={"slate.700"}
                textAlign={"center"}
              >
                Enterprise Integration Hub
              </Text>
            </VStack>
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing="28px"
              maxWidth={"500px"}
            >
              <VStack alignItems={"stretch"}>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Data
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  Real-time, many-2-many, streamed anywhere
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Connectivity
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  On-demand, secured access to any system
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Identity
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  Enterprise SSO with FGA, all customers & systems
                </Text>
              </VStack>
              <VStack>
                <Text
                  variant={"titleMedium"}
                  color={"red.500"}
                  textAlign={"center"}
                >
                  Security
                </Text>
                <Text
                  variant={"bodyMedium"}
                  color={"slate.700"}
                  textAlign={"center"}
                >
                  API & device auth, full audit, & anomaly detection
                </Text>
              </VStack>
            </SimpleGrid>
          </VStack>
          <Image
            src="images/monument-valley.svg"
            alt="United Effects Header"
            blendMode={"multiply"}
            width={"100%"}
            maxHeight={"60vh"}
          />
        </VStack>
      </Flex>
    </>
  );
};

export default LoginPage;
