import axios, { AxiosInstance } from "axios";

export class SolutionLoaderService {
  private authToken: string;
  private group: string;
  private apiRoot =
    process.env.REACT_APP_SOLUTION_LOADER_API_ROOT ||
    "https://loader.qa.uecore.io/api";
  private client: AxiosInstance;

  constructor(authToken: string, group: string) {
    this.authToken = authToken;
    this.group = group;

    this.client = axios.create({ baseURL: this.apiRoot });
    if (this.client.defaults.headers) {
      this.client.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.authToken}`;
    }

    this.client.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.data.data) {
          return response.data.data;
        } else {
          return Promise.reject(response.data);
        }
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  /* Global */
  public async getGlobalSolutions(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/global/solutions`);
  }

  /* Provision */
  public async getSolutions(): Promise<any> {
    return this.client.get(`${this.apiRoot}/${this.group}/solution`);
  }

  public async addSolution(globalId: string): Promise<any> {
    return this.client.put(`${this.apiRoot}/${this.group}/solution`, {
      globalId,
    });
  }
}
