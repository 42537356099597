import React from "react";
import { Select, SelectProps } from "@chakra-ui/react";

export const FormSelect = (props: SelectProps) => {
  return (
    <Select
      {...props}
      border={"1px solid"}
      borderColor={"black.400"}
      backgroundColor={"white.800"}
      borderRadius={"4px"}
      fontWeight={"500"}
      backgroundImage={"linear-gradient(180deg,rgba(255,255,255,.1),rgba(255,255,255,0))"}
    >
      {props.children}
    </Select>
  );
};
