import React, { useContext, useEffect } from "react";
import axios from "axios";
import { JsonPointer } from "json-ptr";
import * as dayjs from "dayjs";

import {
  FormControl,
  FormLabel,
  VStack,
  Flex,
  Text,
  Image,
  Center,
  FormHelperText,
  Switch,
  useClipboard,
  IconButton,
  HStack,
  useRadioGroup,
  TagCloseButton,
  Button,
  Tag,
  TagLabel,
  Divider,
} from "@chakra-ui/react";

import { WindowColumnPrimaryButton } from "../windowColumnPrimaryButton";
import { WindowsContext } from "../../providers/windowsProvider";
import { Clients } from "../clients/clients";
import { FormInput } from "../FormInput";
import { CustomDomainInput } from "../CustomDomainInput";
import { WindowColumnSecondaryButton } from "../windowColumnSecondaryButton";
import {
  CheckCircleIcon,
  CloseIcon,
  CopyIcon,
  RepeatIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import { LoginOrientationCard } from "../loginOrientationCard";
import {
  NewCustomProvider,
  NewCustomProviderParentType,
} from "./newCustomProvider";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../providers/authProvider";
import { CustomDomainService } from "../../services/CustomDomainService";
import { isArray } from "@chakra-ui/utils";
import { SolutionLoaderService } from "../../services/solution-loader/SolutionLoaderService";

export const AuthGroup = ({ authGroup, result, onRefresh }) => {
  let history = useHistory();
  const { updateAuthGroup, getUser, forceSigninSilent } =
    useContext(AuthContext);
  const [authGroupData, setAuthGroupData] = React.useState(authGroup);
  const [primaryColumnSelectedOption, setPrimaryColumnSelectedOption] =
    React.useState({ name: "authGroup", label: "Auth Group" });
  const [prettyName, setPrettyName] = React.useState(authGroup.prettyName);
  const [name, setName] = React.useState(authGroup.name);
  const [locked, setLocked] = React.useState(authGroup.locked);
  const [primaryDomain, setPrimaryDomain] = React.useState(
    authGroup.primaryDomain
  );
  const [primaryTOS, setPrimaryTOS] = React.useState(authGroup.primaryTOS);
  const [primaryPrivacyPolicy, setPrimaryPrivacyPolicy] = React.useState(
    authGroup.primaryPrivacyPolicy
  );
  const [primaryEmail, setPrimaryEmail] = React.useState(
    authGroup.primaryEmail
  );
  const [registerUrl, setRegisterUrl] = React.useState(authGroup.registerUrl);
  const [loginSplashScreen, setLoginSplashScreen] = React.useState(
    authGroup.config?.ui?.skin?.splashImage
  );
  const [eosBackgroundImage, setEosBackgroundImage] = React.useState(
    authGroup.config?.ui?.skin?.backgroundImage
  );
  const [logo, setLogo] = React.useState(authGroup.config?.ui?.skin?.logo);
  const [favicon, setFavicon] = React.useState(
    authGroup.config?.ui?.skin?.favicon
  );
  const [loginOrientation, setLoginOrientation] = React.useState(
    authGroup.config?.ui?.skin?.loginOrientation
  );
  const [requireVerified, setRequireVerified] = React.useState(
    authGroup.config.requireVerified
  );
  const [autoVerify, setAutoVerify] = React.useState(
    authGroup.config.autoVerify
  );
  const [passwordLessSupport, setPasswordLessSupport] = React.useState(
    authGroup.config.passwordLessSupport
  );
  const [centralPasswordReset, setCentralPasswordReset] = React.useState(
    authGroup.config.centralPasswordReset
  );
  const [pkceRequired, setPkceRequired] = React.useState(
    authGroup.config.pkceRequired
  );
  const [ttlAccessToken, setTtlAccessToken] = React.useState(
    authGroup.config.ttl.accessToken
  );
  const [ttlAuthorizationCode, setTtlAuthorizationCode] = React.useState(
    authGroup.config.ttl.authorizationCode
  );
  const [ttlClientCredentials, setTtlClientCredentials] = React.useState(
    authGroup.config.ttl.clientCredentials
  );
  const [ttlDeviceCode, setTtlDeviceCode] = React.useState(
    authGroup.config.ttl.deviceCode
  );
  const [ttlIdToken, setTtlIdToken] = React.useState(
    authGroup.config.ttl.idToken
  );
  const [ttlRefreshToken, setTtlRefreshToken] = React.useState(
    authGroup.config.ttl.refreshToken
  );
  const [ttlInteration, setTtlInteraction] = React.useState(
    authGroup.config.ttl.interaction
  );
  const [ttlSession, setTtlSession] = React.useState(
    authGroup.config.ttl.session
  );
  const [ttlGrant, setTtlGrant] = React.useState(authGroup.config.ttl.grant);
  const [selectedFederationProvider, setSelectedFederationProvider] =
    React.useState(null);
  const [mfaChallengeEnabled, setMfaChallengeEnabled] = React.useState(
    authGroup.config?.mfaChallenge?.enable
  );
  const [mfaChallengeRequired, setMfaChallengeRequired] = React.useState(
    authGroup.config?.mfaChallenge?.required
  );
  const [scopes, setScopes] = React.useState(authGroup.config.scopes);
  const [newScope, setNewScope] = React.useState("");
  const [acrValues, setAcrValues] = React.useState(authGroup.config.acrValues);
  const [newAcrValue, setNewAcrValue] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [clientSecret, setClientSecret] = React.useState("");
  const [customProviderType, setCustomProviderType] = React.useState("");
  const [customProviderName, setCustomProviderName] = React.useState("");
  const [customProviderButtonText, setCustomProviderButtonText] =
    React.useState("");
  const [customProviderPkce, setCustomProviderPkce] = React.useState("");
  const [customProviderScopes, setCustomProviderScopes] = React.useState("");
  const [customProviderNewScope, setCustomProviderNewScope] =
    React.useState("");
  const [customProviderAccessTokenUri, setCustomProviderAccessTokenUri] =
    React.useState("");
  const [customProviderAuthorizationUri, setCustomProviderAuthorizationUri] =
    React.useState("");
  const [customProviderProfileUri, setCustomProviderProfileUri] =
    React.useState("");
  const [customProviderDiscoveryUrl, setCustomProviderDiscoveryUrl] =
    React.useState("");
  const [newCustomProvider, setNewCustomProvider] = React.useState(false);
  const [update, setUpdate] = React.useState(null);
  const { hasCopied, onCopy } = useClipboard(authGroup.id);
  const [redirectUrl, setRedirectUrl] = React.useState("");
  const { hasCopied: hasCopiedRedirectUri, onCopy: onCopyRedirectUrl } =
    useClipboard(redirectUrl);
  const [showClientSecret, setShowClientSecret] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [prettyNameError, setPrettyNameError] = React.useState("");
  const [customDomain, setCustomDomain] = React.useState("");
  const [customDomainUi, setCustomDomainUi] = React.useState("");
  const [customDomainOidc, setCustomDomainOidc] = React.useState("");
  const [customDomainService, setCustomDomainService] = React.useState(null);
  const [customDomains, setCustomDomains] = React.useState([]);
  const [customDomainStatus, setCustomDomainStatus] = React.useState(null);
  const [solutionsService, setSolutionsService] = React.useState(null);
  const [globalSolutions, setGlobalSolutions] = React.useState([]);
  const [solutions, setSolutions] = React.useState([]);
  const [passkey, setPasskey] = React.useState(
    authGroup.pluginOptions.webAuthN.enable
  );

  const [windows, setWindows] = useContext(WindowsContext);

  const loginOrientationClicked = (value) => {
    setLoginOrientation(value);
    updateAuthGroupInfo("config/ui/skin/loginOrientation", value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "label",
    defaultValue: loginOrientation,
    onChange: loginOrientationClicked,
  });

  const radioGroup = getRootProps();

  const primaryColumnOptions = [
    { name: "authGroup", label: "Auth Group" },
    { name: "identity", label: "Identity" },
    { name: "lookAndFeel", label: "Branding" },
    { name: "clients", label: "Client Management ⭢" },
    { name: "federation", label: "Federation Configuration" },
    { name: "solutions", label: "Solutions" },
    { name: "update", label: "System Update" },
  ];

  const PROVIDERS = [
    { provider: "google", label: "Google", type: "oidc" },
    { provider: "microsoft", label: "Microsoft", type: "oidc" },
    { provider: "apple", label: "Apple", type: "oidc" },
    { provider: "github", label: "GitHub", type: "oauth2" },
    { provider: "linkedin", label: "LinkedIn", type: "oauth2" },
  ];

  async function primaryColumnOptionSelected(selectedOption) {
    if (selectedOption.name === "clients") {
      const label = selectedOption.name;
      const children = <Clients group={authGroup.id} />;
      const windowsLocal = windows.length > 0 ? [...windows] : [];

      let existingWindow = windowsLocal.find((w) => w.label === label);

      if (existingWindow) {
        // const index = windowsLocal.indexOf(existingWindow);
        // TODO: figure out how to bring the window to the front
      } else {
        windowsLocal.push({
          children,
          isResizable: true,
          isDraggable: true,
          initialPosition: "center",
          label: label,
        });
      }

      setWindows(windowsLocal);
    } else {
      setPrimaryColumnSelectedOption(selectedOption);
    }
  }

  const setCustomProviderState = (provider) => {
    setCustomProviderName(provider.name);
    setCustomProviderType(provider.type);
    setCustomProviderButtonText(provider.buttonText);
    setCustomProviderPkce(provider.pkce);
    setCustomProviderScopes(provider.scopes);
    setCustomProviderAccessTokenUri(provider.accessTokenUri);
    setCustomProviderAuthorizationUri(provider.authorizationUri);
    setCustomProviderProfileUri(provider.profileUri);
    setCustomProviderDiscoveryUrl(provider.discoveryUrl);
  };

  const updateAuthGroupInfo = async (key, value) => {
    key = key.startsWith("/") ? key : `/${key}`;
    const exists = JsonPointer.get(authGroup, key);
    const op = exists ? "replace" : "add";

    console.log(op, key, value);
    const authUser = await getUser();
    if (value !== "") {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_ROOT}/api/group/${authGroup.id}`,
          [
            {
              op: op,
              path: key,
              value: value,
            },
          ],
          {
            headers: {
              Authorization: `Bearer ${authUser.access_token}`,
            },
          }
        );
        console.log(res);
        setAuthGroupData(res.data.data);
        result(res.data.data);
      } catch (err) {
        console.log(err);
        throw err;
      }
    }
  };

  const updateFederation = async () => {
    let value, type;
    if (
      (clientId && clientSecret) ||
      selectedFederationProvider.provider === "custom"
    ) {
      if (selectedFederationProvider.provider === "google") {
        type = "oidc";
        value = {
          name: "Google",
          provider: "google",
          buttonType: "google",
          buttonText: "Sign in with Google",
          PKCE: false,
          scopes: ["email"],
          client_id: clientId,
          client_secret: clientSecret,
          discovery_url:
            "https://accounts.google.com/.well-known/openid-configuration",
        };
      } else if (selectedFederationProvider.provider === "microsoft") {
        type = "oidc";
        value = {
          name: "Office365",
          provider: "microsoft",
          buttonType: "standard",
          buttonText: "Sign in with Microsoft",
          PKCE: false,
          scopes: ["email"],
          client_id: clientId,
          client_secret: clientSecret,
          discovery_url:
            "https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration",
        };
      } else if (selectedFederationProvider.provider === "apple") {
        type = "oidc";
        value = {
          name: "Apple",
          provider: "apple",
          buttonType: "standard",
          buttonText: "Sign in with Apple",
          PKCE: false,
          client_id: clientId,
          client_secret: clientSecret,
          scopes: ["name", "email"],
          discovery_url:
            "https://appleid.apple.com/.well-known/openid-configuration",
        };
      } else if (selectedFederationProvider.provider === "github") {
        type = "oauth2";
        value = {
          name: "GitHub",
          provider: "github",
          buttonType: "standard",
          buttonText: "Sign in with GitHub",
          client_id: clientId,
          client_secret: clientSecret,
          scopes: ["email", "profile"],
          accessTokenUri: "https://github.com/login/oauth/access_token",
          authorizationUri: "https://github.com/login/oauth/authorize",
          profileUri: "https://api.github.com/user",
        };
      } else if (selectedFederationProvider.provider === "linkedin") {
        type = "oauth2";
        value = {
          name: "LinkedIn",
          provider: "linkedin",
          buttonType: "linkedin",
          buttonText: "Sign in with LinkedIn",
          PKCE: false,
          client_id: clientId,
          client_secret: clientSecret,
          scopes: ["r_liteprofile", "r_emailaddress"],
          accessTokenUri: "https://www.linkedin.com/oauth/v2/accessToken",
          authorizationUri: "https://www.linkedin.com/oauth/v2/authorization",
          profileUri: "https://api.linkedin.com/v2/me",
        };
      } else if (selectedFederationProvider.provider === "custom") {
        type = customProviderType;
        if (type === "oauth2") {
          value = {
            provider: "custom",
            buttonType: "standard",
            name: customProviderName,
            buttonText: customProviderButtonText,
            PKCE: customProviderPkce,
            client_id: clientId,
            client_secret: clientSecret,
            response_type: "code",
            grant_type: "authorization_code",
            scopes: customProviderScopes,
            accessTokenUri: customProviderAccessTokenUri,
            authorizationUri: customProviderAuthorizationUri,
            profileUri: customProviderProfileUri,
          };
        } else if (type === "oidc") {
          value = {
            provider: "custom",
            buttonType: "standard",
            name: customProviderName,
            buttonText: customProviderButtonText,
            PKCE: customProviderPkce,
            client_id: clientId,
            client_secret: clientSecret,
            response_type: "code",
            grant_type: "authorization_code",
            discovery_url: customProviderDiscoveryUrl,
            scopes: customProviderScopes,
          };
        }
      }

      let index;
      if (type === "oidc") {
        index = authGroupData.config?.federate?.oidc?.findIndex(
          (o) => o.provider === selectedFederationProvider.provider
        );
      } else if (type === "oauth2") {
        index = authGroupData.config?.federate?.oauth2?.findIndex(
          (o) => o.provider === selectedFederationProvider.provider
        );
      }

      let op, path;
      if (index > -1) {
        op = "replace";
        path = `/config/federate/${type}/${index}`;
      } else {
        op = "add";
        path = `/config/federate/${type}/-`;
      }

      console.log(op, path, value);

      const authUser = await getUser();

      axios
        .patch(
          `${process.env.REACT_APP_API_ROOT}/api/group/${authGroupData.id}`,
          [
            {
              op,
              path,
              value,
            },
          ],
          {
            headers: {
              Authorization: `Bearer ${authUser.access_token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          result(res.data.data);
          setAuthGroupData(res.data.data);
        });
    }
  };

  const getCustomDomainStatus = () => {
    setCustomDomainStatus("...");
    customDomainService.runVerifyJob().then((response) => {
      console.log(response);
      const data = response.data.data;
      if (data && isArray(data)) {
        const foundPending = data.find((d) => d.success === "false");
        setCustomDomainStatus(foundPending ? "Pending" : "Success");
      } else {
        setCustomDomainStatus("Pending");
      }
    });
  };

  useEffect(() => {
    getUser()
      .then((authUser) => {
        const svc = new CustomDomainService(
          authUser.access_token,
          authGroup.id
        );
        setCustomDomainService(svc);
        svc.getCustomDomains().then((response) => {
          const cds = response.data.data;
          setCustomDomains(cds);

          const foundPending = cds.find((d) => d.verified === false);
          if (!foundPending) {
            setCustomDomainStatus("Verified");
          }

          svc.runVerifyJob().then((response) => {
            console.log(response);
            const data = response.data.data;
            if (data && isArray(data)) {
              const foundPending = data.find((d) => d.success === "false");
              setCustomDomainStatus(foundPending ? "Pending" : "Success");
            } else {
              setCustomDomainStatus("Pending");
            }
          });
        });

        const solutionsService = new SolutionLoaderService(
          authUser.access_token,
          authGroup.id
        );
        setSolutionsService(solutionsService);

        solutionsService
          .getGlobalSolutions()
          .then((res) => {
            setGlobalSolutions(res);
          })
          .catch((error) => {
            console.error(error);
          });

        solutionsService
          .getSolutions()
          .then((res) => {
            setSolutions(res);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [authGroup.id, getUser]);

  const handleEnableSolutionClicked = async (globalId) => {
    try {
      await solutionsService.addSolution(globalId);
      const res = await solutionsService.getSolutions();
      setSolutions(res);
      await forceSigninSilent();
      onRefresh();
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomDomains = () => {
    customDomainService.getCustomDomains().then((response) => {
      const cds = response.data.data;
      setCustomDomains(cds);
    });
  };

  const handleCustomDomainContinueClicked = async () => {
    try {
      if (customDomainUi) {
        const domain = `${customDomainUi}.${customDomain}`;
        await customDomainService.writeCustomDomain(domain, "ui");
      }
      if (customDomainOidc) {
        const domain = `${customDomainOidc}.${customDomain}`;
        await customDomainService.writeCustomDomain(domain, "oidc");
      }

      getCustomDomains();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCustomDomainDeleteAll = async () => {
    try {
      for (const cd of customDomains) {
        await customDomainService.deleteCustomDomain(cd.id);
      }
      getCustomDomains();
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshCustomDomainClicked = async () => {
    getCustomDomainStatus();
  };

  return (
    <Flex h="100%" alignItems="stretch">
      {/* Primary Column */}
      <VStack layerStyle="windowmenu" alignItems="stretch" flex="1" spacing="0">
        <VStack alignItems={"stretch"} spacing="0">
          <Text variant="windowheader" marginBottom="0">
            Settings
          </Text>
          <Text
            variant={"bodyXsmall"}
            color={"neutral.200"}
            padding={"0 0 8px 0"}
          >
            Changes to the Auth Group may take up to 10 minutes to propagate.
          </Text>
          <Divider opacity={".4"} />
        </VStack>
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          paddingTop={"8px"}
          paddingBottom={"8px"}
        >
          {primaryColumnOptions.map((option) => {
            return (
              <WindowColumnPrimaryButton
                key={option.name}
                onClick={() => {
                  const optionSelected = {
                    name: option.name,
                    label: option.label,
                  };
                  primaryColumnOptionSelected(optionSelected);
                }}
                active={primaryColumnSelectedOption.name === option.name}
              >
                <Text
                  width={"162px"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                >
                  {option.label}
                </Text>
              </WindowColumnPrimaryButton>
            );
          })}
        </VStack>
      </VStack>
      {primaryColumnSelectedOption.name === "authGroup" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Auth Group</Text>

          <VStack alignItems={"stretch"} spacing="3">
            <Text variant="contentsubheader">General</Text>
            <FormControl>
              <FormLabel htmlFor="prettyName">Alias</FormLabel>
              <FormInput
                border={0}
                value={prettyName}
                onChange={(e) => setPrettyName(e.target.value)}
                onBlur={async (e) => {
                  //handle 409s
                  try {
                    setPrettyNameError("");
                    await updateAuthGroupInfo("prettyName", e.target.value);
                    localStorage.setItem("groupName", e.target.value);
                  } catch (error) {
                    console.log("error");
                    console.log(error);
                    if (error.response.status === 409) {
                      setPrettyNameError("Sorry, that alias is already in use");
                    }
                  }
                }}
                id="prettyName"
                width="320px"
              />
              {prettyNameError && (
                <Text color="black.900" fontSize={12}>
                  {prettyNameError}
                </Text>
              )}
            </FormControl>

            <VStack alignItems={"flex-start"} spacing={"0"}>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">Auth Group ID</Text>
                <IconButton
                  aria-label="Copy Auth Group ID"
                  icon={<CopyIcon />}
                  onClick={onCopy}
                  size="xs"
                  variant="copyButton"
                />
                <Text variant="labelSmall">{hasCopied && "Copied!"}</Text>
              </HStack>
              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {authGroup.id}
              </Text>
            </VStack>

            <FormControl>
              <FormLabel htmlFor="name">Auth Group Name</FormLabel>
              <FormInput
                border={0}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => updateAuthGroupInfo("name", e.target.value)}
                id="name"
                width="320px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="primaryDomain">Your Business URL</FormLabel>
              <FormInput
                border={0}
                value={primaryDomain}
                onChange={(e) => setPrimaryDomain(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("primaryDomain", e.target.value)
                }
                id="primaryDomain"
                width="320px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="primaryEmail">
                Primary Business Contact Email
              </FormLabel>
              <FormInput
                border={0}
                value={primaryEmail}
                onChange={(e) => setPrimaryEmail(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("primaryEmail", e.target.value)
                }
                id="primaryEmail"
                placeholder=""
                width="320px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="primaryTOS">
                Link to Your Business Terms of Service
              </FormLabel>
              <FormInput
                border={0}
                value={primaryTOS}
                onChange={(e) => setPrimaryTOS(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("primaryTOS", e.target.value)
                }
                id="primaryTOS"
                placeholder=""
                width="480px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="primaryPrivacyPolicy">
                Link to Your Business Privacy Policy
              </FormLabel>
              <FormInput
                border={0}
                value={primaryPrivacyPolicy}
                onChange={(e) => setPrimaryPrivacyPolicy(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("primaryPrivacyPolicy", e.target.value)
                }
                id="primaryPrivacyPolicy"
                placeholder=""
                width="480px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="registerUrl">
                Self-Service Register URL
              </FormLabel>
              <FormInput
                border={0}
                value={registerUrl}
                onChange={(e) => setRegisterUrl(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("registerUrl", e.target.value)
                }
                id="registerUrl"
                placeholder=""
                width="480px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="lastUpdated" mb="0">
                Last Updated
              </FormLabel>
              <Text variant="bodySmall">
                {dayjs(authGroup.modifiedAt).format("MMM DD YYYY hh:mm a")}
              </Text>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="lastUpdated" mb="0">
                Notifications are{" "}
                {authGroup.pluginOptions?.notification?.enabled
                  ? "Enabled"
                  : "Disabled"}
              </FormLabel>
            </FormControl>

            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="locked">Lock Auth Group</FormLabel>
              <Switch
                id="locked"
                isChecked={locked}
                onChange={(e) => {
                  updateAuthGroupInfo("locked", e.target.checked);
                  setLocked(e.target.checked);
                }}
              />
            </FormControl>
          </VStack>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "identity" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Identity</Text>

          <VStack alignItems={"stretch"} spacing="3">
            <Text variant="contentsubheader">General</Text>
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="requireVerified">
                Require Verified Accounts
              </FormLabel>
              <Switch
                id="requireVerified"
                isChecked={requireVerified}
                onChange={(e) => {
                  updateAuthGroupInfo(
                    "config/requireVerified",
                    e.target.checked
                  );
                  setRequireVerified(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="autoVerify">Auto-Verify Accounts</FormLabel>
              <Switch
                id="autoVerify"
                isChecked={autoVerify}
                onChange={(e) => {
                  updateAuthGroupInfo("config/autoVerify", e.target.checked);
                  setAutoVerify(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="centralPasswordReset">
                Enable Centralized Password Reset
              </FormLabel>
              <Switch
                id="centralPasswordReset"
                isChecked={centralPasswordReset}
                onChange={(e) => {
                  updateAuthGroupInfo(
                    "config/centralPasswordReset",
                    e.target.checked
                  );
                  setCentralPasswordReset(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="pkceRequired">
                Require PKCE (Proof of Key Exchange) for all Logins
              </FormLabel>
              <Switch
                id="pkceRequired"
                isChecked={pkceRequired}
                onChange={(e) => {
                  updateAuthGroupInfo("config/pkceRequired", e.target.checked);
                  setPkceRequired(e.target.checked);
                }}
              />
            </FormControl>
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="passwordLessSupport">
                Enable Password-free Email Login
              </FormLabel>
              <Switch
                id="passwordLessSupport"
                isChecked={passwordLessSupport}
                onChange={(e) => {
                  updateAuthGroupInfo(
                    "config/passwordLessSupport",
                    e.target.checked
                  );
                  setPasswordLessSupport(e.target.checked);
                }}
              />
            </FormControl>
            !
            <FormControl
              display="flex"
              flexDirection="row-reverse"
              justifyContent={"flex-end"}
              gap={"8px"}
              alignItems={"center"}
            >
              <FormLabel htmlFor="passkey">
                Enable Password-free Passkey Login
              </FormLabel>
              <Switch
                id="passkey"
                isChecked={passkey}
                onChange={(e) => {
                  updateAuthGroupInfo("pluginOptions/webAuthN", {
                    enable: e.target.checked,
                    type: "privakey",
                  });
                  setPasskey(e.target.checked);
                }}
              />
            </FormControl>
          </VStack>

          <VStack alignItems={"stretch"} spacing={"6"}>
            <VStack alignItems={"stretch"}>
              <Text variant="contentsubheader">Device Login and MFA</Text>
              <FormControl
                display="flex"
                flexDirection="row-reverse"
                justifyContent={"flex-end"}
                gap={"8px"}
                alignItems={"center"}
              >
                <FormLabel htmlFor="mfaEnabled">Enabled</FormLabel>
                <Switch
                  id="mfaEnabled"
                  isChecked={mfaChallengeEnabled}
                  onChange={async (e) => {
                    setMfaChallengeEnabled(e.target.checked);
                    const currentMfaSettings =
                      authGroupData.config.mfaChallenge;
                    let newMfaSettings = { ...currentMfaSettings };
                    newMfaSettings.enable = e.target.checked;
                    newMfaSettings.type = "privakeysuper";
                    if (!e.target.checked) {
                      newMfaSettings.required = false;
                      setMfaChallengeRequired(false);
                    }
                    await updateAuthGroupInfo(
                      "config/mfaChallenge",
                      newMfaSettings
                    );
                  }}
                />
              </FormControl>
              {mfaChallengeEnabled && (
                <FormControl
                  display="flex"
                  flexDirection="row-reverse"
                  justifyContent={"flex-end"}
                  gap={"8px"}
                  alignItems={"center"}
                >
                  <FormLabel htmlFor="mfaRequired">
                    Require MFA for all Users
                  </FormLabel>
                  <Switch
                    id="mfaRequired"
                    isChecked={mfaChallengeRequired}
                    onChange={(e) => {
                      updateAuthGroupInfo(
                        "config/mfaChallenge/required",
                        e.target.checked
                      );
                      setMfaChallengeRequired(e.target.checked);
                    }}
                  />
                </FormControl>
              )}
            </VStack>
          </VStack>

          <VStack alignItems={"stretch"} spacing={"3"}>
            <Text variant="contentsubheader">
              Token Time to Live Configurations
            </Text>

            <FormControl>
              <FormLabel htmlFor="ttlAccessToken">Access Tokens</FormLabel>
              <FormInput
                id="ttlAccessToken"
                value={ttlAccessToken}
                onChange={(e) => {
                  setTtlAccessToken(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/accessToken", e.target.value);
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlAuthorizationCode">
                Authorization Code
              </FormLabel>
              <FormInput
                id="ttlAuthorizationCode"
                value={ttlAuthorizationCode}
                onChange={(e) => {
                  setTtlAuthorizationCode(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo(
                    "config/ttl/authorizationCode",
                    e.target.value
                  );
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlClientCredentials">
                Client Credentials
              </FormLabel>
              <FormInput
                id="ttlClientCredentials"
                value={ttlClientCredentials}
                onChange={(e) => {
                  setTtlClientCredentials(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo(
                    "config/ttl/clientCredentials",
                    e.target.value
                  );
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlDeviceCode">Device Code</FormLabel>
              <FormInput
                id="ttlDeviceCode"
                value={ttlDeviceCode}
                onChange={(e) => {
                  setTtlDeviceCode(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/deviceCode", e.target.value);
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlIdToken">ID Token</FormLabel>
              <FormInput
                id="ttlIdToken"
                value={ttlIdToken}
                onChange={(e) => {
                  setTtlIdToken(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/idToken", e.target.value);
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlrefreshToken">Refresh Token</FormLabel>
              <FormInput
                id="ttlrefreshToken"
                value={ttlRefreshToken}
                onChange={(e) => {
                  setTtlRefreshToken(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo(
                    "config/ttl/refreshToken",
                    e.target.value
                  );
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlInteraction">Interaction</FormLabel>
              <FormInput
                id="ttlInteraction"
                value={ttlInteration}
                onChange={(e) => {
                  setTtlInteraction(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/interaction", e.target.value);
                }}
                width="80px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlSession">Session</FormLabel>
              <FormInput
                id="ttlSession"
                value={ttlSession}
                onChange={(e) => {
                  setTtlSession(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/session", e.target.value);
                }}
                width="100px"
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="ttlGrant">Grant</FormLabel>
              <FormInput
                id="ttlGrant"
                value={ttlGrant}
                onChange={(e) => {
                  setTtlGrant(e.target.value);
                }}
                onBlur={(e) => {
                  updateAuthGroupInfo("config/ttl/grant", e.target.value);
                }}
                width="100px"
              />
            </FormControl>
          </VStack>

          <VStack alignItems={"stretch"} spacing={"3"}>
            <Text variant="contentsubheader">Additional Settings</Text>
            <FormControl>
              <FormLabel htmlFor="newScope">Scopes</FormLabel>
              <HStack>
                <FormInput
                  id="newScope"
                  placeholder=""
                  value={newScope}
                  onChange={(e) => {
                    setNewScope(e.target.value);
                  }}
                />

                <Button
                  variant="primaryBlue"
                  size="md"
                  onClick={() => {
                    const newScopes = [...scopes, newScope];
                    setScopes(newScopes);
                    updateAuthGroupInfo("/config/scopes", newScopes);
                    setNewScope("");
                  }}
                >
                  Add
                </Button>
              </HStack>
              {scopes &&
                scopes.map((scope, index) => (
                  <Tag
                    key={index}
                    size={"lg"}
                    borderRadius="20px"
                    bgColor="blue.600"
                    color="white.900"
                    fontSize="14px"
                    m="8px 4px"
                  >
                    <TagLabel>{scope}</TagLabel>
                    <Button
                      variant="unstyled"
                      size="sm"
                      minWidth="auto"
                      onClick={() => {
                        const newScopes = scopes.filter((_, i) => i !== index);
                        setScopes(newScopes);
                        updateAuthGroupInfo("/config/scopes", newScopes);
                      }}
                    >
                      <TagCloseButton />
                    </Button>
                  </Tag>
                ))}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="newAcrValue">ACR Values</FormLabel>
              <HStack>
                <FormInput
                  id="newAcrValue"
                  placeholder=""
                  value={newAcrValue}
                  onChange={(e) => {
                    setNewAcrValue(e.target.value);
                  }}
                />

                <Button
                  variant="primaryBlue"
                  size="md"
                  onClick={() => {
                    const newAcrValues = [...acrValues, newAcrValue];
                    setAcrValues(newAcrValues);
                    updateAuthGroupInfo("/config/acrValues", newAcrValues);
                    setNewAcrValue("");
                  }}
                >
                  Add
                </Button>
              </HStack>
              {acrValues &&
                acrValues.map((acrValue, index) => (
                  <Tag
                    key={index}
                    size={"lg"}
                    borderRadius="20px"
                    bgColor="blue.600"
                    color="white.900"
                    fontSize="14px"
                    m="8px 4px"
                  >
                    <TagLabel>{acrValue}</TagLabel>
                    <Button
                      variant="unstyled"
                      size="sm"
                      minWidth="auto"
                      onClick={() => {
                        const newAcrValues = acrValues.filter(
                          (_, i) => i !== index
                        );
                        setAcrValues(newAcrValues);
                        updateAuthGroupInfo("/config/acrValues", newAcrValues);
                      }}
                    >
                      <TagCloseButton />
                    </Button>
                  </Tag>
                ))}
            </FormControl>
          </VStack>

          <VStack alignItems={"stretch"} spacing={"3"}>
            <Text variant="contentsubheader">Danger Zone</Text>
            <FormControl>
              <FormLabel htmlFor="customProviderNewScope">OIDC Keys</FormLabel>
              <Flex>
                <Button
                  variant="primaryRed"
                  size="md"
                  onClick={async () => {
                    try {
                      const authUser = await getUser();
                      const res = await axios.post(
                        `${process.env.REACT_APP_API_ROOT}/api/${authGroup.id}/operations`,
                        {
                          operation: "rotate_keys",
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${authUser.access_token}`,
                          },
                        }
                      );
                      console.log(res);
                      history.push("/logout");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Generate new keys
                </Button>
              </Flex>
              <FormLabel marginTop="8px" htmlFor="customProviderNewScope">
                This will force every user on the platform across all
                organizations to have to login again.
              </FormLabel>
            </FormControl>
          </VStack>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "lookAndFeel" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Branding</Text>
          <VStack alignItems={"stretch"} spacing="3">
            <Text variant="contentsubheader">Custom Domains</Text>
            {!customDomains ||
              (customDomains.length < 1 && (
                <>
                  {" "}
                  <FormControl>
                    <FormHelperText color="black.500">
                      Please note, you will need to prove ownership of the
                      domain before any settings take effect. Follow the on
                      screen instructions.
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="yourDomain">Your Domain</FormLabel>
                    <FormInput
                      border={0}
                      value={customDomain}
                      id="yourDomain"
                      placeholder="example.com"
                      onChange={(e) => setCustomDomain(e.target.value)}
                    />
                  </FormControl>
                  <HStack>
                    <FormLabel htmlFor="customDomainsUi" minW="110px">
                      UI Alias
                    </FormLabel>
                    <FormInput
                      border={0}
                      value={customDomainUi}
                      id="customDomainsUi"
                      placeholder=""
                      onChange={(e) => setCustomDomainUi(e.target.value)}
                    />
                    <FormLabel minW="200px">. {customDomain}</FormLabel>
                  </HStack>
                  <HStack>
                    <FormLabel htmlFor="customDomainsOidc" minW="110px">
                      OIDC Screen Alias
                    </FormLabel>
                    <FormInput
                      border={0}
                      value={customDomainOidc}
                      id="customDomainsOidc"
                      placeholder=""
                      onChange={(e) => setCustomDomainOidc(e.target.value)}
                    />
                    <FormLabel minW="200px">. {customDomain}</FormLabel>
                  </HStack>
                  <Button
                    variant="primaryBlue"
                    onClick={handleCustomDomainContinueClicked}
                    enabled={
                      customDomain && (customDomainOidc || customDomainUi)
                    }
                  >
                    Continue
                  </Button>
                </>
              ))}
            {customDomains && customDomains.length > 0 && (
              <>
                <HStack>
                  <FormLabel>Status</FormLabel>
                  <FormLabel>{customDomainStatus}</FormLabel>
                  {customDomainStatus !== "Verified" && (
                    <IconButton
                      aria-label="Copy Auth Group ID"
                      icon={<RepeatIcon />}
                      onClick={handleRefreshCustomDomainClicked}
                      size="md"
                      variant="primaryBlue"
                      marginLeft="12px"
                    />
                  )}
                </HStack>
                {customDomainStatus !== "Verified" && (
                  <FormControl>
                    <FormHelperText>
                      To prove ownership and enable the DNS aliases, please add
                      the following records to your DNS entries in your
                      registrar. services. Once ownership is confirmed, it can
                      take up to 48 hours for the connection to be made and DNS
                      update to propagate. If ownership is not confirmed in 14
                      days, this record will automatically be removed and you
                      will need to start over.
                    </FormHelperText>
                  </FormControl>
                )}
              </>
            )}
            {customDomains &&
              customDomains.length > 0 &&
              customDomains.map((cd, index) => (
                <CustomDomainInput
                  key={cd.id}
                  id={cd.id}
                  target={cd.target}
                  domain={cd.domain}
                  txt={cd.txt}
                  index={index}
                />
              ))}
            {customDomains && customDomains.length > 0 && (
              <Button
                variant="primaryRed"
                onClick={handleCustomDomainDeleteAll}
                alignSelf={"end"}
              >
                Delete
              </Button>
            )}
          </VStack>

          <VStack alignItems={"stretch"} spacing="3">
            <Text variant="contentsubheader">UI Customization</Text>
            <FormControl>
              <FormLabel htmlFor="loginSplashScreen">
                Signin Splash Screen
              </FormLabel>
              <FormInput
                border={0}
                value={loginSplashScreen}
                id="loginSplashScreen"
                placeholder="Link to image"
                onChange={(e) => setLoginSplashScreen(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo(
                    "config/ui/skin/splashImage",
                    e.target.value
                  )
                }
              />
            </FormControl>
            {loginSplashScreen && (
              <Center>
                <Image
                  boxSize="200px"
                  width="auto"
                  objectFit="cover"
                  src={loginSplashScreen}
                  m="12px 0"
                  borderRadius="4px"
                  border="4px solid"
                  borderColor={"white.900"}
                  shadow={"default.500"}
                />
              </Center>
            )}
            <FormControl>
              <FormLabel htmlFor="eosBackgroundImage">
                EOS Background Image
              </FormLabel>
              <FormInput
                border={0}
                value={eosBackgroundImage}
                id="eosBackgroundImage"
                placeholder="Link to image"
                onChange={(e) => setEosBackgroundImage(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo(
                    "config/ui/skin/backgroundImage",
                    e.target.value
                  )
                }
              />
            </FormControl>
            {eosBackgroundImage && (
              <Center>
                <Image
                  boxSize="200px"
                  width="auto"
                  objectFit="cover"
                  src={eosBackgroundImage}
                  m="12px 0"
                  borderRadius="4px"
                  border="4px solid"
                  borderColor="white.1000"
                  shadow={"default.500"}
                />
              </Center>
            )}
            <FormControl>
              <FormLabel htmlFor="logo">Logo</FormLabel>
              <FormInput
                border={0}
                value={logo}
                id="logo"
                placeholder="Link to image"
                onChange={(e) => setLogo(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("config/ui/skin/logo", e.target.value)
                }
              />
            </FormControl>
            {logo && (
              <Center>
                <Image
                  boxSize="200px"
                  width="auto"
                  objectFit="cover"
                  src={logo}
                  m="12px 0"
                  borderRadius="4px"
                  border="4px solid"
                  borderColor="white.1000"
                  shadow={"default.500"}
                />
              </Center>
            )}
            <FormControl>
              <FormLabel htmlFor="logo">Favicon</FormLabel>
              <FormInput
                border={0}
                value={favicon}
                id="logo"
                placeholder="Link to image"
                onChange={(e) => setFavicon(e.target.value)}
                onBlur={(e) =>
                  updateAuthGroupInfo("config/ui/skin/favicon", e.target.value)
                }
              />
            </FormControl>
            {favicon && (
              <Center>
                <Image
                  boxSize="200px"
                  width="auto"
                  objectFit="cover"
                  src={favicon}
                  m="12px 0"
                  borderRadius="4px"
                  border="4px solid"
                  borderColor="white.1000"
                  shadow={"default.500"}
                />
              </Center>
            )}
            <FormControl
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
            >
              <FormLabel htmlFor="loginOrientation">
                Signin Screen Orientation
              </FormLabel>
              <HStack
                {...radioGroup}
                marginTop="4px"
                spacing={"2"}
                border={"1px solid"}
                borderColor="black.300"
                padding="4px"
                borderRadius="20px"
              >
                {["left", "middle", "right"].map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <LoginOrientationCard key={value} {...radio}>
                      {value.charAt(0).toUpperCase() + value.slice(1)}
                    </LoginOrientationCard>
                  );
                })}
              </HStack>
            </FormControl>
          </VStack>
          {/*<FormControl>
            <FormLabel htmlFor="aliasDnsUi">DNS Alias (coming soon)</FormLabel>
            <FormInput
              border={0}
              value={authGroupData.aliasDnsUi}
              isReadOnly
              id="aliasDnsUi"
              placeholder=""
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="aliasDnsOIDC">
              DNS Alias OIDC (coming soon)
            </FormLabel>
            <FormInput
              border={0}
              value={authGroupData.aliasDnsOIDC}
              isReadOnly
              id="aliasDnsOIDC"
              placeholder=""
            />
            </FormControl>*/}
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "federation" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
          borderRadius={selectedFederationProvider ? "0" : "0"}
        >
          <Text variant="titleLarge">Federation Configuration</Text>
          <VStack alignItems={"stretch"} spacing="3">
            {(authGroupData.config?.federate?.oidc ||
              authGroupData.config?.federate?.oauth2) && (
                <Text variant="contentsubheader">Existing Configurations</Text>
              )}

            {authGroupData.config?.federate?.oidc &&
              authGroupData.config?.federate?.oidc.map((option) => {
                return (
                  <>
                    <WindowColumnSecondaryButton
                      key={`${option.provider}.${option.name}`}
                      active={
                        option.provider === selectedFederationProvider?.provider
                      }
                      onClick={() => {
                        console.log(option);
                        setClientId(
                          authGroupData.config?.federate?.oidc?.find(
                            (o) => o.provider === option.provider
                          )?.client_id ?? ""
                        );
                        setClientSecret(
                          authGroupData.config?.federate?.oidc?.find(
                            (o) => o.provider === option.provider
                          )?.client_secret ?? ""
                        );
                        if (option.provider === "custom") {
                          setCustomProviderState(option);
                        }
                        const type = "oidc";
                        let provider = option;
                        provider.type = type;
                        provider.exists = true;
                        setSelectedFederationProvider(provider);
                        setNewCustomProvider(false);
                        setShowClientSecret(false);
                      }}
                    >
                      {option.name}
                    </WindowColumnSecondaryButton>
                  </>
                );
              })}

            {authGroupData.config?.federate?.oauth2 &&
              authGroupData.config?.federate?.oauth2.map((option) => {
                return (
                  <>
                    <WindowColumnSecondaryButton
                      key={`${option.provider}.${option.name}`}
                      active={
                        option.provider === selectedFederationProvider?.provider
                      }
                      onClick={() => {
                        console.log(option);
                        setClientId(
                          authGroupData.config?.federate?.oauth2?.find(
                            (o) => o.provider === option.provider
                          )?.client_id ?? ""
                        );
                        setClientSecret(
                          authGroupData.config?.federate?.oauth2?.find(
                            (o) => o.provider === option.provider
                          )?.client_secret ?? ""
                        );
                        if (option.provider === "custom") {
                          setCustomProviderState(option);
                        }
                        const type = "oauth2";
                        let provider = option;
                        provider.type = type;
                        provider.exists = true;
                        setSelectedFederationProvider(provider);
                        setNewCustomProvider(false);
                        setShowClientSecret(false);
                      }}
                    >
                      {option.name}
                    </WindowColumnSecondaryButton>
                  </>
                );
              })}
          </VStack>
          <VStack alignItems={"stretch"} spacing="3">
            <Text variant="contentsubheader">New Configurations</Text>
            <Text variant="bodySmall">
              To create a new configuration, select a type below.
            </Text>

            {PROVIDERS.map((provider) => {
              if (
                !(
                  (provider.type === "oidc" &&
                    authGroupData.config?.federate?.oidc?.find(
                      (o) => o.provider === provider.provider
                    )) ||
                  (provider.type === "oauth2" &&
                    authGroupData.config?.federate?.oauth2?.find(
                      (o) => o.provider === provider.provider
                    ))
                )
              ) {
                return (
                  <WindowColumnSecondaryButton
                    key={provider.provider}
                    active={
                      provider.provider === selectedFederationProvider?.provider
                    }
                    onClick={() => {
                      setClientId("");
                      setClientSecret("");
                      setSelectedFederationProvider(provider);
                      setNewCustomProvider(false);
                    }}
                  >
                    {provider.label}
                  </WindowColumnSecondaryButton>
                );
              } else return <></>;
            })}
            <WindowColumnSecondaryButton
              active={selectedFederationProvider?.name === "custom"}
              onClick={() => {
                setClientId("");
                setClientSecret("");
                setSelectedFederationProvider(null);
                setNewCustomProvider(true);
              }}
            >
              {"Add Custom Provider"}
            </WindowColumnSecondaryButton>
          </VStack>
        </VStack>
      )}
      {primaryColumnSelectedOption.name === "federation" &&
        selectedFederationProvider &&
        selectedFederationProvider.provider !== "custom" && (
          <VStack
            alignItems={"stretch"}
            layerStyle="windowsubcontentarea"
            spacing="6"
            flex={"1"}
            borderRadius={"0 4px 4px 0"}
          >
            <Text variant="contentheader">
              {selectedFederationProvider.name ??
                selectedFederationProvider.label}
              +
            </Text>

            <FormControl>
              <FormLabel htmlFor="clientId">Client ID</FormLabel>
              <FormInput
                border={0}
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                onBlur={() => updateFederation()}
                id="clientId"
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="clientSecret">Client Secret</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  onBlur={() => updateFederation()}
                  id="clientSecret"
                  placeholder=""
                  type={showClientSecret ? "text" : "password"}
                />
                <IconButton
                  aria-label="Show Privakey Secret"
                  icon={<ViewIcon />}
                  onClick={() => {
                    setShowClientSecret(!showClientSecret);
                  }}
                  variant="primaryBlue"
                  size="md"
                />
              </HStack>
            </FormControl>
            {selectedFederationProvider.type &&
              selectedFederationProvider.name &&
              selectedFederationProvider.provider && (
                <VStack alignItems={"flex-start"} spacing={"0"}>
                  <HStack spacing={"1"}>
                    <Text variant="labelSmall">Redirect URL is</Text>
                    <IconButton
                      aria-label="Copy Redirect URL"
                      icon={<CopyIcon />}
                      onClick={() => {
                        console.log("setting redirecturl to...");
                        setRedirectUrl(
                          `${process.env.REACT_APP_API_ROOT}/${authGroupData.id
                          }/interaction/callback/${selectedFederationProvider.type
                          }/${selectedFederationProvider.provider.toLowerCase()}/${selectedFederationProvider.name
                            .replace(/ /g, "_")
                            .toLowerCase()}`
                        );
                        onCopyRedirectUrl();
                      }}
                      size="xs"
                      variant="copyButton"
                    />
                    <Text
                      variant="labelSmall"
                      cursor={"default"}
                      userSelect={"all"}
                    >
                      {hasCopiedRedirectUri && " Copied!"}
                    </Text>
                  </HStack>
                  <Text
                    variant="bodySmall"
                    wordBreak={"break-word"}
                    cursor={"default"}
                    userSelect={"all"}
                  >
                    {" "}
                    {`${process.env.REACT_APP_API_ROOT}/${authGroupData.id
                      }/interaction/callback/${selectedFederationProvider.type
                      }/${selectedFederationProvider.provider.toLowerCase()}/${selectedFederationProvider.name
                        .replace(/ /g, "_")
                        .toLowerCase()}`}
                  </Text>
                </VStack>
              )}
            {selectedFederationProvider.exists && (
              <FormControl>
                <Flex>
                  <Button
                    size="sm"
                    variant="primaryRed"
                    onClick={async () => {
                      try {
                        let index;
                        if (selectedFederationProvider.type === "oidc") {
                          index =
                            authGroupData.config?.federate?.oidc?.findIndex(
                              (o) =>
                                o.provider ===
                                selectedFederationProvider.provider
                            );
                        } else if (
                          selectedFederationProvider.type === "oauth2"
                        ) {
                          index =
                            authGroupData.config?.federate?.oauth2?.findIndex(
                              (o) =>
                                o.provider ===
                                selectedFederationProvider.provider
                            );
                        }

                        console.log(selectedFederationProvider);
                        console.log(index);
                        console.log(authGroupData);

                        let op, path;
                        if (index > -1) {
                          op = "remove";
                          path = `/config/federate/${selectedFederationProvider.type}/${index}`;
                          const res = await updateAuthGroup(
                            authGroupData.id,
                            op,
                            path,
                            null
                          );
                          setAuthGroupData(res.data.data);
                          result(res.data.data);
                          setSelectedFederationProvider(null);
                          setNewCustomProvider(true);
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Flex>
                <FormLabel marginTop={"8px"} htmlFor="customProviderNewScope">
                  This will affect any services configured to use this Provider.
                  We recommend disabling this provider from all services that
                  use it.
                </FormLabel>
              </FormControl>
            )}
          </VStack>
        )}
      {primaryColumnSelectedOption.name === "federation" &&
        newCustomProvider && (
          <VStack
            alignItems={"stretch"}
            layerStyle="windowsubcontentarea"
            spacing="6"
            flex={"1"}
            borderRadius={"0 4px 4px 0"}
          >
            <NewCustomProvider
              parentType={NewCustomProviderParentType.AuthGroup}
              parentId={authGroupData.id}
              result={(authGroup) => {
                setNewCustomProvider(false);
                setSelectedFederationProvider(null);
                setAuthGroupData(authGroup);
              }}
              title="Add Custom Provider"
            />
          </VStack>
        )}
      {primaryColumnSelectedOption.name === "federation" &&
        selectedFederationProvider &&
        selectedFederationProvider.provider === "custom" && (
          <VStack
            alignItems={"stretch"}
            layerStyle="windowsubcontentarea"
            spacing="6"
            flex={"1"}
            borderRadius={"0 4px 4px 0"}
          >
            <Text>{selectedFederationProvider.name}</Text>

            <FormControl>
              <FormLabel htmlFor="customProviderName">Name</FormLabel>
              <FormInput
                border={0}
                value={customProviderName}
                onChange={(e) => setCustomProviderName(e.target.value)}
                onBlur={() => updateFederation()}
                id="customProviderName"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="customProviderButtonText">
                Button Text
              </FormLabel>
              <FormInput
                border={0}
                value={customProviderButtonText}
                onChange={(e) => setCustomProviderButtonText(e.target.value)}
                onBlur={() => updateFederation()}
                id="customProviderButtonText"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="clientId">Client ID</FormLabel>
              <FormInput
                border={0}
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                onBlur={() => updateFederation()}
                id="clientId"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="clientSecret">Client Secret</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  onBlur={() => updateFederation()}
                  id="clientSecret"
                  placeholder=""
                  type={showClientSecret ? "text" : "password"}
                />
                <IconButton
                  aria-label="Show Privakey Secret"
                  icon={<ViewIcon />}
                  onClick={() => {
                    setShowClientSecret(!showClientSecret);
                  }}
                  ml={2}
                  variant="primaryBlue"
                  size="md"
                />
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="customProviderNewScope">Scopes</FormLabel>
              <HStack>
                <FormInput
                  id="customProviderNewScope"
                  placeholder=""
                  value={customProviderNewScope}
                  onChange={(e) => {
                    setCustomProviderNewScope(e.target.value);
                  }}
                />

                <Button
                  variant="primaryBlue"
                  size={"md"}
                  onClick={() => {
                    const newScopes = [
                      ...customProviderScopes,
                      customProviderNewScope,
                    ];
                    setCustomProviderScopes(newScopes);
                    updateFederation();
                    setCustomProviderNewScope("");
                  }}
                >
                  Add
                </Button>
              </HStack>
              {customProviderScopes &&
                customProviderScopes.map((scope, index) => (
                  <Tag
                    key={index}
                    size={"lg"}
                    borderRadius="20px"
                    bgColor="#3D426A"
                    color="white.1000"
                    fontSize="14px"
                    m="8px 4px"
                  >
                    <TagLabel>{scope}</TagLabel>
                    <Button
                      variant="unstyled"
                      size="sm"
                      minWidth="auto"
                      onClick={() => {
                        const newScopes = scopes.filter((_, i) => i !== index);
                        setCustomProviderNewScope(newScopes);
                        updateFederation();
                      }}
                    >
                      <TagCloseButton />
                    </Button>
                  </Tag>
                ))}
              <Text textAlign="left" pt="5">
                Your Redirect URL is{" "}
                {`${process.env.REACT_APP_API_ROOT}/${authGroupData.id
                  }/interaction/callback/${selectedFederationProvider.type
                  }/${selectedFederationProvider.provider.toLowerCase()}/${selectedFederationProvider.name
                    .replace(/ /g, "_")
                    .toLowerCase()}`}
                <IconButton
                  aria-label="Copy Client Secret"
                  icon={<CopyIcon />}
                  onClick={() => {
                    console.log("setting redirecturl to...");
                    setRedirectUrl(
                      `${process.env.REACT_APP_API_ROOT}/${authGroupData.id
                      }/interaction/callback/${selectedFederationProvider.type
                      }/${selectedFederationProvider.provider.toLowerCase()}/${selectedFederationProvider.name
                        .replace(/ /g, "_")
                        .toLowerCase()}`
                    );
                    onCopyRedirectUrl();
                  }}
                  size="sm"
                  variant="primaryBlue"
                />
                {hasCopiedRedirectUri && " Copied!"}
              </Text>
            </FormControl>
            {selectedFederationProvider.exists && (
              <FormControl>
                <Flex>
                  <Button
                    size="md"
                    colorScheme="red"
                    variant="outline"
                    onClick={async () => {
                      try {
                        let index;
                        if (selectedFederationProvider.type === "oidc") {
                          index =
                            authGroupData.config?.federate?.oidc?.findIndex(
                              (o) =>
                                o.provider ===
                                selectedFederationProvider.provider
                            );
                        } else if (
                          selectedFederationProvider.type === "oauth2"
                        ) {
                          index =
                            authGroupData.config?.federate?.oauth2?.findIndex(
                              (o) =>
                                o.provider ===
                                selectedFederationProvider.provider
                            );
                        }

                        console.log(selectedFederationProvider);
                        console.log(index);
                        console.log(authGroupData);

                        let op, path;
                        if (index > -1) {
                          op = "remove";
                          path = `/config/federate/${selectedFederationProvider.type}/${index}`;
                          const res = await updateAuthGroup(
                            authGroupData.id,
                            op,
                            path,
                            null
                          );
                          setAuthGroupData(res.data.data);
                          result(res.data.data);
                          setSelectedFederationProvider(null);
                          setNewCustomProvider(true);
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Flex>
                <FormLabel htmlFor="customProviderNewScope">
                  This will affect any services configured to use this Provider.
                  We recommend disabling this provider from all services that
                  use it.
                </FormLabel>
              </FormControl>
            )}
          </VStack>
        )}

      {primaryColumnSelectedOption.name === "solutions" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">Solutions</Text>
          <VStack alignItems={"stretch"} spacing={"3"}>
            {globalSolutions.map((sol) => {
              const enabled = solutions.find((s) => s.globalId === sol.id);
              return (
                <HStack>
                  key={sol.id}
                  {enabled ? (
                    <CheckCircleIcon color="blue.500" />
                  ) : (
                    <CloseIcon color="black.600" />
                  )}
                  <Text variant={"titleSmall"}>{sol.name}</Text>
                  {!enabled && (
                    <Button
                      variant={"primaryBlue"}
                      size={"sm"}
                      onClick={() => handleEnableSolutionClicked(sol.id)}
                    >
                      Add
                    </Button>
                  )}
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      )}

      {primaryColumnSelectedOption.name === "update" && (
        <VStack
          alignItems={"stretch"}
          layerStyle="windowcontentarea"
          spacing={"0"}
          gap={"24px"}
        >
          <Text variant="titleLarge">System Update</Text>
          <FormControl>
            <Flex>
              <Center>
                <Button
                  variant="primaryBlue"
                  size="sm"
                  onClick={async () => {
                    const authUser = await getUser();
                    axios
                      .get(
                        `${process.env.REACT_APP_API_ROOT}/api/${authGroup.id}/checkforupdates`,
                        {
                          headers: {
                            Authorization: `Bearer ${authUser.access_token}`,
                          },
                        }
                      )
                      .then((res) => {
                        console.log(res);
                        setUpdate(res.data.data);
                      });
                  }}
                >
                  Check for updates
                </Button>
              </Center>
            </Flex>
          </FormControl>

          {update && update.updateRequired && (
            <>
              <FormControl>
                <Flex>
                  <Center>
                    <Text pr="5">Update available</Text>
                    <Button
                      size={"sm"}
                      variant="primaryBlue"
                      onClick={async () => {
                        const authUser = await getUser();
                        axios
                          .put(
                            `${process.env.REACT_APP_API_ROOT}/api/${authGroup.id}/updatecore`,
                            undefined,
                            {
                              headers: {
                                Authorization: `Bearer ${authUser.access_token}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res);
                            setUpdate(res.data.data);
                            setUpdated(true);
                          });
                      }}
                    >
                      Update
                    </Button>
                    {updated && <Text pr="5">Updated!</Text>}
                  </Center>
                </Flex>
              </FormControl>
            </>
          )}
          {update && !update.updateRequired && (
            <VStack
              background={"white.900"}
              border={"1px solid"}
              borderColor={"black.300"}
              padding={"16px"}
              borderRadius={"4px"}
              spacing={"2"}
            >
              <CheckCircleIcon boxSize={"8"} color="teal.400" />
              <Text variant="titleMedium">You have the latest version.</Text>
              <Text variant="bodyMedium">
                There are currently no updates available.
              </Text>
            </VStack>
          )}
        </VStack>
      )}
    </Flex>
  );
};
