import { Box, useRadio } from "@chakra-ui/react";

export const LoginOrientationCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="20px"
        boxShadow="none"
        fontSize={"13px"}
        fontWeight={"600"}
        transition={"all 100ms ease-in-out"}
        _hover={{
          background: "blue.300",
        }}
        _checked={{
          bg: "blue.600",
          color: "white.900",
          borderRadius: "12px",
          _hover: {
            bg: "blue.600",
          }
        }}
        _focus={{
          boxShadow: "outline",
        }}
        padding="1px 10px 2px 10px"
      >
        {props.children}
      </Box>
    </Box>
  );
};
