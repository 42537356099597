import React, { useContext, useEffect } from "react";

import {
  VStack,
  Flex,
  Text,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  useClipboard,
  Switch,
  FormHelperText,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Skeleton,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import { AuthContext } from "../../providers/authProvider";
import { CopyIcon } from "@chakra-ui/icons";
import { FormInput } from "../FormInput";

export const MobileProfile = ({ group, agMfaEnabled, display }) => {
  const {
    getUserMe,
    updateUser,
    getMyNotifications,
    getMyProfileRequests,
    getOrganizationsAccess,
    accountPanic,
    getRecoveryCodes,
    validateAccount,
  } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [account, setAccount] = React.useState(null);
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [acceptingProfileRequests, setAcceptingProfileRequests] =
    React.useState(true);
  const [sendProfileRequestsToMyDevice, setSendProfileRequestsToMyDevice] =
    React.useState(false);
  const [mfaEnabled, setMfaEnabled] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordMessage, setPasswordMessage] = React.useState("");
  const { onCopy: onCopyUserId } = useClipboard(account?.id);
  const {
    isOpen: isOpenRecoveryCodes,
    onOpen: onOpenRecoveryCodes,
    onClose: onCloseRecoveryCodes,
  } = useDisclosure();
  const [recoveryCodes, setRecoveryCodes] = React.useState([]);
  const { onCopy: onCopyRecoveryCodes } = useClipboard(
    recoveryCodes.join("\n")
  );

  async function updateUserInfo(key, value, overrideOp = null) {
    try {
      const res = await updateUser(account, key, value, overrideOp);
      if (res.data.data) {
        setAccount(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }

    if (key === "password") {
      setPassword("");
      setPasswordMessage("Successfully updated password.");
    }
  }

  useEffect(() => {
    setLoading(true);

    getUserMe()
      .then((res) => {
        setAccount(res.data.data);
        setUsername(res.data.data.username);
        setPhone(res.data.data.phone.txt);
        setEmail(res.data.data.email);
        setMfaEnabled(res.data.data.mfa?.enabled);
        setSendProfileRequestsToMyDevice(
          res.data.data.myNotifications?.profileRequests
        );

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => { };
  }, [
    group,
    getUserMe,
    getMyNotifications,
    getOrganizationsAccess,
    getMyProfileRequests,
    validateAccount,
  ]);


  return (
    <Flex h="100%" alignItems="stretch" overflow={"scroll"} display={display} pb="50px">
      {/* Secondary Column */}
      {loading && (
        <VStack
        alignItems={"stretch"}
        layerStyle="windowcontentarea"
        spacing={"0"}
        gap={"24px"}
        >
          <Text variant="titleLarge">Credentials</Text>
          <Skeleton width="100%" isLoaded={!loading}></Skeleton>
        </VStack>
      )}

      {!loading && (
        <VStack
        alignItems={"stretch"}
        layerStyle="windowcontentarea"
        spacing={"0"}
        gap={"24px"}
        >
          <Text variant="titleLarge">Credentials</Text>
          <VStack alignItems={"flex-start"} spacing={"3"}>
            <Text variant="contentsubheader">General</Text>
            <VStack alignItems={"flex-start"} spacing={"0"}>
              <HStack spacing={"1"}>
                <Text variant="labelSmall">ID</Text>
                <IconButton
                  aria-label="Copy Client Secret"
                  icon={<CopyIcon />}
                  onClick={onCopyUserId}
                  size="xs"
                  variant="copyButton"
                />
              </HStack>
              <Text variant="bodySmall" cursor={"default"} userSelect={"all"}>
                {account?.id}
              </Text>
            </VStack>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => updateUserInfo("email", e.target.value)}
                  id="email"
                  placeholder=""
                />
              </HStack>
              <FormHelperText>
                Changing your email address can result in a loss of access to
                organizations that have restrictions for email domain.
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="username">Username</FormLabel>
              <FormInput
                border={0}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onBlur={(e) => updateUserInfo("username", e.target.value)}
                id="username"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <FormInput
                border={0}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => updateUserInfo("phone", { txt: e.target.value })}
                id="phone"
                placeholder=""
                autoComplete="off"
              />
            </FormControl>
          </VStack>

          <VStack alignItems={"flex-start"} spacing={"3"}>
            <Text variant="contentsubheader">Danger Zone</Text>
            <FormControl>
              <FormLabel htmlFor="password">Change Password</FormLabel>
              <HStack>
                <FormInput
                  border={0}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  placeholder=""
                  type="password"
                  autoComplete="off"
                />
                <Button
                  variant="primaryBlue"
                  size="md"
                  onClick={() => {
                    updateUserInfo("password", password, "replace");
                  }}
                >
                  Save
                </Button>
              </HStack>
              {passwordMessage && <Text fontSize={12}>{passwordMessage}</Text>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="recoveryCodes">Recovery Codes</FormLabel>
              <Button
                variant="primaryBlue"
                size="sm"
                onClick={async () => {
                  try {
                    const res = await getRecoveryCodes();
                    setRecoveryCodes(res.data.data);
                    console.log(isOpenRecoveryCodes);
                    onOpenRecoveryCodes();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Get Recovery Codes
              </Button>
            </FormControl>

            <Modal isOpen={isOpenRecoveryCodes} onClose={onCloseRecoveryCodes}>
              <ModalOverlay />
              <ModalContent overflow={"hidden"}>
                <ModalHeader
                  backgroundColor={"blue.600"}
                  borderBottom="1px solid"
                  borderBottomColor={"blue.700"}
                >
                  <HStack justifyContent={"space-between"} spacing={"0"}>
                    <Text variant="titleSmall" color="white.900">
                      Recovery Codes
                    </Text>
                    <Button
                      variant="unstyledWhite"
                      size={"xs"}
                      height={"13px"}
                      padding={"0"}
                      onClick={onCloseRecoveryCodes}
                    >
                      Close
                    </Button>
                  </HStack>
                </ModalHeader>
                <ModalBody background={"white.900"} paddingTop={"20px"}>
                  <Text variant={"bodyMedium"}>
                    You can use these recovery code in the event that your
                    account is locked.
                  </Text>
                  <SimpleGrid
                    columns={2}
                    spacing={2}
                    mt={"4"}
                    mb={"2"}
                    padding={"20px"}
                    borderRadius={"8px"}
                    background={"black.100"}
                    border={"1px solid"}
                    borderColor={"black.200"}
                  >
                    {recoveryCodes.map((code) => {
                      return (
                        <Box key={code}>
                          <Text variant="labelSmall">{code}</Text>
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </ModalBody>

                <ModalFooter justifyContent="flex-start">
                  <Button
                    variant="primaryBlue"
                    mr={3}
                    onClick={onCopyRecoveryCodes}
                  >
                    Copy
                  </Button>
                  <Button variant="primaryBlack" onClick={onCloseRecoveryCodes}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <FormControl>
              <FormLabel htmlFor="panic">Lock Your Account</FormLabel>
              <Button
                variant="primaryRed"
                size="sm"
                onClick={async () => {
                  await accountPanic();
                  window.location.replace("/logout");
                }}
              >
                Lock
              </Button>
              <FormHelperText>
                If you have noticed unusual activity, you can immediately lock
                your account. <br />
                This action requires your recovery codes to undo. <br />
                If you have not generated recovery codes, you will need to
                contact an administrator for assistance.
              </FormHelperText>
            </FormControl>
          </VStack>
          <>
            <Text variant="contentsubheader">MFA</Text>
            <FormControl>
              {mfaEnabled ? (
                <FormControl display="flex" flexDirection="column">
                  <FormLabel htmlFor="mfaEnabled">MFA Enabled</FormLabel>
                  <Switch
                    marginTop="2px"
                    id="mfaEnabled"
                    isChecked={mfaEnabled}
                    onChange={(e) => {
                      updateUserInfo("mfa/enabled", e.target.checked);
                      setMfaEnabled(e.target.checked);
                    }}
                  />
                </FormControl>
              ) : (
                <>
                  <FormLabel htmlFor="panic">
                    Multifactor Authentication
                  </FormLabel>
                  <Button
                    variant="primaryBlue"
                    size="md"
                    onClick={() => {
                      const url = `${process.env.REACT_APP_API_ROOT}/${group}/recover-mfa`;
                      window.open(url);
                    }}
                  >
                    Enable MFA
                  </Button>
                </>
              )}
            </FormControl>
            <Text variant="contentsubheader">Profile Requests</Text>
            <FormControl display="flex" flexDirection="column">
              <FormLabel htmlFor="acceptingProfileRequests">
                Accept profile requests
              </FormLabel>
              <Switch
                marginTop="2px"
                id="acceptingProfileRequests"
                isChecked={acceptingProfileRequests}
                onChange={(e) => {
                  updateUserInfo("acceptingProfileRequests", e.target.checked);
                  setAcceptingProfileRequests(e.target.checked);
                }}
              />
            </FormControl>
            {mfaEnabled && (
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="sendProfileRequestsToMyDevice">
                  Send profile requests to my device
                </FormLabel>
                <Switch
                  id="sendProfileRequestsToMyDevice"
                  isChecked={sendProfileRequestsToMyDevice}
                  onChange={async (e) => {
                    try {
                      setSendProfileRequestsToMyDevice(e.target.checked);

                      if (!account.myNotifications) {
                        await updateUserInfo("myNotifications", {
                          profileRequests: true,
                        });
                      } else {
                        updateUserInfo(
                          "myNotifications/profileRequests",
                          e.target.value
                        );
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                />
              </FormControl>
            )}
          </>
        </VStack>
      )}
      {/*primaryColumnSelectedOption.name === "profile" && (
        <VStack
              alignItems={"stretch"}
              layerStyle="windowcontentarea"
              spacing={"0"}
              gap={"24px"}
        >
          <Text variant="titleLarge">Secure Profile</Text>
          <SecureProfile />
        </VStack>
      )*/}
      {/*primaryColumnSelectedOption.name === "notifications" && (
        <VStack
              alignItems={"stretch"}
              layerStyle="windowcontentarea"
              spacing={"0"}
              gap={"24px"}
      >
          <Text variant="titleLarge">Requests &amp; Notifications</Text>
          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification) => {
              return (
                <WindowColumnSecondaryButton
                  key={notification.id}
                  onClick={() => {
                    handleNotificationChange(notification, "notification");
                  }}
                  active={selectedNotification?.id === notification.id}
                >
                  {notification.subject}
                </WindowColumnSecondaryButton>
              );
            })}
          {profileRequests &&
            profileRequests.length > 0 &&
            profileRequests.map((profileRequest) => {
              return (
                <WindowColumnSecondaryButton
                  key={profileRequest.id}
                  onClick={() => {
                    handleNotificationChange(profileRequest, "profileRequest");
                  }}
                  active={selectedNotification?.id === profileRequest.id}
                >
                  {profileRequest.requestDetails || "Profile Request"}
                </WindowColumnSecondaryButton>
              );
            })}
          {(!notifications || notifications.length < 1) && (
            <Text>You do not have any notifications</Text>
          )}
        </VStack>
      )*/}
      {/*primaryColumnSelectedOption.name === "notifications" &&
        selectedNotification &&
        selectedNotificationType &&
        selectedNotificationType === "notification" && (
          <VStack
            spacing="2"
            align="left"
            overflowY={"scroll"}
            bg="white.800"
            padding="28px 32px"
            borderRight="1px solid"
            borderRightColor="black.200"
            flex="3"
          >
            <Text fontSize="20px">{selectedNotification.subject}</Text>
            <Text fontSize="14px">{selectedNotification.message}</Text>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Delivered</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {dayjs(selectedNotification.createdAt).format(
                  "MMM DD YYYY hh:mm a"
                )}
              </Text>
            </FormControl>

            {<Flex>
                <Button
                  onClick={onOpen}
                  bg="#D77740"
                  borderRadius="6px"
                  color="#fff"
                  padding="4px 12px 6px 10px"
                  fontSize="14px"
                  mt={2}
                >
                  View Terms
                </Button>
                  </Flex>}
          </VStack>
        )*/}
      {/*primaryColumnSelectedOption.name === "notifications" &&
        selectedNotification &&
        selectedNotificationType === "profileRequest" && (
          <VStack
            spacing="2"
            align="left"
            overflowY={"scroll"}
            bg="white.800"
            padding="28px 32px"
            borderRight="1px solid"
            borderRightColor="black.200"
            flex="3"
          >
            <Text fontSize="20px">
              {selectedNotification.requestDetails || "Profile Request"}
            </Text>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Requested By</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {selectedNotification.requestingEmail}
              </Text>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Requested At</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {dayjs(selectedNotification.createdAt).format(
                  "MMM DD YYYY hh:mm a"
                )}
              </Text>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">Status</FormLabel>
              <Text
                border="1px solid"
                borderColor="black.100"
                backgroundColor="black.50"
                padding="6px 12px"
                borderRadius="20px"
                color="black.900"
              >
                {selectedNotification.state}
              </Text>
            </FormControl>
            {selectedNotification.state === "new" && (
              <HStack>
                <Flex>
                  <Button
                    onClick={() => {
                      handleProfileRequestAction("approved");
                    }}
                    variant="primaryBlue"
                    mt={2}
                  >
                    Approve
                  </Button>
                </Flex>
                <Flex>
                  <Button
                    onClick={() => {
                      handleProfileRequestAction("denied");
                    }}
                    variant="primaryBlue"
                    mt={2}
                  >
                    Deny
                  </Button>
                </Flex>
              </HStack>
            )}
          </VStack>
        )*/}
      {/*primaryColumnSelectedOption.name === "orgs" && (
        <VStack spacing="2" alignItems="flex-start" layerStyle="windowsubmenu">
          <Text variant="windowsubmenuheader">My Organizations</Text>

          <VStack align="left">
            {orgs &&
              orgs.length > 0 &&
              orgs
                .sort((a, b) =>
                  a.organization.name > b.organization.name ? 1 : -1
                )
                .map((org) => {
                  return (
                    <WindowColumnSecondaryButton
                      key={org.organization._id}
                      onClick={() => {
                        handleOrgChange(org);
                      }}
                      active={
                        selectedOrg.organization?._id === org.organization._id
                      }
                    >
                      {org.organization.name}
                    </WindowColumnSecondaryButton>
                  );
                })}
          </VStack>
        </VStack>
      )*/}
      {/*primaryColumnSelectedOption.name === "orgs" && selectedOrg && (
        <VStack
              alignItems={"stretch"}
              layerStyle="windowcontentarea"
              spacing={"0"}
              gap={"24px"}
        >
          <Text variant="contentheader">{selectedOrg.organization?.name}</Text>
          <Text fontSize="14px">{selectedOrg.organization?.description}</Text>
          <Text variant="contentsubheader">Domains</Text>
          <Text fontSize="14px">
            {selectedOrg.domains && selectedOrg.domains.length > 0
              ? selectedOrg.domains.map((domain) => {
                return <Text key={domain.id}>{domain.name}</Text>;
              })
              : "You do not have access to any domains in this organization"}
          </Text>
          <Text variant="contentsubheader">Terms</Text>
          {!selectedOrg.terms?.termsOfAccess && (
            <Text fontSize="14px">
              There are no terms of access in this organization.
            </Text>
          )}
          {selectedOrg.terms?.termsOfAccess && (
            <>
              {selectedOrg.terms?.termsVersion && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb="0">Latest Version</FormLabel>
                  <Text
                border="1px solid"
                borderColor="black.100"
                    backgroundColor="black.50"
                    padding="6px 12px"
                    borderRadius="20px"
                    color="black.900"
                  >
                    {selectedOrg.terms?.termsVersion}
                  </Text>
                </FormControl>
              )}
              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Delivered</FormLabel>
                <Text
                border="1px solid"
                borderColor="black.100"
                  backgroundColor="black.50"
                  padding="6px 12px"
                  borderRadius="20px"
                  color="black.900"
                >
                  {dayjs(selectedOrg.terms?.termsDeliveredOn).format(
                    "MMM DD YYYY hh:mm a"
                  )}
                </Text>
              </FormControl>
              {selectedOrg.terms?.termsAcceptedOn && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel mb="0">Accepted</FormLabel>
                  <Text
                border="1px solid"
                borderColor="black.100"
                    backgroundColor="black.50"
                    padding="6px 12px"
                    borderRadius="20px"
                    color="black.900"
                  >
                    {dayjs(selectedOrg.terms?.termsAcceptedOn).format(
                      "MMM DD YYYY hh:mm a"
                    )}
                  </Text>
                </FormControl>
              )}
              <Flex>
                <Button
                  onClick={onOpen}
                  bg="#D77740"
                  borderRadius="6px"
                  color="#fff"
                  padding="4px 12px 6px 10px"
                  fontSize="14px"
                  mt={2}
                >
                  View Terms
                </Button>
              </Flex>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Terms of Access for {selectedOrg.organization?.name}
                  </ModalHeader>
                  <ModalCloseButton />
                  {/(((https?:\/\/)|(www\.))[^\s]+)/g.test(
                    selectedOrg.terms?.termsOfAccess
                  ) ? (
                    <ModalBody>
                      <Link href={selectedOrg.terms?.termsOfAccess} isExternal>
                        View Terms <ExternalLinkIcon mx="2px" />
                      </Link>
                    </ModalBody>
                  ) : (
                    <ModalBody>{selectedOrg.terms?.termsOfAccess}</ModalBody>
                  )}

                  <ModalFooter justifyContent="flex-start">
                    <Button
                      colorScheme="black"
                      variant="outline"
                      mr={3}
                      onClick={() => onTermsAction("accept")}
                    >
                      Accept
                    </Button>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      onClick={() => onTermsAction("decline")}
                    >
                      Decline
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          )}
        </VStack>
      )*/}
      {/*primaryColumnSelectedOption.name === "debug" && (
        <VStack
              alignItems={"stretch"}
              layerStyle="windowcontentarea"
              spacing={"0"}
              gap={"24px"}
        >
          <Text variant="contentheader">Info</Text>
          {access && (
            <>
              <Text>Auth Group {access.authGroup.id}</Text>
              <Text>Member? {access.authGroup.member ? "Yes" : "No"}</Text>
              <Text>Owner? {access.authGroup.owner ? "Yes" : "No"}</Text>
              <Text>Permissions</Text>
              {access.authGroup.memberPermissions.map((permission) => {
                return <Text key={permission}>{permission}</Text>;
              })}
            </>
          )}
        </VStack>
      )*/}
    </Flex>
  );
};
