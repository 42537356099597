import {
  Button,
  Container,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../providers/authProvider";
import { useCSVReader } from "react-papaparse";
import React from "react";

interface NewUserProps {
  result: any;
}

/*

  File needs to contain the data in the following format: 
  [
    {
      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "username": "string",
      "email": "string",
      "phone": {
        "txt": "string"
      }
    }
  ]

*/

export const ImportUsers = ({ result }: NewUserProps) => {
  const { createUsers } = useContext(AuthContext);
  const { CSVReader } = useCSVReader();
  const [usersToAdd, setUsersToAdd] = React.useState<any>([]);
  const [response, setResponse] = React.useState<any>(null);

  const importUsers = async () => {
    try {
      const res = await createUsers(usersToAdd);
      console.log(res);
      setResponse(res.data.data);
      result(res.data.data?.success);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxW={"2xl"}>
      <CSVReader
        onUploadAccepted={(results: any) => {
          console.log("---------------------------");
          console.log(results);
          console.log("---------------------------");
          setUsersToAdd(results.data);
        }}
        config={{ header: true }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
        }: any) => (
          <>
            <InputGroup size="md">
              <InputLeftElement width="4.5rem">
                <Button size="sm" {...getRootProps()} variant="primaryBlue">
                  Browse
                </Button>
              </InputLeftElement>
              <Input type="text" value={acceptedFile && acceptedFile.name} />
              {acceptedFile && (
                <InputRightElement width="4.5rem">
                  <Button size="sm" {...getRemoveFileProps()}>
                    Remove
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
            <ProgressBar />
            {usersToAdd && usersToAdd.length > 0 && (
              <>
                <HStack>
                  <Text>Found {usersToAdd.length} users.</Text>
                  <Button
                    onClick={() => {
                      importUsers();
                    }}
                  >
                    Import
                  </Button>
                </HStack>
              </>
            )}
            {response && (
              <>
                <Text>Attempted: {response.attempted}</Text>
                <Text>Failed: {response.failed.length}</Text>
                <Text>Suceeded: {response.success.length}</Text>
                <Text>{response.warning}</Text>
              </>
            )}
          </>
        )}
      </CSVReader>
    </Container>
  );
};
