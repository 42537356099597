import {
  FormControl,
  FormLabel,
  Text,
  IconButton,
  useClipboard,
  Stack,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tag,
  TagLeftIcon,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { CopyIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/authProvider";
import React from "react";

interface PermissionProps {
  productId: string;
  permissionData: any;
  showDelete: boolean;
  handleDelete: any;
}

export const Permission = ({
  productId,
  permissionData,
  showDelete,
  handleDelete,
}: PermissionProps) => {
  const { onCopy: onCopyId } = useClipboard(permissionData?.id);
  const { onCopy: onCopyCodedId } = useClipboard(permissionData?.codedId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getPermissionRoleReferences } = useContext(AuthContext);
  const [roleReferences, setRoleReferences] = React.useState([] as any);

  useEffect(() => {
    getPermissionRoleReferences(productId, permissionData?.id)
      .then((res) => {
        setRoleReferences(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getPermissionRoleReferences, permissionData?.id, productId]);

  const deleteClicked = () => {
    handleDelete(permissionData.id);
    onClose();
  };

  return (
    <>
      <HStack alignItems="center" spacing={"2"}>
        <HStack spacing={"1"}>
          <Text variant="contentheader" textTransform={"capitalize"} m={"0"}>
            {`${permissionData?.action}`}
          </Text>
          {permissionData.ownershipRequired ?
            <Text variant="contentheader">your</Text> :
            <Text variant="contentheader">any</Text>
          }
          <Text variant="contentheader" textTransform={"capitalize"}>
            {`${permissionData?.target}`}
          </Text>
        </HStack>
        {permissionData.ownershipRequired && (
          <Tag>
            <TagLeftIcon as={WarningIcon} color="blue.300"></TagLeftIcon>
            Applies if agent owns "{permissionData?.target}"
          </Tag>
        )}
      </HStack>
      <FormControl>
        <FormLabel>{`For the resource target "${permissionData?.target}", allow the calling agent to "${permissionData?.action}" ${permissionData?.ownershipRequired ? `when the agent owns the resource "${permissionData?.target}"` : `any "${permissionData?.target}"`
          }.`}</FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="description">Description</FormLabel>
        <Text>{permissionData?.description}</Text>
      </FormControl>
      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="name">
            ID
          </FormLabel>
          <HStack>
            <Text>{permissionData.id}</Text>
            <IconButton
              aria-label="Copy ID"
              icon={<CopyIcon />}
              onClick={onCopyId}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>

      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="name">
            Coded ID
          </FormLabel>
          <HStack>
            <Text>{permissionData?.coded}</Text>
            <IconButton
              aria-label="Copy Coded ID"
              icon={<CopyIcon />}
              onClick={onCopyCodedId}
              size="sm"
              variant="primaryBlue"
            />
          </HStack>
        </VStack>
      </FormControl>

      <FormControl>
        <VStack alignItems="flex-start" spacing={"0"}>
          <FormLabel htmlFor="created">Created on</FormLabel>
          <Text>{permissionData?.createdAt}</Text>
        </VStack>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="tags">Tags</FormLabel>
        {permissionData?.tags?.map((tag: string) => (
          <Tag key={tag} me="2">
            {tag}
          </Tag>
        ))}
      </FormControl>
      <Stack alignItems={"flex-start"}>
        {showDelete && (
          <Button
            leftIcon={<DeleteIcon />}
            variant="primaryRed"
            onClick={onOpen}
          >
            Delete Permission
          </Button>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Permission</ModalHeader>
          <ModalCloseButton />
          {roleReferences && roleReferences.totalReferences > 0 && (
            <ModalBody>
              {roleReferences.totalReferences} role
              {roleReferences.totalReferences > 1 ? "s are" : " is"} using this
              permission. If you delete this, you may impact user access of the
              associated products. We highly recommend you disassociate this
              permission from these roles first, and ensure access will not be
              interrupted before proceeding.
            </ModalBody>
          )}
          {roleReferences && roleReferences.totalReferences === 0 && (
            <ModalBody>
              There are no roles using this permission. Deleting this will not
              interrupt your users' access.
            </ModalBody>
          )}

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primaryRed" onClick={deleteClicked}>
              Delete Permission
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
