import React, { useContext } from "react";
import { JsonPointer } from "json-ptr";

import {
  FormControl,
  FormLabel,
  Text,
  Switch,
  HStack,
  Tag,
  TagLabel,
  Button,
  TagRightIcon,
  IconButton,
  useClipboard,
  VStack,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FormInput } from "../FormInput";
import { FormSelect } from "../FormSelect";
import { AuthContext } from "../../providers/authProvider";
import { CloseIcon, CopyIcon, DeleteIcon } from "@chakra-ui/icons";

interface OrganizationProps {
  org: any;
  orgAdmin?: boolean;
  result: any;
  orgDeleted: any;
}

export const Organization = ({
  org,
  orgAdmin = false,
  result,
  orgDeleted,
}: OrganizationProps) => {
  const ORG_TYPES = ["external", "customer", "internal", "other"];
  const [organization, setOrganization] = React.useState(org);
  const [name, setName] = React.useState(org.name);
  const [externalId, setExternalId] = React.useState(org.externalId);
  const [description, setDescription] = React.useState(org.description);
  const [type, setType] = React.useState(org.type);
  const [contactName, setContactName] = React.useState(org.contactName);
  const [contactEmail, setContactEmail] = React.useState(org.contactEmail);
  const [contactPhone, setContactPhone] = React.useState(org.contactPhone);
  const [contactAddress, setContactAddress] = React.useState(
    org.contactAddress
  );
  const [accessRequired, setAccessRequired] = React.useState(
    org.access.required
  );
  const [accessTerms, setAccessTerms] = React.useState(org.access.terms);
  const [accessTermsVersion, setAccessTermsVersion] = React.useState(
    org.access.termsVersion
  );
  const [restrictEmailDomains, setRestrictEmailDomains] = React.useState(
    org.restrictEmailDomains
  );
  const [emailDomains, setEmailDomains] = React.useState(org.emailDomains);
  const [newEmailDomain, setNewEmailDomain] = React.useState("");
  const [profileNotifications, setProfileNotifications] = React.useState(
    org.profileNotifications
  );
  const { onCopy } = useClipboard(org?.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = React.useState<any>(null);

  const { updateOrganization, deleteOrganization } = useContext(AuthContext);

  const updateOrg = async (key: string, value: any) => {
    try {
      const exists = JsonPointer.get(organization, key);
      const op = exists ? "replace" : "add";

      const updatedOrg = await updateOrganization(org.id, op, key, value);
      setOrganization(updatedOrg.data.data);
      result(updatedOrg.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await deleteOrganization(org.id);
      console.log(res);
      orgDeleted(org.id);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data);
      onOpen();
    }
  };

  return (
    <>
      <VStack
      alignItems={"stretch"}
      spacing={"4"}
      >
        <Text variant="contentheader">Administration</Text>
        <Text variant="contentsubheader">General</Text>
        <FormControl>
          <VStack alignItems="flex-start" spacing={"0"}>
            <FormLabel htmlFor="name">ID</FormLabel>
            <HStack>
              <Text>{org.id}</Text>
              <IconButton
                aria-label="Copy ID"
                icon={<CopyIcon />}
                onClick={onCopy}
                size="sm"
                variant="primaryBlue"
              />
            </HStack>
          </VStack>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="externalId">External ID</FormLabel>
          <FormInput
            value={externalId}
            onChange={(e) => setExternalId(e.target.value)}
            onBlur={(e) => updateOrg("/externalId", e.target.value)}
            id="externalId"
            placeholder=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="name">Name</FormLabel>
          <FormInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) => updateOrg("/name", e.target.value)}
            id="name"
            placeholder=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">Description</FormLabel>
          <FormInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onBlur={(e) => updateOrg("/description", e.target.value)}
            id="description"
            placeholder=""
          />
        </FormControl>
        {!orgAdmin && (
          <FormControl>
            <FormLabel>Type</FormLabel>
            <FormSelect
              id="type"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                updateOrg("/type", e.target.value);
              }}
            >
              <option value="">Select type</option>
              {ORG_TYPES &&
                ORG_TYPES.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  );
                })}
            </FormSelect>
          </FormControl>
        )}

        <Text variant="contentsubheader">Contact</Text>
        <FormControl>
          <FormLabel htmlFor="contactName">Name</FormLabel>
          <FormInput
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            onBlur={(e) => updateOrg("/contactName", e.target.value)}
            id="contactName"
            placeholder=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="contactAddress">Address</FormLabel>
          <FormInput
            value={contactAddress}
            onChange={(e) => setContactAddress(e.target.value)}
            onBlur={(e) => updateOrg("/contactAddress", e.target.value)}
            id="contactAddress"
            placeholder=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="contactPhone">Phone</FormLabel>
          <FormInput
            value={contactPhone}
            onChange={(e) => setContactPhone(e.target.value)}
            onBlur={(e) => updateOrg("/contactPhone", e.target.value)}
            id="contactPhone"
            placeholder=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="contactEmail">Email</FormLabel>
          <FormInput
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            onBlur={(e) => updateOrg("/contactEmail", e.target.value)}
            id="contactEmail"
            placeholder=""
          />
        </FormControl>

        <Text variant="contentsubheader">Security</Text>

        <FormControl
          display="flex"
          flexDirection="row-reverse"
          justifyContent={"flex-end"}
          gap={"8px"}
          alignItems={"center"}
        >
          <FormLabel htmlFor="restrictEmailDomains">
            Restrict Email Domains
          </FormLabel>
          <Switch
            isChecked={restrictEmailDomains}
            onChange={(e) => {
              console.log(e.target.checked);
              updateOrg("/restrictEmailDomains", e.target.checked);
              setRestrictEmailDomains(e.target.checked);
            }}
            id="restrictEmailDomains"
            placeholder=""
          />
        </FormControl>
        {restrictEmailDomains && (
          <FormControl>
            <FormLabel color="black.900" htmlFor="newEmailDomain">
              Email Domains
            </FormLabel>
            <HStack>
              <FormInput
                id="newEmailDomain"
                placeholder="example.com"
                value={newEmailDomain}
                onChange={(e) => {
                  setNewEmailDomain(e.target.value);
                }}
              />

              <Button
                variant={"primaryBlue"}
                onClick={() => {
                  const newDomains = [...emailDomains, newEmailDomain];
                  setEmailDomains(newDomains);
                  updateOrg("/emailDomains", newDomains);
                  setNewEmailDomain("");
                }}
              >
                Add
              </Button>
            </HStack>
            {emailDomains &&
              emailDomains.map((domain: any, index: number) => (
                <Tag
                  key={index}
                  size={"lg"}
                  borderRadius="6px"
                  bgColor="#878CB8"
                  m={2}
                >
                  <TagLabel>{domain}</TagLabel>
                  <Button
                    variant="unstyled"
                    size="sm"
                    onClick={() => {
                      const newDomains = emailDomains.filter(
                        (_: any, i: number) => i !== index
                      );
                      setEmailDomains(newDomains);
                      updateOrg("/emailDomains", newDomains);
                    }}
                  >
                    <TagRightIcon boxSize="12px" as={CloseIcon} />
                  </Button>
                </Tag>
              ))}
          </FormControl>
        )}

        <FormControl
          display="flex"
          flexDirection="row-reverse"
          justifyContent={"flex-end"}
          gap={"8px"}
          alignItems={"center"}
        >
          <FormLabel htmlFor="accessRequired">
            Require access terms acceptance
          </FormLabel>
          <Switch
            isChecked={accessRequired}
            onChange={(e) => {
              console.log(e.target.checked);
              updateOrg("/access/required", e.target.checked);
              setAccessRequired(e.target.checked);
            }}
            id="accessRequired"
            placeholder=""
          />
        </FormControl>
        {accessRequired && (
          <>
            <FormControl>
              <FormLabel htmlFor="accessTerms">Terms</FormLabel>
              <FormInput
                value={accessTerms}
                onChange={(e) => setAccessTerms(e.target.value)}
                onBlur={(e) => updateOrg("/access/terms", e.target.value)}
                id="accessTerms"
                placeholder=""
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="accessTermsVersion">Terms Version</FormLabel>
              <FormInput
                value={accessTermsVersion}
                onChange={(e) => setAccessTermsVersion(e.target.value)}
                onBlur={(e) => updateOrg("/access/termsVersion", e.target.value)}
                id="accessTermsVersion"
                placeholder=""
              />
            </FormControl>
          </>
        )}

        <FormControl
          display="flex"
          flexDirection="row-reverse"
          justifyContent={"flex-end"}
          gap={"8px"}
          alignItems={"center"}
        >
          <FormLabel htmlFor="profileNotifications">
            Notify Accounts of Profile Changes
          </FormLabel>
          <Switch
            isChecked={profileNotifications}
            onChange={(e) => {
              console.log(e.target.checked);
              updateOrg("/profileNotifications", e.target.checked);
              setProfileNotifications(e.target.checked);
            }}
            id="profileNotifications"
            placeholder=""
          />
        </FormControl>
        {!org.core && (
          <Stack alignItems={"flex-start"}>
            <Button
              leftIcon={<DeleteIcon />}
              variant="primaryRed"
              flex="none"
              onClick={handleDelete}
            >
              Delete Organization
            </Button>
          </Stack>
        )}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{error?.message}</Text>
            {error?.data &&
              error.data.map((error: any) => (
                <Text key={error.id}>{error.name}</Text>
              ))}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
