import { BoxProps, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { Stream, Subject } from "../../services/natty-lite/Models";
import dayjs from "dayjs";
import { CalendarIcon } from "@chakra-ui/icons";

export interface CardProps extends BoxProps {
  type: "subject" | "stream";
  subject?: Subject;
  stream?: Stream;
  active?: boolean;
}

export const Card = ({
  subject,
  stream,
  active,
  type,
  ...props
}: CardProps) => {
  const textColor = active ? "white.900" : "black.900";

  return (
    <VStack
      {...props}
      cursor="pointer"
      flex={"1"}
      alignItems={"stretch"}
      spacing={"4"}
      justifyContent={"space-between"}
      borderRadius="4px"
      minWidth={"200px"}
      padding="20px"
      background={active ? "black.900" : "white.900"}
      backgroundImage={"linear-gradient(180deg,rgba(255,255,255,.1),rgba(255,255,255,0))"}
      shadow={"default.500"}
      transition={"all 100ms ease-in-out"}
      _hover={{
        shadow: 'default.700',
      }}
    >
      <VStack
        alignItems={"stretch"}
        spacing={"0"}
      >
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
          marginBottom={"12px"}
        >
          {stream && (
            <HStack>
              <CalendarIcon color={textColor} boxSize={"3"} />
              <Text
                variant="bodySmall"
                lineHeight={"1em"}
                color={active ? "white.800" : "black.800"}
              >
                {dayjs(stream.createdAt).format(" MMM DD YYYY hh:mm a")}
              </Text>
            </HStack>
          )}
          {subject && subject.stream && (
            <HStack>
              <Image
                src={
                  active
                    ? "/images/stream-card-logo-light.svg"
                    : "/images/stream-card-logo.svg"
                }
              />
              <Text
                color={active ? "white.800" : "black.800"}
                variant="bodySmall"
                lineHeight={"1em"}
              >
                {subject.stream}
              </Text>
            </HStack>
          )}
          {stream && stream.subjects && (
            <HStack>
              <Image
                src={
                  active
                    ? "/images/stream-card-subject-light.svg"
                    : "/images/stream-card-subject.svg"
                }
              />

              <Text
                color={active ? "white.800" : "black.800"}
                variant="bodySmall"
              >
                {stream.subjects.length}{" "}
                {stream.subjects.length > 1 || stream.subjects.length === 0
                  ? "Subjects"
                  : "Subject"}
              </Text>
            </HStack>
          )}
        </VStack>
        <VStack
          alignItems={"stretch"}
          spacing={"2"}
        >
          <Text
            color={textColor}
            variant="titleLarge"
            lineHeight={"1em"}
          >
            {subject ? subject.name : stream?.name}
          </Text>
          <Text
            color={textColor}
            variant="bodySmall"
            lineHeight={"1.4em"}
            noOfLines={3}
          >
            {subject ? subject.description : stream?.description}
          </Text>
        </VStack>
      </VStack>


      <VStack
        alignItems={"stretch"}
        spacing={"0"}
        gap={"4"}
      >
        <HStack>
          {subject && subject.service && (
            <>
              <Image
                src={
                  active
                    ? "/images/stream-card-request-reply-light.svg"
                    : "/images/stream-card-request-reply.svg"
                }
                w="20px"
                h="20px"
              />
              <Text
                color={textColor}
                variant={"bodyMedium"}
              >
                Request/Reply
              </Text>
            </>
          )}
        </HStack>
        <VStack
          alignItems={"stretch"}
          spacing={"0"}
        >
          <HStack>
            {subject && (
              <>
                <Image
                  src={
                    active
                      ? "/images/stream-card-subscribers-light.svg"
                      : "/images/stream-card-subscribers.svg"
                  }
                />
                <Text
                  color={textColor}
                  variant={"bodyMedium"}
                >
                  {subject.accessRecords.sub} Subscribers
                </Text>
              </>
            )}
          </HStack>
          <HStack>
            {subject && (
              <>
                <Image
                  src={
                    active
                      ? "/images/stream-card-publishers-light.svg"
                      : "/images/stream-card-publishers.svg"
                  }
                />
                <Text
                  color={textColor}
                  variant={"bodyMedium"}
                >
                  {subject.accessRecords.pub} Publishers
                </Text>
              </>
            )}
          </HStack>
        </VStack>
        <HStack
          alignItems="flex-start"
          flexWrap={"wrap"}
          spacing={"0"}
          minHeight={"24px"}
        >
          {subject &&
            subject.tags.map((tag) => (
              <Text
                variant={"labelSmall"}
                fontWeight={"600"}
                marginRight={"4px"}
                color={active ? "orange.200" : "orange.600"}
              >
                #{tag}
              </Text>
            ))}
          {stream &&
            stream.tags.map((tag) => (
              <Text
                variant={"labelSmall"}
                fontWeight={"600"}
                color={active ? "green.200" : "green.600"}
                paddingRight={"4px"}
              >
                #{tag}
              </Text>
            ))}
        </HStack>
      </VStack>
    </VStack>
  );
};
