import React from "react";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

export interface FormInputProps extends InputProps {
  updateStatus?: string;
}

export const FormInput = (props: FormInputProps) => {
  return (
    <InputGroup>
      <Input
        {...props}
        border={"1px solid"}
        borderColor={"black.400"}
        backgroundColor={"white.800"}
        borderRadius={"4px"}
        fontWeight={"500"}
        backgroundImage={"linear-gradient(180deg,rgba(255,255,255,0),rgba(255,255,255,0.1))"}
        padding={"0 12px"}
      >
        {props.children}
      </Input>
      {props.updateStatus && (
        <InputRightElement
          children={
            props.updateStatus === "success" ? (
              <CheckIcon color="green.500" />
            ) : (
              <CloseIcon color="red.500" />
            )
          }
        />
      )}
    </InputGroup>
  );
};
