import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";
import { Callback } from "../components/auth/callback";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { PrivateRoute } from "./privateRoute";
import { SilentRenew } from "../components/auth/silentRenew";
import LoginPage from "../components/pages/loginPage";
import { EOSPage } from "../components/pages/eosPage";
import SignupPage from "../components/pages/signupPage";
import GroupPage from "../components/pages/groupPage";
import Analytics from "react-router-ga";
import { AuthContext } from "../providers/authProvider";

const CustomDomainCheck = ({ children }) => {
  const { init, signinRedirect } = useContext(AuthContext);
  const windowHostname = window.location.hostname;
  const baseHostnameUrl = new URL(process.env.REACT_APP_CLIENT_ROOT);
  const baseHostname = baseHostnameUrl.hostname;

  if (windowHostname !== baseHostname) {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/api/group-info/${windowHostname}`)
      .then((response) => {
        console.log(response);

        const { id, groupId, group, preferredHost, preferredOP } = response.data.data;

        console.log(id, groupId);
        if (response.data.data.id) {
          init(`https://${preferredOP}/${groupId}`, id, `https://${preferredHost}`, groupId, group, `https://${preferredOP}`);

          signinRedirect();
        } 
      });
  } else {
    return children;
  }
};

export const Routes = (
  <Analytics id="G-97HCN6M674" debug>
    <Switch>
      <Route exact={true} path="/login" component={LoginPage} />
      <Route exact={true} path="/signin-oidc" component={Callback} />
      <Route exact={true} path="/logout" component={Logout} />
      <Route exact={true} path="/signout-oidc" component={LogoutCallback} />
      <Route exact={true} path="/silentrenew" component={SilentRenew} />

      <PrivateRoute path="/eos" component={EOSPage} />

      <Route path="/register" component={SignupPage} />

      <Route path="/:group" component={GroupPage} />
      <CustomDomainCheck>
        <Route path="/" component={LoginPage} />
      </CustomDomainCheck>
    </Switch>
  </Analytics>
);
