/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useRef, useEffect, useCallback } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { MenuItem, MenuItemSubMenu } from "./../";

function addEL(ref, event, handler) {
  if (ref) ref.addEventListener(event, handler);
}
function remEL(ref, event, handler) {
  if (ref) ref.removeEventListener(event, handler);
}

const InnerMenu = ({ title, childrenItems, onClick }) => {
  const refSubMenuButton = useRef();
  const refSubMenuList = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const subMenuButtonEnterHandle = useCallback(() => {
    onOpen();
    // fix bug with list animation
    refSubMenuList.current.style.pointerEvents = "auto";
  }, [onOpen]);
  const subMenuButtonLeaveHandle = useCallback(() => {
    onClose();
  }, [onClose]);

  // fix bug with list animation,
  // hide it when menu closed and open only if button menu is hovered
  useEffect(() => {
    if (!isOpen) {
      refSubMenuList.current.style.pointerEvents = "none";
    } else {
    }
  }, [isOpen]);

  useEffect(() => {
    addEL(refSubMenuButton.current, "mouseenter", subMenuButtonEnterHandle);
    addEL(refSubMenuList.current, "mouseenter", subMenuButtonEnterHandle);
    addEL(refSubMenuButton.current, "mouseleave", subMenuButtonLeaveHandle);
    addEL(refSubMenuList.current, "mouseleave", subMenuButtonLeaveHandle);

    return () => {
      remEL(refSubMenuButton.current, "mouseenter", subMenuButtonEnterHandle);
      remEL(refSubMenuList.current, "mouseenter", subMenuButtonEnterHandle);
      remEL(refSubMenuButton.current, "mouseleave", subMenuButtonLeaveHandle);
      remEL(refSubMenuList.current, "mouseleave", subMenuButtonLeaveHandle);
    };
  }, [subMenuButtonEnterHandle, subMenuButtonLeaveHandle]);

  return (
    <Menu
      autoSelect={false}
      offset={[0, 0]}
      isOpen={isOpen}
      placement="right"
    >
      <MenuButton
        ref={refSubMenuButton}
        rightIcon={<ChevronRightIcon />}
        as={Button}
        w="full"
        textAlign="left"
        borderRadius="none"
        bg="none"
        zIndex={999}
        //color={"neutral.300"}
        color={"neutral.800"}
      >
        {title}
      </MenuButton>
      <MenuList
        minW={0}
        ref={refSubMenuList}
        zIndex={999}
        _hover={{ zIndex: "1000" }}
      >
        {childrenItems.map((item, i) => {
          if (typeof item === "string") {
            return <MenuItem key={item} onClick={onClick}>{item}</MenuItem>;
          }
          return <MenuItemSubMenu key={item}>{item}</MenuItemSubMenu>;
        })}
      </MenuList>
    </Menu>
  );
};

export default InnerMenu;
